import React, { useState, useEffect, useCallback } from 'react';
import { get, post } from '../helpers/apiHelper';
import ReviewModal from '../components/ReviewModal';
import FilterPanel from '../components/FilterPanel';
import TeacherCard from '../components/TeacherCard';
import { useNavigate } from 'react-router-dom';
import { provinciasESP } from '../data/provinciasESP';
import { municipiosESP } from '../data/municipiosESP';
import useAuthStore from '../store/authStore';

function TeachersList() {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableCities, setAvailableCities] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [rating, setRating] = useState('');
  const [subject, setSubject] = useState([]);
  const [level, setLevel] = useState('');
  const [price, setPrice] = useState('');
  const [sublevelsList, setSublevelsList] = useState([]);
  const [subjectsList, setSubjectsList] = useState([]);
  const [levelsList, setLevelsList] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const user = useAuthStore((state) => state.user);
  const navigate = useNavigate();

  const fetchTeachers = async (filters = {}) => {
    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams(filters).toString();
      const response = await get(`users/teachers?${queryParams}`);
      setTeachers(response.data);
    } catch (error) {
      console.error('Failed to fetch teachers:', error);
      setError('Failed to fetch teachers. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchSubjects = async () => {
    try {
      const response = await get('subjects');
      setSubjectsList(response);
      const levels = [...new Set(response.map(subject => subject.level))];
      setLevelsList(levels);
    } catch (error) {
      console.error('Failed to fetch subjects:', error);
    }
  };

  const fetchSublevels = async (level) => {
    setSublevelsList(
      subjectsList
        .filter((subject) => subject.level === level)
        .map((subject) => ({ value: subject.id, label: subject.name }))
    );
  };

  const fetchChats = useCallback(async () => {
    if (!user || !user.userId) {
      console.error('User ID is undefined');
      return;
    }

    try {
      const response = await get(`chats/user/${user.userId}`);
      setChats(response);
    } catch (error) {
      console.log(error);
      console.error('Failed to fetch chats:', error);
    }
  }, [user]);

  useEffect(() => {
    fetchSubjects();
    fetchChats();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
    } else {
      fetchTeachers();
    }
  }, [fetchChats]);

  const handleGeolocationSuccess = async (position) => {
    const { latitude, longitude } = position.coords;
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
      const address = data.address;
      const city = address.town || address.village || address.city || address.suburb || address.hamlet || address.neighbourhood || 'Unknown City';
      const province = address.province || 'Unknown Province';
  
      const normalize = (str) => str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      const foundProvince = provinciasESP.find(prov => normalize(prov.nm) === normalize(province));
      const foundCity = municipiosESP.find(mun => normalize(mun.nm) === normalize(city));
      
      if (foundProvince) {
        setSelectedProvince({ value: foundProvince.id, label: foundProvince.nm });
        const cities = municipiosESP
          .filter((municipio) => municipio.id === foundProvince.nm)
          .map((municipio) => ({ value: municipio.nm, label: municipio.nm }));
        setAvailableCities(cities);
        if (foundCity) {
          setSelectedCity({ value: foundCity.nm, label: foundCity.nm });
        }
        fetchTeachers({ city });
      }
    } catch (error) {
      console.error('Failed to fetch city from coordinates:', error);
      fetchTeachers();
    }
  };

  const handleGeolocationError = (error) => {
    fetchTeachers();
  };

  useEffect(() => {
    const filters = {};
    if (selectedCity) filters.city = selectedCity.value;
    if (rating) filters.rating = rating;
    if (subject.length > 0) filters.subjects = subject.map(sub => sub.value); // Enviar los IDs de las materias
    if (level) filters.level = level;
    if (price) filters.price = price;
    fetchTeachers(filters);
  }, [selectedCity, rating, subject, level, price]);

  const handleOpenReviewModal = (teacher) => {
    setSelectedTeacher(teacher);
  };

  const handleCloseReviewModal = () => {
    setSelectedTeacher(null);
  };

  const handleReviewSubmitted = () => {
    const filters = {};
    if (selectedCity) filters.city = selectedCity.value;
    if (rating) filters.rating = rating;
    if (subject.length > 0) filters.subjects = subject.map(sub => sub.value); // Enviar los IDs de las materias
    if (level) filters.level = level;
    if (price) filters.price = price;
    fetchTeachers(filters);
  };

  const handleStartChat = async (teacher) => {
    try {
      if (!user || !user.userId) {
        console.error('User ID is undefined');
        return;
      }
      const newChat = await post('chats', {
        user1Id: user.userId,
        user2Id: teacher.user.id
      });
      setChats([...chats, newChat]);
      setSelectedChat(newChat.id);
      setSelectedRecipient(teacher.user.id);
    } catch (error) {
      console.error('Failed to start chat:', error);
    }
  };

  const closeChat = () => {
    setSelectedChat(null);
    setSelectedRecipient(null);
  };

  const handleFilterChange = (updatedFilters) => {
    const filters = {
      city: selectedCity ? selectedCity.value : null,
      rating,
      subjects: subject.length > 0 ? subject.map(sub => sub.value) : null, // Enviar los IDs de las materias
      level,
      price,
      ...updatedFilters,
    };
    
    // Remove null or empty values
    Object.keys(filters).forEach(key => {
      if (!filters[key]) {
        delete filters[key];
      }
    });

    fetchTeachers(filters);
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white p-10">
      <h1 className="text-4xl font-bold mb-4">Lista de Profesores</h1>
      <FilterPanel
        selectedProvince={selectedProvince}
        setSelectedProvince={setSelectedProvince}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
        availableCities={availableCities}
        setAvailableCities={setAvailableCities}
        rating={rating}
        setRating={setRating}
        level={level}
        setLevel={setLevel}
        subject={subject}
        setSubject={setSubject}
        price={price}
        setPrice={setPrice}
        levelsList={levelsList}
        sublevelsList={sublevelsList}
        fetchSublevels={fetchSublevels}
        onFilterChange={handleFilterChange} // Pasar la función handleFilterChange
      />
      {teachers.length === 0 ? (
        <div className="flex items-center justify-center text-white text-xl">
          No hay profesores con las características seleccionadas.
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {teachers.map((teacher) => (
            <TeacherCard
              key={teacher.id}
              teacher={teacher}
              navigate={navigate}
              handleOpenReviewModal={handleOpenReviewModal}
              handleStartChat={handleStartChat}
            />
          ))}
        </div>
      )}
      {selectedTeacher && (
        <ReviewModal
          teacherId={selectedTeacher.id}
          isOpen={!!selectedTeacher}
          onClose={handleCloseReviewModal}
          onReviewSubmitted={handleReviewSubmitted}
        />
      )}
      {/*
      
      {chats.length > 0 && (
        <>
          <ChatList
            chats={chats}
            selectChat={(chatId, recipientId) => {
              console.log('Selected chat:', chatId, 'with recipient:', recipientId);
              setSelectedChat(chatId);
              setSelectedRecipient(recipientId);
            }}
          />
          {selectedChat && selectedRecipient && (
            <Chat
              chatId={selectedChat}
              recipientId={selectedRecipient}
              closeChat={closeChat}
            />
          )}
        </>
      )}
      */}
      
    </div>
  );
}

export default TeachersList;
