import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { provinciasESP } from '../data/provinciasESP';
import { municipiosESP } from '../data/municipiosESP';

const LocationModal = ({
  isOpen,
  onRequestClose,
  newProvince,
  setNewProvince,
  newCities,
  setNewCities,
  handleLocationSave,
}) => {
  const [availableCities, setAvailableCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    if (newProvince) {
      const cities = municipiosESP
        .filter((municipio) => municipio.id === newProvince.label)
        .map((municipio) => ({ value: municipio.nm, label: municipio.nm }));
      setAvailableCities(cities);
    } else {
      setAvailableCities([]);
    }
  }, [newProvince]);

  const handleProvinceChange = (selectedOption) => {
    setNewProvince(selectedOption);
    const cities = municipiosESP
      .filter((municipio) => municipio.id === selectedOption.label)
      .map((municipio) => ({ value: municipio.nm, label: municipio.nm }));
    setAvailableCities(cities);
    setSelectedCity(null);
    setNewCities([]); // Ensure newCities is reset when province changes
  };

  const handleCityAdd = () => {
    if (selectedCity && !newCities.some(city => city.value === selectedCity.value)) {
      setNewCities([...newCities, selectedCity]);
      setSelectedCity(null);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Agregar Ubicación"
      className="modal bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto my-20"
    >
      <h2 className="text-2xl font-bold mb-4">Agregar Nueva Ubicación</h2>
      <Select
        options={provinciasESP.map((provincia) => ({
          value: provincia.id,
          label: provincia.nm,
        }))}
        value={newProvince}
        onChange={handleProvinceChange}
        placeholder="Provincia"
        className="w-full mb-4"
      />
      {newProvince && (
        <>
          <Select
            options={availableCities}
            value={selectedCity}
            onChange={setSelectedCity}
            placeholder="Municipios"
            className="w-full mb-4"
          />
          <button
            onClick={handleCityAdd}
            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mb-4"
          >
            Agregar Ciudad
          </button>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Ciudades Seleccionadas:</label>
            <Select
              options={newCities}
              value={newCities}
              onChange={(selectedOptions) => setNewCities(selectedOptions || [])}
              placeholder="Ciudades Seleccionadas"
              isMulti
              className="w-full"
            />
          </div>
        </>
      )}
      <button
        onClick={handleLocationSave}
        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        Guardar
      </button>
    </Modal>
  );
};

export default LocationModal;
