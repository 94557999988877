import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <FontAwesomeIcon
        key={i}
        icon={faStar}
        className={i <= rating ? 'text-yellow-400' : 'text-gray-300'}
      />
    );
  }
  return stars;
};

const ProfileSummary = ({ teacher }) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/teacher-profile/edit`);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg text-black mb-6 relative">
      <button
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        onClick={handleEditClick}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <div className="flex items-center mb-4">
        {teacher.user.profileImage ? (
          <img src={teacher.user.profileImage} alt="Avatar" className="w-16 h-16 rounded-full flex-shrink-0" />
        ) : (
          <div className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0"></div>
        )}
        <div className="ml-4 flex-grow">
          <h2 className="text-2xl font-bold">{teacher.user.name}</h2>
        </div>
      </div>
      <p className="mb-2">{teacher.bio}</p>
      <p className="mb-2">
        <span className="font-semibold">Tarifa por hora:</span> €{teacher.hourly_rate}
      </p>
      <p className="mb-2">
        <span className="font-semibold">Rating:</span> {renderStars(teacher.rating)} ({teacher.reviews_count})
      </p>
      {teacher.subjects && (
        <p className="mb-2">
          <span className="font-semibold">Materias:</span> {teacher.subjects.map((subject) => subject.name).join(', ')}
        </p>
      )}
      <p className="mb-2">
        <span className="font-semibold">Ubicaciones:</span> {teacher.availability}
      </p>
    </div>
  );
};

export default ProfileSummary;
