// helpers/api/Helper
const baseUrl = process.env.REACT_APP_BASE_API_URL;

async function fetchApi(endpoint, options = {}) {
    const url = new URL(`${baseUrl}/${endpoint}`);

    const storedData = localStorage.getItem('auth-storage-repaso-pro');
    const parsedData = JSON.parse(storedData); 
  
    // Extract the token from the parsed data
    const token = parsedData?.state?.token;
    // Setup headers directly here
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    // If token exists, append it to the headers
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }

    // Consolidate all request options here
    const requestOptions = {
        ...options,
        headers,
    };

    // Include the body only if it's provided and the method isn't GET or DELETE
    if (options.body && !['GET', 'DELETE'].includes(options.method)) {
        requestOptions.body = JSON.stringify(options.body);
    }

    const response = await fetch(url, requestOptions);
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong');
    }
    return response.json();
}

export const get = (endpoint) => fetchApi(endpoint, { method: 'GET' });
export const post = (endpoint, body) => fetchApi(endpoint, { method: 'POST', body });
export const put = (endpoint, body) => fetchApi(endpoint, { method: 'PUT', body });
export const del = (endpoint, body) => fetchApi(endpoint, { method: 'DELETE', body });
