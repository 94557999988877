// src/pages/RegisterStudent.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import AvatarModal from '../components/AvatarModal';
import { post } from '../helpers/apiHelper';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function RegisterStudent() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const navigate = useNavigate();

  const avatars = Array.from({ length: 14 }, (_, i) => ({
    value: `avatar${i + 1}.webp`,
    label: `Avatar ${i + 1}`,
    url: `/avatars/avatar${i + 1}.webp`,
  }));

  const handleRegister = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      Swal.fire('Error', 'Las contraseñas no coinciden!', 'error');
      return;
    }

    const registerData = {
      name,
      email,
      password,
      country: 'España',
      role: 'student',
      profileImage: selectedAvatar,
    };

    try {
      const response = await post('auth/register', registerData);
      console.log(response);
      Swal.fire('Success', 'Registration successful! Please verify your email.', 'success');
      navigate('/');
    } catch (error) {
      console.error('Registration failed:', error);
      Swal.fire('Error', `Registration failed. ${error.response?.data?.message || error.message}`, 'error');
    }
  };

  const openAvatarModal = () => {
    setIsAvatarModalOpen(true);
  };

  const closeAvatarModal = () => {
    setIsAvatarModalOpen(false);
  };

  const selectAvatar = (avatar) => {
    setSelectedAvatar(avatar.url);
    closeAvatarModal();
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <h2 className="mt-6 text-center text-3xl font-extrabold">Crear una cuenta</h2>
        <form className="mt-8 space-y-6" onSubmit={handleRegister}>
          <input type="text" onChange={(e) => setName(e.target.value)} placeholder="Nombre" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />
          <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Correo electrónico" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />
          <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Contraseña" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />
          <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirmar Contraseña" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />
          
          {selectedAvatar && (
            <div className="flex justify-center mb-4">
              <img src={selectedAvatar} alt="Selected Avatar" className="w-24 h-24 rounded-full" />
            </div>
          )}

          <button type="button" onClick={openAvatarModal} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Escoger Avatar
          </button>
          <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Registrarse
          </button>
        </form>

        <AvatarModal
          isOpen={isAvatarModalOpen}
          onRequestClose={closeAvatarModal}
          avatars={avatars}
          onSelectAvatar={selectAvatar}
        />
      </div>
    </div>
  );
}

export default RegisterStudent;
