// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Header from './components/Header';
import Footer from './components/Footer';
import PublicRoute from './components/PublicRoute';
import TermsAndConditions from './pages/TermsAndConditions';
import CookiePolicyPage from './pages/CookiePolicyPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentResult from './pages/PaymentResult';
import Settings from './pages/Settings';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import EmailVerification from './pages/EmailVerification';
import RegisterStudent from './pages/RegisterStudent';
import RegisterTeacher from './pages/RegisterTeacher';
import TeachersList from './pages/TeachersList';
import TeacherProfilePage from './pages/TeacherProfilePage';
import TeachersDashboard from './pages/TeachersDashboard';
import EditProfilePage from './pages/EditProfilePage';
import useAuthStore from './store/authStore';
import ChatList from './components/ChatList';
import Chat from './components/Chat';

const stripePub = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePub);

function App() {
  const token = useAuthStore((state) => state.token); // Asumiendo que tienes un token en tu store
  const isAuthenticated = !!token; // Cambia esto según tu lógica de autenticación
  const [selectedChat, setSelectedChat] = React.useState(null);

  const closeChat = () => {
    setSelectedChat(null);
  };

  return (
    <Router>
      <Header />
      <main className="flex-grow">
        <Elements stripe={stripePromise}>
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register-student" element={<RegisterStudent />} />
              <Route path="/register-teacher" element={<RegisterTeacher />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/verify" element={<EmailVerification />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={['teacher']} />}>
              <Route path="/dashboard-teacher" element={<TeachersDashboard />} />
              <Route path="/teacher-profile/edit" element={<EditProfilePage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={['teacher', 'student']} />}>
              <Route path="/dashboard-student" element={<TeachersList />} />
              <Route path="/teacher/:id" element={<TeacherProfilePage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={['teacher', 'student']} />}>
              <Route path="/settings" element={<Settings />} />
            </Route>

            <Route path="/payment-result" element={<PaymentResult />} />
            <Route path="/terminos" element={<TermsAndConditions />} />
            <Route path="/politica-de-cookies" element={<CookiePolicyPage />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Elements>
        {/*
        {isAuthenticated && (
          <>
            <ChatList selectChat={setSelectedChat} />
            {selectedChat && <Chat recipientId={selectedChat} />}
          </>
        )}      
      */}
      </main>
      <Footer />
    </Router>
  );
}

export default App;
