// src/pages/EditProfilePage.jsx
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { get, post } from '../helpers/apiHelper';
import { municipiosESP } from '../data/municipiosESP';
import useAuthStore from '../store/authStore';
import LocationModal from '../components/LocationModal';
import AvatarModal from '../components/AvatarModal';

const levels = ["ESO", "Bachillerato", "CFGM", "CFGS", "Grado Universitario"];
const bachilleratoTypes = ["Científico", "Tecnológico", "Social", "Artístico", "Humanístico"];

const EditProfilePage = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [hourlyRate, setHourlyRate] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSubjectName, setNewSubjectName] = useState('');
  const [newSubjectLevel, setNewSubjectLevel] = useState('');
  const [bachilleratoType, setBachilleratoType] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [newProvince, setNewProvince] = useState(null);
  const [newCities, setNewCities] = useState([]);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const avatars = Array.from({ length: 14 }, (_, i) => ({
    value: `avatar${i + 1}.webp`,
    label: `Avatar ${i + 1}`,
    url: `/avatars/avatar${i + 1}.webp`,
  }));

  useEffect(() => {
    if (user && user.userId) {
      fetchProfileData(user.userId);
      fetchSubjects();
    }
  }, [user]);

  const fetchProfileData = async (teacherId) => {
    setLoading(true);
    try {
      const profileData = await get(`users/teachers/${teacherId}`);
      setName(profileData.user.name);
      setBio(profileData.bio);
      setHourlyRate(profileData.hourly_rate);
      setSelectedAvatar(profileData.user.profileImage);
      setSelectedSubjects(profileData.subjects.map(sub => ({ value: sub.id, label: `${sub.name} - ${sub.level}` })));
      
      const cities = municipiosESP
        .filter(municipio => profileData.availability.includes(municipio.nm))
        .map(municipio => ({ value: municipio.nm, label: municipio.nm }));
      setSelectedCities(cities);
    } catch (error) {
      console.error('Failed to fetch profile data:', error.message);
      Swal.fire('Error', 'Failed to load profile data. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchSubjects = async () => {
    try {
      const subjectsData = await get('subjects');
      setSubjects([
        { value: 'add_new', label: 'Agregar nueva materia...' },
        ...subjectsData.map(subject => ({
          value: subject.id,
          label: `${subject.name} - ${subject.level}`
        }))
      ]);
    } catch (error) {
      console.error('Failed to fetch subjects:', error);
    }
  };

  const handleSave = async () => {
    const updatedTeacher = {
      name,
      bio,
      hourly_rate: parseFloat(hourlyRate), // Ensure hourly_rate is sent as a number
      availability: selectedCities.map(city => city.value).join(', '),
      subjects: selectedSubjects.map(sub => sub.value),
      profileImage: selectedAvatar, // Añadir el avatar seleccionado
    };
    try {
      await post(`users/teachers/${user.userId}/update`, updatedTeacher);
      Swal.fire('Success', 'Profile updated successfully.', 'success');
      navigate('/teacher-profile');
    } catch (error) {
      console.error('Failed to update profile:', error.message);
      Swal.fire('Error', 'Failed to update profile. Please try again later.', 'error');
    }
  };

  const handleLocationSave = () => {
    if (!newProvince || newCities.length === 0) {
      Swal.fire('Error', 'Seleccione una provincia y al menos una ciudad.', 'error');
      return;
    }
    setSelectedCities([...selectedCities, ...newCities.filter(newCity => !selectedCities.some(city => city.value === newCity.value))]);
    setIsLocationModalOpen(false);
    setNewProvince(null);
    setNewCities([]);
  };

  const handleCityRemove = (removedCity) => {
    setSelectedCities(selectedCities.filter(city => city.value !== removedCity.value));
  };

  const handleSubjectChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'add_new')) {
      setIsModalOpen(true);
    } else {
      setSelectedSubjects(selectedOptions);
    }
  };

  const handleAddSubject = async () => {
    if (!newSubjectName || !newSubjectLevel || (newSubjectLevel === "Bachillerato" && !bachilleratoType) || (["CFGM", "CFGS", "Grado Universitario"].includes(newSubjectLevel) && !additionalInfo)) {
      Swal.fire('Error', 'Por favor, complete todos los campos.', 'error');
      return;
    }

    const levelInfo = newSubjectLevel === "Bachillerato" ? `Bachillerato ${bachilleratoType}` : newSubjectLevel === "CFGM" || newSubjectLevel === "CFGS" || newSubjectLevel === "Grado Universitario" ? `${newSubjectLevel} de ${additionalInfo}` : newSubjectLevel;
    const normalizedSubjectName = newSubjectName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    const existingSubject = subjects.find(subject =>
      subject.label.toLowerCase().includes(normalizedSubjectName) &&
      subject.label.toLowerCase().includes(levelInfo.toLowerCase())
    );

    if (existingSubject) {
      Swal.fire('Error', 'La materia ya existe.', 'error');
      return;
    }

    try {
      const newSubject = await post('subjects', { name: newSubjectName, level: levelInfo });
      const newSubjectOption = { value: newSubject.id, label: `${newSubject.name} - ${newSubject.level}` };
      setSubjects(prevSubjects => [
        ...prevSubjects.filter(subject => subject.value !== 'add_new'),
        newSubjectOption,
        { value: 'add_new', label: 'Agregar nueva materia...' }
      ]);
      setSelectedSubjects(prevSelected => [
        ...prevSelected.filter(subject => subject.value !== 'add_new'),
        newSubjectOption
      ]);
      setIsModalOpen(false);
      setNewSubjectName('');
      setNewSubjectLevel('');
      setBachilleratoType('');
      setAdditionalInfo('');
      Swal.fire('Success', 'Materia agregada exitosamente.', 'success');
    } catch (error) {
      console.error('Failed to add subject:', error);
      Swal.fire('Error', `Failed to add subject. ${error.response?.data?.message || error.message}`, 'error');
    }
  };

  const handleLevelChange = (e) => {
    const selectedLevel = e.target.value;
    setNewSubjectLevel(selectedLevel);
    setBachilleratoType('');
    setAdditionalInfo('');
  };

  const openAvatarModal = () => {
    setIsAvatarModalOpen(true);
  };

  const closeAvatarModal = () => {
    setIsAvatarModalOpen(false);
  };

  const selectAvatar = (avatar) => {
    setSelectedAvatar(avatar.url);
    closeAvatarModal();
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white p-10">
      <h1 className="text-4xl font-bold mb-4">Edita tu Perfil</h1>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <p className="text-lg text-gray-600">Cargando datos del perfil...</p>
        </div>
      ) : (
        <div className="bg-white p-6 rounded-lg shadow-lg text-black">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Nombre:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Biografía:</label>
            <textarea
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Tarifa por hora:</label>
            <input
              type="number"
              value={hourlyRate}
              onChange={(e) => setHourlyRate(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Ubicaciones:</label>
            <Select
              options={selectedCities}
              value={selectedCities}
              onChange={setSelectedCities}
              placeholder="Ubicaciones"
              isMulti
              className="w-full"
              onMenuOpen={() => setIsLocationModalOpen(true)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Materias:</label>
            <Select
              isMulti
              options={[...subjects]}
              value={selectedSubjects}
              onChange={handleSubjectChange}
              placeholder="Materias"
              className="w-full"
            />
          </div>

          {selectedAvatar && (
            <div className="flex justify-center mb-4">
              <img src={selectedAvatar} alt="Selected Avatar" className="w-24 h-24 rounded-full" />
            </div>
          )}

          <button type="button" onClick={openAvatarModal} className="group relative w-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mb-4">
            Cambiar Avatar
          </button>

          <button
            onClick={handleSave}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700"
          >
            Guardar
          </button>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Agregar Materia"
        className="modal bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto my-20"
      >
        <h2 className="text-2xl font-bold mb-4">Agregar Nueva Materia</h2>
        <input 
          type="text" 
          value={newSubjectName} 
          onChange={(e) => setNewSubjectName(e.target.value)} 
          placeholder="Nombre de la Materia" 
          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md mb-4" 
        />
        <select
          value={newSubjectLevel}
          onChange={handleLevelChange}
          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md mb-4"
        >
          <option value="" disabled>Seleccione el nivel</option>
          {levels.map(level => (
            <option key={level} value={level}>{level}</option>
          ))}
        </select>
        {newSubjectLevel === "Bachillerato" && (
          <select
            value={bachilleratoType}
            onChange={(e) => setBachilleratoType(e.target.value)}
            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md mb-4"
          >
            <option value="" disabled>Seleccione el tipo de Bachillerato</option>
            {bachilleratoTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        )}
        {(newSubjectLevel === "CFGM" || newSubjectLevel === "CFGS" || newSubjectLevel === "Grado Universitario") && (
          <input
            type="text"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            placeholder={`Especifique el ${newSubjectLevel}`}
            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md mb-4"
          />
        )}
        <button 
          onClick={handleAddSubject} 
          className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Agregar
        </button>
      </Modal>

      <LocationModal
        isOpen={isLocationModalOpen}
        onRequestClose={() => setIsLocationModalOpen(false)}
        newProvince={newProvince}
        setNewProvince={setNewProvince}
        newCities={newCities}
        setNewCities={setNewCities}
        handleLocationSave={handleLocationSave}
      />

      <AvatarModal
        isOpen={isAvatarModalOpen}
        onRequestClose={closeAvatarModal}
        avatars={avatars}
        onSelectAvatar={selectAvatar}
      />
    </div>
  );
};

export default EditProfilePage;
