import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { get } from '../helpers/apiHelper';
import Swal from 'sweetalert2';  // Importar SweetAlert2
import { CircularProgress } from '@mui/material';

function PaymentResult() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    const userId = searchParams.get('userId');
    const cancelled = searchParams.has('cancelled');

    if (!sessionId || !userId) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Missing session ID or user ID.',
        });
        navigate('/');
        return;
    }

    const verifyUrl = cancelled ? `payments/verify/cancel/${sessionId}` : `payments/verify/${userId}/${sessionId}`;
    
    const verifyPayment = async () => {
      try {
        const result = await get(verifyUrl);
        if (result.message.includes("successful") && !cancelled) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Payment Successful!',
          }).then(() => {
            navigate('/dashboard', { replace: true });
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: 'Payment Failed or Cancelled!',
          }).then(() => {
            navigate('/');
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an error processing your payment. Please try again.',
        }).then(() => {
          navigate('/');
        });
      }
    };

    verifyPayment();
  }, [navigate, searchParams]);

  // Mientras se procesa el resultado, puede mostrar un spinner o mensaje similar
  return (
    <div className="text-center mt-5">
      {loading ? (
        <CircularProgress />  // Mostrar un spinner mientras se carga
      ) : (
        <h1>Processing payment result...</h1>
      )}
    </div>
  );
}

export default PaymentResult;
