// src/components/ReviewModal.jsx
import React, { useState } from 'react';
import { post } from '../helpers/apiHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

function ReviewModal({ teacherId, isOpen, onClose, onReviewSubmitted }) {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await post('users/teachers/review', { teacherId, rating, comment });
      onReviewSubmitted();
      onClose();
    } catch (error) {
      console.error('Failed to submit review:', error);
    }
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <FontAwesomeIcon
          key={i}
          icon={faStar}
          className={i <= rating ? 'text-yellow-400 cursor-pointer' : 'text-gray-300 cursor-pointer'}
          onClick={() => setRating(i)}
        />
      );
    }
    return stars;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-black">Dejar una Reseña</h2>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-center mb-4">{renderStars()}</div>
          <textarea
            className="w-full p-2 border border-gray-300 rounded mb-4 text-black"
            rows="4"
            placeholder="Escribe tu comentario"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          ></textarea>
          <div className="flex justify-end">
            <button type="button" className="bg-gray-300 p-2 rounded mr-2" onClick={onClose}>
              Cancelar
            </button>
            <button type="submit" className="bg-blue-500 text-white p-2 rounded">
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReviewModal;
