import React from 'react';
import Modal from 'react-modal';

const AvatarModal = ({ isOpen, onRequestClose, avatars, onSelectAvatar }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Select Avatar"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="overflow-y-scroll max-h-96 p-4">
        <h2 className="text-xl mb-4">Select an Avatar</h2>
        <div className="grid grid-cols-3 gap-4">
          {avatars.map((avatar, index) => (
            <div key={index} onClick={() => onSelectAvatar(avatar)} className="cursor-pointer">
              <img src={avatar.url} alt={`Avatar ${index + 1}`} className="w-full h-auto rounded-full border-2 border-transparent hover:border-blue-500" />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AvatarModal;
