import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <FontAwesomeIcon
        key={i}
        icon={faStar}
        className={i <= rating ? 'text-yellow-400' : 'text-gray-300'}
      />
    );
  }
  return stars;
};

const ReviewsList = ({ reviews }) => {
  const [visibleReviews, setVisibleReviews] = useState(5);

  const loadMoreReviews = () => {
    setVisibleReviews((prev) => prev + 5);
  };

  return (
    <div className="mt-6 text-black">
      <h3 className="text-xl font-bold text-white">Reseñas:</h3>
      {reviews && reviews.length > 0 ? (
        <div>
          {reviews.slice(0, visibleReviews).map((review) => (
            <div key={review.id} className="bg-gray-100 p-4 rounded-lg shadow mt-4">
              <div className="flex items-center">
                <div className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"></div>
                <div className="ml-4">
                  <p className="font-semibold">{review.student && review.student.name ? review.student.name : 'Usuario Desconocido'}</p>
                  <div>{renderStars(review.rating)}</div>
                </div>
              </div>
              <p className="mt-2">{review.comment}</p>
            </div>
          ))}
          {visibleReviews < reviews.length && (
            <button
              className="mt-4 p-2 bg-blue-500 text-white rounded-lg"
              onClick={loadMoreReviews}
            >
              Cargar más reseñas
            </button>
          )}
        </div>
      ) : (
        <p>No hay reseñas disponibles.</p>
      )}
    </div>
  );
};

export default ReviewsList;
