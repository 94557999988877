import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="min-h-screen flex flex-col items-center bg-green-50 text-gray-900">
      <header className="relative w-full h-96 flex items-center justify-center bg-cover bg-center text-white" style={{ backgroundImage: 'url(/path-to-your-background-image.jpg)' }}>
        <div className="absolute inset-0 bg-gradient-to-r from-green-600 via-green-400 to-green-600"></div>
        <div className="relative z-10 text-center px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Mejora tus <span className="text-yellow-300">resultados académicos</span> con los <span className="text-yellow-300">mejores profesores</span> de repaso
          </h1>
          <div className="flex justify-center space-x-4">
            <Link to="/register-student" className="bg-white text-green-600 font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-200">
              Quiero buscar profesor
            </Link>
            <Link to="/register-teacher" className="bg-white text-green-600 font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-200">
              Soy profesor
            </Link>
          </div>
        </div>
      </header>

      <main className="flex flex-col items-center justify-center flex-1 px-4 py-12">
        <section className="w-full max-w-4xl mb-12">
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="bg-white shadow-md rounded-lg p-6 text-center mb-6 md:mb-0">
              <h3 className="text-xl font-bold mb-2">Para <span className="text-green-600">Profesores</span></h3>
              <p className="text-gray-700 mb-4">Únete a nuestra plataforma y encuentra alumnos que necesitan tus conocimientos.</p>
              <Link to="/register-teacher" className="bg-green-400 hover:bg-green-500 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 ease-in-out">
                Más información
              </Link>
            </div>

            <div className="bg-white shadow-md rounded-lg p-6 text-center">
              <h3 className="text-xl font-bold mb-2">Para <span className="text-green-600">Alumnos</span></h3>
              <p className="text-gray-700 mb-4">Encuentra al profesor ideal que te ayudará a mejorar tus resultados académicos.</p>
              <Link to="/search-teacher" className="bg-green-400 hover:bg-green-500 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 ease-in-out">
                Más información
              </Link>
            </div>
          </div>
        </section>

        <section className="w-full max-w-4xl text-center mt-12">
          <h2 className="text-2xl font-bold mb-6">Instituciones que confían en nosotros</h2>
          <div className="flex flex-wrap justify-center items-center space-x-4 space-y-4">
            {/* Aquí puedes poner los logos de las instituciones */}
            <img src="/path-to-institution-logo1.png" alt="Logo Institución 1" className="h-12" />
            <img src="/path-to-institution-logo2.png" alt="Logo Institución 2" className="h-12" />
            <img src="/path-to-institution-logo3.png" alt="Logo Institución 3" className="h-12" />
            {/* Agrega más logos según sea necesario */}
          </div>
        </section>
      </main>
    </div>
  );
}

export default Home;
