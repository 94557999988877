import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';
import AvatarModal from '../components/AvatarModal';
import { get, post } from '../helpers/apiHelper';
import { provinciasESP } from '../data/provinciasESP';
import { municipiosESP } from '../data/municipiosESP';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const levels = ["ESO", "Bachillerato", "CFGM", "CFGS", "Grado Universitario"];
const bachilleratoTypes = ["Científico", "Tecnológico", "Social", "Artístico", "Humanístico"];

function RegisterTeacher() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [bio, setBio] = useState('');
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [hourlyRate, setHourlyRate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSubjectName, setNewSubjectName] = useState('');
  const [newSubjectLevel, setNewSubjectLevel] = useState('');
  const [bachilleratoType, setBachilleratoType] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const navigate = useNavigate();

  const avatars = Array.from({ length: 14 }, (_, i) => ({
    value: `avatar${i + 1}.webp`,
    label: `Avatar ${i + 1}`,
    url: `/avatars/avatar${i + 1}.webp`,
  }));

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const subjectsData = await get('subjects');
        setSubjects([
          { value: 'add_new', label: 'Mi materia no está' },
          ...subjectsData.map(subject => ({
            value: subject.id,
            label: `${subject.name} - ${subject.level}`
          }))
        ]);
      } catch (error) {
        console.error('Failed to fetch subjects:', error);
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    if (selectedProvince) {
      const cities = municipiosESP
        .filter(municipio => municipio.id === selectedProvince.label)
        .map(municipio => ({ value: municipio.nm, label: municipio.nm }));
      setAvailableCities(cities);
    } else {
      setAvailableCities([]);
    }
  }, [selectedProvince]);

  const handleRegister = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      Swal.fire('Error', 'Las contraseñas no coinciden!', 'error');
      return;
    }

    const validSelectedSubjects = selectedSubjects.filter(subject => subject.value !== 'add_new');
    
    const registerData = {
      name,
      email,
      password,
      country: 'España',
      role: 'teacher',
      bio,
      subjects: validSelectedSubjects.map(s => s.value),
      hourly_rate: hourlyRate,
      availability: selectedCities.map(city => city.value).join(', '),
      profileImage: selectedAvatar,
    };

    try {
      const response = await post('auth/register', registerData);
      Swal.fire('Success', 'Registration successful! Please verify your email.', 'success');
      navigate('/');
    } catch (error) {
      console.log(error);
      console.error('Registration failed:', error);
      Swal.fire('Error', `Registration failed. ${error.response?.data?.message || error.message}`, 'error');
    }
  };

  const handleCityChange = (selectedOptions) => {
    setSelectedCities(selectedOptions);
  };

  const handleSubjectChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'add_new')) {
      setIsModalOpen(true);
    } else {
      setSelectedSubjects(selectedOptions);
    }
  };

  const handleProvinceChange = (selectedOption) => {
    setSelectedProvince(selectedOption);
    setSelectedCities([]);
  };

  const handleAddSubject = async () => {
    if (!newSubjectName || !newSubjectLevel || (newSubjectLevel === "Bachillerato" && !bachilleratoType) || (["CFGM", "CFGS", "Grado Universitario"].includes(newSubjectLevel) && !additionalInfo)) {
      Swal.fire('Error', 'Por favor, complete todos los campos.', 'error');
      return;
    }

    const levelInfo = newSubjectLevel === "Bachillerato" ? `Bachillerato ${bachilleratoType}` : newSubjectLevel === "CFGM" || newSubjectLevel === "CFGS" || newSubjectLevel === "Grado Universitario" ? `${newSubjectLevel} de ${additionalInfo}` : newSubjectLevel;
    const normalizedSubjectName = newSubjectName.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    const existingSubject = subjects.find(subject =>
      subject.label.toLowerCase().includes(normalizedSubjectName) &&
      subject.label.toLowerCase().includes(levelInfo.toLowerCase())
    );

    if (existingSubject) {
      Swal.fire('Error', 'La materia ya existe.', 'error');
      return;
    }

    try {
      const newSubject = await post('subjects', { name: newSubjectName, level: levelInfo });
      const newSubjectOption = { value: newSubject.id, label: `${newSubject.name} - ${newSubject.level}` };
      setSubjects(prevSubjects => [
        ...prevSubjects.filter(subject => subject.value !== 'add_new'),
        newSubjectOption,
        { value: 'add_new', label: 'Mi materia no está' }
      ]);
      setSelectedSubjects(prevSelected => [
        ...prevSelected.filter(subject => subject.value !== 'add_new'),
        newSubjectOption
      ]);
      setIsModalOpen(false);
      setNewSubjectName('');
      setNewSubjectLevel('');
      setBachilleratoType('');
      setAdditionalInfo('');
      Swal.fire('Success', 'Materia agregada exitosamente.', 'success');
    } catch (error) {
      console.error('Failed to add subject:', error);
      Swal.fire('Error', `Failed to add subject. ${error.response?.data?.message || error.message}`, 'error');
    }
  };

  const handleLevelChange = (e) => {
    const selectedLevel = e.target.value;
    setNewSubjectLevel(selectedLevel);
    setBachilleratoType('');
    setAdditionalInfo('');
  };

  const openAvatarModal = () => {
    setIsAvatarModalOpen(true);
  };

  const closeAvatarModal = () => {
    setIsAvatarModalOpen(false);
  };

  const selectAvatar = (avatar) => {
    setSelectedAvatar(avatar.url);
    closeAvatarModal();
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <h2 className="mt-6 text-center text-3xl font-extrabold">Crear una cuenta</h2>
        <form className="mt-8 space-y-6" onSubmit={handleRegister}>
          <input type="text" onChange={(e) => setName(e.target.value)} placeholder="Nombre" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />
          <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Correo electrónico" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />
          <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Contraseña" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />
          <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirmar Contraseña" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />

          <textarea
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            placeholder="Biografía"
            required
            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
          />

          <div>
            <Select
              options={provinciasESP.map(provincia => ({ value: provincia.id, label: provincia.nm }))}
              onChange={handleProvinceChange}
              placeholder="Provincia"
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
            />
          </div>
          {selectedProvince && (
            <div>
              <Select
                isMulti
                options={availableCities}
                onChange={handleCityChange}
                placeholder="Municipios"
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              />
            </div>
          )}
          <div>
            <Select
              isMulti
              options={subjects}
              onChange={handleSubjectChange}
              placeholder="Materias"
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              value={selectedSubjects.filter(subject => subject.value !== 'add_new')}
            />
          </div>
          <input type="number" onChange={(e) => setHourlyRate(e.target.value)} placeholder="Tarifa por hora" required className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" />

          {selectedAvatar && (
            <div className="flex justify-center mb-4">
              <img src={selectedAvatar} alt="Selected Avatar" className="w-24 h-24 rounded-full" />
            </div>
          )}

          <button type="button" onClick={openAvatarModal} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Escoger Avatar
          </button>
          <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Registrarse
          </button>
        </form>

        <AvatarModal
          isOpen={isAvatarModalOpen}
          onRequestClose={closeAvatarModal}
          avatars={avatars}
          onSelectAvatar={selectAvatar}
        />

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Agregar Materia"
          className="modal bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto my-20"
        >
          <h2 className="text-2xl font-bold mb-4">Agregar Nueva Materia</h2>
          <input type="text" value={newSubjectName} onChange={(e) => setNewSubjectName(e.target.value)} placeholder="Nombre de la Materia" className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md mb-4" />
          <select
            value={newSubjectLevel}
            onChange={handleLevelChange}
            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md mb-4"
          >
            <option value="" disabled>Seleccione el nivel</option>
            {levels.map(level => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
          {newSubjectLevel === "Bachillerato" && (
            <select
              value={bachilleratoType}
              onChange={(e) => setBachilleratoType(e.target.value)}
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md mb-4"
            >
              <option value="" disabled>Seleccione el tipo de Bachillerato</option>
              {bachilleratoTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          )}
          {(newSubjectLevel === "CFGM" || newSubjectLevel === "CFGS" || newSubjectLevel === "Grado Universitario") && (
            <input
              type="text"
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              placeholder={`Especifique el ${newSubjectLevel}`}
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md mb-4"
            />
          )}
          <button onClick={handleAddSubject} className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Agregar
          </button>
        </Modal>
      </div>
    </div>
  );
}

export default RegisterTeacher;
