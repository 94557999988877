import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import { get } from '../helpers/apiHelper';
import Swal from 'sweetalert2';
import ProfileSummary from '../components/ProfileSummary';
import ReviewsList from '../components/ReviewsList';

function TeachersDashboard() {
  const navigate = useNavigate();
  const [teacherProfile, setTeacherProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuthStore();

  useEffect(() => {
    if (isAuthenticated && user && user.userId) {
      fetchTeacherProfile(user.userId);
    }
  }, [isAuthenticated, user]);

  const fetchTeacherProfile = async (teacherId) => {
    setLoading(true);
    try {
      const profileData = await get(`users/teachers/${teacherId}`);
      setTeacherProfile(profileData);
    } catch (error) {
      console.error('Failed to fetch teacher profile:', error.message);
      Swal.fire('Error', 'Failed to load profile. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white p-10">
      <h1 className="text-4xl font-bold mb-4">Mi Perfil de Profesor</h1>
      <p className="text-xl mb-4">Gestiona tu perfil y ve los detalles de tus clases y reseñas.</p>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <p className="text-lg text-black">Cargando perfil...</p>
        </div>
      ) : teacherProfile ? (
        <div>
          <ProfileSummary teacher={teacherProfile} />
          <ReviewsList reviews={teacherProfile.reviews} />
        </div>
      ) : (
        <div className="flex justify-center items-center min-h-screen">
          <p className="text-lg text-black">No se encontró el perfil del profesor.</p>
        </div>
      )}
    </div>
  );
}

export default TeachersDashboard;
