import React, { useEffect } from 'react';
import useAuthStore from '../store/authStore';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const { user, logout } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
    }
  }, [user]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <header className="bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white p-4 flex justify-between items-center">
      <h1 className="text-2xl font-bold cursor-pointer">
        <Link to="/" className="hover:underline">RepasoPro</Link>
      </h1>
      {user ? (
        <div className="flex items-center space-x-4">
          <Link to="/settings" className="py-2 px-4 bg-green-500 hover:bg-green-600 rounded-full text-lg transition-colors duration-300">
            <FontAwesomeIcon icon={faCog} className="text-xl" />
          </Link>
          <button onClick={handleLogout} className="py-2 px-4 bg-red-500 hover:bg-red-600 rounded-full text-lg transition-colors duration-300">
            <FontAwesomeIcon icon={faSignOutAlt} className="text-xl" />
          </button>
        </div>
      ) : (
        <button onClick={() => navigate('/login')} className="bg-white text-green-600 font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-200">
          Iniciar sesión
        </button>
      )}
    </header>
  );
}

export default Header;
