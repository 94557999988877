import React from 'react';
import Select from 'react-select';
import { provinciasESP } from '../data/provinciasESP';
import { municipiosESP } from '../data/municipiosESP';

const FilterPanel = ({
  selectedProvince,
  setSelectedProvince,
  selectedCity,
  setSelectedCity,
  availableCities,
  setAvailableCities,
  rating,
  setRating,
  level,
  setLevel,
  subject,
  setSubject,
  price,
  setPrice,
  levelsList,
  sublevelsList,
  fetchSublevels,
  onFilterChange
}) => {
  const handleProvinceChange = (selectedOption) => {
    setSelectedProvince(selectedOption);
    setSelectedCity(null);
    const cities = municipiosESP
      .filter((municipio) => municipio.id === selectedOption.label)
      .map((municipio) => ({ value: municipio.nm, label: municipio.nm }));
    setAvailableCities(cities);
    onFilterChange({ city: null, province: selectedOption });
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    onFilterChange({ city: selectedOption });
  };

  const handleLevelChange = (selectedOption) => {
    const selectedLevel = selectedOption.value;
    setLevel(selectedLevel);
    setSubject(null);
    fetchSublevels(selectedLevel);
    onFilterChange({ level: selectedLevel, subject: null });
  };

  const handleRatingChange = (e) => {
    const selectedRating = e.target.value;
    setRating(selectedRating);
    onFilterChange({ rating: selectedRating });
  };

  const handleSubjectChange = (selectedOption) => {
    setSubject(selectedOption);
    onFilterChange({ subject: selectedOption });
  };

  const handlePriceChange = (e) => {
    const selectedPrice = e.target.value;
    setPrice(selectedPrice);
    onFilterChange({ price: selectedPrice });
  };

  const provinceOptions = provinciasESP
    .sort((a, b) => a.nm.localeCompare(b.nm))
    .map((provincia) => ({
      value: provincia.id,
      label: provincia.nm,
    }));

  return (
    <div className="mb-4 flex flex-wrap gap-2">
      <Select
        options={provinceOptions}
        value={selectedProvince}
        onChange={handleProvinceChange}
        placeholder="Seleccione Provincia"
        className="w-full sm:w-48 text-black"
      />
      <Select
        options={availableCities}
        value={selectedCity}
        onChange={handleCityChange}
        placeholder="Seleccione Municipio"
        className="w-full sm:w-48 text-black"
        isDisabled={!selectedProvince}
      />
      <select
        value={rating}
        onChange={handleRatingChange}
        className="p-2 rounded bg-gray-200 text-gray-800 w-full sm:w-48"
      >
        <option value="">Seleccione Rating</option>
        {[0, 1, 2, 3, 4, 5].map((r) => (
          <option key={r} value={r}>
            {r} Estrellas
          </option>
        ))}
      </select>
      <Select
        options={levelsList.map(level => ({ value: level, label: level }))}
        value={level ? { value: level, label: level } : null}
        onChange={handleLevelChange}
        placeholder="Seleccione Nivel"
        className="w-full sm:w-48 text-black"
      />
      {level && sublevelsList.length > 0 && (
        <Select
          options={sublevelsList}
          value={subject}
          onChange={handleSubjectChange}
          placeholder="Seleccione Materia"
          className="w-full sm:w-48 text-black"
        />
      )}
      <input
        type="number"
        value={price}
        onChange={handlePriceChange}
        className="p-2 rounded bg-gray-200 text-gray-800 w-full sm:w-48"
        placeholder="Precio por hora"
      />
    </div>
  );
};

export default FilterPanel;
