// src/store/authStore.js
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useAuthStore = create(persist((set) => ({
  user: null,
  token: null,
  isAuthenticated: false,
  setUser: (userData) => {
    set({
      user: {
        ...userData,
      },
      token: userData.token,
      isAuthenticated: true
    });
  },
  logout: () => set({
    user: null,
    token: null,
    isAuthenticated: false
  })
}), {
  name: 'auth-storage-repaso-pro',
  getStorage: () => localStorage
}));

export default useAuthStore;
