export const municipiosESP = [{
	"id": "Araba/Álava",
	"nm": "Amurrio"
}, {
	"id": "Araba/Álava",
	"nm": "Añana"
}, {
	"id": "Araba/Álava",
	"nm": "Aramaio"
}, {
	"id": "Araba/Álava",
	"nm": "Armiñón"
}, {
	"id": "Araba/Álava",
	"nm": "Arraia-Maeztu"
}, {
	"id": "Araba/Álava",
	"nm": "Arratzua-Ubarrundia"
}, {
	"id": "Araba/Álava",
	"nm": "Artziniega"
}, {
	"id": "Araba/Álava",
	"nm": "Asparrena"
}, {
	"id": "Araba/Álava",
	"nm": "Ayala/Aiara"
}, {
	"id": "Araba/Álava",
	"nm": "Baños de Ebro/Mañueta"
}, {
	"id": "Araba/Álava",
	"nm": "Barrundia"
}, {
	"id": "Araba/Álava",
	"nm": "Berantevilla"
}, {
	"id": "Araba/Álava",
	"nm": "Bernedo"
}, {
	"id": "Araba/Álava",
	"nm": "Campezo/Kanpezu"
}, {
	"id": "Araba/Álava",
	"nm": "Elburgo/Burgelu"
}, {
	"id": "Araba/Álava",
	"nm": "Elciego"
}, {
	"id": "Araba/Álava",
	"nm": "Elvillar/Bilar"
}, {
	"id": "Araba/Álava",
	"nm": "Erriberagoitia/Ribera Alta"
}, {
	"id": "Araba/Álava",
	"nm": "Harana/Valle de Arana"
}, {
	"id": "Araba/Álava",
	"nm": "Iruña Oka/Iruña de Oca"
}, {
	"id": "Araba/Álava",
	"nm": "Iruraiz-Gauna"
}, {
	"id": "Araba/Álava",
	"nm": "Kripan"
}, {
	"id": "Araba/Álava",
	"nm": "Kuartango"
}, {
	"id": "Araba/Álava",
	"nm": "Labastida/Bastida"
}, {
	"id": "Araba/Álava",
	"nm": "Lagrán"
}, {
	"id": "Araba/Álava",
	"nm": "Laguardia"
}, {
	"id": "Araba/Álava",
	"nm": "Lanciego/Lantziego"
}, {
	"id": "Araba/Álava",
	"nm": "Lantarón"
}, {
	"id": "Araba/Álava",
	"nm": "Lapuebla de Labarca"
}, {
	"id": "Araba/Álava",
	"nm": "Laudio/Llodio"
}, {
	"id": "Araba/Álava",
	"nm": "Legutio"
}, {
	"id": "Araba/Álava",
	"nm": "Leza"
}, {
	"id": "Araba/Álava",
	"nm": "Moreda de Álava/Moreda Araba"
}, {
	"id": "Araba/Álava",
	"nm": "Navaridas"
}, {
	"id": "Araba/Álava",
	"nm": "Okondo"
}, {
	"id": "Araba/Álava",
	"nm": "Oyón-Oion"
}, {
	"id": "Araba/Álava",
	"nm": "Peñacerrada-Urizaharra"
}, {
	"id": "Araba/Álava",
	"nm": "Ribera Baja/Erribera Beitia"
}, {
	"id": "Araba/Álava",
	"nm": "Salvatierra/Agurain"
}, {
	"id": "Araba/Álava",
	"nm": "Samaniego"
}, {
	"id": "Araba/Álava",
	"nm": "San Millán/Donemiliaga"
}, {
	"id": "Araba/Álava",
	"nm": "Urkabustaiz"
}, {
	"id": "Araba/Álava",
	"nm": "Valdegovía/Gaubea"
}, {
	"id": "Araba/Álava",
	"nm": "Villabuena de Álava/Eskuernaga"
}, {
	"id": "Araba/Álava",
	"nm": "Vitoria-Gasteiz"
}, {
	"id": "Araba/Álava",
	"nm": "Yécora/Iekora"
}, {
	"id": "Araba/Álava",
	"nm": "Zalduondo"
}, {
	"id": "Araba/Álava",
	"nm": "Zambrana"
}, {
	"id": "Araba/Álava",
	"nm": "Zigoitia"
}, {
	"id": "Araba/Álava",
	"nm": "Zuia"
}, {
	"id": "Albacete",
	"nm": "Abengibre"
}, {
	"id": "Albacete",
	"nm": "Alatoz"
}, {
	"id": "Albacete",
	"nm": "Albacete"
}, {
	"id": "Albacete",
	"nm": "Albatana"
}, {
	"id": "Albacete",
	"nm": "Alborea"
}, {
	"id": "Albacete",
	"nm": "Alcadozo"
}, {
	"id": "Albacete",
	"nm": "Alcalá del Júcar"
}, {
	"id": "Albacete",
	"nm": "Alcaraz"
}, {
	"id": "Albacete",
	"nm": "Almansa"
}, {
	"id": "Albacete",
	"nm": "Alpera"
}, {
	"id": "Albacete",
	"nm": "Ayna"
}, {
	"id": "Albacete",
	"nm": "Balazote"
}, {
	"id": "Albacete",
	"nm": "Ballestero, El"
}, {
	"id": "Albacete",
	"nm": "Balsa de Ves"
}, {
	"id": "Albacete",
	"nm": "Barrax"
}, {
	"id": "Albacete",
	"nm": "Bienservida"
}, {
	"id": "Albacete",
	"nm": "Bogarra"
}, {
	"id": "Albacete",
	"nm": "Bonete"
}, {
	"id": "Albacete",
	"nm": "Bonillo, El"
}, {
	"id": "Albacete",
	"nm": "Carcelén"
}, {
	"id": "Albacete",
	"nm": "Casas de Juan Núñez"
}, {
	"id": "Albacete",
	"nm": "Casas de Lázaro"
}, {
	"id": "Albacete",
	"nm": "Casas de Ves"
}, {
	"id": "Albacete",
	"nm": "Casas-Ibáñez"
}, {
	"id": "Albacete",
	"nm": "Caudete"
}, {
	"id": "Albacete",
	"nm": "Cenizate"
}, {
	"id": "Albacete",
	"nm": "Chinchilla de Monte-Aragón"
}, {
	"id": "Albacete",
	"nm": "Corral-Rubio"
}, {
	"id": "Albacete",
	"nm": "Cotillas"
}, {
	"id": "Albacete",
	"nm": "Elche de la Sierra"
}, {
	"id": "Albacete",
	"nm": "Férez"
}, {
	"id": "Albacete",
	"nm": "Fuensanta"
}, {
	"id": "Albacete",
	"nm": "Fuente-Álamo"
}, {
	"id": "Albacete",
	"nm": "Fuentealbilla"
}, {
	"id": "Albacete",
	"nm": "Gineta, La"
}, {
	"id": "Albacete",
	"nm": "Golosalvo"
}, {
	"id": "Albacete",
	"nm": "Hellín"
}, {
	"id": "Albacete",
	"nm": "Herrera, La"
}, {
	"id": "Albacete",
	"nm": "Higueruela"
}, {
	"id": "Albacete",
	"nm": "Hoya-Gonzalo"
}, {
	"id": "Albacete",
	"nm": "Jorquera"
}, {
	"id": "Albacete",
	"nm": "Letur"
}, {
	"id": "Albacete",
	"nm": "Lezuza"
}, {
	"id": "Albacete",
	"nm": "Liétor"
}, {
	"id": "Albacete",
	"nm": "Madrigueras"
}, {
	"id": "Albacete",
	"nm": "Mahora"
}, {
	"id": "Albacete",
	"nm": "Masegoso"
}, {
	"id": "Albacete",
	"nm": "Minaya"
}, {
	"id": "Albacete",
	"nm": "Molinicos"
}, {
	"id": "Albacete",
	"nm": "Montalvos"
}, {
	"id": "Albacete",
	"nm": "Montealegre del Castillo"
}, {
	"id": "Albacete",
	"nm": "Motilleja"
}, {
	"id": "Albacete",
	"nm": "Munera"
}, {
	"id": "Albacete",
	"nm": "Navas de Jorquera"
}, {
	"id": "Albacete",
	"nm": "Nerpio"
}, {
	"id": "Albacete",
	"nm": "Ontur"
}, {
	"id": "Albacete",
	"nm": "Ossa de Montiel"
}, {
	"id": "Albacete",
	"nm": "Paterna del Madera"
}, {
	"id": "Albacete",
	"nm": "Peñas de San Pedro"
}, {
	"id": "Albacete",
	"nm": "Peñascosa"
}, {
	"id": "Albacete",
	"nm": "Pétrola"
}, {
	"id": "Albacete",
	"nm": "Povedilla"
}, {
	"id": "Albacete",
	"nm": "Pozo Cañada"
}, {
	"id": "Albacete",
	"nm": "Pozohondo"
}, {
	"id": "Albacete",
	"nm": "Pozo-Lorente"
}, {
	"id": "Albacete",
	"nm": "Pozuelo"
}, {
	"id": "Albacete",
	"nm": "Recueja, La"
}, {
	"id": "Albacete",
	"nm": "Riópar"
}, {
	"id": "Albacete",
	"nm": "Robledo"
}, {
	"id": "Albacete",
	"nm": "Roda, La"
}, {
	"id": "Albacete",
	"nm": "Salobre"
}, {
	"id": "Albacete",
	"nm": "San Pedro"
}, {
	"id": "Albacete",
	"nm": "Socovos"
}, {
	"id": "Albacete",
	"nm": "Tarazona de la Mancha"
}, {
	"id": "Albacete",
	"nm": "Tobarra"
}, {
	"id": "Albacete",
	"nm": "Valdeganga"
}, {
	"id": "Albacete",
	"nm": "Vianos"
}, {
	"id": "Albacete",
	"nm": "Villa de Ves"
}, {
	"id": "Albacete",
	"nm": "Villalgordo del Júcar"
}, {
	"id": "Albacete",
	"nm": "Villamalea"
}, {
	"id": "Albacete",
	"nm": "Villapalacios"
}, {
	"id": "Albacete",
	"nm": "Villarrobledo"
}, {
	"id": "Albacete",
	"nm": "Villatoya"
}, {
	"id": "Albacete",
	"nm": "Villavaliente"
}, {
	"id": "Albacete",
	"nm": "Villaverde de Guadalimar"
}, {
	"id": "Albacete",
	"nm": "Viveros"
}, {
	"id": "Albacete",
	"nm": "Yeste"
}, {
	"id": "Alicante/Alacant",
	"nm": "Agost"
}, {
	"id": "Alicante/Alacant",
	"nm": "Agres"
}, {
	"id": "Alicante/Alacant",
	"nm": "Aigües"
}, {
	"id": "Alicante/Alacant",
	"nm": "Albatera"
}, {
	"id": "Alicante/Alacant",
	"nm": "Alcalalí"
}, {
	"id": "Alicante/Alacant",
	"nm": "Alcocer de Planes"
}, {
	"id": "Alicante/Alacant",
	"nm": "Alcoleja"
}, {
	"id": "Alicante/Alacant",
	"nm": "Alcoy/Alcoi"
}, {
	"id": "Alicante/Alacant",
	"nm": "Alfafara"
}, {
	"id": "Alicante/Alacant",
	"nm": "Alfàs del Pi, l'"
}, {
	"id": "Alicante/Alacant",
	"nm": "Algorfa"
}, {
	"id": "Alicante/Alacant",
	"nm": "Algueña"
}, {
	"id": "Alicante/Alacant",
	"nm": "Alicante/Alacant"
}, {
	"id": "Alicante/Alacant",
	"nm": "Almoradí"
}, {
	"id": "Alicante/Alacant",
	"nm": "Almudaina"
}, {
	"id": "Alicante/Alacant",
	"nm": "Alqueria d'Asnar, l'"
}, {
	"id": "Alicante/Alacant",
	"nm": "Altea"
}, {
	"id": "Alicante/Alacant",
	"nm": "Aspe"
}, {
	"id": "Alicante/Alacant",
	"nm": "Atzúbia, l'"
}, {
	"id": "Alicante/Alacant",
	"nm": "Balones"
}, {
	"id": "Alicante/Alacant",
	"nm": "Banyeres de Mariola"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benasau"
}, {
	"id": "Alicante/Alacant",
	"nm": "Beneixama"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benejúzar"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benferri"
}, {
	"id": "Alicante/Alacant",
	"nm": "Beniarbeig"
}, {
	"id": "Alicante/Alacant",
	"nm": "Beniardá"
}, {
	"id": "Alicante/Alacant",
	"nm": "Beniarrés"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benidoleig"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benidorm"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benifallim"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benifato"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benigembla"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benijófar"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benilloba"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benillup"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benimantell"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benimarfull"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benimassot"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benimeli"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benissa"
}, {
	"id": "Alicante/Alacant",
	"nm": "Benitachell/Poble Nou de Benitatxell, el"
}, {
	"id": "Alicante/Alacant",
	"nm": "Biar"
}, {
	"id": "Alicante/Alacant",
	"nm": "Bigastro"
}, {
	"id": "Alicante/Alacant",
	"nm": "Bolulla"
}, {
	"id": "Alicante/Alacant",
	"nm": "Busot"
}, {
	"id": "Alicante/Alacant",
	"nm": "Callosa de Segura"
}, {
	"id": "Alicante/Alacant",
	"nm": "Callosa d'en Sarrià"
}, {
	"id": "Alicante/Alacant",
	"nm": "Calp"
}, {
	"id": "Alicante/Alacant",
	"nm": "Campello, el"
}, {
	"id": "Alicante/Alacant",
	"nm": "Campo de Mirra/Camp de Mirra, el"
}, {
	"id": "Alicante/Alacant",
	"nm": "Cañada"
}, {
	"id": "Alicante/Alacant",
	"nm": "Castalla"
}, {
	"id": "Alicante/Alacant",
	"nm": "Castell de Castells"
}, {
	"id": "Alicante/Alacant",
	"nm": "Castell de Guadalest, el"
}, {
	"id": "Alicante/Alacant",
	"nm": "Catral"
}, {
	"id": "Alicante/Alacant",
	"nm": "Cocentaina"
}, {
	"id": "Alicante/Alacant",
	"nm": "Confrides"
}, {
	"id": "Alicante/Alacant",
	"nm": "Cox"
}, {
	"id": "Alicante/Alacant",
	"nm": "Crevillent"
}, {
	"id": "Alicante/Alacant",
	"nm": "Daya Nueva"
}, {
	"id": "Alicante/Alacant",
	"nm": "Daya Vieja"
}, {
	"id": "Alicante/Alacant",
	"nm": "Dénia"
}, {
	"id": "Alicante/Alacant",
	"nm": "Dolores"
}, {
	"id": "Alicante/Alacant",
	"nm": "Elche/Elx"
}, {
	"id": "Alicante/Alacant",
	"nm": "Elda"
}, {
	"id": "Alicante/Alacant",
	"nm": "Facheca"
}, {
	"id": "Alicante/Alacant",
	"nm": "Famorca"
}, {
	"id": "Alicante/Alacant",
	"nm": "Finestrat"
}, {
	"id": "Alicante/Alacant",
	"nm": "Fondó de les Neus, el/Hondón de las Nieves"
}, {
	"id": "Alicante/Alacant",
	"nm": "Formentera del Segura"
}, {
	"id": "Alicante/Alacant",
	"nm": "Gaianes"
}, {
	"id": "Alicante/Alacant",
	"nm": "Gata de Gorgos"
}, {
	"id": "Alicante/Alacant",
	"nm": "Gorga"
}, {
	"id": "Alicante/Alacant",
	"nm": "Granja de Rocamora"
}, {
	"id": "Alicante/Alacant",
	"nm": "Guardamar del Segura"
}, {
	"id": "Alicante/Alacant",
	"nm": "Hondón de los Frailes"
}, {
	"id": "Alicante/Alacant",
	"nm": "Ibi"
}, {
	"id": "Alicante/Alacant",
	"nm": "Jacarilla"
}, {
	"id": "Alicante/Alacant",
	"nm": "Jávea/Xàbia"
}, {
	"id": "Alicante/Alacant",
	"nm": "Jijona/Xixona"
}, {
	"id": "Alicante/Alacant",
	"nm": "Llíber"
}, {
	"id": "Alicante/Alacant",
	"nm": "Lorcha/Orxa, l'"
}, {
	"id": "Alicante/Alacant",
	"nm": "Millena"
}, {
	"id": "Alicante/Alacant",
	"nm": "Monforte del Cid"
}, {
	"id": "Alicante/Alacant",
	"nm": "Monóvar/Monòver"
}, {
	"id": "Alicante/Alacant",
	"nm": "Montesinos, Los"
}, {
	"id": "Alicante/Alacant",
	"nm": "Murla"
}, {
	"id": "Alicante/Alacant",
	"nm": "Muro de Alcoy"
}, {
	"id": "Alicante/Alacant",
	"nm": "Mutxamel"
}, {
	"id": "Alicante/Alacant",
	"nm": "Novelda"
}, {
	"id": "Alicante/Alacant",
	"nm": "Nucia, la"
}, {
	"id": "Alicante/Alacant",
	"nm": "Ondara"
}, {
	"id": "Alicante/Alacant",
	"nm": "Onil"
}, {
	"id": "Alicante/Alacant",
	"nm": "Orba"
}, {
	"id": "Alicante/Alacant",
	"nm": "Orihuela"
}, {
	"id": "Alicante/Alacant",
	"nm": "Orxeta"
}, {
	"id": "Alicante/Alacant",
	"nm": "Parcent"
}, {
	"id": "Alicante/Alacant",
	"nm": "Pedreguer"
}, {
	"id": "Alicante/Alacant",
	"nm": "Pego"
}, {
	"id": "Alicante/Alacant",
	"nm": "Penàguila"
}, {
	"id": "Alicante/Alacant",
	"nm": "Petrer"
}, {
	"id": "Alicante/Alacant",
	"nm": "Pilar de la Horadada"
}, {
	"id": "Alicante/Alacant",
	"nm": "Pinós, el/Pinoso"
}, {
	"id": "Alicante/Alacant",
	"nm": "Planes"
}, {
	"id": "Alicante/Alacant",
	"nm": "Poblets, els"
}, {
	"id": "Alicante/Alacant",
	"nm": "Polop"
}, {
	"id": "Alicante/Alacant",
	"nm": "Quatretondeta"
}, {
	"id": "Alicante/Alacant",
	"nm": "Rafal"
}, {
	"id": "Alicante/Alacant",
	"nm": "Ràfol d'Almúnia, el"
}, {
	"id": "Alicante/Alacant",
	"nm": "Redován"
}, {
	"id": "Alicante/Alacant",
	"nm": "Relleu"
}, {
	"id": "Alicante/Alacant",
	"nm": "Rojales"
}, {
	"id": "Alicante/Alacant",
	"nm": "Romana, la"
}, {
	"id": "Alicante/Alacant",
	"nm": "Sagra"
}, {
	"id": "Alicante/Alacant",
	"nm": "Salinas"
}, {
	"id": "Alicante/Alacant",
	"nm": "San Fulgencio"
}, {
	"id": "Alicante/Alacant",
	"nm": "San Isidro"
}, {
	"id": "Alicante/Alacant",
	"nm": "San Miguel de Salinas"
}, {
	"id": "Alicante/Alacant",
	"nm": "San Vicente del Raspeig/Sant Vicent del Raspeig"
}, {
	"id": "Alicante/Alacant",
	"nm": "Sanet y Negrals"
}, {
	"id": "Alicante/Alacant",
	"nm": "Sant Joan d'Alacant"
}, {
	"id": "Alicante/Alacant",
	"nm": "Santa Pola"
}, {
	"id": "Alicante/Alacant",
	"nm": "Sax"
}, {
	"id": "Alicante/Alacant",
	"nm": "Sella"
}, {
	"id": "Alicante/Alacant",
	"nm": "Senija"
}, {
	"id": "Alicante/Alacant",
	"nm": "Tàrbena"
}, {
	"id": "Alicante/Alacant",
	"nm": "Teulada"
}, {
	"id": "Alicante/Alacant",
	"nm": "Tibi"
}, {
	"id": "Alicante/Alacant",
	"nm": "Tollos"
}, {
	"id": "Alicante/Alacant",
	"nm": "Tormos"
}, {
	"id": "Alicante/Alacant",
	"nm": "Torremanzanas/Torre de les Maçanes, la"
}, {
	"id": "Alicante/Alacant",
	"nm": "Torrevieja"
}, {
	"id": "Alicante/Alacant",
	"nm": "Vall d'Alcalà, la"
}, {
	"id": "Alicante/Alacant",
	"nm": "Vall de Gallinera"
}, {
	"id": "Alicante/Alacant",
	"nm": "Vall de Laguar, la"
}, {
	"id": "Alicante/Alacant",
	"nm": "Vall d'Ebo, la"
}, {
	"id": "Alicante/Alacant",
	"nm": "Verger, el"
}, {
	"id": "Alicante/Alacant",
	"nm": "Villajoyosa/Vila Joiosa, la"
}, {
	"id": "Alicante/Alacant",
	"nm": "Villena"
}, {
	"id": "Alicante/Alacant",
	"nm": "Xaló"
}, {
	"id": "Almería",
	"nm": "Abla"
}, {
	"id": "Almería",
	"nm": "Abrucena"
}, {
	"id": "Almería",
	"nm": "Adra"
}, {
	"id": "Almería",
	"nm": "Albánchez"
}, {
	"id": "Almería",
	"nm": "Alboloduy"
}, {
	"id": "Almería",
	"nm": "Albox"
}, {
	"id": "Almería",
	"nm": "Alcolea"
}, {
	"id": "Almería",
	"nm": "Alcóntar"
}, {
	"id": "Almería",
	"nm": "Alcudia de Monteagud"
}, {
	"id": "Almería",
	"nm": "Alhabia"
}, {
	"id": "Almería",
	"nm": "Alhama de Almería"
}, {
	"id": "Almería",
	"nm": "Alicún"
}, {
	"id": "Almería",
	"nm": "Almería"
}, {
	"id": "Almería",
	"nm": "Almócita"
}, {
	"id": "Almería",
	"nm": "Alsodux"
}, {
	"id": "Almería",
	"nm": "Antas"
}, {
	"id": "Almería",
	"nm": "Arboleas"
}, {
	"id": "Almería",
	"nm": "Armuña de Almanzora"
}, {
	"id": "Almería",
	"nm": "Bacares"
}, {
	"id": "Almería",
	"nm": "Balanegra"
}, {
	"id": "Almería",
	"nm": "Bayárcal"
}, {
	"id": "Almería",
	"nm": "Bayarque"
}, {
	"id": "Almería",
	"nm": "Bédar"
}, {
	"id": "Almería",
	"nm": "Beires"
}, {
	"id": "Almería",
	"nm": "Benahadux"
}, {
	"id": "Almería",
	"nm": "Benitagla"
}, {
	"id": "Almería",
	"nm": "Benizalón"
}, {
	"id": "Almería",
	"nm": "Bentarique"
}, {
	"id": "Almería",
	"nm": "Berja"
}, {
	"id": "Almería",
	"nm": "Canjáyar"
}, {
	"id": "Almería",
	"nm": "Cantoria"
}, {
	"id": "Almería",
	"nm": "Carboneras"
}, {
	"id": "Almería",
	"nm": "Castro de Filabres"
}, {
	"id": "Almería",
	"nm": "Chercos"
}, {
	"id": "Almería",
	"nm": "Chirivel"
}, {
	"id": "Almería",
	"nm": "Cóbdar"
}, {
	"id": "Almería",
	"nm": "Cuevas del Almanzora"
}, {
	"id": "Almería",
	"nm": "Dalías"
}, {
	"id": "Almería",
	"nm": "Ejido, El"
}, {
	"id": "Almería",
	"nm": "Enix"
}, {
	"id": "Almería",
	"nm": "Felix"
}, {
	"id": "Almería",
	"nm": "Fines"
}, {
	"id": "Almería",
	"nm": "Fiñana"
}, {
	"id": "Almería",
	"nm": "Fondón"
}, {
	"id": "Almería",
	"nm": "Gádor"
}, {
	"id": "Almería",
	"nm": "Gallardos, Los"
}, {
	"id": "Almería",
	"nm": "Garrucha"
}, {
	"id": "Almería",
	"nm": "Gérgal"
}, {
	"id": "Almería",
	"nm": "Huécija"
}, {
	"id": "Almería",
	"nm": "Huércal de Almería"
}, {
	"id": "Almería",
	"nm": "Huércal-Overa"
}, {
	"id": "Almería",
	"nm": "Illar"
}, {
	"id": "Almería",
	"nm": "Instinción"
}, {
	"id": "Almería",
	"nm": "Laroya"
}, {
	"id": "Almería",
	"nm": "Láujar de Andarax"
}, {
	"id": "Almería",
	"nm": "Líjar"
}, {
	"id": "Almería",
	"nm": "Lubrín"
}, {
	"id": "Almería",
	"nm": "Lucainena de las Torres"
}, {
	"id": "Almería",
	"nm": "Lúcar"
}, {
	"id": "Almería",
	"nm": "Macael"
}, {
	"id": "Almería",
	"nm": "María"
}, {
	"id": "Almería",
	"nm": "Mojácar"
}, {
	"id": "Almería",
	"nm": "Mojonera, La"
}, {
	"id": "Almería",
	"nm": "Nacimiento"
}, {
	"id": "Almería",
	"nm": "Níjar"
}, {
	"id": "Almería",
	"nm": "Ohanes"
}, {
	"id": "Almería",
	"nm": "Olula de Castro"
}, {
	"id": "Almería",
	"nm": "Olula del Río"
}, {
	"id": "Almería",
	"nm": "Oria"
}, {
	"id": "Almería",
	"nm": "Padules"
}, {
	"id": "Almería",
	"nm": "Partaloa"
}, {
	"id": "Almería",
	"nm": "Paterna del Río"
}, {
	"id": "Almería",
	"nm": "Pechina"
}, {
	"id": "Almería",
	"nm": "Pulpí"
}, {
	"id": "Almería",
	"nm": "Purchena"
}, {
	"id": "Almería",
	"nm": "Rágol"
}, {
	"id": "Almería",
	"nm": "Rioja"
}, {
	"id": "Almería",
	"nm": "Roquetas de Mar"
}, {
	"id": "Almería",
	"nm": "Santa Cruz de Marchena"
}, {
	"id": "Almería",
	"nm": "Santa Fe de Mondújar"
}, {
	"id": "Almería",
	"nm": "Senés"
}, {
	"id": "Almería",
	"nm": "Serón"
}, {
	"id": "Almería",
	"nm": "Sierro"
}, {
	"id": "Almería",
	"nm": "Somontín"
}, {
	"id": "Almería",
	"nm": "Sorbas"
}, {
	"id": "Almería",
	"nm": "Suflí"
}, {
	"id": "Almería",
	"nm": "Tabernas"
}, {
	"id": "Almería",
	"nm": "Taberno"
}, {
	"id": "Almería",
	"nm": "Tahal"
}, {
	"id": "Almería",
	"nm": "Terque"
}, {
	"id": "Almería",
	"nm": "Tíjola"
}, {
	"id": "Almería",
	"nm": "Tres Villas, Las"
}, {
	"id": "Almería",
	"nm": "Turre"
}, {
	"id": "Almería",
	"nm": "Turrillas"
}, {
	"id": "Almería",
	"nm": "Uleila del Campo"
}, {
	"id": "Almería",
	"nm": "Urrácal"
}, {
	"id": "Almería",
	"nm": "Velefique"
}, {
	"id": "Almería",
	"nm": "Vélez-Blanco"
}, {
	"id": "Almería",
	"nm": "Vélez-Rubio"
}, {
	"id": "Almería",
	"nm": "Vera"
}, {
	"id": "Almería",
	"nm": "Viator"
}, {
	"id": "Almería",
	"nm": "Vícar"
}, {
	"id": "Almería",
	"nm": "Zurgena"
}, {
	"id": "Ávila",
	"nm": "Adanero"
}, {
	"id": "Ávila",
	"nm": "Adrada, La"
}, {
	"id": "Ávila",
	"nm": "Albornos"
}, {
	"id": "Ávila",
	"nm": "Aldeanueva de Santa Cruz"
}, {
	"id": "Ávila",
	"nm": "Aldeaseca"
}, {
	"id": "Ávila",
	"nm": "Aldehuela, La"
}, {
	"id": "Ávila",
	"nm": "Amavida"
}, {
	"id": "Ávila",
	"nm": "Arenal, El"
}, {
	"id": "Ávila",
	"nm": "Arenas de San Pedro"
}, {
	"id": "Ávila",
	"nm": "Arevalillo"
}, {
	"id": "Ávila",
	"nm": "Arévalo"
}, {
	"id": "Ávila",
	"nm": "Aveinte"
}, {
	"id": "Ávila",
	"nm": "Avellaneda"
}, {
	"id": "Ávila",
	"nm": "Ávila"
}, {
	"id": "Ávila",
	"nm": "Barco de Ávila, El"
}, {
	"id": "Ávila",
	"nm": "Barraco, El"
}, {
	"id": "Ávila",
	"nm": "Barromán"
}, {
	"id": "Ávila",
	"nm": "Becedas"
}, {
	"id": "Ávila",
	"nm": "Becedillas"
}, {
	"id": "Ávila",
	"nm": "Bercial de Zapardiel"
}, {
	"id": "Ávila",
	"nm": "Berlanas, Las"
}, {
	"id": "Ávila",
	"nm": "Bernuy-Zapardiel"
}, {
	"id": "Ávila",
	"nm": "Berrocalejo de Aragona"
}, {
	"id": "Ávila",
	"nm": "Blascomillán"
}, {
	"id": "Ávila",
	"nm": "Blasconuño de Matacabras"
}, {
	"id": "Ávila",
	"nm": "Blascosancho"
}, {
	"id": "Ávila",
	"nm": "Bohodón, El"
}, {
	"id": "Ávila",
	"nm": "Bohoyo"
}, {
	"id": "Ávila",
	"nm": "Bonilla de la Sierra"
}, {
	"id": "Ávila",
	"nm": "Brabos"
}, {
	"id": "Ávila",
	"nm": "Bularros"
}, {
	"id": "Ávila",
	"nm": "Burgohondo"
}, {
	"id": "Ávila",
	"nm": "Cabezas de Alambre"
}, {
	"id": "Ávila",
	"nm": "Cabezas del Pozo"
}, {
	"id": "Ávila",
	"nm": "Cabezas del Villar"
}, {
	"id": "Ávila",
	"nm": "Cabizuela"
}, {
	"id": "Ávila",
	"nm": "Canales"
}, {
	"id": "Ávila",
	"nm": "Candeleda"
}, {
	"id": "Ávila",
	"nm": "Cantiveros"
}, {
	"id": "Ávila",
	"nm": "Cardeñosa"
}, {
	"id": "Ávila",
	"nm": "Carrera, La"
}, {
	"id": "Ávila",
	"nm": "Casas del Puerto"
}, {
	"id": "Ávila",
	"nm": "Casasola"
}, {
	"id": "Ávila",
	"nm": "Casavieja"
}, {
	"id": "Ávila",
	"nm": "Casillas"
}, {
	"id": "Ávila",
	"nm": "Castellanos de Zapardiel"
}, {
	"id": "Ávila",
	"nm": "Cebreros"
}, {
	"id": "Ávila",
	"nm": "Cepeda la Mora"
}, {
	"id": "Ávila",
	"nm": "Chamartín"
}, {
	"id": "Ávila",
	"nm": "Cillán"
}, {
	"id": "Ávila",
	"nm": "Cisla"
}, {
	"id": "Ávila",
	"nm": "Colilla, La"
}, {
	"id": "Ávila",
	"nm": "Collado de Contreras"
}, {
	"id": "Ávila",
	"nm": "Collado del Mirón"
}, {
	"id": "Ávila",
	"nm": "Constanzana"
}, {
	"id": "Ávila",
	"nm": "Crespos"
}, {
	"id": "Ávila",
	"nm": "Cuevas del Valle"
}, {
	"id": "Ávila",
	"nm": "Diego del Carpio"
}, {
	"id": "Ávila",
	"nm": "Donjimeno"
}, {
	"id": "Ávila",
	"nm": "Donvidas"
}, {
	"id": "Ávila",
	"nm": "Espinosa de los Caballeros"
}, {
	"id": "Ávila",
	"nm": "Flores de Ávila"
}, {
	"id": "Ávila",
	"nm": "Fontiveros"
}, {
	"id": "Ávila",
	"nm": "Fresnedilla"
}, {
	"id": "Ávila",
	"nm": "Fresno, El"
}, {
	"id": "Ávila",
	"nm": "Fuente el Saúz"
}, {
	"id": "Ávila",
	"nm": "Fuentes de Año"
}, {
	"id": "Ávila",
	"nm": "Gallegos de Altamiros"
}, {
	"id": "Ávila",
	"nm": "Gallegos de Sobrinos"
}, {
	"id": "Ávila",
	"nm": "Garganta del Villar"
}, {
	"id": "Ávila",
	"nm": "Gavilanes"
}, {
	"id": "Ávila",
	"nm": "Gemuño"
}, {
	"id": "Ávila",
	"nm": "Gil García"
}, {
	"id": "Ávila",
	"nm": "Gilbuena"
}, {
	"id": "Ávila",
	"nm": "Gimialcón"
}, {
	"id": "Ávila",
	"nm": "Gotarrendura"
}, {
	"id": "Ávila",
	"nm": "Grandes y San Martín"
}, {
	"id": "Ávila",
	"nm": "Guisando"
}, {
	"id": "Ávila",
	"nm": "Gutierre-Muñoz"
}, {
	"id": "Ávila",
	"nm": "Hernansancho"
}, {
	"id": "Ávila",
	"nm": "Herradón de Pinares"
}, {
	"id": "Ávila",
	"nm": "Herreros de Suso"
}, {
	"id": "Ávila",
	"nm": "Higuera de las Dueñas"
}, {
	"id": "Ávila",
	"nm": "Hija de Dios, La"
}, {
	"id": "Ávila",
	"nm": "Horcajada, La"
}, {
	"id": "Ávila",
	"nm": "Horcajo de las Torres"
}, {
	"id": "Ávila",
	"nm": "Hornillo, El"
}, {
	"id": "Ávila",
	"nm": "Hoyo de Pinares, El"
}, {
	"id": "Ávila",
	"nm": "Hoyocasero"
}, {
	"id": "Ávila",
	"nm": "Hoyorredondo"
}, {
	"id": "Ávila",
	"nm": "Hoyos de Miguel Muñoz"
}, {
	"id": "Ávila",
	"nm": "Hoyos del Collado"
}, {
	"id": "Ávila",
	"nm": "Hoyos del Espino"
}, {
	"id": "Ávila",
	"nm": "Hurtumpascual"
}, {
	"id": "Ávila",
	"nm": "Junciana"
}, {
	"id": "Ávila",
	"nm": "Langa"
}, {
	"id": "Ávila",
	"nm": "Lanzahíta"
}, {
	"id": "Ávila",
	"nm": "Llanos de Tormes, Los"
}, {
	"id": "Ávila",
	"nm": "Losar del Barco, El"
}, {
	"id": "Ávila",
	"nm": "Madrigal de las Altas Torres"
}, {
	"id": "Ávila",
	"nm": "Maello"
}, {
	"id": "Ávila",
	"nm": "Malpartida de Corneja"
}, {
	"id": "Ávila",
	"nm": "Mamblas"
}, {
	"id": "Ávila",
	"nm": "Mancera de Arriba"
}, {
	"id": "Ávila",
	"nm": "Manjabálago y Ortigosa de Rioalmar"
}, {
	"id": "Ávila",
	"nm": "Marlín"
}, {
	"id": "Ávila",
	"nm": "Martiherrero"
}, {
	"id": "Ávila",
	"nm": "Martínez"
}, {
	"id": "Ávila",
	"nm": "Mediana de Voltoya"
}, {
	"id": "Ávila",
	"nm": "Medinilla"
}, {
	"id": "Ávila",
	"nm": "Mengamuñoz"
}, {
	"id": "Ávila",
	"nm": "Mesegar de Corneja"
}, {
	"id": "Ávila",
	"nm": "Mijares"
}, {
	"id": "Ávila",
	"nm": "Mingorría"
}, {
	"id": "Ávila",
	"nm": "Mirón, El"
}, {
	"id": "Ávila",
	"nm": "Mironcillo"
}, {
	"id": "Ávila",
	"nm": "Mirueña de los Infanzones"
}, {
	"id": "Ávila",
	"nm": "Mombeltrán"
}, {
	"id": "Ávila",
	"nm": "Monsalupe"
}, {
	"id": "Ávila",
	"nm": "Moraleja de Matacabras"
}, {
	"id": "Ávila",
	"nm": "Muñana"
}, {
	"id": "Ávila",
	"nm": "Muñico"
}, {
	"id": "Ávila",
	"nm": "Muñogalindo"
}, {
	"id": "Ávila",
	"nm": "Muñogrande"
}, {
	"id": "Ávila",
	"nm": "Muñomer del Peco"
}, {
	"id": "Ávila",
	"nm": "Muñopepe"
}, {
	"id": "Ávila",
	"nm": "Muñosancho"
}, {
	"id": "Ávila",
	"nm": "Muñotello"
}, {
	"id": "Ávila",
	"nm": "Narrillos del Álamo"
}, {
	"id": "Ávila",
	"nm": "Narrillos del Rebollar"
}, {
	"id": "Ávila",
	"nm": "Narros de Saldueña"
}, {
	"id": "Ávila",
	"nm": "Narros del Castillo"
}, {
	"id": "Ávila",
	"nm": "Narros del Puerto"
}, {
	"id": "Ávila",
	"nm": "Nava de Arévalo"
}, {
	"id": "Ávila",
	"nm": "Nava del Barco"
}, {
	"id": "Ávila",
	"nm": "Navacepedilla de Corneja"
}, {
	"id": "Ávila",
	"nm": "Navadijos"
}, {
	"id": "Ávila",
	"nm": "Navaescurial"
}, {
	"id": "Ávila",
	"nm": "Navahondilla"
}, {
	"id": "Ávila",
	"nm": "Navalacruz"
}, {
	"id": "Ávila",
	"nm": "Navalmoral"
}, {
	"id": "Ávila",
	"nm": "Navalonguilla"
}, {
	"id": "Ávila",
	"nm": "Navalosa"
}, {
	"id": "Ávila",
	"nm": "Navalperal de Pinares"
}, {
	"id": "Ávila",
	"nm": "Navalperal de Tormes"
}, {
	"id": "Ávila",
	"nm": "Navaluenga"
}, {
	"id": "Ávila",
	"nm": "Navaquesera"
}, {
	"id": "Ávila",
	"nm": "Navarredonda de Gredos"
}, {
	"id": "Ávila",
	"nm": "Navarredondilla"
}, {
	"id": "Ávila",
	"nm": "Navarrevisca"
}, {
	"id": "Ávila",
	"nm": "Navas del Marqués, Las"
}, {
	"id": "Ávila",
	"nm": "Navatalgordo"
}, {
	"id": "Ávila",
	"nm": "Navatejares"
}, {
	"id": "Ávila",
	"nm": "Neila de San Miguel"
}, {
	"id": "Ávila",
	"nm": "Niharra"
}, {
	"id": "Ávila",
	"nm": "Ojos-Albos"
}, {
	"id": "Ávila",
	"nm": "Orbita"
}, {
	"id": "Ávila",
	"nm": "Oso, El"
}, {
	"id": "Ávila",
	"nm": "Padiernos"
}, {
	"id": "Ávila",
	"nm": "Pajares de Adaja"
}, {
	"id": "Ávila",
	"nm": "Palacios de Goda"
}, {
	"id": "Ávila",
	"nm": "Papatrigo"
}, {
	"id": "Ávila",
	"nm": "Parral, El"
}, {
	"id": "Ávila",
	"nm": "Pascualcobo"
}, {
	"id": "Ávila",
	"nm": "Pedro Bernardo"
}, {
	"id": "Ávila",
	"nm": "Pedro-Rodríguez"
}, {
	"id": "Ávila",
	"nm": "Peguerinos"
}, {
	"id": "Ávila",
	"nm": "Peñalba de Ávila"
}, {
	"id": "Ávila",
	"nm": "Piedrahíta"
}, {
	"id": "Ávila",
	"nm": "Piedralaves"
}, {
	"id": "Ávila",
	"nm": "Poveda"
}, {
	"id": "Ávila",
	"nm": "Poyales del Hoyo"
}, {
	"id": "Ávila",
	"nm": "Pozanco"
}, {
	"id": "Ávila",
	"nm": "Pradosegar"
}, {
	"id": "Ávila",
	"nm": "Puerto Castilla"
}, {
	"id": "Ávila",
	"nm": "Rasueros"
}, {
	"id": "Ávila",
	"nm": "Riocabado"
}, {
	"id": "Ávila",
	"nm": "Riofrío"
}, {
	"id": "Ávila",
	"nm": "Rivilla de Barajas"
}, {
	"id": "Ávila",
	"nm": "Salobral"
}, {
	"id": "Ávila",
	"nm": "Salvadiós"
}, {
	"id": "Ávila",
	"nm": "San Bartolomé de Béjar"
}, {
	"id": "Ávila",
	"nm": "San Bartolomé de Corneja"
}, {
	"id": "Ávila",
	"nm": "San Bartolomé de Pinares"
}, {
	"id": "Ávila",
	"nm": "San Esteban de los Patos"
}, {
	"id": "Ávila",
	"nm": "San Esteban de Zapardiel"
}, {
	"id": "Ávila",
	"nm": "San Esteban del Valle"
}, {
	"id": "Ávila",
	"nm": "San García de Ingelmos"
}, {
	"id": "Ávila",
	"nm": "San Juan de Gredos"
}, {
	"id": "Ávila",
	"nm": "San Juan de la Encinilla"
}, {
	"id": "Ávila",
	"nm": "San Juan de la Nava"
}, {
	"id": "Ávila",
	"nm": "San Juan del Molinillo"
}, {
	"id": "Ávila",
	"nm": "San Juan del Olmo"
}, {
	"id": "Ávila",
	"nm": "San Lorenzo de Tormes"
}, {
	"id": "Ávila",
	"nm": "San Martín de la Vega del Alberche"
}, {
	"id": "Ávila",
	"nm": "San Martín del Pimpollar"
}, {
	"id": "Ávila",
	"nm": "San Miguel de Corneja"
}, {
	"id": "Ávila",
	"nm": "San Miguel de Serrezuela"
}, {
	"id": "Ávila",
	"nm": "San Pascual"
}, {
	"id": "Ávila",
	"nm": "San Pedro del Arroyo"
}, {
	"id": "Ávila",
	"nm": "San Vicente de Arévalo"
}, {
	"id": "Ávila",
	"nm": "Sanchidrián"
}, {
	"id": "Ávila",
	"nm": "Sanchorreja"
}, {
	"id": "Ávila",
	"nm": "Santa Cruz de Pinares"
}, {
	"id": "Ávila",
	"nm": "Santa Cruz del Valle"
}, {
	"id": "Ávila",
	"nm": "Santa María de los Caballeros"
}, {
	"id": "Ávila",
	"nm": "Santa María del Arroyo"
}, {
	"id": "Ávila",
	"nm": "Santa María del Berrocal"
}, {
	"id": "Ávila",
	"nm": "Santa María del Cubillo"
}, {
	"id": "Ávila",
	"nm": "Santa María del Tiétar"
}, {
	"id": "Ávila",
	"nm": "Santiago del Collado"
}, {
	"id": "Ávila",
	"nm": "Santiago del Tormes"
}, {
	"id": "Ávila",
	"nm": "Santo Domingo de las Posadas"
}, {
	"id": "Ávila",
	"nm": "Santo Tomé de Zabarcos"
}, {
	"id": "Ávila",
	"nm": "Serrada, La"
}, {
	"id": "Ávila",
	"nm": "Serranillos"
}, {
	"id": "Ávila",
	"nm": "Sigeres"
}, {
	"id": "Ávila",
	"nm": "Sinlabajos"
}, {
	"id": "Ávila",
	"nm": "Solana de Ávila"
}, {
	"id": "Ávila",
	"nm": "Solana de Rioalmar"
}, {
	"id": "Ávila",
	"nm": "Solosancho"
}, {
	"id": "Ávila",
	"nm": "Sotalbo"
}, {
	"id": "Ávila",
	"nm": "Sotillo de la Adrada"
}, {
	"id": "Ávila",
	"nm": "Tiemblo, El"
}, {
	"id": "Ávila",
	"nm": "Tiñosillos"
}, {
	"id": "Ávila",
	"nm": "Tolbaños"
}, {
	"id": "Ávila",
	"nm": "Tormellas"
}, {
	"id": "Ávila",
	"nm": "Tornadizos de Ávila"
}, {
	"id": "Ávila",
	"nm": "Torre, La"
}, {
	"id": "Ávila",
	"nm": "Tórtoles"
}, {
	"id": "Ávila",
	"nm": "Umbrías"
}, {
	"id": "Ávila",
	"nm": "Vadillo de la Sierra"
}, {
	"id": "Ávila",
	"nm": "Valdecasa"
}, {
	"id": "Ávila",
	"nm": "Vega de Santa María"
}, {
	"id": "Ávila",
	"nm": "Velayos"
}, {
	"id": "Ávila",
	"nm": "Villaflor"
}, {
	"id": "Ávila",
	"nm": "Villafranca de la Sierra"
}, {
	"id": "Ávila",
	"nm": "Villanueva de Ávila"
}, {
	"id": "Ávila",
	"nm": "Villanueva de Gómez"
}, {
	"id": "Ávila",
	"nm": "Villanueva del Aceral"
}, {
	"id": "Ávila",
	"nm": "Villanueva del Campillo"
}, {
	"id": "Ávila",
	"nm": "Villar de Corneja"
}, {
	"id": "Ávila",
	"nm": "Villarejo del Valle"
}, {
	"id": "Ávila",
	"nm": "Villatoro"
}, {
	"id": "Ávila",
	"nm": "Viñegra de Moraña"
}, {
	"id": "Ávila",
	"nm": "Vita"
}, {
	"id": "Ávila",
	"nm": "Zapardiel de la Cañada"
}, {
	"id": "Ávila",
	"nm": "Zapardiel de la Ribera"
}, {
	"id": "Badajoz",
	"nm": "Acedera"
}, {
	"id": "Badajoz",
	"nm": "Aceuchal"
}, {
	"id": "Badajoz",
	"nm": "Ahillones"
}, {
	"id": "Badajoz",
	"nm": "Alange"
}, {
	"id": "Badajoz",
	"nm": "Albuera, La"
}, {
	"id": "Badajoz",
	"nm": "Alburquerque"
}, {
	"id": "Badajoz",
	"nm": "Alconchel"
}, {
	"id": "Badajoz",
	"nm": "Alconera"
}, {
	"id": "Badajoz",
	"nm": "Aljucén"
}, {
	"id": "Badajoz",
	"nm": "Almendral"
}, {
	"id": "Badajoz",
	"nm": "Almendralejo"
}, {
	"id": "Badajoz",
	"nm": "Arroyo de San Serván"
}, {
	"id": "Badajoz",
	"nm": "Atalaya"
}, {
	"id": "Badajoz",
	"nm": "Azuaga"
}, {
	"id": "Badajoz",
	"nm": "Badajoz"
}, {
	"id": "Badajoz",
	"nm": "Barcarrota"
}, {
	"id": "Badajoz",
	"nm": "Baterno"
}, {
	"id": "Badajoz",
	"nm": "Benquerencia de la Serena"
}, {
	"id": "Badajoz",
	"nm": "Berlanga"
}, {
	"id": "Badajoz",
	"nm": "Bienvenida"
}, {
	"id": "Badajoz",
	"nm": "Bodonal de la Sierra"
}, {
	"id": "Badajoz",
	"nm": "Burguillos del Cerro"
}, {
	"id": "Badajoz",
	"nm": "Cabeza del Buey"
}, {
	"id": "Badajoz",
	"nm": "Cabeza la Vaca"
}, {
	"id": "Badajoz",
	"nm": "Calamonte"
}, {
	"id": "Badajoz",
	"nm": "Calera de León"
}, {
	"id": "Badajoz",
	"nm": "Calzadilla de los Barros"
}, {
	"id": "Badajoz",
	"nm": "Campanario"
}, {
	"id": "Badajoz",
	"nm": "Campillo de Llerena"
}, {
	"id": "Badajoz",
	"nm": "Capilla"
}, {
	"id": "Badajoz",
	"nm": "Carmonita"
}, {
	"id": "Badajoz",
	"nm": "Carrascalejo, El"
}, {
	"id": "Badajoz",
	"nm": "Casas de Don Pedro"
}, {
	"id": "Badajoz",
	"nm": "Casas de Reina"
}, {
	"id": "Badajoz",
	"nm": "Castilblanco"
}, {
	"id": "Badajoz",
	"nm": "Castuera"
}, {
	"id": "Badajoz",
	"nm": "Cheles"
}, {
	"id": "Badajoz",
	"nm": "Codosera, La"
}, {
	"id": "Badajoz",
	"nm": "Cordobilla de Lácara"
}, {
	"id": "Badajoz",
	"nm": "Coronada, La"
}, {
	"id": "Badajoz",
	"nm": "Corte de Peleas"
}, {
	"id": "Badajoz",
	"nm": "Cristina"
}, {
	"id": "Badajoz",
	"nm": "Don Álvaro"
}, {
	"id": "Badajoz",
	"nm": "Don Benito"
}, {
	"id": "Badajoz",
	"nm": "Entrín Bajo"
}, {
	"id": "Badajoz",
	"nm": "Esparragalejo"
}, {
	"id": "Badajoz",
	"nm": "Esparragosa de la Serena"
}, {
	"id": "Badajoz",
	"nm": "Esparragosa de Lares"
}, {
	"id": "Badajoz",
	"nm": "Feria"
}, {
	"id": "Badajoz",
	"nm": "Fregenal de la Sierra"
}, {
	"id": "Badajoz",
	"nm": "Fuenlabrada de los Montes"
}, {
	"id": "Badajoz",
	"nm": "Fuente de Cantos"
}, {
	"id": "Badajoz",
	"nm": "Fuente del Arco"
}, {
	"id": "Badajoz",
	"nm": "Fuente del Maestre"
}, {
	"id": "Badajoz",
	"nm": "Fuentes de León"
}, {
	"id": "Badajoz",
	"nm": "Garbayuela"
}, {
	"id": "Badajoz",
	"nm": "Garlitos"
}, {
	"id": "Badajoz",
	"nm": "Garrovilla, La"
}, {
	"id": "Badajoz",
	"nm": "Granja de Torrehermosa"
}, {
	"id": "Badajoz",
	"nm": "Guadiana del Caudillo"
}, {
	"id": "Badajoz",
	"nm": "Guareña"
}, {
	"id": "Badajoz",
	"nm": "Haba, La"
}, {
	"id": "Badajoz",
	"nm": "Helechosa de los Montes"
}, {
	"id": "Badajoz",
	"nm": "Herrera del Duque"
}, {
	"id": "Badajoz",
	"nm": "Higuera de la Serena"
}, {
	"id": "Badajoz",
	"nm": "Higuera de Llerena"
}, {
	"id": "Badajoz",
	"nm": "Higuera de Vargas"
}, {
	"id": "Badajoz",
	"nm": "Higuera la Real"
}, {
	"id": "Badajoz",
	"nm": "Hinojosa del Valle"
}, {
	"id": "Badajoz",
	"nm": "Hornachos"
}, {
	"id": "Badajoz",
	"nm": "Jerez de los Caballeros"
}, {
	"id": "Badajoz",
	"nm": "Lapa, La"
}, {
	"id": "Badajoz",
	"nm": "Llera"
}, {
	"id": "Badajoz",
	"nm": "Llerena"
}, {
	"id": "Badajoz",
	"nm": "Lobón"
}, {
	"id": "Badajoz",
	"nm": "Magacela"
}, {
	"id": "Badajoz",
	"nm": "Maguilla"
}, {
	"id": "Badajoz",
	"nm": "Malcocinado"
}, {
	"id": "Badajoz",
	"nm": "Malpartida de la Serena"
}, {
	"id": "Badajoz",
	"nm": "Manchita"
}, {
	"id": "Badajoz",
	"nm": "Medellín"
}, {
	"id": "Badajoz",
	"nm": "Medina de las Torres"
}, {
	"id": "Badajoz",
	"nm": "Mengabril"
}, {
	"id": "Badajoz",
	"nm": "Mérida"
}, {
	"id": "Badajoz",
	"nm": "Mirandilla"
}, {
	"id": "Badajoz",
	"nm": "Monesterio"
}, {
	"id": "Badajoz",
	"nm": "Montemolín"
}, {
	"id": "Badajoz",
	"nm": "Monterrubio de la Serena"
}, {
	"id": "Badajoz",
	"nm": "Montijo"
}, {
	"id": "Badajoz",
	"nm": "Morera, La"
}, {
	"id": "Badajoz",
	"nm": "Nava de Santiago, La"
}, {
	"id": "Badajoz",
	"nm": "Navalvillar de Pela"
}, {
	"id": "Badajoz",
	"nm": "Nogales"
}, {
	"id": "Badajoz",
	"nm": "Oliva de la Frontera"
}, {
	"id": "Badajoz",
	"nm": "Oliva de Mérida"
}, {
	"id": "Badajoz",
	"nm": "Olivenza"
}, {
	"id": "Badajoz",
	"nm": "Orellana de la Sierra"
}, {
	"id": "Badajoz",
	"nm": "Orellana la Vieja"
}, {
	"id": "Badajoz",
	"nm": "Palomas"
}, {
	"id": "Badajoz",
	"nm": "Parra, La"
}, {
	"id": "Badajoz",
	"nm": "Peñalsordo"
}, {
	"id": "Badajoz",
	"nm": "Peraleda del Zaucejo"
}, {
	"id": "Badajoz",
	"nm": "Puebla de Alcocer"
}, {
	"id": "Badajoz",
	"nm": "Puebla de la Calzada"
}, {
	"id": "Badajoz",
	"nm": "Puebla de la Reina"
}, {
	"id": "Badajoz",
	"nm": "Puebla de Obando"
}, {
	"id": "Badajoz",
	"nm": "Puebla de Sancho Pérez"
}, {
	"id": "Badajoz",
	"nm": "Puebla del Maestre"
}, {
	"id": "Badajoz",
	"nm": "Puebla del Prior"
}, {
	"id": "Badajoz",
	"nm": "Pueblonuevo del Guadiana"
}, {
	"id": "Badajoz",
	"nm": "Quintana de la Serena"
}, {
	"id": "Badajoz",
	"nm": "Reina"
}, {
	"id": "Badajoz",
	"nm": "Rena"
}, {
	"id": "Badajoz",
	"nm": "Retamal de Llerena"
}, {
	"id": "Badajoz",
	"nm": "Ribera del Fresno"
}, {
	"id": "Badajoz",
	"nm": "Risco"
}, {
	"id": "Badajoz",
	"nm": "Roca de la Sierra, La"
}, {
	"id": "Badajoz",
	"nm": "Salvaleón"
}, {
	"id": "Badajoz",
	"nm": "Salvatierra de los Barros"
}, {
	"id": "Badajoz",
	"nm": "San Pedro de Mérida"
}, {
	"id": "Badajoz",
	"nm": "San Vicente de Alcántara"
}, {
	"id": "Badajoz",
	"nm": "Sancti-Spíritus"
}, {
	"id": "Badajoz",
	"nm": "Santa Amalia"
}, {
	"id": "Badajoz",
	"nm": "Santa Marta"
}, {
	"id": "Badajoz",
	"nm": "Santos de Maimona, Los"
}, {
	"id": "Badajoz",
	"nm": "Segura de León"
}, {
	"id": "Badajoz",
	"nm": "Siruela"
}, {
	"id": "Badajoz",
	"nm": "Solana de los Barros"
}, {
	"id": "Badajoz",
	"nm": "Talarrubias"
}, {
	"id": "Badajoz",
	"nm": "Talavera la Real"
}, {
	"id": "Badajoz",
	"nm": "Táliga"
}, {
	"id": "Badajoz",
	"nm": "Tamurejo"
}, {
	"id": "Badajoz",
	"nm": "Torre de Miguel Sesmero"
}, {
	"id": "Badajoz",
	"nm": "Torremayor"
}, {
	"id": "Badajoz",
	"nm": "Torremejía"
}, {
	"id": "Badajoz",
	"nm": "Trasierra"
}, {
	"id": "Badajoz",
	"nm": "Trujillanos"
}, {
	"id": "Badajoz",
	"nm": "Usagre"
}, {
	"id": "Badajoz",
	"nm": "Valdecaballeros"
}, {
	"id": "Badajoz",
	"nm": "Valdelacalzada"
}, {
	"id": "Badajoz",
	"nm": "Valdetorres"
}, {
	"id": "Badajoz",
	"nm": "Valencia de las Torres"
}, {
	"id": "Badajoz",
	"nm": "Valencia del Mombuey"
}, {
	"id": "Badajoz",
	"nm": "Valencia del Ventoso"
}, {
	"id": "Badajoz",
	"nm": "Valle de la Serena"
}, {
	"id": "Badajoz",
	"nm": "Valle de Matamoros"
}, {
	"id": "Badajoz",
	"nm": "Valle de Santa Ana"
}, {
	"id": "Badajoz",
	"nm": "Valverde de Burguillos"
}, {
	"id": "Badajoz",
	"nm": "Valverde de Leganés"
}, {
	"id": "Badajoz",
	"nm": "Valverde de Llerena"
}, {
	"id": "Badajoz",
	"nm": "Valverde de Mérida"
}, {
	"id": "Badajoz",
	"nm": "Villafranca de los Barros"
}, {
	"id": "Badajoz",
	"nm": "Villagarcía de la Torre"
}, {
	"id": "Badajoz",
	"nm": "Villagonzalo"
}, {
	"id": "Badajoz",
	"nm": "Villalba de los Barros"
}, {
	"id": "Badajoz",
	"nm": "Villanueva de la Serena"
}, {
	"id": "Badajoz",
	"nm": "Villanueva del Fresno"
}, {
	"id": "Badajoz",
	"nm": "Villar de Rena"
}, {
	"id": "Badajoz",
	"nm": "Villar del Rey"
}, {
	"id": "Badajoz",
	"nm": "Villarta de los Montes"
}, {
	"id": "Badajoz",
	"nm": "Zafra"
}, {
	"id": "Badajoz",
	"nm": "Zahínos"
}, {
	"id": "Badajoz",
	"nm": "Zalamea de la Serena"
}, {
	"id": "Badajoz",
	"nm": "Zarza, La"
}, {
	"id": "Badajoz",
	"nm": "Zarza-Capilla"
}, {
	"id": "Asturias",
	"nm": "Alaior"
}, {
	"id": "Asturias",
	"nm": "Alaró"
}, {
	"id": "Asturias",
	"nm": "Alcúdia"
}, {
	"id": "Asturias",
	"nm": "Algaida"
}, {
	"id": "Asturias",
	"nm": "Andratx"
}, {
	"id": "Asturias",
	"nm": "Ariany"
}, {
	"id": "Asturias",
	"nm": "Artà"
}, {
	"id": "Asturias",
	"nm": "Banyalbufar"
}, {
	"id": "Asturias",
	"nm": "Binissalem"
}, {
	"id": "Asturias",
	"nm": "Búger"
}, {
	"id": "Asturias",
	"nm": "Bunyola"
}, {
	"id": "Asturias",
	"nm": "Calvià"
}, {
	"id": "Asturias",
	"nm": "Campanet"
}, {
	"id": "Asturias",
	"nm": "Campos"
}, {
	"id": "Asturias",
	"nm": "Capdepera"
}, {
	"id": "Asturias",
	"nm": "Castell, Es"
}, {
	"id": "Asturias",
	"nm": "Ciutadella de Menorca"
}, {
	"id": "Asturias",
	"nm": "Consell"
}, {
	"id": "Asturias",
	"nm": "Costitx"
}, {
	"id": "Asturias",
	"nm": "Deià"
}, {
	"id": "Asturias",
	"nm": "Eivissa"
}, {
	"id": "Asturias",
	"nm": "Escorca"
}, {
	"id": "Asturias",
	"nm": "Esporles"
}, {
	"id": "Asturias",
	"nm": "Estellencs"
}, {
	"id": "Asturias",
	"nm": "Felanitx"
}, {
	"id": "Asturias",
	"nm": "Ferreries"
}, {
	"id": "Asturias",
	"nm": "Formentera"
}, {
	"id": "Asturias",
	"nm": "Fornalutx"
}, {
	"id": "Asturias",
	"nm": "Inca"
}, {
	"id": "Asturias",
	"nm": "Lloret de Vistalegre"
}, {
	"id": "Asturias",
	"nm": "Lloseta"
}, {
	"id": "Asturias",
	"nm": "Llubí"
}, {
	"id": "Asturias",
	"nm": "Llucmajor"
}, {
	"id": "Asturias",
	"nm": "Manacor"
}, {
	"id": "Asturias",
	"nm": "Mancor de la Vall"
}, {
	"id": "Asturias",
	"nm": "Maó-Mahón"
}, {
	"id": "Asturias",
	"nm": "Maria de la Salut"
}, {
	"id": "Asturias",
	"nm": "Marratxí"
}, {
	"id": "Asturias",
	"nm": "Mercadal, Es"
}, {
	"id": "Asturias",
	"nm": "Migjorn Gran, Es"
}, {
	"id": "Asturias",
	"nm": "Montuïri"
}, {
	"id": "Asturias",
	"nm": "Muro"
}, {
	"id": "Asturias",
	"nm": "Palma de Mallorca"
}, {
	"id": "Asturias",
	"nm": "Petra"
}, {
	"id": "Asturias",
	"nm": "Pobla, Sa"
}, {
	"id": "Asturias",
	"nm": "Pollença"
}, {
	"id": "Asturias",
	"nm": "Porreres"
}, {
	"id": "Asturias",
	"nm": "Puigpunyent"
}, {
	"id": "Asturias",
	"nm": "Salines, Ses"
}, {
	"id": "Asturias",
	"nm": "Sant Antoni de Portmany"
}, {
	"id": "Asturias",
	"nm": "Sant Joan"
}, {
	"id": "Asturias",
	"nm": "Sant Joan de Labritja"
}, {
	"id": "Asturias",
	"nm": "Sant Josep de sa Talaia"
}, {
	"id": "Asturias",
	"nm": "Sant Llorenç des Cardassar"
}, {
	"id": "Asturias",
	"nm": "Sant Lluís"
}, {
	"id": "Asturias",
	"nm": "Santa Eugènia"
}, {
	"id": "Asturias",
	"nm": "Santa Eulària des Riu"
}, {
	"id": "Asturias",
	"nm": "Santa Margalida"
}, {
	"id": "Asturias",
	"nm": "Santa María del Camí"
}, {
	"id": "Asturias",
	"nm": "Santanyí"
}, {
	"id": "Asturias",
	"nm": "Selva"
}, {
	"id": "Asturias",
	"nm": "Sencelles"
}, {
	"id": "Asturias",
	"nm": "Sineu"
}, {
	"id": "Asturias",
	"nm": "Sóller"
}, {
	"id": "Asturias",
	"nm": "Son Servera"
}, {
	"id": "Asturias",
	"nm": "Valldemossa"
}, {
	"id": "Asturias",
	"nm": "Vilafranca de Bonany"
}, {
	"id": "Barcelona",
	"nm": "Abrera"
}, {
	"id": "Barcelona",
	"nm": "Aguilar de Segarra"
}, {
	"id": "Barcelona",
	"nm": "Aiguafreda"
}, {
	"id": "Barcelona",
	"nm": "Alella"
}, {
	"id": "Barcelona",
	"nm": "Alpens"
}, {
	"id": "Barcelona",
	"nm": "Ametlla del Vallès, L'"
}, {
	"id": "Barcelona",
	"nm": "Arenys de Mar"
}, {
	"id": "Barcelona",
	"nm": "Arenys de Munt"
}, {
	"id": "Barcelona",
	"nm": "Argençola"
}, {
	"id": "Barcelona",
	"nm": "Argentona"
}, {
	"id": "Barcelona",
	"nm": "Artés"
}, {
	"id": "Barcelona",
	"nm": "Avià"
}, {
	"id": "Barcelona",
	"nm": "Avinyó"
}, {
	"id": "Barcelona",
	"nm": "Avinyonet del Penedès"
}, {
	"id": "Barcelona",
	"nm": "Badalona"
}, {
	"id": "Barcelona",
	"nm": "Badia del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Bagà"
}, {
	"id": "Barcelona",
	"nm": "Balenyà"
}, {
	"id": "Barcelona",
	"nm": "Balsareny"
}, {
	"id": "Barcelona",
	"nm": "Barberà del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Barcelona"
}, {
	"id": "Barcelona",
	"nm": "Begues"
}, {
	"id": "Barcelona",
	"nm": "Bellprat"
}, {
	"id": "Barcelona",
	"nm": "Berga"
}, {
	"id": "Barcelona",
	"nm": "Bigues i Riells"
}, {
	"id": "Barcelona",
	"nm": "Borredà"
}, {
	"id": "Barcelona",
	"nm": "Bruc, El"
}, {
	"id": "Barcelona",
	"nm": "Brull, El"
}, {
	"id": "Barcelona",
	"nm": "Cabanyes, Les"
}, {
	"id": "Barcelona",
	"nm": "Cabrera d'Anoia"
}, {
	"id": "Barcelona",
	"nm": "Cabrera de Mar"
}, {
	"id": "Barcelona",
	"nm": "Cabrils"
}, {
	"id": "Barcelona",
	"nm": "Calaf"
}, {
	"id": "Barcelona",
	"nm": "Calders"
}, {
	"id": "Barcelona",
	"nm": "Caldes de Montbui"
}, {
	"id": "Barcelona",
	"nm": "Caldes d'Estrac"
}, {
	"id": "Barcelona",
	"nm": "Calella"
}, {
	"id": "Barcelona",
	"nm": "Calldetenes"
}, {
	"id": "Barcelona",
	"nm": "Callús"
}, {
	"id": "Barcelona",
	"nm": "Calonge de Segarra"
}, {
	"id": "Barcelona",
	"nm": "Campins"
}, {
	"id": "Barcelona",
	"nm": "Canet de Mar"
}, {
	"id": "Barcelona",
	"nm": "Canovelles"
}, {
	"id": "Barcelona",
	"nm": "Cànoves i Samalús"
}, {
	"id": "Barcelona",
	"nm": "Canyelles"
}, {
	"id": "Barcelona",
	"nm": "Capellades"
}, {
	"id": "Barcelona",
	"nm": "Capolat"
}, {
	"id": "Barcelona",
	"nm": "Cardedeu"
}, {
	"id": "Barcelona",
	"nm": "Cardona"
}, {
	"id": "Barcelona",
	"nm": "Carme"
}, {
	"id": "Barcelona",
	"nm": "Casserres"
}, {
	"id": "Barcelona",
	"nm": "Castell de l'Areny"
}, {
	"id": "Barcelona",
	"nm": "Castellar de n'Hug"
}, {
	"id": "Barcelona",
	"nm": "Castellar del Riu"
}, {
	"id": "Barcelona",
	"nm": "Castellar del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Castellbell i el Vilar"
}, {
	"id": "Barcelona",
	"nm": "Castellbisbal"
}, {
	"id": "Barcelona",
	"nm": "Castellcir"
}, {
	"id": "Barcelona",
	"nm": "Castelldefels"
}, {
	"id": "Barcelona",
	"nm": "Castellet i la Gornal"
}, {
	"id": "Barcelona",
	"nm": "Castellfollit de Riubregós"
}, {
	"id": "Barcelona",
	"nm": "Castellfollit del Boix"
}, {
	"id": "Barcelona",
	"nm": "Castellgalí"
}, {
	"id": "Barcelona",
	"nm": "Castellnou de Bages"
}, {
	"id": "Barcelona",
	"nm": "Castellolí"
}, {
	"id": "Barcelona",
	"nm": "Castellterçol"
}, {
	"id": "Barcelona",
	"nm": "Castellví de la Marca"
}, {
	"id": "Barcelona",
	"nm": "Castellví de Rosanes"
}, {
	"id": "Barcelona",
	"nm": "Centelles"
}, {
	"id": "Barcelona",
	"nm": "Cercs"
}, {
	"id": "Barcelona",
	"nm": "Cerdanyola del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Cervelló"
}, {
	"id": "Barcelona",
	"nm": "Collbató"
}, {
	"id": "Barcelona",
	"nm": "Collsuspina"
}, {
	"id": "Barcelona",
	"nm": "Copons"
}, {
	"id": "Barcelona",
	"nm": "Corbera de Llobregat"
}, {
	"id": "Barcelona",
	"nm": "Cornellà de Llobregat"
}, {
	"id": "Barcelona",
	"nm": "Cubelles"
}, {
	"id": "Barcelona",
	"nm": "Dosrius"
}, {
	"id": "Barcelona",
	"nm": "Esparreguera"
}, {
	"id": "Barcelona",
	"nm": "Esplugues de Llobregat"
}, {
	"id": "Barcelona",
	"nm": "Espunyola, L'"
}, {
	"id": "Barcelona",
	"nm": "Esquirol, L'"
}, {
	"id": "Barcelona",
	"nm": "Estany, L'"
}, {
	"id": "Barcelona",
	"nm": "Figaró-Montmany"
}, {
	"id": "Barcelona",
	"nm": "Fígols"
}, {
	"id": "Barcelona",
	"nm": "Fogars de la Selva"
}, {
	"id": "Barcelona",
	"nm": "Fogars de Montclús"
}, {
	"id": "Barcelona",
	"nm": "Folgueroles"
}, {
	"id": "Barcelona",
	"nm": "Fonollosa"
}, {
	"id": "Barcelona",
	"nm": "Font-rubí"
}, {
	"id": "Barcelona",
	"nm": "Franqueses del Vallès, Les"
}, {
	"id": "Barcelona",
	"nm": "Gaià"
}, {
	"id": "Barcelona",
	"nm": "Gallifa"
}, {
	"id": "Barcelona",
	"nm": "Garriga, La"
}, {
	"id": "Barcelona",
	"nm": "Gavà"
}, {
	"id": "Barcelona",
	"nm": "Gelida"
}, {
	"id": "Barcelona",
	"nm": "Gironella"
}, {
	"id": "Barcelona",
	"nm": "Gisclareny"
}, {
	"id": "Barcelona",
	"nm": "Granada, La"
}, {
	"id": "Barcelona",
	"nm": "Granera"
}, {
	"id": "Barcelona",
	"nm": "Granollers"
}, {
	"id": "Barcelona",
	"nm": "Gualba"
}, {
	"id": "Barcelona",
	"nm": "Guardiola de Berguedà"
}, {
	"id": "Barcelona",
	"nm": "Gurb"
}, {
	"id": "Barcelona",
	"nm": "Hospitalet de Llobregat, L'"
}, {
	"id": "Barcelona",
	"nm": "Hostalets de Pierola, Els"
}, {
	"id": "Barcelona",
	"nm": "Igualada"
}, {
	"id": "Barcelona",
	"nm": "Jorba"
}, {
	"id": "Barcelona",
	"nm": "Llacuna, La"
}, {
	"id": "Barcelona",
	"nm": "Llagosta, La"
}, {
	"id": "Barcelona",
	"nm": "Lliçà d'Amunt"
}, {
	"id": "Barcelona",
	"nm": "Lliçà de Vall"
}, {
	"id": "Barcelona",
	"nm": "Llinars del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Lluçà"
}, {
	"id": "Barcelona",
	"nm": "Malgrat de Mar"
}, {
	"id": "Barcelona",
	"nm": "Malla"
}, {
	"id": "Barcelona",
	"nm": "Manlleu"
}, {
	"id": "Barcelona",
	"nm": "Manresa"
}, {
	"id": "Barcelona",
	"nm": "Marganell"
}, {
	"id": "Barcelona",
	"nm": "Martorell"
}, {
	"id": "Barcelona",
	"nm": "Martorelles"
}, {
	"id": "Barcelona",
	"nm": "Masies de Roda, Les"
}, {
	"id": "Barcelona",
	"nm": "Masies de Voltregà, Les"
}, {
	"id": "Barcelona",
	"nm": "Masnou, El"
}, {
	"id": "Barcelona",
	"nm": "Masquefa"
}, {
	"id": "Barcelona",
	"nm": "Matadepera"
}, {
	"id": "Barcelona",
	"nm": "Mataró"
}, {
	"id": "Barcelona",
	"nm": "Mediona"
}, {
	"id": "Barcelona",
	"nm": "Moià"
}, {
	"id": "Barcelona",
	"nm": "Molins de Rei"
}, {
	"id": "Barcelona",
	"nm": "Mollet del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Monistrol de Calders"
}, {
	"id": "Barcelona",
	"nm": "Monistrol de Montserrat"
}, {
	"id": "Barcelona",
	"nm": "Montcada i Reixac"
}, {
	"id": "Barcelona",
	"nm": "Montclar"
}, {
	"id": "Barcelona",
	"nm": "Montesquiu"
}, {
	"id": "Barcelona",
	"nm": "Montgat"
}, {
	"id": "Barcelona",
	"nm": "Montmajor"
}, {
	"id": "Barcelona",
	"nm": "Montmaneu"
}, {
	"id": "Barcelona",
	"nm": "Montmeló"
}, {
	"id": "Barcelona",
	"nm": "Montornès del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Montseny"
}, {
	"id": "Barcelona",
	"nm": "Muntanyola"
}, {
	"id": "Barcelona",
	"nm": "Mura"
}, {
	"id": "Barcelona",
	"nm": "Navarcles"
}, {
	"id": "Barcelona",
	"nm": "Navàs"
}, {
	"id": "Barcelona",
	"nm": "Nou de Berguedà, La"
}, {
	"id": "Barcelona",
	"nm": "Òdena"
}, {
	"id": "Barcelona",
	"nm": "Olèrdola"
}, {
	"id": "Barcelona",
	"nm": "Olesa de Bonesvalls"
}, {
	"id": "Barcelona",
	"nm": "Olesa de Montserrat"
}, {
	"id": "Barcelona",
	"nm": "Olivella"
}, {
	"id": "Barcelona",
	"nm": "Olost"
}, {
	"id": "Barcelona",
	"nm": "Olvan"
}, {
	"id": "Barcelona",
	"nm": "Orís"
}, {
	"id": "Barcelona",
	"nm": "Oristà"
}, {
	"id": "Barcelona",
	"nm": "Orpí"
}, {
	"id": "Barcelona",
	"nm": "Òrrius"
}, {
	"id": "Barcelona",
	"nm": "Pacs del Penedès"
}, {
	"id": "Barcelona",
	"nm": "Palafolls"
}, {
	"id": "Barcelona",
	"nm": "Palau-solità i Plegamans"
}, {
	"id": "Barcelona",
	"nm": "Pallejà"
}, {
	"id": "Barcelona",
	"nm": "Palma de Cervelló, La"
}, {
	"id": "Barcelona",
	"nm": "Papiol, El"
}, {
	"id": "Barcelona",
	"nm": "Parets del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Perafita"
}, {
	"id": "Barcelona",
	"nm": "Piera"
}, {
	"id": "Barcelona",
	"nm": "Pineda de Mar"
}, {
	"id": "Barcelona",
	"nm": "Pla del Penedès, El"
}, {
	"id": "Barcelona",
	"nm": "Pobla de Claramunt, La"
}, {
	"id": "Barcelona",
	"nm": "Pobla de Lillet, La"
}, {
	"id": "Barcelona",
	"nm": "Polinyà"
}, {
	"id": "Barcelona",
	"nm": "Pont de Vilomara i Rocafort, El"
}, {
	"id": "Barcelona",
	"nm": "Pontons"
}, {
	"id": "Barcelona",
	"nm": "Prat de Llobregat, El"
}, {
	"id": "Barcelona",
	"nm": "Prats de Lluçanès"
}, {
	"id": "Barcelona",
	"nm": "Prats de Rei, Els"
}, {
	"id": "Barcelona",
	"nm": "Premià de Dalt"
}, {
	"id": "Barcelona",
	"nm": "Premià de Mar"
}, {
	"id": "Barcelona",
	"nm": "Puigdàlber"
}, {
	"id": "Barcelona",
	"nm": "Puig-reig"
}, {
	"id": "Barcelona",
	"nm": "Pujalt"
}, {
	"id": "Barcelona",
	"nm": "Quar, La"
}, {
	"id": "Barcelona",
	"nm": "Rajadell"
}, {
	"id": "Barcelona",
	"nm": "Rellinars"
}, {
	"id": "Barcelona",
	"nm": "Ripollet"
}, {
	"id": "Barcelona",
	"nm": "Roca del Vallès, La"
}, {
	"id": "Barcelona",
	"nm": "Roda de Ter"
}, {
	"id": "Barcelona",
	"nm": "Rubí"
}, {
	"id": "Barcelona",
	"nm": "Rubió"
}, {
	"id": "Barcelona",
	"nm": "Rupit i Pruit"
}, {
	"id": "Barcelona",
	"nm": "Sabadell"
}, {
	"id": "Barcelona",
	"nm": "Sagàs"
}, {
	"id": "Barcelona",
	"nm": "Saldes"
}, {
	"id": "Barcelona",
	"nm": "Sallent"
}, {
	"id": "Barcelona",
	"nm": "Sant Adrià de Besòs"
}, {
	"id": "Barcelona",
	"nm": "Sant Agustí de Lluçanès"
}, {
	"id": "Barcelona",
	"nm": "Sant Andreu de la Barca"
}, {
	"id": "Barcelona",
	"nm": "Sant Andreu de Llavaneres"
}, {
	"id": "Barcelona",
	"nm": "Sant Antoni de Vilamajor"
}, {
	"id": "Barcelona",
	"nm": "Sant Bartomeu del Grau"
}, {
	"id": "Barcelona",
	"nm": "Sant Boi de Llobregat"
}, {
	"id": "Barcelona",
	"nm": "Sant Boi de Lluçanès"
}, {
	"id": "Barcelona",
	"nm": "Sant Cebrià de Vallalta"
}, {
	"id": "Barcelona",
	"nm": "Sant Celoni"
}, {
	"id": "Barcelona",
	"nm": "Sant Climent de Llobregat"
}, {
	"id": "Barcelona",
	"nm": "Sant Cugat del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Sant Cugat Sesgarrigues"
}, {
	"id": "Barcelona",
	"nm": "Sant Esteve de Palautordera"
}, {
	"id": "Barcelona",
	"nm": "Sant Esteve Sesrovires"
}, {
	"id": "Barcelona",
	"nm": "Sant Feliu de Codines"
}, {
	"id": "Barcelona",
	"nm": "Sant Feliu de Llobregat"
}, {
	"id": "Barcelona",
	"nm": "Sant Feliu Sasserra"
}, {
	"id": "Barcelona",
	"nm": "Sant Fost de Campsentelles"
}, {
	"id": "Barcelona",
	"nm": "Sant Fruitós de Bages"
}, {
	"id": "Barcelona",
	"nm": "Sant Hipòlit de Voltregà"
}, {
	"id": "Barcelona",
	"nm": "Sant Iscle de Vallalta"
}, {
	"id": "Barcelona",
	"nm": "Sant Jaume de Frontanyà"
}, {
	"id": "Barcelona",
	"nm": "Sant Joan de Vilatorrada"
}, {
	"id": "Barcelona",
	"nm": "Sant Joan Despí"
}, {
	"id": "Barcelona",
	"nm": "Sant Julià de Cerdanyola"
}, {
	"id": "Barcelona",
	"nm": "Sant Julià de Vilatorta"
}, {
	"id": "Barcelona",
	"nm": "Sant Just Desvern"
}, {
	"id": "Barcelona",
	"nm": "Sant Llorenç d'Hortons"
}, {
	"id": "Barcelona",
	"nm": "Sant Llorenç Savall"
}, {
	"id": "Barcelona",
	"nm": "Sant Martí d'Albars"
}, {
	"id": "Barcelona",
	"nm": "Sant Martí de Centelles"
}, {
	"id": "Barcelona",
	"nm": "Sant Martí de Tous"
}, {
	"id": "Barcelona",
	"nm": "Sant Martí Sarroca"
}, {
	"id": "Barcelona",
	"nm": "Sant Martí Sesgueioles"
}, {
	"id": "Barcelona",
	"nm": "Sant Mateu de Bages"
}, {
	"id": "Barcelona",
	"nm": "Sant Pere de Ribes"
}, {
	"id": "Barcelona",
	"nm": "Sant Pere de Riudebitlles"
}, {
	"id": "Barcelona",
	"nm": "Sant Pere de Torelló"
}, {
	"id": "Barcelona",
	"nm": "Sant Pere de Vilamajor"
}, {
	"id": "Barcelona",
	"nm": "Sant Pere Sallavinera"
}, {
	"id": "Barcelona",
	"nm": "Sant Pol de Mar"
}, {
	"id": "Barcelona",
	"nm": "Sant Quintí de Mediona"
}, {
	"id": "Barcelona",
	"nm": "Sant Quirze de Besora"
}, {
	"id": "Barcelona",
	"nm": "Sant Quirze del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Sant Quirze Safaja"
}, {
	"id": "Barcelona",
	"nm": "Sant Sadurní d'Anoia"
}, {
	"id": "Barcelona",
	"nm": "Sant Sadurní d'Osormort"
}, {
	"id": "Barcelona",
	"nm": "Sant Salvador de Guardiola"
}, {
	"id": "Barcelona",
	"nm": "Sant Vicenç de Castellet"
}, {
	"id": "Barcelona",
	"nm": "Sant Vicenç de Montalt"
}, {
	"id": "Barcelona",
	"nm": "Sant Vicenç de Torelló"
}, {
	"id": "Barcelona",
	"nm": "Sant Vicenç dels Horts"
}, {
	"id": "Barcelona",
	"nm": "Santa Cecília de Voltregà"
}, {
	"id": "Barcelona",
	"nm": "Santa Coloma de Cervelló"
}, {
	"id": "Barcelona",
	"nm": "Santa Coloma de Gramenet"
}, {
	"id": "Barcelona",
	"nm": "Santa Eugènia de Berga"
}, {
	"id": "Barcelona",
	"nm": "Santa Eulàlia de Riuprimer"
}, {
	"id": "Barcelona",
	"nm": "Santa Eulàlia de Ronçana"
}, {
	"id": "Barcelona",
	"nm": "Santa Fe del Penedès"
}, {
	"id": "Barcelona",
	"nm": "Santa Margarida de Montbui"
}, {
	"id": "Barcelona",
	"nm": "Santa Margarida i els Monjos"
}, {
	"id": "Barcelona",
	"nm": "Santa Maria de Besora"
}, {
	"id": "Barcelona",
	"nm": "Santa Maria de Martorelles"
}, {
	"id": "Barcelona",
	"nm": "Santa Maria de Merlès"
}, {
	"id": "Barcelona",
	"nm": "Santa Maria de Miralles"
}, {
	"id": "Barcelona",
	"nm": "Santa Maria de Palautordera"
}, {
	"id": "Barcelona",
	"nm": "Santa Maria d'Oló"
}, {
	"id": "Barcelona",
	"nm": "Santa Perpètua de Mogoda"
}, {
	"id": "Barcelona",
	"nm": "Santa Susanna"
}, {
	"id": "Barcelona",
	"nm": "Santpedor"
}, {
	"id": "Barcelona",
	"nm": "Sentmenat"
}, {
	"id": "Barcelona",
	"nm": "Seva"
}, {
	"id": "Barcelona",
	"nm": "Sitges"
}, {
	"id": "Barcelona",
	"nm": "Sobremunt"
}, {
	"id": "Barcelona",
	"nm": "Sora"
}, {
	"id": "Barcelona",
	"nm": "Subirats"
}, {
	"id": "Barcelona",
	"nm": "Súria"
}, {
	"id": "Barcelona",
	"nm": "Tagamanent"
}, {
	"id": "Barcelona",
	"nm": "Talamanca"
}, {
	"id": "Barcelona",
	"nm": "Taradell"
}, {
	"id": "Barcelona",
	"nm": "Tavèrnoles"
}, {
	"id": "Barcelona",
	"nm": "Tavertet"
}, {
	"id": "Barcelona",
	"nm": "Teià"
}, {
	"id": "Barcelona",
	"nm": "Terrassa"
}, {
	"id": "Barcelona",
	"nm": "Tiana"
}, {
	"id": "Barcelona",
	"nm": "Tona"
}, {
	"id": "Barcelona",
	"nm": "Tordera"
}, {
	"id": "Barcelona",
	"nm": "Torelló"
}, {
	"id": "Barcelona",
	"nm": "Torre de Claramunt, La"
}, {
	"id": "Barcelona",
	"nm": "Torrelavit"
}, {
	"id": "Barcelona",
	"nm": "Torrelles de Foix"
}, {
	"id": "Barcelona",
	"nm": "Torrelles de Llobregat"
}, {
	"id": "Barcelona",
	"nm": "Ullastrell"
}, {
	"id": "Barcelona",
	"nm": "Vacarisses"
}, {
	"id": "Barcelona",
	"nm": "Vallbona d'Anoia"
}, {
	"id": "Barcelona",
	"nm": "Vallcebre"
}, {
	"id": "Barcelona",
	"nm": "Vallgorguina"
}, {
	"id": "Barcelona",
	"nm": "Vallirana"
}, {
	"id": "Barcelona",
	"nm": "Vallromanes"
}, {
	"id": "Barcelona",
	"nm": "Veciana"
}, {
	"id": "Barcelona",
	"nm": "Vic"
}, {
	"id": "Barcelona",
	"nm": "Vilada"
}, {
	"id": "Barcelona",
	"nm": "Viladecans"
}, {
	"id": "Barcelona",
	"nm": "Viladecavalls"
}, {
	"id": "Barcelona",
	"nm": "Vilafranca del Penedès"
}, {
	"id": "Barcelona",
	"nm": "Vilalba Sasserra"
}, {
	"id": "Barcelona",
	"nm": "Vilanova de Sau"
}, {
	"id": "Barcelona",
	"nm": "Vilanova del Camí"
}, {
	"id": "Barcelona",
	"nm": "Vilanova del Vallès"
}, {
	"id": "Barcelona",
	"nm": "Vilanova i la Geltrú"
}, {
	"id": "Barcelona",
	"nm": "Vilassar de Dalt"
}, {
	"id": "Barcelona",
	"nm": "Vilassar de Mar"
}, {
	"id": "Barcelona",
	"nm": "Vilobí del Penedès"
}, {
	"id": "Barcelona",
	"nm": "Viver i Serrateix"
}, {
	"id": "Burgos",
	"nm": "Abajas"
}, {
	"id": "Burgos",
	"nm": "Adrada de Haza"
}, {
	"id": "Burgos",
	"nm": "Aguas Cándidas"
}, {
	"id": "Burgos",
	"nm": "Aguilar de Bureba"
}, {
	"id": "Burgos",
	"nm": "Albillos"
}, {
	"id": "Burgos",
	"nm": "Alcocero de Mola"
}, {
	"id": "Burgos",
	"nm": "Alfoz de Bricia"
}, {
	"id": "Burgos",
	"nm": "Alfoz de Quintanadueñas"
}, {
	"id": "Burgos",
	"nm": "Alfoz de Santa Gadea"
}, {
	"id": "Burgos",
	"nm": "Altable"
}, {
	"id": "Burgos",
	"nm": "Altos, Los"
}, {
	"id": "Burgos",
	"nm": "Ameyugo"
}, {
	"id": "Burgos",
	"nm": "Anguix"
}, {
	"id": "Burgos",
	"nm": "Aranda de Duero"
}, {
	"id": "Burgos",
	"nm": "Arandilla"
}, {
	"id": "Burgos",
	"nm": "Arauzo de Miel"
}, {
	"id": "Burgos",
	"nm": "Arauzo de Salce"
}, {
	"id": "Burgos",
	"nm": "Arauzo de Torre"
}, {
	"id": "Burgos",
	"nm": "Arcos"
}, {
	"id": "Burgos",
	"nm": "Arenillas de Riopisuerga"
}, {
	"id": "Burgos",
	"nm": "Arija"
}, {
	"id": "Burgos",
	"nm": "Arlanzón"
}, {
	"id": "Burgos",
	"nm": "Arraya de Oca"
}, {
	"id": "Burgos",
	"nm": "Atapuerca"
}, {
	"id": "Burgos",
	"nm": "Ausines, Los"
}, {
	"id": "Burgos",
	"nm": "Avellanosa de Muñó"
}, {
	"id": "Burgos",
	"nm": "Bahabón de Esgueva"
}, {
	"id": "Burgos",
	"nm": "Balbases, Los"
}, {
	"id": "Burgos",
	"nm": "Baños de Valdearados"
}, {
	"id": "Burgos",
	"nm": "Bañuelos de Bureba"
}, {
	"id": "Burgos",
	"nm": "Barbadillo de Herreros"
}, {
	"id": "Burgos",
	"nm": "Barbadillo del Mercado"
}, {
	"id": "Burgos",
	"nm": "Barbadillo del Pez"
}, {
	"id": "Burgos",
	"nm": "Barrio de Muñó"
}, {
	"id": "Burgos",
	"nm": "Barrios de Bureba, Los"
}, {
	"id": "Burgos",
	"nm": "Barrios de Colina"
}, {
	"id": "Burgos",
	"nm": "Basconcillos del Tozo"
}, {
	"id": "Burgos",
	"nm": "Bascuñana"
}, {
	"id": "Burgos",
	"nm": "Belbimbre"
}, {
	"id": "Burgos",
	"nm": "Belorado"
}, {
	"id": "Burgos",
	"nm": "Berberana"
}, {
	"id": "Burgos",
	"nm": "Berlangas de Roa"
}, {
	"id": "Burgos",
	"nm": "Berzosa de Bureba"
}, {
	"id": "Burgos",
	"nm": "Bozoó"
}, {
	"id": "Burgos",
	"nm": "Brazacorta"
}, {
	"id": "Burgos",
	"nm": "Briviesca"
}, {
	"id": "Burgos",
	"nm": "Bugedo"
}, {
	"id": "Burgos",
	"nm": "Buniel"
}, {
	"id": "Burgos",
	"nm": "Burgos"
}, {
	"id": "Burgos",
	"nm": "Busto de Bureba"
}, {
	"id": "Burgos",
	"nm": "Cabañes de Esgueva"
}, {
	"id": "Burgos",
	"nm": "Cabezón de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Caleruega"
}, {
	"id": "Burgos",
	"nm": "Campillo de Aranda"
}, {
	"id": "Burgos",
	"nm": "Campolara"
}, {
	"id": "Burgos",
	"nm": "Canicosa de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Cantabrana"
}, {
	"id": "Burgos",
	"nm": "Carazo"
}, {
	"id": "Burgos",
	"nm": "Carcedo de Bureba"
}, {
	"id": "Burgos",
	"nm": "Carcedo de Burgos"
}, {
	"id": "Burgos",
	"nm": "Cardeñadijo"
}, {
	"id": "Burgos",
	"nm": "Cardeñajimeno"
}, {
	"id": "Burgos",
	"nm": "Cardeñuela Riopico"
}, {
	"id": "Burgos",
	"nm": "Carrias"
}, {
	"id": "Burgos",
	"nm": "Cascajares de Bureba"
}, {
	"id": "Burgos",
	"nm": "Cascajares de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Castellanos de Castro"
}, {
	"id": "Burgos",
	"nm": "Castil de Peones"
}, {
	"id": "Burgos",
	"nm": "Castildelgado"
}, {
	"id": "Burgos",
	"nm": "Castrillo de la Reina"
}, {
	"id": "Burgos",
	"nm": "Castrillo de la Vega"
}, {
	"id": "Burgos",
	"nm": "Castrillo de Riopisuerga"
}, {
	"id": "Burgos",
	"nm": "Castrillo del Val"
}, {
	"id": "Burgos",
	"nm": "Castrillo Mota de Judíos"
}, {
	"id": "Burgos",
	"nm": "Castrojeriz"
}, {
	"id": "Burgos",
	"nm": "Cavia"
}, {
	"id": "Burgos",
	"nm": "Cayuela"
}, {
	"id": "Burgos",
	"nm": "Cebrecos"
}, {
	"id": "Burgos",
	"nm": "Celada del Camino"
}, {
	"id": "Burgos",
	"nm": "Cerezo de Río Tirón"
}, {
	"id": "Burgos",
	"nm": "Cerratón de Juarros"
}, {
	"id": "Burgos",
	"nm": "Ciadoncha"
}, {
	"id": "Burgos",
	"nm": "Cillaperlata"
}, {
	"id": "Burgos",
	"nm": "Cilleruelo de Abajo"
}, {
	"id": "Burgos",
	"nm": "Cilleruelo de Arriba"
}, {
	"id": "Burgos",
	"nm": "Ciruelos de Cervera"
}, {
	"id": "Burgos",
	"nm": "Cogollos"
}, {
	"id": "Burgos",
	"nm": "Condado de Treviño"
}, {
	"id": "Burgos",
	"nm": "Contreras"
}, {
	"id": "Burgos",
	"nm": "Coruña del Conde"
}, {
	"id": "Burgos",
	"nm": "Covarrubias"
}, {
	"id": "Burgos",
	"nm": "Cubillo del Campo"
}, {
	"id": "Burgos",
	"nm": "Cubo de Bureba"
}, {
	"id": "Burgos",
	"nm": "Cueva de Roa, La"
}, {
	"id": "Burgos",
	"nm": "Cuevas de San Clemente"
}, {
	"id": "Burgos",
	"nm": "Encío"
}, {
	"id": "Burgos",
	"nm": "Espinosa de Cervera"
}, {
	"id": "Burgos",
	"nm": "Espinosa de los Monteros"
}, {
	"id": "Burgos",
	"nm": "Espinosa del Camino"
}, {
	"id": "Burgos",
	"nm": "Estépar"
}, {
	"id": "Burgos",
	"nm": "Fontioso"
}, {
	"id": "Burgos",
	"nm": "Frandovínez"
}, {
	"id": "Burgos",
	"nm": "Fresneda de la Sierra Tirón"
}, {
	"id": "Burgos",
	"nm": "Fresneña"
}, {
	"id": "Burgos",
	"nm": "Fresnillo de las Dueñas"
}, {
	"id": "Burgos",
	"nm": "Fresno de Río Tirón"
}, {
	"id": "Burgos",
	"nm": "Fresno de Rodilla"
}, {
	"id": "Burgos",
	"nm": "Frías"
}, {
	"id": "Burgos",
	"nm": "Fuentebureba"
}, {
	"id": "Burgos",
	"nm": "Fuentecén"
}, {
	"id": "Burgos",
	"nm": "Fuentelcésped"
}, {
	"id": "Burgos",
	"nm": "Fuentelisendo"
}, {
	"id": "Burgos",
	"nm": "Fuentemolinos"
}, {
	"id": "Burgos",
	"nm": "Fuentenebro"
}, {
	"id": "Burgos",
	"nm": "Fuentespina"
}, {
	"id": "Burgos",
	"nm": "Galbarros"
}, {
	"id": "Burgos",
	"nm": "Gallega, La"
}, {
	"id": "Burgos",
	"nm": "Grijalba"
}, {
	"id": "Burgos",
	"nm": "Grisaleña"
}, {
	"id": "Burgos",
	"nm": "Gumiel de Izán"
}, {
	"id": "Burgos",
	"nm": "Gumiel de Mercado"
}, {
	"id": "Burgos",
	"nm": "Hacinas"
}, {
	"id": "Burgos",
	"nm": "Haza"
}, {
	"id": "Burgos",
	"nm": "Hontanas"
}, {
	"id": "Burgos",
	"nm": "Hontangas"
}, {
	"id": "Burgos",
	"nm": "Hontoria de la Cantera"
}, {
	"id": "Burgos",
	"nm": "Hontoria de Valdearados"
}, {
	"id": "Burgos",
	"nm": "Hontoria del Pinar"
}, {
	"id": "Burgos",
	"nm": "Hormazas, Las"
}, {
	"id": "Burgos",
	"nm": "Hornillos del Camino"
}, {
	"id": "Burgos",
	"nm": "Horra, La"
}, {
	"id": "Burgos",
	"nm": "Hortigüela"
}, {
	"id": "Burgos",
	"nm": "Hoyales de Roa"
}, {
	"id": "Burgos",
	"nm": "Huérmeces"
}, {
	"id": "Burgos",
	"nm": "Huerta de Arriba"
}, {
	"id": "Burgos",
	"nm": "Huerta de Rey"
}, {
	"id": "Burgos",
	"nm": "Humada"
}, {
	"id": "Burgos",
	"nm": "Hurones"
}, {
	"id": "Burgos",
	"nm": "Ibeas de Juarros"
}, {
	"id": "Burgos",
	"nm": "Ibrillos"
}, {
	"id": "Burgos",
	"nm": "Iglesiarrubia"
}, {
	"id": "Burgos",
	"nm": "Iglesias"
}, {
	"id": "Burgos",
	"nm": "Isar"
}, {
	"id": "Burgos",
	"nm": "Itero del Castillo"
}, {
	"id": "Burgos",
	"nm": "Jaramillo de la Fuente"
}, {
	"id": "Burgos",
	"nm": "Jaramillo Quemado"
}, {
	"id": "Burgos",
	"nm": "Junta de Traslaloma"
}, {
	"id": "Burgos",
	"nm": "Junta de Villalba de Losa"
}, {
	"id": "Burgos",
	"nm": "Jurisdicción de Lara"
}, {
	"id": "Burgos",
	"nm": "Jurisdicción de San Zadornil"
}, {
	"id": "Burgos",
	"nm": "Lerma"
}, {
	"id": "Burgos",
	"nm": "Llano de Bureba"
}, {
	"id": "Burgos",
	"nm": "Madrigal del Monte"
}, {
	"id": "Burgos",
	"nm": "Madrigalejo del Monte"
}, {
	"id": "Burgos",
	"nm": "Mahamud"
}, {
	"id": "Burgos",
	"nm": "Mambrilla de Castrejón"
}, {
	"id": "Burgos",
	"nm": "Mambrillas de Lara"
}, {
	"id": "Burgos",
	"nm": "Mamolar"
}, {
	"id": "Burgos",
	"nm": "Manciles"
}, {
	"id": "Burgos",
	"nm": "Mazuela"
}, {
	"id": "Burgos",
	"nm": "Mecerreyes"
}, {
	"id": "Burgos",
	"nm": "Medina de Pomar"
}, {
	"id": "Burgos",
	"nm": "Melgar de Fernamental"
}, {
	"id": "Burgos",
	"nm": "Merindad de Cuesta-Urria"
}, {
	"id": "Burgos",
	"nm": "Merindad de Montija"
}, {
	"id": "Burgos",
	"nm": "Merindad de Río Ubierna"
}, {
	"id": "Burgos",
	"nm": "Merindad de Sotoscueva"
}, {
	"id": "Burgos",
	"nm": "Merindad de Valdeporres"
}, {
	"id": "Burgos",
	"nm": "Merindad de Valdivielso"
}, {
	"id": "Burgos",
	"nm": "Milagros"
}, {
	"id": "Burgos",
	"nm": "Miranda de Ebro"
}, {
	"id": "Burgos",
	"nm": "Miraveche"
}, {
	"id": "Burgos",
	"nm": "Modúbar de la Emparedada"
}, {
	"id": "Burgos",
	"nm": "Monasterio de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Monasterio de Rodilla"
}, {
	"id": "Burgos",
	"nm": "Moncalvillo"
}, {
	"id": "Burgos",
	"nm": "Monterrubio de la Demanda"
}, {
	"id": "Burgos",
	"nm": "Montorio"
}, {
	"id": "Burgos",
	"nm": "Moradillo de Roa"
}, {
	"id": "Burgos",
	"nm": "Nava de Roa"
}, {
	"id": "Burgos",
	"nm": "Navas de Bureba"
}, {
	"id": "Burgos",
	"nm": "Nebreda"
}, {
	"id": "Burgos",
	"nm": "Neila"
}, {
	"id": "Burgos",
	"nm": "Olmedillo de Roa"
}, {
	"id": "Burgos",
	"nm": "Olmillos de Muñó"
}, {
	"id": "Burgos",
	"nm": "Oña"
}, {
	"id": "Burgos",
	"nm": "Oquillas"
}, {
	"id": "Burgos",
	"nm": "Orbaneja Riopico"
}, {
	"id": "Burgos",
	"nm": "Padilla de Abajo"
}, {
	"id": "Burgos",
	"nm": "Padilla de Arriba"
}, {
	"id": "Burgos",
	"nm": "Padrones de Bureba"
}, {
	"id": "Burgos",
	"nm": "Palacios de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Palacios de Riopisuerga"
}, {
	"id": "Burgos",
	"nm": "Palazuelos de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Palazuelos de Muñó"
}, {
	"id": "Burgos",
	"nm": "Pampliega"
}, {
	"id": "Burgos",
	"nm": "Pancorbo"
}, {
	"id": "Burgos",
	"nm": "Pardilla"
}, {
	"id": "Burgos",
	"nm": "Partido de la Sierra en Tobalina"
}, {
	"id": "Burgos",
	"nm": "Pedrosa de Duero"
}, {
	"id": "Burgos",
	"nm": "Pedrosa de Río Úrbel"
}, {
	"id": "Burgos",
	"nm": "Pedrosa del Páramo"
}, {
	"id": "Burgos",
	"nm": "Pedrosa del Príncipe"
}, {
	"id": "Burgos",
	"nm": "Peñaranda de Duero"
}, {
	"id": "Burgos",
	"nm": "Peral de Arlanza"
}, {
	"id": "Burgos",
	"nm": "Piérnigas"
}, {
	"id": "Burgos",
	"nm": "Pineda de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Pineda Trasmonte"
}, {
	"id": "Burgos",
	"nm": "Pinilla de los Barruecos"
}, {
	"id": "Burgos",
	"nm": "Pinilla de los Moros"
}, {
	"id": "Burgos",
	"nm": "Pinilla Trasmonte"
}, {
	"id": "Burgos",
	"nm": "Poza de la Sal"
}, {
	"id": "Burgos",
	"nm": "Prádanos de Bureba"
}, {
	"id": "Burgos",
	"nm": "Pradoluengo"
}, {
	"id": "Burgos",
	"nm": "Presencio"
}, {
	"id": "Burgos",
	"nm": "Puebla de Arganzón, La"
}, {
	"id": "Burgos",
	"nm": "Puentedura"
}, {
	"id": "Burgos",
	"nm": "Quemada"
}, {
	"id": "Burgos",
	"nm": "Quintana del Pidio"
}, {
	"id": "Burgos",
	"nm": "Quintanabureba"
}, {
	"id": "Burgos",
	"nm": "Quintanaélez"
}, {
	"id": "Burgos",
	"nm": "Quintanaortuño"
}, {
	"id": "Burgos",
	"nm": "Quintanapalla"
}, {
	"id": "Burgos",
	"nm": "Quintanar de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Quintanavides"
}, {
	"id": "Burgos",
	"nm": "Quintanilla de la Mata"
}, {
	"id": "Burgos",
	"nm": "Quintanilla del Agua y Tordueles"
}, {
	"id": "Burgos",
	"nm": "Quintanilla del Coco"
}, {
	"id": "Burgos",
	"nm": "Quintanilla San García"
}, {
	"id": "Burgos",
	"nm": "Quintanilla Vivar"
}, {
	"id": "Burgos",
	"nm": "Quintanillas, Las"
}, {
	"id": "Burgos",
	"nm": "Rabanera del Pinar"
}, {
	"id": "Burgos",
	"nm": "Rábanos"
}, {
	"id": "Burgos",
	"nm": "Rabé de las Calzadas"
}, {
	"id": "Burgos",
	"nm": "Rebolledo de la Torre"
}, {
	"id": "Burgos",
	"nm": "Redecilla del Camino"
}, {
	"id": "Burgos",
	"nm": "Redecilla del Campo"
}, {
	"id": "Burgos",
	"nm": "Regumiel de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Reinoso"
}, {
	"id": "Burgos",
	"nm": "Retuerta"
}, {
	"id": "Burgos",
	"nm": "Revilla del Campo"
}, {
	"id": "Burgos",
	"nm": "Revilla Vallejera"
}, {
	"id": "Burgos",
	"nm": "Revilla y Ahedo, La"
}, {
	"id": "Burgos",
	"nm": "Revillarruz"
}, {
	"id": "Burgos",
	"nm": "Rezmondo"
}, {
	"id": "Burgos",
	"nm": "Riocavado de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Roa"
}, {
	"id": "Burgos",
	"nm": "Rojas"
}, {
	"id": "Burgos",
	"nm": "Royuela de Río Franco"
}, {
	"id": "Burgos",
	"nm": "Rubena"
}, {
	"id": "Burgos",
	"nm": "Rublacedo de Abajo"
}, {
	"id": "Burgos",
	"nm": "Rucandio"
}, {
	"id": "Burgos",
	"nm": "Salas de Bureba"
}, {
	"id": "Burgos",
	"nm": "Salas de los Infantes"
}, {
	"id": "Burgos",
	"nm": "Saldaña de Burgos"
}, {
	"id": "Burgos",
	"nm": "Salinillas de Bureba"
}, {
	"id": "Burgos",
	"nm": "San Adrián de Juarros"
}, {
	"id": "Burgos",
	"nm": "San Juan del Monte"
}, {
	"id": "Burgos",
	"nm": "San Mamés de Burgos"
}, {
	"id": "Burgos",
	"nm": "San Martín de Rubiales"
}, {
	"id": "Burgos",
	"nm": "San Millán de Lara"
}, {
	"id": "Burgos",
	"nm": "San Vicente del Valle"
}, {
	"id": "Burgos",
	"nm": "Santa Cecilia"
}, {
	"id": "Burgos",
	"nm": "Santa Cruz de la Salceda"
}, {
	"id": "Burgos",
	"nm": "Santa Cruz del Valle Urbión"
}, {
	"id": "Burgos",
	"nm": "Santa Gadea del Cid"
}, {
	"id": "Burgos",
	"nm": "Santa Inés"
}, {
	"id": "Burgos",
	"nm": "Santa María del Campo"
}, {
	"id": "Burgos",
	"nm": "Santa María del Invierno"
}, {
	"id": "Burgos",
	"nm": "Santa María del Mercadillo"
}, {
	"id": "Burgos",
	"nm": "Santa María Rivarredonda"
}, {
	"id": "Burgos",
	"nm": "Santa Olalla de Bureba"
}, {
	"id": "Burgos",
	"nm": "Santibáñez de Esgueva"
}, {
	"id": "Burgos",
	"nm": "Santibáñez del Val"
}, {
	"id": "Burgos",
	"nm": "Santo Domingo de Silos"
}, {
	"id": "Burgos",
	"nm": "Sargentes de la Lora"
}, {
	"id": "Burgos",
	"nm": "Sarracín"
}, {
	"id": "Burgos",
	"nm": "Sasamón"
}, {
	"id": "Burgos",
	"nm": "Sequera de Haza, La"
}, {
	"id": "Burgos",
	"nm": "Solarana"
}, {
	"id": "Burgos",
	"nm": "Sordillos"
}, {
	"id": "Burgos",
	"nm": "Sotillo de la Ribera"
}, {
	"id": "Burgos",
	"nm": "Sotragero"
}, {
	"id": "Burgos",
	"nm": "Sotresgudo"
}, {
	"id": "Burgos",
	"nm": "Susinos del Páramo"
}, {
	"id": "Burgos",
	"nm": "Tamarón"
}, {
	"id": "Burgos",
	"nm": "Tardajos"
}, {
	"id": "Burgos",
	"nm": "Tejada"
}, {
	"id": "Burgos",
	"nm": "Terradillos de Esgueva"
}, {
	"id": "Burgos",
	"nm": "Tinieblas de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Tobar"
}, {
	"id": "Burgos",
	"nm": "Tordómar"
}, {
	"id": "Burgos",
	"nm": "Torrecilla del Monte"
}, {
	"id": "Burgos",
	"nm": "Torregalindo"
}, {
	"id": "Burgos",
	"nm": "Torrelara"
}, {
	"id": "Burgos",
	"nm": "Torrepadre"
}, {
	"id": "Burgos",
	"nm": "Torresandino"
}, {
	"id": "Burgos",
	"nm": "Tórtoles de Esgueva"
}, {
	"id": "Burgos",
	"nm": "Tosantos"
}, {
	"id": "Burgos",
	"nm": "Trespaderne"
}, {
	"id": "Burgos",
	"nm": "Tubilla del Agua"
}, {
	"id": "Burgos",
	"nm": "Tubilla del Lago"
}, {
	"id": "Burgos",
	"nm": "Úrbel del Castillo"
}, {
	"id": "Burgos",
	"nm": "Vadocondes"
}, {
	"id": "Burgos",
	"nm": "Valdeande"
}, {
	"id": "Burgos",
	"nm": "Valdezate"
}, {
	"id": "Burgos",
	"nm": "Valdorros"
}, {
	"id": "Burgos",
	"nm": "Vallarta de Bureba"
}, {
	"id": "Burgos",
	"nm": "Valle de las Navas"
}, {
	"id": "Burgos",
	"nm": "Valle de Losa"
}, {
	"id": "Burgos",
	"nm": "Valle de Manzanedo"
}, {
	"id": "Burgos",
	"nm": "Valle de Mena"
}, {
	"id": "Burgos",
	"nm": "Valle de Oca"
}, {
	"id": "Burgos",
	"nm": "Valle de Santibáñez"
}, {
	"id": "Burgos",
	"nm": "Valle de Sedano"
}, {
	"id": "Burgos",
	"nm": "Valle de Tobalina"
}, {
	"id": "Burgos",
	"nm": "Valle de Valdebezana"
}, {
	"id": "Burgos",
	"nm": "Valle de Valdelaguna"
}, {
	"id": "Burgos",
	"nm": "Valle de Valdelucio"
}, {
	"id": "Burgos",
	"nm": "Valle de Zamanzas"
}, {
	"id": "Burgos",
	"nm": "Vallejera"
}, {
	"id": "Burgos",
	"nm": "Valles de Palenzuela"
}, {
	"id": "Burgos",
	"nm": "Valluércanes"
}, {
	"id": "Burgos",
	"nm": "Valmala"
}, {
	"id": "Burgos",
	"nm": "Vid de Bureba, La"
}, {
	"id": "Burgos",
	"nm": "Vid y Barrios, La"
}, {
	"id": "Burgos",
	"nm": "Vileña"
}, {
	"id": "Burgos",
	"nm": "Villadiego"
}, {
	"id": "Burgos",
	"nm": "Villaescusa de Roa"
}, {
	"id": "Burgos",
	"nm": "Villaescusa la Sombría"
}, {
	"id": "Burgos",
	"nm": "Villaespasa"
}, {
	"id": "Burgos",
	"nm": "Villafranca Montes de Oca"
}, {
	"id": "Burgos",
	"nm": "Villafruela"
}, {
	"id": "Burgos",
	"nm": "Villagalijo"
}, {
	"id": "Burgos",
	"nm": "Villagonzalo Pedernales"
}, {
	"id": "Burgos",
	"nm": "Villahoz"
}, {
	"id": "Burgos",
	"nm": "Villalba de Duero"
}, {
	"id": "Burgos",
	"nm": "Villalbilla de Burgos"
}, {
	"id": "Burgos",
	"nm": "Villalbilla de Gumiel"
}, {
	"id": "Burgos",
	"nm": "Villaldemiro"
}, {
	"id": "Burgos",
	"nm": "Villalmanzo"
}, {
	"id": "Burgos",
	"nm": "Villamayor de los Montes"
}, {
	"id": "Burgos",
	"nm": "Villamayor de Treviño"
}, {
	"id": "Burgos",
	"nm": "Villambistia"
}, {
	"id": "Burgos",
	"nm": "Villamedianilla"
}, {
	"id": "Burgos",
	"nm": "Villamiel de la Sierra"
}, {
	"id": "Burgos",
	"nm": "Villangómez"
}, {
	"id": "Burgos",
	"nm": "Villanueva de Argaño"
}, {
	"id": "Burgos",
	"nm": "Villanueva de Carazo"
}, {
	"id": "Burgos",
	"nm": "Villanueva de Gumiel"
}, {
	"id": "Burgos",
	"nm": "Villanueva de Teba"
}, {
	"id": "Burgos",
	"nm": "Villaquirán de la Puebla"
}, {
	"id": "Burgos",
	"nm": "Villaquirán de los Infantes"
}, {
	"id": "Burgos",
	"nm": "Villarcayo de Merindad de Castilla la Vieja"
}, {
	"id": "Burgos",
	"nm": "Villariezo"
}, {
	"id": "Burgos",
	"nm": "Villasandino"
}, {
	"id": "Burgos",
	"nm": "Villasur de Herreros"
}, {
	"id": "Burgos",
	"nm": "Villatuelda"
}, {
	"id": "Burgos",
	"nm": "Villaverde del Monte"
}, {
	"id": "Burgos",
	"nm": "Villaverde-Mogina"
}, {
	"id": "Burgos",
	"nm": "Villayerno Morquillas"
}, {
	"id": "Burgos",
	"nm": "Villazopeque"
}, {
	"id": "Burgos",
	"nm": "Villegas"
}, {
	"id": "Burgos",
	"nm": "Villoruebo"
}, {
	"id": "Burgos",
	"nm": "Viloria de Rioja"
}, {
	"id": "Burgos",
	"nm": "Vilviestre del Pinar"
}, {
	"id": "Burgos",
	"nm": "Vizcaínos"
}, {
	"id": "Burgos",
	"nm": "Zael"
}, {
	"id": "Burgos",
	"nm": "Zarzosa de Río Pisuerga"
}, {
	"id": "Burgos",
	"nm": "Zazuar"
}, {
	"id": "Burgos",
	"nm": "Zuñeda"
}, {
	"id": "Cáceres",
	"nm": "Abadía"
}, {
	"id": "Cáceres",
	"nm": "Abertura"
}, {
	"id": "Cáceres",
	"nm": "Acebo"
}, {
	"id": "Cáceres",
	"nm": "Acehúche"
}, {
	"id": "Cáceres",
	"nm": "Aceituna"
}, {
	"id": "Cáceres",
	"nm": "Ahigal"
}, {
	"id": "Cáceres",
	"nm": "Alagón del Río"
}, {
	"id": "Cáceres",
	"nm": "Albalá"
}, {
	"id": "Cáceres",
	"nm": "Alcántara"
}, {
	"id": "Cáceres",
	"nm": "Alcollarín"
}, {
	"id": "Cáceres",
	"nm": "Alcuéscar"
}, {
	"id": "Cáceres",
	"nm": "Aldea del Cano"
}, {
	"id": "Cáceres",
	"nm": "Aldea del Obispo, La"
}, {
	"id": "Cáceres",
	"nm": "Aldeacentenera"
}, {
	"id": "Cáceres",
	"nm": "Aldeanueva de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Aldeanueva del Camino"
}, {
	"id": "Cáceres",
	"nm": "Aldehuela de Jerte"
}, {
	"id": "Cáceres",
	"nm": "Alía"
}, {
	"id": "Cáceres",
	"nm": "Aliseda"
}, {
	"id": "Cáceres",
	"nm": "Almaraz"
}, {
	"id": "Cáceres",
	"nm": "Almoharín"
}, {
	"id": "Cáceres",
	"nm": "Arroyo de la Luz"
}, {
	"id": "Cáceres",
	"nm": "Arroyomolinos"
}, {
	"id": "Cáceres",
	"nm": "Arroyomolinos de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Baños de Montemayor"
}, {
	"id": "Cáceres",
	"nm": "Barrado"
}, {
	"id": "Cáceres",
	"nm": "Belvís de Monroy"
}, {
	"id": "Cáceres",
	"nm": "Benquerencia"
}, {
	"id": "Cáceres",
	"nm": "Berrocalejo"
}, {
	"id": "Cáceres",
	"nm": "Berzocana"
}, {
	"id": "Cáceres",
	"nm": "Bohonal de Ibor"
}, {
	"id": "Cáceres",
	"nm": "Botija"
}, {
	"id": "Cáceres",
	"nm": "Brozas"
}, {
	"id": "Cáceres",
	"nm": "Cabañas del Castillo"
}, {
	"id": "Cáceres",
	"nm": "Cabezabellosa"
}, {
	"id": "Cáceres",
	"nm": "Cabezuela del Valle"
}, {
	"id": "Cáceres",
	"nm": "Cabrero"
}, {
	"id": "Cáceres",
	"nm": "Cáceres"
}, {
	"id": "Cáceres",
	"nm": "Cachorrilla"
}, {
	"id": "Cáceres",
	"nm": "Cadalso"
}, {
	"id": "Cáceres",
	"nm": "Calzadilla"
}, {
	"id": "Cáceres",
	"nm": "Caminomorisco"
}, {
	"id": "Cáceres",
	"nm": "Campillo de Deleitosa"
}, {
	"id": "Cáceres",
	"nm": "Campo Lugar"
}, {
	"id": "Cáceres",
	"nm": "Cañamero"
}, {
	"id": "Cáceres",
	"nm": "Cañaveral"
}, {
	"id": "Cáceres",
	"nm": "Carbajo"
}, {
	"id": "Cáceres",
	"nm": "Carcaboso"
}, {
	"id": "Cáceres",
	"nm": "Carrascalejo"
}, {
	"id": "Cáceres",
	"nm": "Casar de Cáceres"
}, {
	"id": "Cáceres",
	"nm": "Casar de Palomero"
}, {
	"id": "Cáceres",
	"nm": "Casares de las Hurdes"
}, {
	"id": "Cáceres",
	"nm": "Casas de Don Antonio"
}, {
	"id": "Cáceres",
	"nm": "Casas de Don Gómez"
}, {
	"id": "Cáceres",
	"nm": "Casas de Millán"
}, {
	"id": "Cáceres",
	"nm": "Casas de Miravete"
}, {
	"id": "Cáceres",
	"nm": "Casas del Castañar"
}, {
	"id": "Cáceres",
	"nm": "Casas del Monte"
}, {
	"id": "Cáceres",
	"nm": "Casatejada"
}, {
	"id": "Cáceres",
	"nm": "Casillas de Coria"
}, {
	"id": "Cáceres",
	"nm": "Castañar de Ibor"
}, {
	"id": "Cáceres",
	"nm": "Ceclavín"
}, {
	"id": "Cáceres",
	"nm": "Cedillo"
}, {
	"id": "Cáceres",
	"nm": "Cerezo"
}, {
	"id": "Cáceres",
	"nm": "Cilleros"
}, {
	"id": "Cáceres",
	"nm": "Collado de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Conquista de la Sierra"
}, {
	"id": "Cáceres",
	"nm": "Coria"
}, {
	"id": "Cáceres",
	"nm": "Cuacos de Yuste"
}, {
	"id": "Cáceres",
	"nm": "Cumbre, La"
}, {
	"id": "Cáceres",
	"nm": "Deleitosa"
}, {
	"id": "Cáceres",
	"nm": "Descargamaría"
}, {
	"id": "Cáceres",
	"nm": "Eljas"
}, {
	"id": "Cáceres",
	"nm": "Escurial"
}, {
	"id": "Cáceres",
	"nm": "Fresnedoso de Ibor"
}, {
	"id": "Cáceres",
	"nm": "Galisteo"
}, {
	"id": "Cáceres",
	"nm": "Garciaz"
}, {
	"id": "Cáceres",
	"nm": "Garganta la Olla"
}, {
	"id": "Cáceres",
	"nm": "Garganta, La"
}, {
	"id": "Cáceres",
	"nm": "Gargantilla"
}, {
	"id": "Cáceres",
	"nm": "Gargüera"
}, {
	"id": "Cáceres",
	"nm": "Garrovillas de Alconétar"
}, {
	"id": "Cáceres",
	"nm": "Garvín"
}, {
	"id": "Cáceres",
	"nm": "Gata"
}, {
	"id": "Cáceres",
	"nm": "Gordo, El"
}, {
	"id": "Cáceres",
	"nm": "Granja, La"
}, {
	"id": "Cáceres",
	"nm": "Guadalupe"
}, {
	"id": "Cáceres",
	"nm": "Guijo de Coria"
}, {
	"id": "Cáceres",
	"nm": "Guijo de Galisteo"
}, {
	"id": "Cáceres",
	"nm": "Guijo de Granadilla"
}, {
	"id": "Cáceres",
	"nm": "Guijo de Santa Bárbara"
}, {
	"id": "Cáceres",
	"nm": "Herguijuela"
}, {
	"id": "Cáceres",
	"nm": "Hernán-Pérez"
}, {
	"id": "Cáceres",
	"nm": "Herrera de Alcántara"
}, {
	"id": "Cáceres",
	"nm": "Herreruela"
}, {
	"id": "Cáceres",
	"nm": "Hervás"
}, {
	"id": "Cáceres",
	"nm": "Higuera"
}, {
	"id": "Cáceres",
	"nm": "Hinojal"
}, {
	"id": "Cáceres",
	"nm": "Holguera"
}, {
	"id": "Cáceres",
	"nm": "Hoyos"
}, {
	"id": "Cáceres",
	"nm": "Huélaga"
}, {
	"id": "Cáceres",
	"nm": "Ibahernando"
}, {
	"id": "Cáceres",
	"nm": "Jaraicejo"
}, {
	"id": "Cáceres",
	"nm": "Jaraíz de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Jarandilla de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Jarilla"
}, {
	"id": "Cáceres",
	"nm": "Jerte"
}, {
	"id": "Cáceres",
	"nm": "Ladrillar"
}, {
	"id": "Cáceres",
	"nm": "Logrosán"
}, {
	"id": "Cáceres",
	"nm": "Losar de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Madrigal de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Madrigalejo"
}, {
	"id": "Cáceres",
	"nm": "Madroñera"
}, {
	"id": "Cáceres",
	"nm": "Majadas"
}, {
	"id": "Cáceres",
	"nm": "Malpartida de Cáceres"
}, {
	"id": "Cáceres",
	"nm": "Malpartida de Plasencia"
}, {
	"id": "Cáceres",
	"nm": "Marchagaz"
}, {
	"id": "Cáceres",
	"nm": "Mata de Alcántara"
}, {
	"id": "Cáceres",
	"nm": "Membrío"
}, {
	"id": "Cáceres",
	"nm": "Mesas de Ibor"
}, {
	"id": "Cáceres",
	"nm": "Miajadas"
}, {
	"id": "Cáceres",
	"nm": "Millanes"
}, {
	"id": "Cáceres",
	"nm": "Mirabel"
}, {
	"id": "Cáceres",
	"nm": "Mohedas de Granadilla"
}, {
	"id": "Cáceres",
	"nm": "Monroy"
}, {
	"id": "Cáceres",
	"nm": "Montánchez"
}, {
	"id": "Cáceres",
	"nm": "Montehermoso"
}, {
	"id": "Cáceres",
	"nm": "Moraleja"
}, {
	"id": "Cáceres",
	"nm": "Morcillo"
}, {
	"id": "Cáceres",
	"nm": "Navaconcejo"
}, {
	"id": "Cáceres",
	"nm": "Navalmoral de la Mata"
}, {
	"id": "Cáceres",
	"nm": "Navalvillar de Ibor"
}, {
	"id": "Cáceres",
	"nm": "Navas del Madroño"
}, {
	"id": "Cáceres",
	"nm": "Navezuelas"
}, {
	"id": "Cáceres",
	"nm": "Nuñomoral"
}, {
	"id": "Cáceres",
	"nm": "Oliva de Plasencia"
}, {
	"id": "Cáceres",
	"nm": "Palomero"
}, {
	"id": "Cáceres",
	"nm": "Pasarón de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Pedroso de Acim"
}, {
	"id": "Cáceres",
	"nm": "Peraleda de la Mata"
}, {
	"id": "Cáceres",
	"nm": "Peraleda de San Román"
}, {
	"id": "Cáceres",
	"nm": "Perales del Puerto"
}, {
	"id": "Cáceres",
	"nm": "Pescueza"
}, {
	"id": "Cáceres",
	"nm": "Pesga, La"
}, {
	"id": "Cáceres",
	"nm": "Piedras Albas"
}, {
	"id": "Cáceres",
	"nm": "Pinofranqueado"
}, {
	"id": "Cáceres",
	"nm": "Piornal"
}, {
	"id": "Cáceres",
	"nm": "Plasencia"
}, {
	"id": "Cáceres",
	"nm": "Plasenzuela"
}, {
	"id": "Cáceres",
	"nm": "Portaje"
}, {
	"id": "Cáceres",
	"nm": "Portezuelo"
}, {
	"id": "Cáceres",
	"nm": "Pozuelo de Zarzón"
}, {
	"id": "Cáceres",
	"nm": "Pueblonuevo de Miramontes"
}, {
	"id": "Cáceres",
	"nm": "Puerto de Santa Cruz"
}, {
	"id": "Cáceres",
	"nm": "Rebollar"
}, {
	"id": "Cáceres",
	"nm": "Riolobos"
}, {
	"id": "Cáceres",
	"nm": "Robledillo de Gata"
}, {
	"id": "Cáceres",
	"nm": "Robledillo de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Robledillo de Trujillo"
}, {
	"id": "Cáceres",
	"nm": "Robledollano"
}, {
	"id": "Cáceres",
	"nm": "Romangordo"
}, {
	"id": "Cáceres",
	"nm": "Rosalejo"
}, {
	"id": "Cáceres",
	"nm": "Ruanes"
}, {
	"id": "Cáceres",
	"nm": "Salorino"
}, {
	"id": "Cáceres",
	"nm": "Salvatierra de Santiago"
}, {
	"id": "Cáceres",
	"nm": "San Martín de Trevejo"
}, {
	"id": "Cáceres",
	"nm": "Santa Ana"
}, {
	"id": "Cáceres",
	"nm": "Santa Cruz de la Sierra"
}, {
	"id": "Cáceres",
	"nm": "Santa Cruz de Paniagua"
}, {
	"id": "Cáceres",
	"nm": "Santa Marta de Magasca"
}, {
	"id": "Cáceres",
	"nm": "Santiago de Alcántara"
}, {
	"id": "Cáceres",
	"nm": "Santiago del Campo"
}, {
	"id": "Cáceres",
	"nm": "Santibáñez el Alto"
}, {
	"id": "Cáceres",
	"nm": "Santibáñez el Bajo"
}, {
	"id": "Cáceres",
	"nm": "Saucedilla"
}, {
	"id": "Cáceres",
	"nm": "Segura de Toro"
}, {
	"id": "Cáceres",
	"nm": "Serradilla"
}, {
	"id": "Cáceres",
	"nm": "Serrejón"
}, {
	"id": "Cáceres",
	"nm": "Sierra de Fuentes"
}, {
	"id": "Cáceres",
	"nm": "Talaván"
}, {
	"id": "Cáceres",
	"nm": "Talaveruela de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Talayuela"
}, {
	"id": "Cáceres",
	"nm": "Tejeda de Tiétar"
}, {
	"id": "Cáceres",
	"nm": "Tiétar"
}, {
	"id": "Cáceres",
	"nm": "Toril"
}, {
	"id": "Cáceres",
	"nm": "Tornavacas"
}, {
	"id": "Cáceres",
	"nm": "Torno, El"
}, {
	"id": "Cáceres",
	"nm": "Torre de Don Miguel"
}, {
	"id": "Cáceres",
	"nm": "Torre de Santa María"
}, {
	"id": "Cáceres",
	"nm": "Torrecilla de los Ángeles"
}, {
	"id": "Cáceres",
	"nm": "Torrecillas de la Tiesa"
}, {
	"id": "Cáceres",
	"nm": "Torrejón el Rubio"
}, {
	"id": "Cáceres",
	"nm": "Torrejoncillo"
}, {
	"id": "Cáceres",
	"nm": "Torremenga"
}, {
	"id": "Cáceres",
	"nm": "Torremocha"
}, {
	"id": "Cáceres",
	"nm": "Torreorgaz"
}, {
	"id": "Cáceres",
	"nm": "Torrequemada"
}, {
	"id": "Cáceres",
	"nm": "Trujillo"
}, {
	"id": "Cáceres",
	"nm": "Valdastillas"
}, {
	"id": "Cáceres",
	"nm": "Valdecañas de Tajo"
}, {
	"id": "Cáceres",
	"nm": "Valdefuentes"
}, {
	"id": "Cáceres",
	"nm": "Valdehúncar"
}, {
	"id": "Cáceres",
	"nm": "Valdelacasa de Tajo"
}, {
	"id": "Cáceres",
	"nm": "Valdemorales"
}, {
	"id": "Cáceres",
	"nm": "Valdeobispo"
}, {
	"id": "Cáceres",
	"nm": "Valencia de Alcántara"
}, {
	"id": "Cáceres",
	"nm": "Valverde de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Valverde del Fresno"
}, {
	"id": "Cáceres",
	"nm": "Vegaviana"
}, {
	"id": "Cáceres",
	"nm": "Viandar de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Villa del Campo"
}, {
	"id": "Cáceres",
	"nm": "Villa del Rey"
}, {
	"id": "Cáceres",
	"nm": "Villamesías"
}, {
	"id": "Cáceres",
	"nm": "Villamiel"
}, {
	"id": "Cáceres",
	"nm": "Villanueva de la Sierra"
}, {
	"id": "Cáceres",
	"nm": "Villanueva de la Vera"
}, {
	"id": "Cáceres",
	"nm": "Villar de Plasencia"
}, {
	"id": "Cáceres",
	"nm": "Villar del Pedroso"
}, {
	"id": "Cáceres",
	"nm": "Villasbuenas de Gata"
}, {
	"id": "Cáceres",
	"nm": "Zarza de Granadilla"
}, {
	"id": "Cáceres",
	"nm": "Zarza de Montánchez"
}, {
	"id": "Cáceres",
	"nm": "Zarza la Mayor"
}, {
	"id": "Cáceres",
	"nm": "Zorita"
}, {
	"id": "Cádiz",
	"nm": "Alcalá de los Gazules"
}, {
	"id": "Cádiz",
	"nm": "Alcalá del Valle"
}, {
	"id": "Cádiz",
	"nm": "Algar"
}, {
	"id": "Cádiz",
	"nm": "Algeciras"
}, {
	"id": "Cádiz",
	"nm": "Algodonales"
}, {
	"id": "Cádiz",
	"nm": "Arcos de la Frontera"
}, {
	"id": "Cádiz",
	"nm": "Barbate"
}, {
	"id": "Cádiz",
	"nm": "Barrios, Los"
}, {
	"id": "Cádiz",
	"nm": "Benalup-Casas Viejas"
}, {
	"id": "Cádiz",
	"nm": "Benaocaz"
}, {
	"id": "Cádiz",
	"nm": "Bornos"
}, {
	"id": "Cádiz",
	"nm": "Bosque, El"
}, {
	"id": "Cádiz",
	"nm": "Cádiz"
}, {
	"id": "Cádiz",
	"nm": "Castellar de la Frontera"
}, {
	"id": "Cádiz",
	"nm": "Chiclana de la Frontera"
}, {
	"id": "Cádiz",
	"nm": "Chipiona"
}, {
	"id": "Cádiz",
	"nm": "Conil de la Frontera"
}, {
	"id": "Cádiz",
	"nm": "Espera"
}, {
	"id": "Cádiz",
	"nm": "Gastor, El"
}, {
	"id": "Cádiz",
	"nm": "Grazalema"
}, {
	"id": "Cádiz",
	"nm": "Jerez de la Frontera"
}, {
	"id": "Cádiz",
	"nm": "Jimena de la Frontera"
}, {
	"id": "Cádiz",
	"nm": "Línea de la Concepción, La"
}, {
	"id": "Cádiz",
	"nm": "Medina-Sidonia"
}, {
	"id": "Cádiz",
	"nm": "Olvera"
}, {
	"id": "Cádiz",
	"nm": "Paterna de Rivera"
}, {
	"id": "Cádiz",
	"nm": "Prado del Rey"
}, {
	"id": "Cádiz",
	"nm": "Puerto de Santa María, El"
}, {
	"id": "Cádiz",
	"nm": "Puerto Real"
}, {
	"id": "Cádiz",
	"nm": "Puerto Serrano"
}, {
	"id": "Cádiz",
	"nm": "Rota"
}, {
	"id": "Cádiz",
	"nm": "San Fernando"
}, {
	"id": "Cádiz",
	"nm": "San José del Valle"
}, {
	"id": "Cádiz",
	"nm": "San Roque"
}, {
	"id": "Cádiz",
	"nm": "Sanlúcar de Barrameda"
}, {
	"id": "Cádiz",
	"nm": "Setenil de las Bodegas"
}, {
	"id": "Cádiz",
	"nm": "Tarifa"
}, {
	"id": "Cádiz",
	"nm": "Torre Alháquime"
}, {
	"id": "Cádiz",
	"nm": "Trebujena"
}, {
	"id": "Cádiz",
	"nm": "Ubrique"
}, {
	"id": "Cádiz",
	"nm": "Vejer de la Frontera"
}, {
	"id": "Cádiz",
	"nm": "Villaluenga del Rosario"
}, {
	"id": "Cádiz",
	"nm": "Villamartín"
}, {
	"id": "Cádiz",
	"nm": "Zahara"
}, {
	"id": "Castellón/Castelló",
	"nm": "Aín"
}, {
	"id": "Castellón/Castelló",
	"nm": "Albocàsser"
}, {
	"id": "Castellón/Castelló",
	"nm": "Alcalà de Xivert"
}, {
	"id": "Castellón/Castelló",
	"nm": "Alcora, l'"
}, {
	"id": "Castellón/Castelló",
	"nm": "Alcudia de Veo"
}, {
	"id": "Castellón/Castelló",
	"nm": "Alfondeguilla"
}, {
	"id": "Castellón/Castelló",
	"nm": "Algimia de Almonacid"
}, {
	"id": "Castellón/Castelló",
	"nm": "Almazora/Almassora"
}, {
	"id": "Castellón/Castelló",
	"nm": "Almedíjar"
}, {
	"id": "Castellón/Castelló",
	"nm": "Almenara"
}, {
	"id": "Castellón/Castelló",
	"nm": "Alqueries, les/Alquerías del Niño Perdido"
}, {
	"id": "Castellón/Castelló",
	"nm": "Altura"
}, {
	"id": "Castellón/Castelló",
	"nm": "Arañuel"
}, {
	"id": "Castellón/Castelló",
	"nm": "Ares del Maestrat"
}, {
	"id": "Castellón/Castelló",
	"nm": "Argelita"
}, {
	"id": "Castellón/Castelló",
	"nm": "Artana"
}, {
	"id": "Castellón/Castelló",
	"nm": "Atzeneta del Maestrat"
}, {
	"id": "Castellón/Castelló",
	"nm": "Ayódar"
}, {
	"id": "Castellón/Castelló",
	"nm": "Azuébar"
}, {
	"id": "Castellón/Castelló",
	"nm": "Barracas"
}, {
	"id": "Castellón/Castelló",
	"nm": "Bejís"
}, {
	"id": "Castellón/Castelló",
	"nm": "Benafer"
}, {
	"id": "Castellón/Castelló",
	"nm": "Benafigos"
}, {
	"id": "Castellón/Castelló",
	"nm": "Benassal"
}, {
	"id": "Castellón/Castelló",
	"nm": "Benicarló"
}, {
	"id": "Castellón/Castelló",
	"nm": "Benicasim/Benicàssim"
}, {
	"id": "Castellón/Castelló",
	"nm": "Benlloch"
}, {
	"id": "Castellón/Castelló",
	"nm": "Betxí"
}, {
	"id": "Castellón/Castelló",
	"nm": "Borriana/Burriana"
}, {
	"id": "Castellón/Castelló",
	"nm": "Borriol"
}, {
	"id": "Castellón/Castelló",
	"nm": "Cabanes"
}, {
	"id": "Castellón/Castelló",
	"nm": "Càlig"
}, {
	"id": "Castellón/Castelló",
	"nm": "Canet lo Roig"
}, {
	"id": "Castellón/Castelló",
	"nm": "Castell de Cabres"
}, {
	"id": "Castellón/Castelló",
	"nm": "Castellfort"
}, {
	"id": "Castellón/Castelló",
	"nm": "Castellnovo"
}, {
	"id": "Castellón/Castelló",
	"nm": "Castellón de la Plana/Castelló de la Plana"
}, {
	"id": "Castellón/Castelló",
	"nm": "Castillo de Villamalefa"
}, {
	"id": "Castellón/Castelló",
	"nm": "Catí"
}, {
	"id": "Castellón/Castelló",
	"nm": "Caudiel"
}, {
	"id": "Castellón/Castelló",
	"nm": "Cervera del Maestre"
}, {
	"id": "Castellón/Castelló",
	"nm": "Chilches/Xilxes"
}, {
	"id": "Castellón/Castelló",
	"nm": "Chodos/Xodos"
}, {
	"id": "Castellón/Castelló",
	"nm": "Chóvar"
}, {
	"id": "Castellón/Castelló",
	"nm": "Cinctorres"
}, {
	"id": "Castellón/Castelló",
	"nm": "Cirat"
}, {
	"id": "Castellón/Castelló",
	"nm": "Cortes de Arenoso"
}, {
	"id": "Castellón/Castelló",
	"nm": "Costur"
}, {
	"id": "Castellón/Castelló",
	"nm": "Coves de Vinromà, les"
}, {
	"id": "Castellón/Castelló",
	"nm": "Culla"
}, {
	"id": "Castellón/Castelló",
	"nm": "Eslida"
}, {
	"id": "Castellón/Castelló",
	"nm": "Espadilla"
}, {
	"id": "Castellón/Castelló",
	"nm": "Fanzara"
}, {
	"id": "Castellón/Castelló",
	"nm": "Figueroles"
}, {
	"id": "Castellón/Castelló",
	"nm": "Forcall"
}, {
	"id": "Castellón/Castelló",
	"nm": "Fuente la Reina"
}, {
	"id": "Castellón/Castelló",
	"nm": "Fuentes de Ayódar"
}, {
	"id": "Castellón/Castelló",
	"nm": "Gaibiel"
}, {
	"id": "Castellón/Castelló",
	"nm": "Geldo"
}, {
	"id": "Castellón/Castelló",
	"nm": "Herbés"
}, {
	"id": "Castellón/Castelló",
	"nm": "Higueras"
}, {
	"id": "Castellón/Castelló",
	"nm": "Jana, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "Jérica"
}, {
	"id": "Castellón/Castelló",
	"nm": "Llosa, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "Lucena del Cid"
}, {
	"id": "Castellón/Castelló",
	"nm": "Ludiente"
}, {
	"id": "Castellón/Castelló",
	"nm": "Mata de Morella, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "Matet"
}, {
	"id": "Castellón/Castelló",
	"nm": "Moncofa"
}, {
	"id": "Castellón/Castelló",
	"nm": "Montán"
}, {
	"id": "Castellón/Castelló",
	"nm": "Montanejos"
}, {
	"id": "Castellón/Castelló",
	"nm": "Morella"
}, {
	"id": "Castellón/Castelló",
	"nm": "Navajas"
}, {
	"id": "Castellón/Castelló",
	"nm": "Nules"
}, {
	"id": "Castellón/Castelló",
	"nm": "Olocau del Rey"
}, {
	"id": "Castellón/Castelló",
	"nm": "Onda"
}, {
	"id": "Castellón/Castelló",
	"nm": "Oropesa del Mar/Orpesa"
}, {
	"id": "Castellón/Castelló",
	"nm": "Palanques"
}, {
	"id": "Castellón/Castelló",
	"nm": "Pavías"
}, {
	"id": "Castellón/Castelló",
	"nm": "Peníscola/Peñíscola"
}, {
	"id": "Castellón/Castelló",
	"nm": "Pina de Montalgrao"
}, {
	"id": "Castellón/Castelló",
	"nm": "Pobla de Benifassà, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "Pobla Tornesa, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "Portell de Morella"
}, {
	"id": "Castellón/Castelló",
	"nm": "Puebla de Arenoso"
}, {
	"id": "Castellón/Castelló",
	"nm": "Ribesalbes"
}, {
	"id": "Castellón/Castelló",
	"nm": "Rossell"
}, {
	"id": "Castellón/Castelló",
	"nm": "Sacañet"
}, {
	"id": "Castellón/Castelló",
	"nm": "Salzadella, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "San Rafael del Río"
}, {
	"id": "Castellón/Castelló",
	"nm": "Sant Joan de Moró"
}, {
	"id": "Castellón/Castelló",
	"nm": "Sant Jordi/San Jorge"
}, {
	"id": "Castellón/Castelló",
	"nm": "Sant Mateu"
}, {
	"id": "Castellón/Castelló",
	"nm": "Santa Magdalena de Pulpis"
}, {
	"id": "Castellón/Castelló",
	"nm": "Segorbe"
}, {
	"id": "Castellón/Castelló",
	"nm": "Serratella, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "Sierra Engarcerán"
}, {
	"id": "Castellón/Castelló",
	"nm": "Soneja"
}, {
	"id": "Castellón/Castelló",
	"nm": "Sot de Ferrer"
}, {
	"id": "Castellón/Castelló",
	"nm": "Sueras/Suera"
}, {
	"id": "Castellón/Castelló",
	"nm": "Tales"
}, {
	"id": "Castellón/Castelló",
	"nm": "Teresa"
}, {
	"id": "Castellón/Castelló",
	"nm": "Tírig"
}, {
	"id": "Castellón/Castelló",
	"nm": "Todolella"
}, {
	"id": "Castellón/Castelló",
	"nm": "Toga"
}, {
	"id": "Castellón/Castelló",
	"nm": "Torás"
}, {
	"id": "Castellón/Castelló",
	"nm": "Toro, El"
}, {
	"id": "Castellón/Castelló",
	"nm": "Torralba del Pinar"
}, {
	"id": "Castellón/Castelló",
	"nm": "Torre d'En Besora, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "Torre d'en Doménec, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "Torreblanca"
}, {
	"id": "Castellón/Castelló",
	"nm": "Torrechiva"
}, {
	"id": "Castellón/Castelló",
	"nm": "Traiguera"
}, {
	"id": "Castellón/Castelló",
	"nm": "Useras/Useres, les"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vall d'Alba"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vall de Almonacid"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vall d'Uixó, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vallat"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vallibona"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vilafamés"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vilanova d'Alcolea"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vilar de Canes"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vila-real"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vilavella, la"
}, {
	"id": "Castellón/Castelló",
	"nm": "Villafranca del Cid/Vilafranca"
}, {
	"id": "Castellón/Castelló",
	"nm": "Villahermosa del Río"
}, {
	"id": "Castellón/Castelló",
	"nm": "Villamalur"
}, {
	"id": "Castellón/Castelló",
	"nm": "Villanueva de Viver"
}, {
	"id": "Castellón/Castelló",
	"nm": "Villores"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vinaròs"
}, {
	"id": "Castellón/Castelló",
	"nm": "Vistabella del Maestrat"
}, {
	"id": "Castellón/Castelló",
	"nm": "Viver"
}, {
	"id": "Castellón/Castelló",
	"nm": "Xert"
}, {
	"id": "Castellón/Castelló",
	"nm": "Zorita del Maestrazgo"
}, {
	"id": "Castellón/Castelló",
	"nm": "Zucaina"
}, {
	"id": "Ciudad Real",
	"nm": "Abenójar"
}, {
	"id": "Ciudad Real",
	"nm": "Agudo"
}, {
	"id": "Ciudad Real",
	"nm": "Alamillo"
}, {
	"id": "Ciudad Real",
	"nm": "Albaladejo"
}, {
	"id": "Ciudad Real",
	"nm": "Alcázar de San Juan"
}, {
	"id": "Ciudad Real",
	"nm": "Alcoba"
}, {
	"id": "Ciudad Real",
	"nm": "Alcolea de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Alcubillas"
}, {
	"id": "Ciudad Real",
	"nm": "Aldea del Rey"
}, {
	"id": "Ciudad Real",
	"nm": "Alhambra"
}, {
	"id": "Ciudad Real",
	"nm": "Almadén"
}, {
	"id": "Ciudad Real",
	"nm": "Almadenejos"
}, {
	"id": "Ciudad Real",
	"nm": "Almagro"
}, {
	"id": "Ciudad Real",
	"nm": "Almedina"
}, {
	"id": "Ciudad Real",
	"nm": "Almodóvar del Campo"
}, {
	"id": "Ciudad Real",
	"nm": "Almuradiel"
}, {
	"id": "Ciudad Real",
	"nm": "Anchuras"
}, {
	"id": "Ciudad Real",
	"nm": "Arenales de San Gregorio"
}, {
	"id": "Ciudad Real",
	"nm": "Arenas de San Juan"
}, {
	"id": "Ciudad Real",
	"nm": "Argamasilla de Alba"
}, {
	"id": "Ciudad Real",
	"nm": "Argamasilla de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Arroba de los Montes"
}, {
	"id": "Ciudad Real",
	"nm": "Ballesteros de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Bolaños de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Brazatortas"
}, {
	"id": "Ciudad Real",
	"nm": "Cabezarados"
}, {
	"id": "Ciudad Real",
	"nm": "Cabezarrubias del Puerto"
}, {
	"id": "Ciudad Real",
	"nm": "Calzada de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Campo de Criptana"
}, {
	"id": "Ciudad Real",
	"nm": "Cañada de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Caracuel de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Carrión de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Carrizosa"
}, {
	"id": "Ciudad Real",
	"nm": "Castellar de Santiago"
}, {
	"id": "Ciudad Real",
	"nm": "Chillón"
}, {
	"id": "Ciudad Real",
	"nm": "Ciudad Real"
}, {
	"id": "Ciudad Real",
	"nm": "Corral de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Cortijos, Los"
}, {
	"id": "Ciudad Real",
	"nm": "Cózar"
}, {
	"id": "Ciudad Real",
	"nm": "Daimiel"
}, {
	"id": "Ciudad Real",
	"nm": "Fernán Caballero"
}, {
	"id": "Ciudad Real",
	"nm": "Fontanarejo"
}, {
	"id": "Ciudad Real",
	"nm": "Fuencaliente"
}, {
	"id": "Ciudad Real",
	"nm": "Fuenllana"
}, {
	"id": "Ciudad Real",
	"nm": "Fuente el Fresno"
}, {
	"id": "Ciudad Real",
	"nm": "Granátula de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Guadalmez"
}, {
	"id": "Ciudad Real",
	"nm": "Herencia"
}, {
	"id": "Ciudad Real",
	"nm": "Hinojosas de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Horcajo de los Montes"
}, {
	"id": "Ciudad Real",
	"nm": "Labores, Las"
}, {
	"id": "Ciudad Real",
	"nm": "Llanos del Caudillo"
}, {
	"id": "Ciudad Real",
	"nm": "Luciana"
}, {
	"id": "Ciudad Real",
	"nm": "Malagón"
}, {
	"id": "Ciudad Real",
	"nm": "Manzanares"
}, {
	"id": "Ciudad Real",
	"nm": "Membrilla"
}, {
	"id": "Ciudad Real",
	"nm": "Mestanza"
}, {
	"id": "Ciudad Real",
	"nm": "Miguelturra"
}, {
	"id": "Ciudad Real",
	"nm": "Montiel"
}, {
	"id": "Ciudad Real",
	"nm": "Moral de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Navalpino"
}, {
	"id": "Ciudad Real",
	"nm": "Navas de Estena"
}, {
	"id": "Ciudad Real",
	"nm": "Pedro Muñoz"
}, {
	"id": "Ciudad Real",
	"nm": "Picón"
}, {
	"id": "Ciudad Real",
	"nm": "Piedrabuena"
}, {
	"id": "Ciudad Real",
	"nm": "Poblete"
}, {
	"id": "Ciudad Real",
	"nm": "Porzuna"
}, {
	"id": "Ciudad Real",
	"nm": "Pozuelo de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Pozuelos de Calatrava, Los"
}, {
	"id": "Ciudad Real",
	"nm": "Puebla de Don Rodrigo"
}, {
	"id": "Ciudad Real",
	"nm": "Puebla del Príncipe"
}, {
	"id": "Ciudad Real",
	"nm": "Puerto Lápice"
}, {
	"id": "Ciudad Real",
	"nm": "Puertollano"
}, {
	"id": "Ciudad Real",
	"nm": "Retuerta del Bullaque"
}, {
	"id": "Ciudad Real",
	"nm": "Robledo, El"
}, {
	"id": "Ciudad Real",
	"nm": "Ruidera"
}, {
	"id": "Ciudad Real",
	"nm": "Saceruela"
}, {
	"id": "Ciudad Real",
	"nm": "San Carlos del Valle"
}, {
	"id": "Ciudad Real",
	"nm": "San Lorenzo de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Santa Cruz de los Cáñamos"
}, {
	"id": "Ciudad Real",
	"nm": "Santa Cruz de Mudela"
}, {
	"id": "Ciudad Real",
	"nm": "Socuéllamos"
}, {
	"id": "Ciudad Real",
	"nm": "Solana del Pino"
}, {
	"id": "Ciudad Real",
	"nm": "Solana, La"
}, {
	"id": "Ciudad Real",
	"nm": "Terrinches"
}, {
	"id": "Ciudad Real",
	"nm": "Tomelloso"
}, {
	"id": "Ciudad Real",
	"nm": "Torralba de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Torre de Juan Abad"
}, {
	"id": "Ciudad Real",
	"nm": "Torrenueva"
}, {
	"id": "Ciudad Real",
	"nm": "Valdemanco del Esteras"
}, {
	"id": "Ciudad Real",
	"nm": "Valdepeñas"
}, {
	"id": "Ciudad Real",
	"nm": "Valenzuela de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Villahermosa"
}, {
	"id": "Ciudad Real",
	"nm": "Villamanrique"
}, {
	"id": "Ciudad Real",
	"nm": "Villamayor de Calatrava"
}, {
	"id": "Ciudad Real",
	"nm": "Villanueva de la Fuente"
}, {
	"id": "Ciudad Real",
	"nm": "Villanueva de los Infantes"
}, {
	"id": "Ciudad Real",
	"nm": "Villanueva de San Carlos"
}, {
	"id": "Ciudad Real",
	"nm": "Villar del Pozo"
}, {
	"id": "Ciudad Real",
	"nm": "Villarrubia de los Ojos"
}, {
	"id": "Ciudad Real",
	"nm": "Villarta de San Juan"
}, {
	"id": "Ciudad Real",
	"nm": "Viso del Marqués"
}, {
	"id": "Córdoba",
	"nm": "Adamuz"
}, {
	"id": "Córdoba",
	"nm": "Aguilar de la Frontera"
}, {
	"id": "Córdoba",
	"nm": "Alcaracejos"
}, {
	"id": "Córdoba",
	"nm": "Almedinilla"
}, {
	"id": "Córdoba",
	"nm": "Almodóvar del Río"
}, {
	"id": "Córdoba",
	"nm": "Añora"
}, {
	"id": "Córdoba",
	"nm": "Baena"
}, {
	"id": "Córdoba",
	"nm": "Belalcázar"
}, {
	"id": "Córdoba",
	"nm": "Belmez"
}, {
	"id": "Córdoba",
	"nm": "Benamejí"
}, {
	"id": "Córdoba",
	"nm": "Blázquez, Los"
}, {
	"id": "Córdoba",
	"nm": "Bujalance"
}, {
	"id": "Córdoba",
	"nm": "Cabra"
}, {
	"id": "Córdoba",
	"nm": "Cañete de las Torres"
}, {
	"id": "Córdoba",
	"nm": "Carcabuey"
}, {
	"id": "Córdoba",
	"nm": "Cardeña"
}, {
	"id": "Córdoba",
	"nm": "Carlota, La"
}, {
	"id": "Córdoba",
	"nm": "Carpio, El"
}, {
	"id": "Córdoba",
	"nm": "Castro del Río"
}, {
	"id": "Córdoba",
	"nm": "Conquista"
}, {
	"id": "Córdoba",
	"nm": "Córdoba"
}, {
	"id": "Córdoba",
	"nm": "Doña Mencía"
}, {
	"id": "Córdoba",
	"nm": "Dos Torres"
}, {
	"id": "Córdoba",
	"nm": "Encinas Reales"
}, {
	"id": "Córdoba",
	"nm": "Espejo"
}, {
	"id": "Córdoba",
	"nm": "Espiel"
}, {
	"id": "Córdoba",
	"nm": "Fernán-Núñez"
}, {
	"id": "Córdoba",
	"nm": "Fuente la Lancha"
}, {
	"id": "Córdoba",
	"nm": "Fuente Obejuna"
}, {
	"id": "Córdoba",
	"nm": "Fuente Palmera"
}, {
	"id": "Córdoba",
	"nm": "Fuente-Tójar"
}, {
	"id": "Córdoba",
	"nm": "Granjuela, La"
}, {
	"id": "Córdoba",
	"nm": "Guadalcázar"
}, {
	"id": "Córdoba",
	"nm": "Guijo, El"
}, {
	"id": "Córdoba",
	"nm": "Hinojosa del Duque"
}, {
	"id": "Córdoba",
	"nm": "Hornachuelos"
}, {
	"id": "Córdoba",
	"nm": "Iznájar"
}, {
	"id": "Córdoba",
	"nm": "Lucena"
}, {
	"id": "Córdoba",
	"nm": "Luque"
}, {
	"id": "Córdoba",
	"nm": "Montalbán de Córdoba"
}, {
	"id": "Córdoba",
	"nm": "Montemayor"
}, {
	"id": "Córdoba",
	"nm": "Montilla"
}, {
	"id": "Córdoba",
	"nm": "Montoro"
}, {
	"id": "Córdoba",
	"nm": "Monturque"
}, {
	"id": "Córdoba",
	"nm": "Moriles"
}, {
	"id": "Córdoba",
	"nm": "Nueva Carteya"
}, {
	"id": "Córdoba",
	"nm": "Obejo"
}, {
	"id": "Córdoba",
	"nm": "Palenciana"
}, {
	"id": "Córdoba",
	"nm": "Palma del Río"
}, {
	"id": "Córdoba",
	"nm": "Pedro Abad"
}, {
	"id": "Córdoba",
	"nm": "Pedroche"
}, {
	"id": "Córdoba",
	"nm": "Peñarroya-Pueblonuevo"
}, {
	"id": "Córdoba",
	"nm": "Posadas"
}, {
	"id": "Córdoba",
	"nm": "Pozoblanco"
}, {
	"id": "Córdoba",
	"nm": "Priego de Córdoba"
}, {
	"id": "Córdoba",
	"nm": "Puente Genil"
}, {
	"id": "Córdoba",
	"nm": "Rambla, La"
}, {
	"id": "Córdoba",
	"nm": "Rute"
}, {
	"id": "Córdoba",
	"nm": "San Sebastián de los Ballesteros"
}, {
	"id": "Córdoba",
	"nm": "Santa Eufemia"
}, {
	"id": "Córdoba",
	"nm": "Santaella"
}, {
	"id": "Córdoba",
	"nm": "Torrecampo"
}, {
	"id": "Córdoba",
	"nm": "Valenzuela"
}, {
	"id": "Córdoba",
	"nm": "Valsequillo"
}, {
	"id": "Córdoba",
	"nm": "Victoria, La"
}, {
	"id": "Córdoba",
	"nm": "Villa del Río"
}, {
	"id": "Córdoba",
	"nm": "Villafranca de Córdoba"
}, {
	"id": "Córdoba",
	"nm": "Villaharta"
}, {
	"id": "Córdoba",
	"nm": "Villanueva de Córdoba"
}, {
	"id": "Córdoba",
	"nm": "Villanueva del Duque"
}, {
	"id": "Córdoba",
	"nm": "Villanueva del Rey"
}, {
	"id": "Córdoba",
	"nm": "Villaralto"
}, {
	"id": "Córdoba",
	"nm": "Villaviciosa de Córdoba"
}, {
	"id": "Córdoba",
	"nm": "Viso, El"
}, {
	"id": "Córdoba",
	"nm": "Zuheros"
}, {
	"id": "Coruña, A",
	"nm": "Abegondo"
}, {
	"id": "Coruña, A",
	"nm": "Ames"
}, {
	"id": "Coruña, A",
	"nm": "Aranga"
}, {
	"id": "Coruña, A",
	"nm": "Ares"
}, {
	"id": "Coruña, A",
	"nm": "Arteixo"
}, {
	"id": "Coruña, A",
	"nm": "Arzúa"
}, {
	"id": "Coruña, A",
	"nm": "Baña, A"
}, {
	"id": "Coruña, A",
	"nm": "Bergondo"
}, {
	"id": "Coruña, A",
	"nm": "Betanzos"
}, {
	"id": "Coruña, A",
	"nm": "Boimorto"
}, {
	"id": "Coruña, A",
	"nm": "Boiro"
}, {
	"id": "Coruña, A",
	"nm": "Boqueixón"
}, {
	"id": "Coruña, A",
	"nm": "Brión"
}, {
	"id": "Coruña, A",
	"nm": "Cabana de Bergantiños"
}, {
	"id": "Coruña, A",
	"nm": "Cabanas"
}, {
	"id": "Coruña, A",
	"nm": "Camariñas"
}, {
	"id": "Coruña, A",
	"nm": "Cambre"
}, {
	"id": "Coruña, A",
	"nm": "Capela, A"
}, {
	"id": "Coruña, A",
	"nm": "Carballo"
}, {
	"id": "Coruña, A",
	"nm": "Cariño"
}, {
	"id": "Coruña, A",
	"nm": "Carnota"
}, {
	"id": "Coruña, A",
	"nm": "Carral"
}, {
	"id": "Coruña, A",
	"nm": "Cedeira"
}, {
	"id": "Coruña, A",
	"nm": "Cee"
}, {
	"id": "Coruña, A",
	"nm": "Cerceda"
}, {
	"id": "Coruña, A",
	"nm": "Cerdido"
}, {
	"id": "Coruña, A",
	"nm": "Coirós"
}, {
	"id": "Coruña, A",
	"nm": "Corcubión"
}, {
	"id": "Coruña, A",
	"nm": "Coristanco"
}, {
	"id": "Coruña, A",
	"nm": "Coruña, A"
}, {
	"id": "Coruña, A",
	"nm": "Culleredo"
}, {
	"id": "Coruña, A",
	"nm": "Curtis"
}, {
	"id": "Coruña, A",
	"nm": "Dodro"
}, {
	"id": "Coruña, A",
	"nm": "Dumbría"
}, {
	"id": "Coruña, A",
	"nm": "Fene"
}, {
	"id": "Coruña, A",
	"nm": "Ferrol"
}, {
	"id": "Coruña, A",
	"nm": "Fisterra"
}, {
	"id": "Coruña, A",
	"nm": "Frades"
}, {
	"id": "Coruña, A",
	"nm": "Irixoa"
}, {
	"id": "Coruña, A",
	"nm": "Laracha, A"
}, {
	"id": "Coruña, A",
	"nm": "Laxe"
}, {
	"id": "Coruña, A",
	"nm": "Lousame"
}, {
	"id": "Coruña, A",
	"nm": "Malpica de Bergantiños"
}, {
	"id": "Coruña, A",
	"nm": "Mañón"
}, {
	"id": "Coruña, A",
	"nm": "Mazaricos"
}, {
	"id": "Coruña, A",
	"nm": "Melide"
}, {
	"id": "Coruña, A",
	"nm": "Mesía"
}, {
	"id": "Coruña, A",
	"nm": "Miño"
}, {
	"id": "Coruña, A",
	"nm": "Moeche"
}, {
	"id": "Coruña, A",
	"nm": "Monfero"
}, {
	"id": "Coruña, A",
	"nm": "Mugardos"
}, {
	"id": "Coruña, A",
	"nm": "Muros"
}, {
	"id": "Coruña, A",
	"nm": "Muxía"
}, {
	"id": "Coruña, A",
	"nm": "Narón"
}, {
	"id": "Coruña, A",
	"nm": "Neda"
}, {
	"id": "Coruña, A",
	"nm": "Negreira"
}, {
	"id": "Coruña, A",
	"nm": "Noia"
}, {
	"id": "Coruña, A",
	"nm": "Oleiros"
}, {
	"id": "Coruña, A",
	"nm": "Ordes"
}, {
	"id": "Coruña, A",
	"nm": "Oroso"
}, {
	"id": "Coruña, A",
	"nm": "Ortigueira"
}, {
	"id": "Coruña, A",
	"nm": "Outes"
}, {
	"id": "Coruña, A",
	"nm": "Oza-Cesuras"
}, {
	"id": "Coruña, A",
	"nm": "Paderne"
}, {
	"id": "Coruña, A",
	"nm": "Padrón"
}, {
	"id": "Coruña, A",
	"nm": "Pino, O"
}, {
	"id": "Coruña, A",
	"nm": "Pobra do Caramiñal, A"
}, {
	"id": "Coruña, A",
	"nm": "Ponteceso"
}, {
	"id": "Coruña, A",
	"nm": "Pontedeume"
}, {
	"id": "Coruña, A",
	"nm": "Pontes de García Rodríguez, As"
}, {
	"id": "Coruña, A",
	"nm": "Porto do Son"
}, {
	"id": "Coruña, A",
	"nm": "Rianxo"
}, {
	"id": "Coruña, A",
	"nm": "Ribeira"
}, {
	"id": "Coruña, A",
	"nm": "Rois"
}, {
	"id": "Coruña, A",
	"nm": "Sada"
}, {
	"id": "Coruña, A",
	"nm": "San Sadurniño"
}, {
	"id": "Coruña, A",
	"nm": "Santa Comba"
}, {
	"id": "Coruña, A",
	"nm": "Santiago de Compostela"
}, {
	"id": "Coruña, A",
	"nm": "Santiso"
}, {
	"id": "Coruña, A",
	"nm": "Sobrado"
}, {
	"id": "Coruña, A",
	"nm": "Somozas, As"
}, {
	"id": "Coruña, A",
	"nm": "Teo"
}, {
	"id": "Coruña, A",
	"nm": "Toques"
}, {
	"id": "Coruña, A",
	"nm": "Tordoia"
}, {
	"id": "Coruña, A",
	"nm": "Touro"
}, {
	"id": "Coruña, A",
	"nm": "Trazo"
}, {
	"id": "Coruña, A",
	"nm": "Val do Dubra"
}, {
	"id": "Coruña, A",
	"nm": "Valdoviño"
}, {
	"id": "Coruña, A",
	"nm": "Vedra"
}, {
	"id": "Coruña, A",
	"nm": "Vilarmaior"
}, {
	"id": "Coruña, A",
	"nm": "Vilasantar"
}, {
	"id": "Coruña, A",
	"nm": "Vimianzo"
}, {
	"id": "Coruña, A",
	"nm": "Zas"
}, {
	"id": "Cuenca",
	"nm": "Abia de la Obispalía"
}, {
	"id": "Cuenca",
	"nm": "Acebrón, El"
}, {
	"id": "Cuenca",
	"nm": "Alarcón"
}, {
	"id": "Cuenca",
	"nm": "Albaladejo del Cuende"
}, {
	"id": "Cuenca",
	"nm": "Albalate de las Nogueras"
}, {
	"id": "Cuenca",
	"nm": "Albendea"
}, {
	"id": "Cuenca",
	"nm": "Alberca de Záncara, La"
}, {
	"id": "Cuenca",
	"nm": "Alcalá de la Vega"
}, {
	"id": "Cuenca",
	"nm": "Alcantud"
}, {
	"id": "Cuenca",
	"nm": "Alcázar del Rey"
}, {
	"id": "Cuenca",
	"nm": "Alcohujate"
}, {
	"id": "Cuenca",
	"nm": "Alconchel de la Estrella"
}, {
	"id": "Cuenca",
	"nm": "Algarra"
}, {
	"id": "Cuenca",
	"nm": "Aliaguilla"
}, {
	"id": "Cuenca",
	"nm": "Almarcha, La"
}, {
	"id": "Cuenca",
	"nm": "Almendros"
}, {
	"id": "Cuenca",
	"nm": "Almodóvar del Pinar"
}, {
	"id": "Cuenca",
	"nm": "Almonacid del Marquesado"
}, {
	"id": "Cuenca",
	"nm": "Altarejos"
}, {
	"id": "Cuenca",
	"nm": "Arandilla del Arroyo"
}, {
	"id": "Cuenca",
	"nm": "Arcas"
}, {
	"id": "Cuenca",
	"nm": "Arcos de la Sierra"
}, {
	"id": "Cuenca",
	"nm": "Arguisuelas"
}, {
	"id": "Cuenca",
	"nm": "Arrancacepas"
}, {
	"id": "Cuenca",
	"nm": "Atalaya del Cañavate"
}, {
	"id": "Cuenca",
	"nm": "Barajas de Melo"
}, {
	"id": "Cuenca",
	"nm": "Barchín del Hoyo"
}, {
	"id": "Cuenca",
	"nm": "Bascuñana de San Pedro"
}, {
	"id": "Cuenca",
	"nm": "Beamud"
}, {
	"id": "Cuenca",
	"nm": "Belinchón"
}, {
	"id": "Cuenca",
	"nm": "Belmonte"
}, {
	"id": "Cuenca",
	"nm": "Belmontejo"
}, {
	"id": "Cuenca",
	"nm": "Beteta"
}, {
	"id": "Cuenca",
	"nm": "Boniches"
}, {
	"id": "Cuenca",
	"nm": "Buciegas"
}, {
	"id": "Cuenca",
	"nm": "Buenache de Alarcón"
}, {
	"id": "Cuenca",
	"nm": "Buenache de la Sierra"
}, {
	"id": "Cuenca",
	"nm": "Buendía"
}, {
	"id": "Cuenca",
	"nm": "Campillo de Altobuey"
}, {
	"id": "Cuenca",
	"nm": "Campillos-Paravientos"
}, {
	"id": "Cuenca",
	"nm": "Campillos-Sierra"
}, {
	"id": "Cuenca",
	"nm": "Campos del Paraíso"
}, {
	"id": "Cuenca",
	"nm": "Canalejas del Arroyo"
}, {
	"id": "Cuenca",
	"nm": "Cañada del Hoyo"
}, {
	"id": "Cuenca",
	"nm": "Cañada Juncosa"
}, {
	"id": "Cuenca",
	"nm": "Cañamares"
}, {
	"id": "Cuenca",
	"nm": "Cañavate, El"
}, {
	"id": "Cuenca",
	"nm": "Cañaveras"
}, {
	"id": "Cuenca",
	"nm": "Cañaveruelas"
}, {
	"id": "Cuenca",
	"nm": "Cañete"
}, {
	"id": "Cuenca",
	"nm": "Cañizares"
}, {
	"id": "Cuenca",
	"nm": "Carboneras de Guadazaón"
}, {
	"id": "Cuenca",
	"nm": "Cardenete"
}, {
	"id": "Cuenca",
	"nm": "Carrascosa"
}, {
	"id": "Cuenca",
	"nm": "Carrascosa de Haro"
}, {
	"id": "Cuenca",
	"nm": "Casas de Benítez"
}, {
	"id": "Cuenca",
	"nm": "Casas de Fernando Alonso"
}, {
	"id": "Cuenca",
	"nm": "Casas de Garcimolina"
}, {
	"id": "Cuenca",
	"nm": "Casas de Guijarro"
}, {
	"id": "Cuenca",
	"nm": "Casas de Haro"
}, {
	"id": "Cuenca",
	"nm": "Casas de los Pinos"
}, {
	"id": "Cuenca",
	"nm": "Casasimarro"
}, {
	"id": "Cuenca",
	"nm": "Castejón"
}, {
	"id": "Cuenca",
	"nm": "Castillejo de Iniesta"
}, {
	"id": "Cuenca",
	"nm": "Castillejo-Sierra"
}, {
	"id": "Cuenca",
	"nm": "Castillo de Garcimuñoz"
}, {
	"id": "Cuenca",
	"nm": "Castillo-Albaráñez"
}, {
	"id": "Cuenca",
	"nm": "Cervera del Llano"
}, {
	"id": "Cuenca",
	"nm": "Chillarón de Cuenca"
}, {
	"id": "Cuenca",
	"nm": "Chumillas"
}, {
	"id": "Cuenca",
	"nm": "Cierva, La"
}, {
	"id": "Cuenca",
	"nm": "Cuenca"
}, {
	"id": "Cuenca",
	"nm": "Cueva del Hierro"
}, {
	"id": "Cuenca",
	"nm": "Enguídanos"
}, {
	"id": "Cuenca",
	"nm": "Fresneda de Altarejos"
}, {
	"id": "Cuenca",
	"nm": "Fresneda de la Sierra"
}, {
	"id": "Cuenca",
	"nm": "Frontera, La"
}, {
	"id": "Cuenca",
	"nm": "Fuente de Pedro Naharro"
}, {
	"id": "Cuenca",
	"nm": "Fuentelespino de Haro"
}, {
	"id": "Cuenca",
	"nm": "Fuentelespino de Moya"
}, {
	"id": "Cuenca",
	"nm": "Fuentenava de Jábaga"
}, {
	"id": "Cuenca",
	"nm": "Fuentes"
}, {
	"id": "Cuenca",
	"nm": "Fuertescusa"
}, {
	"id": "Cuenca",
	"nm": "Gabaldón"
}, {
	"id": "Cuenca",
	"nm": "Garaballa"
}, {
	"id": "Cuenca",
	"nm": "Gascueña"
}, {
	"id": "Cuenca",
	"nm": "Graja de Campalbo"
}, {
	"id": "Cuenca",
	"nm": "Graja de Iniesta"
}, {
	"id": "Cuenca",
	"nm": "Henarejos"
}, {
	"id": "Cuenca",
	"nm": "Herrumblar, El"
}, {
	"id": "Cuenca",
	"nm": "Hinojosa, La"
}, {
	"id": "Cuenca",
	"nm": "Hinojosos, Los"
}, {
	"id": "Cuenca",
	"nm": "Hito, El"
}, {
	"id": "Cuenca",
	"nm": "Honrubia"
}, {
	"id": "Cuenca",
	"nm": "Hontanaya"
}, {
	"id": "Cuenca",
	"nm": "Hontecillas"
}, {
	"id": "Cuenca",
	"nm": "Horcajo de Santiago"
}, {
	"id": "Cuenca",
	"nm": "Huélamo"
}, {
	"id": "Cuenca",
	"nm": "Huelves"
}, {
	"id": "Cuenca",
	"nm": "Huérguina"
}, {
	"id": "Cuenca",
	"nm": "Huerta de la Obispalía"
}, {
	"id": "Cuenca",
	"nm": "Huerta del Marquesado"
}, {
	"id": "Cuenca",
	"nm": "Huete"
}, {
	"id": "Cuenca",
	"nm": "Iniesta"
}, {
	"id": "Cuenca",
	"nm": "Laguna del Marquesado"
}, {
	"id": "Cuenca",
	"nm": "Lagunaseca"
}, {
	"id": "Cuenca",
	"nm": "Landete"
}, {
	"id": "Cuenca",
	"nm": "Ledaña"
}, {
	"id": "Cuenca",
	"nm": "Leganiel"
}, {
	"id": "Cuenca",
	"nm": "Majadas, Las"
}, {
	"id": "Cuenca",
	"nm": "Mariana"
}, {
	"id": "Cuenca",
	"nm": "Masegosa"
}, {
	"id": "Cuenca",
	"nm": "Mesas, Las"
}, {
	"id": "Cuenca",
	"nm": "Minglanilla"
}, {
	"id": "Cuenca",
	"nm": "Mira"
}, {
	"id": "Cuenca",
	"nm": "Monreal del Llano"
}, {
	"id": "Cuenca",
	"nm": "Montalbanejo"
}, {
	"id": "Cuenca",
	"nm": "Montalbo"
}, {
	"id": "Cuenca",
	"nm": "Monteagudo de las Salinas"
}, {
	"id": "Cuenca",
	"nm": "Mota de Altarejos"
}, {
	"id": "Cuenca",
	"nm": "Mota del Cuervo"
}, {
	"id": "Cuenca",
	"nm": "Motilla del Palancar"
}, {
	"id": "Cuenca",
	"nm": "Moya"
}, {
	"id": "Cuenca",
	"nm": "Narboneta"
}, {
	"id": "Cuenca",
	"nm": "Olivares de Júcar"
}, {
	"id": "Cuenca",
	"nm": "Olmeda de la Cuesta"
}, {
	"id": "Cuenca",
	"nm": "Olmeda del Rey"
}, {
	"id": "Cuenca",
	"nm": "Olmedilla de Alarcón"
}, {
	"id": "Cuenca",
	"nm": "Olmedilla de Eliz"
}, {
	"id": "Cuenca",
	"nm": "Osa de la Vega"
}, {
	"id": "Cuenca",
	"nm": "Pajarón"
}, {
	"id": "Cuenca",
	"nm": "Pajaroncillo"
}, {
	"id": "Cuenca",
	"nm": "Palomares del Campo"
}, {
	"id": "Cuenca",
	"nm": "Palomera"
}, {
	"id": "Cuenca",
	"nm": "Paracuellos"
}, {
	"id": "Cuenca",
	"nm": "Paredes"
}, {
	"id": "Cuenca",
	"nm": "Parra de las Vegas, La"
}, {
	"id": "Cuenca",
	"nm": "Pedernoso, El"
}, {
	"id": "Cuenca",
	"nm": "Pedroñeras, Las"
}, {
	"id": "Cuenca",
	"nm": "Peral, El"
}, {
	"id": "Cuenca",
	"nm": "Peraleja, La"
}, {
	"id": "Cuenca",
	"nm": "Pesquera, La"
}, {
	"id": "Cuenca",
	"nm": "Picazo, El"
}, {
	"id": "Cuenca",
	"nm": "Pinarejo"
}, {
	"id": "Cuenca",
	"nm": "Pineda de Gigüela"
}, {
	"id": "Cuenca",
	"nm": "Piqueras del Castillo"
}, {
	"id": "Cuenca",
	"nm": "Portalrubio de Guadamejud"
}, {
	"id": "Cuenca",
	"nm": "Portilla"
}, {
	"id": "Cuenca",
	"nm": "Poyatos"
}, {
	"id": "Cuenca",
	"nm": "Pozoamargo"
}, {
	"id": "Cuenca",
	"nm": "Pozorrubielos de la Mancha"
}, {
	"id": "Cuenca",
	"nm": "Pozorrubio de Santiago"
}, {
	"id": "Cuenca",
	"nm": "Pozuelo, El"
}, {
	"id": "Cuenca",
	"nm": "Priego"
}, {
	"id": "Cuenca",
	"nm": "Provencio, El"
}, {
	"id": "Cuenca",
	"nm": "Puebla de Almenara"
}, {
	"id": "Cuenca",
	"nm": "Puebla del Salvador"
}, {
	"id": "Cuenca",
	"nm": "Quintanar del Rey"
}, {
	"id": "Cuenca",
	"nm": "Rada de Haro"
}, {
	"id": "Cuenca",
	"nm": "Reíllo"
}, {
	"id": "Cuenca",
	"nm": "Rozalén del Monte"
}, {
	"id": "Cuenca",
	"nm": "Saceda-Trasierra"
}, {
	"id": "Cuenca",
	"nm": "Saelices"
}, {
	"id": "Cuenca",
	"nm": "Salinas del Manzano"
}, {
	"id": "Cuenca",
	"nm": "Salmeroncillos"
}, {
	"id": "Cuenca",
	"nm": "Salvacañete"
}, {
	"id": "Cuenca",
	"nm": "San Clemente"
}, {
	"id": "Cuenca",
	"nm": "San Lorenzo de la Parrilla"
}, {
	"id": "Cuenca",
	"nm": "San Martín de Boniches"
}, {
	"id": "Cuenca",
	"nm": "San Pedro Palmiches"
}, {
	"id": "Cuenca",
	"nm": "Santa Cruz de Moya"
}, {
	"id": "Cuenca",
	"nm": "Santa María de los Llanos"
}, {
	"id": "Cuenca",
	"nm": "Santa María del Campo Rus"
}, {
	"id": "Cuenca",
	"nm": "Santa María del Val"
}, {
	"id": "Cuenca",
	"nm": "Sisante"
}, {
	"id": "Cuenca",
	"nm": "Solera de Gabaldón"
}, {
	"id": "Cuenca",
	"nm": "Sotorribas"
}, {
	"id": "Cuenca",
	"nm": "Talayuelas"
}, {
	"id": "Cuenca",
	"nm": "Tarancón"
}, {
	"id": "Cuenca",
	"nm": "Tébar"
}, {
	"id": "Cuenca",
	"nm": "Tejadillos"
}, {
	"id": "Cuenca",
	"nm": "Tinajas"
}, {
	"id": "Cuenca",
	"nm": "Torralba"
}, {
	"id": "Cuenca",
	"nm": "Torrejoncillo del Rey"
}, {
	"id": "Cuenca",
	"nm": "Torrubia del Campo"
}, {
	"id": "Cuenca",
	"nm": "Torrubia del Castillo"
}, {
	"id": "Cuenca",
	"nm": "Tragacete"
}, {
	"id": "Cuenca",
	"nm": "Tresjuncos"
}, {
	"id": "Cuenca",
	"nm": "Tribaldos"
}, {
	"id": "Cuenca",
	"nm": "Uclés"
}, {
	"id": "Cuenca",
	"nm": "Uña"
}, {
	"id": "Cuenca",
	"nm": "Valdecolmenas, Los"
}, {
	"id": "Cuenca",
	"nm": "Valdemeca"
}, {
	"id": "Cuenca",
	"nm": "Valdemorillo de la Sierra"
}, {
	"id": "Cuenca",
	"nm": "Valdemoro-Sierra"
}, {
	"id": "Cuenca",
	"nm": "Valdeolivas"
}, {
	"id": "Cuenca",
	"nm": "Valdetórtola"
}, {
	"id": "Cuenca",
	"nm": "Valeras, Las"
}, {
	"id": "Cuenca",
	"nm": "Valhermoso de la Fuente"
}, {
	"id": "Cuenca",
	"nm": "Valle de Altomira, El"
}, {
	"id": "Cuenca",
	"nm": "Valsalobre"
}, {
	"id": "Cuenca",
	"nm": "Valverde de Júcar"
}, {
	"id": "Cuenca",
	"nm": "Valverdejo"
}, {
	"id": "Cuenca",
	"nm": "Vara de Rey"
}, {
	"id": "Cuenca",
	"nm": "Vega del Codorno"
}, {
	"id": "Cuenca",
	"nm": "Vellisca"
}, {
	"id": "Cuenca",
	"nm": "Villaconejos de Trabaque"
}, {
	"id": "Cuenca",
	"nm": "Villaescusa de Haro"
}, {
	"id": "Cuenca",
	"nm": "Villagarcía del Llano"
}, {
	"id": "Cuenca",
	"nm": "Villalba de la Sierra"
}, {
	"id": "Cuenca",
	"nm": "Villalba del Rey"
}, {
	"id": "Cuenca",
	"nm": "Villalgordo del Marquesado"
}, {
	"id": "Cuenca",
	"nm": "Villalpardo"
}, {
	"id": "Cuenca",
	"nm": "Villamayor de Santiago"
}, {
	"id": "Cuenca",
	"nm": "Villanueva de Guadamejud"
}, {
	"id": "Cuenca",
	"nm": "Villanueva de la Jara"
}, {
	"id": "Cuenca",
	"nm": "Villar de Cañas"
}, {
	"id": "Cuenca",
	"nm": "Villar de Domingo García"
}, {
	"id": "Cuenca",
	"nm": "Villar de la Encina"
}, {
	"id": "Cuenca",
	"nm": "Villar de Olalla"
}, {
	"id": "Cuenca",
	"nm": "Villar del Humo"
}, {
	"id": "Cuenca",
	"nm": "Villar del Infantado"
}, {
	"id": "Cuenca",
	"nm": "Villar y Velasco"
}, {
	"id": "Cuenca",
	"nm": "Villarejo de Fuentes"
}, {
	"id": "Cuenca",
	"nm": "Villarejo de la Peñuela"
}, {
	"id": "Cuenca",
	"nm": "Villarejo-Periesteban"
}, {
	"id": "Cuenca",
	"nm": "Villares del Saz"
}, {
	"id": "Cuenca",
	"nm": "Villarrubio"
}, {
	"id": "Cuenca",
	"nm": "Villarta"
}, {
	"id": "Cuenca",
	"nm": "Villas de la Ventosa"
}, {
	"id": "Cuenca",
	"nm": "Villaverde y Pasaconsol"
}, {
	"id": "Cuenca",
	"nm": "Víllora"
}, {
	"id": "Cuenca",
	"nm": "Vindel"
}, {
	"id": "Cuenca",
	"nm": "Yémeda"
}, {
	"id": "Cuenca",
	"nm": "Zafra de Záncara"
}, {
	"id": "Cuenca",
	"nm": "Zafrilla"
}, {
	"id": "Cuenca",
	"nm": "Zarza de Tajo"
}, {
	"id": "Cuenca",
	"nm": "Zarzuela"
}, {
	"id": "Girona",
	"nm": "Agullana"
}, {
	"id": "Girona",
	"nm": "Aiguaviva"
}, {
	"id": "Girona",
	"nm": "Albanyà"
}, {
	"id": "Girona",
	"nm": "Albons"
}, {
	"id": "Girona",
	"nm": "Alp"
}, {
	"id": "Girona",
	"nm": "Amer"
}, {
	"id": "Girona",
	"nm": "Anglès"
}, {
	"id": "Girona",
	"nm": "Arbúcies"
}, {
	"id": "Girona",
	"nm": "Argelaguer"
}, {
	"id": "Girona",
	"nm": "Armentera, L'"
}, {
	"id": "Girona",
	"nm": "Avinyonet de Puigventós"
}, {
	"id": "Girona",
	"nm": "Banyoles"
}, {
	"id": "Girona",
	"nm": "Bàscara"
}, {
	"id": "Girona",
	"nm": "Begur"
}, {
	"id": "Girona",
	"nm": "Bellcaire d'Empordà"
}, {
	"id": "Girona",
	"nm": "Besalú"
}, {
	"id": "Girona",
	"nm": "Bescanó"
}, {
	"id": "Girona",
	"nm": "Beuda"
}, {
	"id": "Girona",
	"nm": "Bisbal d'Empordà, La"
}, {
	"id": "Girona",
	"nm": "Biure"
}, {
	"id": "Girona",
	"nm": "Blanes"
}, {
	"id": "Girona",
	"nm": "Boadella i les Escaules"
}, {
	"id": "Girona",
	"nm": "Bolvir"
}, {
	"id": "Girona",
	"nm": "Bordils"
}, {
	"id": "Girona",
	"nm": "Borrassà"
}, {
	"id": "Girona",
	"nm": "Breda"
}, {
	"id": "Girona",
	"nm": "Brunyola"
}, {
	"id": "Girona",
	"nm": "Cabanelles"
}, {
	"id": "Girona",
	"nm": "Cabanes"
}, {
	"id": "Girona",
	"nm": "Cadaqués"
}, {
	"id": "Girona",
	"nm": "Caldes de Malavella"
}, {
	"id": "Girona",
	"nm": "Calonge"
}, {
	"id": "Girona",
	"nm": "Camós"
}, {
	"id": "Girona",
	"nm": "Campdevànol"
}, {
	"id": "Girona",
	"nm": "Campelles"
}, {
	"id": "Girona",
	"nm": "Campllong"
}, {
	"id": "Girona",
	"nm": "Camprodon"
}, {
	"id": "Girona",
	"nm": "Canet d'Adri"
}, {
	"id": "Girona",
	"nm": "Cantallops"
}, {
	"id": "Girona",
	"nm": "Capmany"
}, {
	"id": "Girona",
	"nm": "Cassà de la Selva"
}, {
	"id": "Girona",
	"nm": "Castellfollit de la Roca"
}, {
	"id": "Girona",
	"nm": "Castelló d'Empúries"
}, {
	"id": "Girona",
	"nm": "Castell-Platja d'Aro"
}, {
	"id": "Girona",
	"nm": "Cellera de Ter, La"
}, {
	"id": "Girona",
	"nm": "Celrà"
}, {
	"id": "Girona",
	"nm": "Cervià de Ter"
}, {
	"id": "Girona",
	"nm": "Cistella"
}, {
	"id": "Girona",
	"nm": "Colera"
}, {
	"id": "Girona",
	"nm": "Colomers"
}, {
	"id": "Girona",
	"nm": "Corçà"
}, {
	"id": "Girona",
	"nm": "Cornellà del Terri"
}, {
	"id": "Girona",
	"nm": "Crespià"
}, {
	"id": "Girona",
	"nm": "Cruïlles, Monells i Sant Sadurní de l'Heura"
}, {
	"id": "Girona",
	"nm": "Darnius"
}, {
	"id": "Girona",
	"nm": "Das"
}, {
	"id": "Girona",
	"nm": "Escala, L'"
}, {
	"id": "Girona",
	"nm": "Espinelves"
}, {
	"id": "Girona",
	"nm": "Espolla"
}, {
	"id": "Girona",
	"nm": "Esponellà"
}, {
	"id": "Girona",
	"nm": "Far d'Empordà, El"
}, {
	"id": "Girona",
	"nm": "Figueres"
}, {
	"id": "Girona",
	"nm": "Flaçà"
}, {
	"id": "Girona",
	"nm": "Foixà"
}, {
	"id": "Girona",
	"nm": "Fontanals de Cerdanya"
}, {
	"id": "Girona",
	"nm": "Fontanilles"
}, {
	"id": "Girona",
	"nm": "Fontcoberta"
}, {
	"id": "Girona",
	"nm": "Forallac"
}, {
	"id": "Girona",
	"nm": "Fornells de la Selva"
}, {
	"id": "Girona",
	"nm": "Fortià"
}, {
	"id": "Girona",
	"nm": "Garrigàs"
}, {
	"id": "Girona",
	"nm": "Garrigoles"
}, {
	"id": "Girona",
	"nm": "Garriguella"
}, {
	"id": "Girona",
	"nm": "Ger"
}, {
	"id": "Girona",
	"nm": "Girona"
}, {
	"id": "Girona",
	"nm": "Gombrèn"
}, {
	"id": "Girona",
	"nm": "Gualta"
}, {
	"id": "Girona",
	"nm": "Guils de Cerdanya"
}, {
	"id": "Girona",
	"nm": "Hostalric"
}, {
	"id": "Girona",
	"nm": "Isòvol"
}, {
	"id": "Girona",
	"nm": "Jafre"
}, {
	"id": "Girona",
	"nm": "Jonquera, La"
}, {
	"id": "Girona",
	"nm": "Juià"
}, {
	"id": "Girona",
	"nm": "Lladó"
}, {
	"id": "Girona",
	"nm": "Llagostera"
}, {
	"id": "Girona",
	"nm": "Llambilles"
}, {
	"id": "Girona",
	"nm": "Llanars"
}, {
	"id": "Girona",
	"nm": "Llançà"
}, {
	"id": "Girona",
	"nm": "Llers"
}, {
	"id": "Girona",
	"nm": "Llívia"
}, {
	"id": "Girona",
	"nm": "Lloret de Mar"
}, {
	"id": "Girona",
	"nm": "Llosses, Les"
}, {
	"id": "Girona",
	"nm": "Maçanet de Cabrenys"
}, {
	"id": "Girona",
	"nm": "Maçanet de la Selva"
}, {
	"id": "Girona",
	"nm": "Madremanya"
}, {
	"id": "Girona",
	"nm": "Maià de Montcal"
}, {
	"id": "Girona",
	"nm": "Masarac"
}, {
	"id": "Girona",
	"nm": "Massanes"
}, {
	"id": "Girona",
	"nm": "Meranges"
}, {
	"id": "Girona",
	"nm": "Mieres"
}, {
	"id": "Girona",
	"nm": "Mollet de Peralada"
}, {
	"id": "Girona",
	"nm": "Molló"
}, {
	"id": "Girona",
	"nm": "Montagut i Oix"
}, {
	"id": "Girona",
	"nm": "Mont-ras"
}, {
	"id": "Girona",
	"nm": "Navata"
}, {
	"id": "Girona",
	"nm": "Ogassa"
}, {
	"id": "Girona",
	"nm": "Olot"
}, {
	"id": "Girona",
	"nm": "Ordis"
}, {
	"id": "Girona",
	"nm": "Osor"
}, {
	"id": "Girona",
	"nm": "Palafrugell"
}, {
	"id": "Girona",
	"nm": "Palamós"
}, {
	"id": "Girona",
	"nm": "Palau de Santa Eulàlia"
}, {
	"id": "Girona",
	"nm": "Palau-sator"
}, {
	"id": "Girona",
	"nm": "Palau-saverdera"
}, {
	"id": "Girona",
	"nm": "Palol de Revardit"
}, {
	"id": "Girona",
	"nm": "Pals"
}, {
	"id": "Girona",
	"nm": "Pardines"
}, {
	"id": "Girona",
	"nm": "Parlavà"
}, {
	"id": "Girona",
	"nm": "Pau"
}, {
	"id": "Girona",
	"nm": "Pedret i Marzà"
}, {
	"id": "Girona",
	"nm": "Pera, La"
}, {
	"id": "Girona",
	"nm": "Peralada"
}, {
	"id": "Girona",
	"nm": "Planes d'Hostoles, Les"
}, {
	"id": "Girona",
	"nm": "Planoles"
}, {
	"id": "Girona",
	"nm": "Pont de Molins"
}, {
	"id": "Girona",
	"nm": "Pontós"
}, {
	"id": "Girona",
	"nm": "Porqueres"
}, {
	"id": "Girona",
	"nm": "Port de la Selva, El"
}, {
	"id": "Girona",
	"nm": "Portbou"
}, {
	"id": "Girona",
	"nm": "Preses, Les"
}, {
	"id": "Girona",
	"nm": "Puigcerdà"
}, {
	"id": "Girona",
	"nm": "Quart"
}, {
	"id": "Girona",
	"nm": "Queralbs"
}, {
	"id": "Girona",
	"nm": "Rabós"
}, {
	"id": "Girona",
	"nm": "Regencós"
}, {
	"id": "Girona",
	"nm": "Ribes de Freser"
}, {
	"id": "Girona",
	"nm": "Riells i Viabrea"
}, {
	"id": "Girona",
	"nm": "Ripoll"
}, {
	"id": "Girona",
	"nm": "Riudarenes"
}, {
	"id": "Girona",
	"nm": "Riudaura"
}, {
	"id": "Girona",
	"nm": "Riudellots de la Selva"
}, {
	"id": "Girona",
	"nm": "Riumors"
}, {
	"id": "Girona",
	"nm": "Roses"
}, {
	"id": "Girona",
	"nm": "Rupià"
}, {
	"id": "Girona",
	"nm": "Sales de Llierca"
}, {
	"id": "Girona",
	"nm": "Salt"
}, {
	"id": "Girona",
	"nm": "Sant Andreu Salou"
}, {
	"id": "Girona",
	"nm": "Sant Aniol de Finestres"
}, {
	"id": "Girona",
	"nm": "Sant Climent Sescebes"
}, {
	"id": "Girona",
	"nm": "Sant Feliu de Buixalleu"
}, {
	"id": "Girona",
	"nm": "Sant Feliu de Guíxols"
}, {
	"id": "Girona",
	"nm": "Sant Feliu de Pallerols"
}, {
	"id": "Girona",
	"nm": "Sant Ferriol"
}, {
	"id": "Girona",
	"nm": "Sant Gregori"
}, {
	"id": "Girona",
	"nm": "Sant Hilari Sacalm"
}, {
	"id": "Girona",
	"nm": "Sant Jaume de Llierca"
}, {
	"id": "Girona",
	"nm": "Sant Joan de les Abadesses"
}, {
	"id": "Girona",
	"nm": "Sant Joan de Mollet"
}, {
	"id": "Girona",
	"nm": "Sant Joan les Fonts"
}, {
	"id": "Girona",
	"nm": "Sant Jordi Desvalls"
}, {
	"id": "Girona",
	"nm": "Sant Julià de Ramis"
}, {
	"id": "Girona",
	"nm": "Sant Julià del Llor i Bonmatí"
}, {
	"id": "Girona",
	"nm": "Sant Llorenç de la Muga"
}, {
	"id": "Girona",
	"nm": "Sant Martí de Llémena"
}, {
	"id": "Girona",
	"nm": "Sant Martí Vell"
}, {
	"id": "Girona",
	"nm": "Sant Miquel de Campmajor"
}, {
	"id": "Girona",
	"nm": "Sant Miquel de Fluvià"
}, {
	"id": "Girona",
	"nm": "Sant Mori"
}, {
	"id": "Girona",
	"nm": "Sant Pau de Segúries"
}, {
	"id": "Girona",
	"nm": "Sant Pere Pescador"
}, {
	"id": "Girona",
	"nm": "Santa Coloma de Farners"
}, {
	"id": "Girona",
	"nm": "Santa Cristina d'Aro"
}, {
	"id": "Girona",
	"nm": "Santa Llogaia d'Àlguema"
}, {
	"id": "Girona",
	"nm": "Santa Pau"
}, {
	"id": "Girona",
	"nm": "Sarrià de Ter"
}, {
	"id": "Girona",
	"nm": "Saus, Camallera i Llampaies"
}, {
	"id": "Girona",
	"nm": "Selva de Mar, La"
}, {
	"id": "Girona",
	"nm": "Serinyà"
}, {
	"id": "Girona",
	"nm": "Serra de Daró"
}, {
	"id": "Girona",
	"nm": "Setcases"
}, {
	"id": "Girona",
	"nm": "Sils"
}, {
	"id": "Girona",
	"nm": "Siurana"
}, {
	"id": "Girona",
	"nm": "Susqueda"
}, {
	"id": "Girona",
	"nm": "Tallada d'Empordà, La"
}, {
	"id": "Girona",
	"nm": "Terrades"
}, {
	"id": "Girona",
	"nm": "Torrent"
}, {
	"id": "Girona",
	"nm": "Torroella de Fluvià"
}, {
	"id": "Girona",
	"nm": "Torroella de Montgrí"
}, {
	"id": "Girona",
	"nm": "Tortellà"
}, {
	"id": "Girona",
	"nm": "Toses"
}, {
	"id": "Girona",
	"nm": "Tossa de Mar"
}, {
	"id": "Girona",
	"nm": "Ullà"
}, {
	"id": "Girona",
	"nm": "Ullastret"
}, {
	"id": "Girona",
	"nm": "Ultramort"
}, {
	"id": "Girona",
	"nm": "Urús"
}, {
	"id": "Girona",
	"nm": "Vajol, La"
}, {
	"id": "Girona",
	"nm": "Vall de Bianya, La"
}, {
	"id": "Girona",
	"nm": "Vall d'en Bas, La"
}, {
	"id": "Girona",
	"nm": "Vallfogona de Ripollès"
}, {
	"id": "Girona",
	"nm": "Vall-llobrega"
}, {
	"id": "Girona",
	"nm": "Ventalló"
}, {
	"id": "Girona",
	"nm": "Verges"
}, {
	"id": "Girona",
	"nm": "Vidrà"
}, {
	"id": "Girona",
	"nm": "Vidreres"
}, {
	"id": "Girona",
	"nm": "Vilabertran"
}, {
	"id": "Girona",
	"nm": "Vilablareix"
}, {
	"id": "Girona",
	"nm": "Viladamat"
}, {
	"id": "Girona",
	"nm": "Viladasens"
}, {
	"id": "Girona",
	"nm": "Vilademuls"
}, {
	"id": "Girona",
	"nm": "Viladrau"
}, {
	"id": "Girona",
	"nm": "Vilafant"
}, {
	"id": "Girona",
	"nm": "Vilajuïga"
}, {
	"id": "Girona",
	"nm": "Vilallonga de Ter"
}, {
	"id": "Girona",
	"nm": "Vilamacolum"
}, {
	"id": "Girona",
	"nm": "Vilamalla"
}, {
	"id": "Girona",
	"nm": "Vilamaniscle"
}, {
	"id": "Girona",
	"nm": "Vilanant"
}, {
	"id": "Girona",
	"nm": "Vila-sacra"
}, {
	"id": "Girona",
	"nm": "Vilaür"
}, {
	"id": "Girona",
	"nm": "Vilobí d'Onyar"
}, {
	"id": "Girona",
	"nm": "Vilopriu"
}, {
	"id": "Granada",
	"nm": "Agrón"
}, {
	"id": "Granada",
	"nm": "Alamedilla"
}, {
	"id": "Granada",
	"nm": "Albolote"
}, {
	"id": "Granada",
	"nm": "Albondón"
}, {
	"id": "Granada",
	"nm": "Albuñán"
}, {
	"id": "Granada",
	"nm": "Albuñol"
}, {
	"id": "Granada",
	"nm": "Albuñuelas"
}, {
	"id": "Granada",
	"nm": "Aldeire"
}, {
	"id": "Granada",
	"nm": "Alfacar"
}, {
	"id": "Granada",
	"nm": "Algarinejo"
}, {
	"id": "Granada",
	"nm": "Alhama de Granada"
}, {
	"id": "Granada",
	"nm": "Alhendín"
}, {
	"id": "Granada",
	"nm": "Alicún de Ortega"
}, {
	"id": "Granada",
	"nm": "Almegíjar"
}, {
	"id": "Granada",
	"nm": "Almuñécar"
}, {
	"id": "Granada",
	"nm": "Alpujarra de la Sierra"
}, {
	"id": "Granada",
	"nm": "Alquife"
}, {
	"id": "Granada",
	"nm": "Arenas del Rey"
}, {
	"id": "Granada",
	"nm": "Armilla"
}, {
	"id": "Granada",
	"nm": "Atarfe"
}, {
	"id": "Granada",
	"nm": "Baza"
}, {
	"id": "Granada",
	"nm": "Beas de Granada"
}, {
	"id": "Granada",
	"nm": "Beas de Guadix"
}, {
	"id": "Granada",
	"nm": "Benalúa"
}, {
	"id": "Granada",
	"nm": "Benalúa de las Villas"
}, {
	"id": "Granada",
	"nm": "Benamaurel"
}, {
	"id": "Granada",
	"nm": "Bérchules"
}, {
	"id": "Granada",
	"nm": "Bubión"
}, {
	"id": "Granada",
	"nm": "Busquístar"
}, {
	"id": "Granada",
	"nm": "Cacín"
}, {
	"id": "Granada",
	"nm": "Cádiar"
}, {
	"id": "Granada",
	"nm": "Cájar"
}, {
	"id": "Granada",
	"nm": "Calahorra, La"
}, {
	"id": "Granada",
	"nm": "Calicasas"
}, {
	"id": "Granada",
	"nm": "Campotéjar"
}, {
	"id": "Granada",
	"nm": "Caniles"
}, {
	"id": "Granada",
	"nm": "Cáñar"
}, {
	"id": "Granada",
	"nm": "Capileira"
}, {
	"id": "Granada",
	"nm": "Carataunas"
}, {
	"id": "Granada",
	"nm": "Cástaras"
}, {
	"id": "Granada",
	"nm": "Castilléjar"
}, {
	"id": "Granada",
	"nm": "Castril"
}, {
	"id": "Granada",
	"nm": "Cenes de la Vega"
}, {
	"id": "Granada",
	"nm": "Chauchina"
}, {
	"id": "Granada",
	"nm": "Chimeneas"
}, {
	"id": "Granada",
	"nm": "Churriana de la Vega"
}, {
	"id": "Granada",
	"nm": "Cijuela"
}, {
	"id": "Granada",
	"nm": "Cogollos de Guadix"
}, {
	"id": "Granada",
	"nm": "Cogollos de la Vega"
}, {
	"id": "Granada",
	"nm": "Colomera"
}, {
	"id": "Granada",
	"nm": "Cortes de Baza"
}, {
	"id": "Granada",
	"nm": "Cortes y Graena"
}, {
	"id": "Granada",
	"nm": "Cuevas del Campo"
}, {
	"id": "Granada",
	"nm": "Cúllar"
}, {
	"id": "Granada",
	"nm": "Cúllar Vega"
}, {
	"id": "Granada",
	"nm": "Darro"
}, {
	"id": "Granada",
	"nm": "Dehesas de Guadix"
}, {
	"id": "Granada",
	"nm": "Dehesas Viejas"
}, {
	"id": "Granada",
	"nm": "Deifontes"
}, {
	"id": "Granada",
	"nm": "Diezma"
}, {
	"id": "Granada",
	"nm": "Dílar"
}, {
	"id": "Granada",
	"nm": "Dólar"
}, {
	"id": "Granada",
	"nm": "Domingo Pérez de Granada"
}, {
	"id": "Granada",
	"nm": "Dúdar"
}, {
	"id": "Granada",
	"nm": "Dúrcal"
}, {
	"id": "Granada",
	"nm": "Escúzar"
}, {
	"id": "Granada",
	"nm": "Ferreira"
}, {
	"id": "Granada",
	"nm": "Fonelas"
}, {
	"id": "Granada",
	"nm": "Freila"
}, {
	"id": "Granada",
	"nm": "Fuente Vaqueros"
}, {
	"id": "Granada",
	"nm": "Gabias, Las"
}, {
	"id": "Granada",
	"nm": "Galera"
}, {
	"id": "Granada",
	"nm": "Gobernador"
}, {
	"id": "Granada",
	"nm": "Gójar"
}, {
	"id": "Granada",
	"nm": "Gor"
}, {
	"id": "Granada",
	"nm": "Gorafe"
}, {
	"id": "Granada",
	"nm": "Granada"
}, {
	"id": "Granada",
	"nm": "Guadahortuna"
}, {
	"id": "Granada",
	"nm": "Guadix"
}, {
	"id": "Granada",
	"nm": "Guájares, Los"
}, {
	"id": "Granada",
	"nm": "Gualchos"
}, {
	"id": "Granada",
	"nm": "Güéjar Sierra"
}, {
	"id": "Granada",
	"nm": "Güevéjar"
}, {
	"id": "Granada",
	"nm": "Huélago"
}, {
	"id": "Granada",
	"nm": "Huéneja"
}, {
	"id": "Granada",
	"nm": "Huéscar"
}, {
	"id": "Granada",
	"nm": "Huétor de Santillán"
}, {
	"id": "Granada",
	"nm": "Huétor Tájar"
}, {
	"id": "Granada",
	"nm": "Huétor Vega"
}, {
	"id": "Granada",
	"nm": "Illora"
}, {
	"id": "Granada",
	"nm": "Ítrabo"
}, {
	"id": "Granada",
	"nm": "Iznalloz"
}, {
	"id": "Granada",
	"nm": "Játar"
}, {
	"id": "Granada",
	"nm": "Jayena"
}, {
	"id": "Granada",
	"nm": "Jerez del Marquesado"
}, {
	"id": "Granada",
	"nm": "Jete"
}, {
	"id": "Granada",
	"nm": "Jun"
}, {
	"id": "Granada",
	"nm": "Juviles"
}, {
	"id": "Granada",
	"nm": "Láchar"
}, {
	"id": "Granada",
	"nm": "Lanjarón"
}, {
	"id": "Granada",
	"nm": "Lanteira"
}, {
	"id": "Granada",
	"nm": "Lecrín"
}, {
	"id": "Granada",
	"nm": "Lentegí"
}, {
	"id": "Granada",
	"nm": "Lobras"
}, {
	"id": "Granada",
	"nm": "Loja"
}, {
	"id": "Granada",
	"nm": "Lugros"
}, {
	"id": "Granada",
	"nm": "Lújar"
}, {
	"id": "Granada",
	"nm": "Malahá, La"
}, {
	"id": "Granada",
	"nm": "Maracena"
}, {
	"id": "Granada",
	"nm": "Marchal"
}, {
	"id": "Granada",
	"nm": "Moclín"
}, {
	"id": "Granada",
	"nm": "Molvízar"
}, {
	"id": "Granada",
	"nm": "Monachil"
}, {
	"id": "Granada",
	"nm": "Montefrío"
}, {
	"id": "Granada",
	"nm": "Montejícar"
}, {
	"id": "Granada",
	"nm": "Montillana"
}, {
	"id": "Granada",
	"nm": "Moraleda de Zafayona"
}, {
	"id": "Granada",
	"nm": "Morelábor"
}, {
	"id": "Granada",
	"nm": "Motril"
}, {
	"id": "Granada",
	"nm": "Murtas"
}, {
	"id": "Granada",
	"nm": "Nevada"
}, {
	"id": "Granada",
	"nm": "Nigüelas"
}, {
	"id": "Granada",
	"nm": "Nívar"
}, {
	"id": "Granada",
	"nm": "Ogíjares"
}, {
	"id": "Granada",
	"nm": "Orce"
}, {
	"id": "Granada",
	"nm": "Órgiva"
}, {
	"id": "Granada",
	"nm": "Otívar"
}, {
	"id": "Granada",
	"nm": "Padul"
}, {
	"id": "Granada",
	"nm": "Pampaneira"
}, {
	"id": "Granada",
	"nm": "Pedro Martínez"
}, {
	"id": "Granada",
	"nm": "Peligros"
}, {
	"id": "Granada",
	"nm": "Peza, La"
}, {
	"id": "Granada",
	"nm": "Pinar, El"
}, {
	"id": "Granada",
	"nm": "Pinos Genil"
}, {
	"id": "Granada",
	"nm": "Pinos Puente"
}, {
	"id": "Granada",
	"nm": "Píñar"
}, {
	"id": "Granada",
	"nm": "Polícar"
}, {
	"id": "Granada",
	"nm": "Polopos"
}, {
	"id": "Granada",
	"nm": "Pórtugos"
}, {
	"id": "Granada",
	"nm": "Puebla de Don Fadrique"
}, {
	"id": "Granada",
	"nm": "Pulianas"
}, {
	"id": "Granada",
	"nm": "Purullena"
}, {
	"id": "Granada",
	"nm": "Quéntar"
}, {
	"id": "Granada",
	"nm": "Rubite"
}, {
	"id": "Granada",
	"nm": "Salar"
}, {
	"id": "Granada",
	"nm": "Salobreña"
}, {
	"id": "Granada",
	"nm": "Santa Cruz del Comercio"
}, {
	"id": "Granada",
	"nm": "Santa Fe"
}, {
	"id": "Granada",
	"nm": "Soportújar"
}, {
	"id": "Granada",
	"nm": "Sorvilán"
}, {
	"id": "Granada",
	"nm": "Taha, La"
}, {
	"id": "Granada",
	"nm": "Torre-Cardela"
}, {
	"id": "Granada",
	"nm": "Torvizcón"
}, {
	"id": "Granada",
	"nm": "Trevélez"
}, {
	"id": "Granada",
	"nm": "Turón"
}, {
	"id": "Granada",
	"nm": "Ugíjar"
}, {
	"id": "Granada",
	"nm": "Valderrubio"
}, {
	"id": "Granada",
	"nm": "Valle del Zalabí"
}, {
	"id": "Granada",
	"nm": "Valle, El"
}, {
	"id": "Granada",
	"nm": "Válor"
}, {
	"id": "Granada",
	"nm": "Vegas del Genil"
}, {
	"id": "Granada",
	"nm": "Vélez de Benaudalla"
}, {
	"id": "Granada",
	"nm": "Ventas de Huelma"
}, {
	"id": "Granada",
	"nm": "Villa de Otura"
}, {
	"id": "Granada",
	"nm": "Villamena"
}, {
	"id": "Granada",
	"nm": "Villanueva de las Torres"
}, {
	"id": "Granada",
	"nm": "Villanueva Mesía"
}, {
	"id": "Granada",
	"nm": "Víznar"
}, {
	"id": "Granada",
	"nm": "Zafarraya"
}, {
	"id": "Granada",
	"nm": "Zagra"
}, {
	"id": "Granada",
	"nm": "Zubia, La"
}, {
	"id": "Granada",
	"nm": "Zújar"
}, {
	"id": "Guadalajara",
	"nm": "Abánades"
}, {
	"id": "Guadalajara",
	"nm": "Ablanque"
}, {
	"id": "Guadalajara",
	"nm": "Adobes"
}, {
	"id": "Guadalajara",
	"nm": "Alaminos"
}, {
	"id": "Guadalajara",
	"nm": "Alarilla"
}, {
	"id": "Guadalajara",
	"nm": "Albalate de Zorita"
}, {
	"id": "Guadalajara",
	"nm": "Albares"
}, {
	"id": "Guadalajara",
	"nm": "Albendiego"
}, {
	"id": "Guadalajara",
	"nm": "Alcocer"
}, {
	"id": "Guadalajara",
	"nm": "Alcolea de las Peñas"
}, {
	"id": "Guadalajara",
	"nm": "Alcolea del Pinar"
}, {
	"id": "Guadalajara",
	"nm": "Alcoroches"
}, {
	"id": "Guadalajara",
	"nm": "Aldeanueva de Guadalajara"
}, {
	"id": "Guadalajara",
	"nm": "Algar de Mesa"
}, {
	"id": "Guadalajara",
	"nm": "Algora"
}, {
	"id": "Guadalajara",
	"nm": "Alhóndiga"
}, {
	"id": "Guadalajara",
	"nm": "Alique"
}, {
	"id": "Guadalajara",
	"nm": "Almadrones"
}, {
	"id": "Guadalajara",
	"nm": "Almoguera"
}, {
	"id": "Guadalajara",
	"nm": "Almonacid de Zorita"
}, {
	"id": "Guadalajara",
	"nm": "Alocén"
}, {
	"id": "Guadalajara",
	"nm": "Alovera"
}, {
	"id": "Guadalajara",
	"nm": "Alustante"
}, {
	"id": "Guadalajara",
	"nm": "Angón"
}, {
	"id": "Guadalajara",
	"nm": "Anguita"
}, {
	"id": "Guadalajara",
	"nm": "Anquela del Ducado"
}, {
	"id": "Guadalajara",
	"nm": "Anquela del Pedregal"
}, {
	"id": "Guadalajara",
	"nm": "Aranzueque"
}, {
	"id": "Guadalajara",
	"nm": "Arbancón"
}, {
	"id": "Guadalajara",
	"nm": "Arbeteta"
}, {
	"id": "Guadalajara",
	"nm": "Argecilla"
}, {
	"id": "Guadalajara",
	"nm": "Armallones"
}, {
	"id": "Guadalajara",
	"nm": "Armuña de Tajuña"
}, {
	"id": "Guadalajara",
	"nm": "Arroyo de las Fraguas"
}, {
	"id": "Guadalajara",
	"nm": "Atanzón"
}, {
	"id": "Guadalajara",
	"nm": "Atienza"
}, {
	"id": "Guadalajara",
	"nm": "Auñón"
}, {
	"id": "Guadalajara",
	"nm": "Azuqueca de Henares"
}, {
	"id": "Guadalajara",
	"nm": "Baides"
}, {
	"id": "Guadalajara",
	"nm": "Baños de Tajo"
}, {
	"id": "Guadalajara",
	"nm": "Bañuelos"
}, {
	"id": "Guadalajara",
	"nm": "Barriopedro"
}, {
	"id": "Guadalajara",
	"nm": "Berninches"
}, {
	"id": "Guadalajara",
	"nm": "Bodera, La"
}, {
	"id": "Guadalajara",
	"nm": "Brihuega"
}, {
	"id": "Guadalajara",
	"nm": "Budia"
}, {
	"id": "Guadalajara",
	"nm": "Bujalaro"
}, {
	"id": "Guadalajara",
	"nm": "Bustares"
}, {
	"id": "Guadalajara",
	"nm": "Cabanillas del Campo"
}, {
	"id": "Guadalajara",
	"nm": "Campillo de Dueñas"
}, {
	"id": "Guadalajara",
	"nm": "Campillo de Ranas"
}, {
	"id": "Guadalajara",
	"nm": "Campisábalos"
}, {
	"id": "Guadalajara",
	"nm": "Canredondo"
}, {
	"id": "Guadalajara",
	"nm": "Cantalojas"
}, {
	"id": "Guadalajara",
	"nm": "Cañizar"
}, {
	"id": "Guadalajara",
	"nm": "Cardoso de la Sierra, El"
}, {
	"id": "Guadalajara",
	"nm": "Casa de Uceda"
}, {
	"id": "Guadalajara",
	"nm": "Casar, El"
}, {
	"id": "Guadalajara",
	"nm": "Casas de San Galindo"
}, {
	"id": "Guadalajara",
	"nm": "Caspueñas"
}, {
	"id": "Guadalajara",
	"nm": "Castejón de Henares"
}, {
	"id": "Guadalajara",
	"nm": "Castellar de la Muela"
}, {
	"id": "Guadalajara",
	"nm": "Castilforte"
}, {
	"id": "Guadalajara",
	"nm": "Castilnuevo"
}, {
	"id": "Guadalajara",
	"nm": "Cendejas de Enmedio"
}, {
	"id": "Guadalajara",
	"nm": "Cendejas de la Torre"
}, {
	"id": "Guadalajara",
	"nm": "Centenera"
}, {
	"id": "Guadalajara",
	"nm": "Checa"
}, {
	"id": "Guadalajara",
	"nm": "Chequilla"
}, {
	"id": "Guadalajara",
	"nm": "Chillarón del Rey"
}, {
	"id": "Guadalajara",
	"nm": "Chiloeches"
}, {
	"id": "Guadalajara",
	"nm": "Cifuentes"
}, {
	"id": "Guadalajara",
	"nm": "Cincovillas"
}, {
	"id": "Guadalajara",
	"nm": "Ciruelas"
}, {
	"id": "Guadalajara",
	"nm": "Ciruelos del Pinar"
}, {
	"id": "Guadalajara",
	"nm": "Cobeta"
}, {
	"id": "Guadalajara",
	"nm": "Cogollor"
}, {
	"id": "Guadalajara",
	"nm": "Cogolludo"
}, {
	"id": "Guadalajara",
	"nm": "Condemios de Abajo"
}, {
	"id": "Guadalajara",
	"nm": "Condemios de Arriba"
}, {
	"id": "Guadalajara",
	"nm": "Congostrina"
}, {
	"id": "Guadalajara",
	"nm": "Copernal"
}, {
	"id": "Guadalajara",
	"nm": "Corduente"
}, {
	"id": "Guadalajara",
	"nm": "Cubillo de Uceda, El"
}, {
	"id": "Guadalajara",
	"nm": "Driebes"
}, {
	"id": "Guadalajara",
	"nm": "Durón"
}, {
	"id": "Guadalajara",
	"nm": "Embid"
}, {
	"id": "Guadalajara",
	"nm": "Escamilla"
}, {
	"id": "Guadalajara",
	"nm": "Escariche"
}, {
	"id": "Guadalajara",
	"nm": "Escopete"
}, {
	"id": "Guadalajara",
	"nm": "Espinosa de Henares"
}, {
	"id": "Guadalajara",
	"nm": "Esplegares"
}, {
	"id": "Guadalajara",
	"nm": "Establés"
}, {
	"id": "Guadalajara",
	"nm": "Estriégana"
}, {
	"id": "Guadalajara",
	"nm": "Fontanar"
}, {
	"id": "Guadalajara",
	"nm": "Fuembellida"
}, {
	"id": "Guadalajara",
	"nm": "Fuencemillán"
}, {
	"id": "Guadalajara",
	"nm": "Fuentelahiguera de Albatages"
}, {
	"id": "Guadalajara",
	"nm": "Fuentelencina"
}, {
	"id": "Guadalajara",
	"nm": "Fuentelsaz"
}, {
	"id": "Guadalajara",
	"nm": "Fuentelviejo"
}, {
	"id": "Guadalajara",
	"nm": "Fuentenovilla"
}, {
	"id": "Guadalajara",
	"nm": "Gajanejos"
}, {
	"id": "Guadalajara",
	"nm": "Galápagos"
}, {
	"id": "Guadalajara",
	"nm": "Galve de Sorbe"
}, {
	"id": "Guadalajara",
	"nm": "Gascueña de Bornova"
}, {
	"id": "Guadalajara",
	"nm": "Guadalajara"
}, {
	"id": "Guadalajara",
	"nm": "Henche"
}, {
	"id": "Guadalajara",
	"nm": "Heras de Ayuso"
}, {
	"id": "Guadalajara",
	"nm": "Herrería"
}, {
	"id": "Guadalajara",
	"nm": "Hiendelaencina"
}, {
	"id": "Guadalajara",
	"nm": "Hijes"
}, {
	"id": "Guadalajara",
	"nm": "Hita"
}, {
	"id": "Guadalajara",
	"nm": "Hombrados"
}, {
	"id": "Guadalajara",
	"nm": "Hontoba"
}, {
	"id": "Guadalajara",
	"nm": "Horche"
}, {
	"id": "Guadalajara",
	"nm": "Hortezuela de Océn"
}, {
	"id": "Guadalajara",
	"nm": "Huerce, La"
}, {
	"id": "Guadalajara",
	"nm": "Huérmeces del Cerro"
}, {
	"id": "Guadalajara",
	"nm": "Huertahernando"
}, {
	"id": "Guadalajara",
	"nm": "Hueva"
}, {
	"id": "Guadalajara",
	"nm": "Humanes"
}, {
	"id": "Guadalajara",
	"nm": "Illana"
}, {
	"id": "Guadalajara",
	"nm": "Iniéstola"
}, {
	"id": "Guadalajara",
	"nm": "Inviernas, Las"
}, {
	"id": "Guadalajara",
	"nm": "Irueste"
}, {
	"id": "Guadalajara",
	"nm": "Jadraque"
}, {
	"id": "Guadalajara",
	"nm": "Jirueque"
}, {
	"id": "Guadalajara",
	"nm": "Ledanca"
}, {
	"id": "Guadalajara",
	"nm": "Loranca de Tajuña"
}, {
	"id": "Guadalajara",
	"nm": "Lupiana"
}, {
	"id": "Guadalajara",
	"nm": "Luzaga"
}, {
	"id": "Guadalajara",
	"nm": "Luzón"
}, {
	"id": "Guadalajara",
	"nm": "Majaelrayo"
}, {
	"id": "Guadalajara",
	"nm": "Málaga del Fresno"
}, {
	"id": "Guadalajara",
	"nm": "Malaguilla"
}, {
	"id": "Guadalajara",
	"nm": "Mandayona"
}, {
	"id": "Guadalajara",
	"nm": "Mantiel"
}, {
	"id": "Guadalajara",
	"nm": "Maranchón"
}, {
	"id": "Guadalajara",
	"nm": "Marchamalo"
}, {
	"id": "Guadalajara",
	"nm": "Masegoso de Tajuña"
}, {
	"id": "Guadalajara",
	"nm": "Matarrubia"
}, {
	"id": "Guadalajara",
	"nm": "Matillas"
}, {
	"id": "Guadalajara",
	"nm": "Mazarete"
}, {
	"id": "Guadalajara",
	"nm": "Mazuecos"
}, {
	"id": "Guadalajara",
	"nm": "Medranda"
}, {
	"id": "Guadalajara",
	"nm": "Megina"
}, {
	"id": "Guadalajara",
	"nm": "Membrillera"
}, {
	"id": "Guadalajara",
	"nm": "Miedes de Atienza"
}, {
	"id": "Guadalajara",
	"nm": "Mierla, La"
}, {
	"id": "Guadalajara",
	"nm": "Millana"
}, {
	"id": "Guadalajara",
	"nm": "Milmarcos"
}, {
	"id": "Guadalajara",
	"nm": "Miñosa, La"
}, {
	"id": "Guadalajara",
	"nm": "Mirabueno"
}, {
	"id": "Guadalajara",
	"nm": "Miralrío"
}, {
	"id": "Guadalajara",
	"nm": "Mochales"
}, {
	"id": "Guadalajara",
	"nm": "Mohernando"
}, {
	"id": "Guadalajara",
	"nm": "Molina de Aragón"
}, {
	"id": "Guadalajara",
	"nm": "Monasterio"
}, {
	"id": "Guadalajara",
	"nm": "Mondéjar"
}, {
	"id": "Guadalajara",
	"nm": "Montarrón"
}, {
	"id": "Guadalajara",
	"nm": "Moratilla de los Meleros"
}, {
	"id": "Guadalajara",
	"nm": "Morenilla"
}, {
	"id": "Guadalajara",
	"nm": "Muduex"
}, {
	"id": "Guadalajara",
	"nm": "Navas de Jadraque, Las"
}, {
	"id": "Guadalajara",
	"nm": "Negredo"
}, {
	"id": "Guadalajara",
	"nm": "Ocentejo"
}, {
	"id": "Guadalajara",
	"nm": "Olivar, El"
}, {
	"id": "Guadalajara",
	"nm": "Olmeda de Cobeta"
}, {
	"id": "Guadalajara",
	"nm": "Olmeda de Jadraque, La"
}, {
	"id": "Guadalajara",
	"nm": "Ordial, El"
}, {
	"id": "Guadalajara",
	"nm": "Orea"
}, {
	"id": "Guadalajara",
	"nm": "Pálmaces de Jadraque"
}, {
	"id": "Guadalajara",
	"nm": "Pardos"
}, {
	"id": "Guadalajara",
	"nm": "Paredes de Sigüenza"
}, {
	"id": "Guadalajara",
	"nm": "Pareja"
}, {
	"id": "Guadalajara",
	"nm": "Pastrana"
}, {
	"id": "Guadalajara",
	"nm": "Pedregal, El"
}, {
	"id": "Guadalajara",
	"nm": "Peñalén"
}, {
	"id": "Guadalajara",
	"nm": "Peñalver"
}, {
	"id": "Guadalajara",
	"nm": "Peralejos de las Truchas"
}, {
	"id": "Guadalajara",
	"nm": "Peralveche"
}, {
	"id": "Guadalajara",
	"nm": "Pinilla de Jadraque"
}, {
	"id": "Guadalajara",
	"nm": "Pinilla de Molina"
}, {
	"id": "Guadalajara",
	"nm": "Pioz"
}, {
	"id": "Guadalajara",
	"nm": "Piqueras"
}, {
	"id": "Guadalajara",
	"nm": "Pobo de Dueñas, El"
}, {
	"id": "Guadalajara",
	"nm": "Poveda de la Sierra"
}, {
	"id": "Guadalajara",
	"nm": "Pozo de Almoguera"
}, {
	"id": "Guadalajara",
	"nm": "Pozo de Guadalajara"
}, {
	"id": "Guadalajara",
	"nm": "Prádena de Atienza"
}, {
	"id": "Guadalajara",
	"nm": "Prados Redondos"
}, {
	"id": "Guadalajara",
	"nm": "Puebla de Beleña"
}, {
	"id": "Guadalajara",
	"nm": "Puebla de Valles"
}, {
	"id": "Guadalajara",
	"nm": "Quer"
}, {
	"id": "Guadalajara",
	"nm": "Rebollosa de Jadraque"
}, {
	"id": "Guadalajara",
	"nm": "Recuenco, El"
}, {
	"id": "Guadalajara",
	"nm": "Renera"
}, {
	"id": "Guadalajara",
	"nm": "Retiendas"
}, {
	"id": "Guadalajara",
	"nm": "Riba de Saelices"
}, {
	"id": "Guadalajara",
	"nm": "Rillo de Gallo"
}, {
	"id": "Guadalajara",
	"nm": "Riofrío del Llano"
}, {
	"id": "Guadalajara",
	"nm": "Robledillo de Mohernando"
}, {
	"id": "Guadalajara",
	"nm": "Robledo de Corpes"
}, {
	"id": "Guadalajara",
	"nm": "Romanillos de Atienza"
}, {
	"id": "Guadalajara",
	"nm": "Romanones"
}, {
	"id": "Guadalajara",
	"nm": "Rueda de la Sierra"
}, {
	"id": "Guadalajara",
	"nm": "Sacecorbo"
}, {
	"id": "Guadalajara",
	"nm": "Sacedón"
}, {
	"id": "Guadalajara",
	"nm": "Saelices de la Sal"
}, {
	"id": "Guadalajara",
	"nm": "Salmerón"
}, {
	"id": "Guadalajara",
	"nm": "San Andrés del Congosto"
}, {
	"id": "Guadalajara",
	"nm": "San Andrés del Rey"
}, {
	"id": "Guadalajara",
	"nm": "Santiuste"
}, {
	"id": "Guadalajara",
	"nm": "Saúca"
}, {
	"id": "Guadalajara",
	"nm": "Sayatón"
}, {
	"id": "Guadalajara",
	"nm": "Selas"
}, {
	"id": "Guadalajara",
	"nm": "Semillas"
}, {
	"id": "Guadalajara",
	"nm": "Setiles"
}, {
	"id": "Guadalajara",
	"nm": "Sienes"
}, {
	"id": "Guadalajara",
	"nm": "Sigüenza"
}, {
	"id": "Guadalajara",
	"nm": "Solanillos del Extremo"
}, {
	"id": "Guadalajara",
	"nm": "Somolinos"
}, {
	"id": "Guadalajara",
	"nm": "Sotillo, El"
}, {
	"id": "Guadalajara",
	"nm": "Sotodosos"
}, {
	"id": "Guadalajara",
	"nm": "Tamajón"
}, {
	"id": "Guadalajara",
	"nm": "Taragudo"
}, {
	"id": "Guadalajara",
	"nm": "Taravilla"
}, {
	"id": "Guadalajara",
	"nm": "Tartanedo"
}, {
	"id": "Guadalajara",
	"nm": "Tendilla"
}, {
	"id": "Guadalajara",
	"nm": "Terzaga"
}, {
	"id": "Guadalajara",
	"nm": "Tierzo"
}, {
	"id": "Guadalajara",
	"nm": "Toba, La"
}, {
	"id": "Guadalajara",
	"nm": "Tordellego"
}, {
	"id": "Guadalajara",
	"nm": "Tordelrábano"
}, {
	"id": "Guadalajara",
	"nm": "Tordesilos"
}, {
	"id": "Guadalajara",
	"nm": "Torija"
}, {
	"id": "Guadalajara",
	"nm": "Torre del Burgo"
}, {
	"id": "Guadalajara",
	"nm": "Torrecuadrada de Molina"
}, {
	"id": "Guadalajara",
	"nm": "Torrecuadradilla"
}, {
	"id": "Guadalajara",
	"nm": "Torrejón del Rey"
}, {
	"id": "Guadalajara",
	"nm": "Torremocha de Jadraque"
}, {
	"id": "Guadalajara",
	"nm": "Torremocha del Campo"
}, {
	"id": "Guadalajara",
	"nm": "Torremocha del Pinar"
}, {
	"id": "Guadalajara",
	"nm": "Torremochuela"
}, {
	"id": "Guadalajara",
	"nm": "Torrubia"
}, {
	"id": "Guadalajara",
	"nm": "Tórtola de Henares"
}, {
	"id": "Guadalajara",
	"nm": "Tortuera"
}, {
	"id": "Guadalajara",
	"nm": "Tortuero"
}, {
	"id": "Guadalajara",
	"nm": "Traíd"
}, {
	"id": "Guadalajara",
	"nm": "Trijueque"
}, {
	"id": "Guadalajara",
	"nm": "Trillo"
}, {
	"id": "Guadalajara",
	"nm": "Uceda"
}, {
	"id": "Guadalajara",
	"nm": "Ujados"
}, {
	"id": "Guadalajara",
	"nm": "Utande"
}, {
	"id": "Guadalajara",
	"nm": "Valdarachas"
}, {
	"id": "Guadalajara",
	"nm": "Valdearenas"
}, {
	"id": "Guadalajara",
	"nm": "Valdeavellano"
}, {
	"id": "Guadalajara",
	"nm": "Valdeaveruelo"
}, {
	"id": "Guadalajara",
	"nm": "Valdeconcha"
}, {
	"id": "Guadalajara",
	"nm": "Valdegrudas"
}, {
	"id": "Guadalajara",
	"nm": "Valdelcubo"
}, {
	"id": "Guadalajara",
	"nm": "Valdenuño Fernández"
}, {
	"id": "Guadalajara",
	"nm": "Valdepeñas de la Sierra"
}, {
	"id": "Guadalajara",
	"nm": "Valderrebollo"
}, {
	"id": "Guadalajara",
	"nm": "Valdesotos"
}, {
	"id": "Guadalajara",
	"nm": "Valfermoso de Tajuña"
}, {
	"id": "Guadalajara",
	"nm": "Valhermoso"
}, {
	"id": "Guadalajara",
	"nm": "Valtablado del Río"
}, {
	"id": "Guadalajara",
	"nm": "Valverde de los Arroyos"
}, {
	"id": "Guadalajara",
	"nm": "Viana de Jadraque"
}, {
	"id": "Guadalajara",
	"nm": "Villanueva de Alcorón"
}, {
	"id": "Guadalajara",
	"nm": "Villanueva de Argecilla"
}, {
	"id": "Guadalajara",
	"nm": "Villanueva de la Torre"
}, {
	"id": "Guadalajara",
	"nm": "Villares de Jadraque"
}, {
	"id": "Guadalajara",
	"nm": "Villaseca de Henares"
}, {
	"id": "Guadalajara",
	"nm": "Villaseca de Uceda"
}, {
	"id": "Guadalajara",
	"nm": "Villel de Mesa"
}, {
	"id": "Guadalajara",
	"nm": "Viñuelas"
}, {
	"id": "Guadalajara",
	"nm": "Yebes"
}, {
	"id": "Guadalajara",
	"nm": "Yebra"
}, {
	"id": "Guadalajara",
	"nm": "Yélamos de Abajo"
}, {
	"id": "Guadalajara",
	"nm": "Yélamos de Arriba"
}, {
	"id": "Guadalajara",
	"nm": "Yunquera de Henares"
}, {
	"id": "Guadalajara",
	"nm": "Yunta, La"
}, {
	"id": "Guadalajara",
	"nm": "Zaorejas"
}, {
	"id": "Guadalajara",
	"nm": "Zarzuela de Jadraque"
}, {
	"id": "Guadalajara",
	"nm": "Zorita de los Canes"
}, {
	"id": "Gipuzkoa",
	"nm": "Abaltzisketa"
}, {
	"id": "Gipuzkoa",
	"nm": "Aduna"
}, {
	"id": "Gipuzkoa",
	"nm": "Aia"
}, {
	"id": "Gipuzkoa",
	"nm": "Aizarnazabal"
}, {
	"id": "Gipuzkoa",
	"nm": "Albiztur"
}, {
	"id": "Gipuzkoa",
	"nm": "Alegia"
}, {
	"id": "Gipuzkoa",
	"nm": "Alkiza"
}, {
	"id": "Gipuzkoa",
	"nm": "Altzaga"
}, {
	"id": "Gipuzkoa",
	"nm": "Altzo"
}, {
	"id": "Gipuzkoa",
	"nm": "Amezketa"
}, {
	"id": "Gipuzkoa",
	"nm": "Andoain"
}, {
	"id": "Gipuzkoa",
	"nm": "Anoeta"
}, {
	"id": "Gipuzkoa",
	"nm": "Antzuola"
}, {
	"id": "Gipuzkoa",
	"nm": "Arama"
}, {
	"id": "Gipuzkoa",
	"nm": "Aretxabaleta"
}, {
	"id": "Gipuzkoa",
	"nm": "Arrasate/Mondragón"
}, {
	"id": "Gipuzkoa",
	"nm": "Asteasu"
}, {
	"id": "Gipuzkoa",
	"nm": "Astigarraga"
}, {
	"id": "Gipuzkoa",
	"nm": "Ataun"
}, {
	"id": "Gipuzkoa",
	"nm": "Azkoitia"
}, {
	"id": "Gipuzkoa",
	"nm": "Azpeitia"
}, {
	"id": "Gipuzkoa",
	"nm": "Baliarrain"
}, {
	"id": "Gipuzkoa",
	"nm": "Beasain"
}, {
	"id": "Gipuzkoa",
	"nm": "Beizama"
}, {
	"id": "Gipuzkoa",
	"nm": "Belauntza"
}, {
	"id": "Gipuzkoa",
	"nm": "Berastegi"
}, {
	"id": "Gipuzkoa",
	"nm": "Bergara"
}, {
	"id": "Gipuzkoa",
	"nm": "Berrobi"
}, {
	"id": "Gipuzkoa",
	"nm": "Bidania-Goiatz"
}, {
	"id": "Gipuzkoa",
	"nm": "Deba"
}, {
	"id": "Gipuzkoa",
	"nm": "Donostia/San Sebastián"
}, {
	"id": "Gipuzkoa",
	"nm": "Eibar"
}, {
	"id": "Gipuzkoa",
	"nm": "Elduain"
}, {
	"id": "Gipuzkoa",
	"nm": "Elgeta"
}, {
	"id": "Gipuzkoa",
	"nm": "Elgoibar"
}, {
	"id": "Gipuzkoa",
	"nm": "Errenteria"
}, {
	"id": "Gipuzkoa",
	"nm": "Errezil"
}, {
	"id": "Gipuzkoa",
	"nm": "Eskoriatza"
}, {
	"id": "Gipuzkoa",
	"nm": "Ezkio-Itsaso"
}, {
	"id": "Gipuzkoa",
	"nm": "Gabiria"
}, {
	"id": "Gipuzkoa",
	"nm": "Gaintza"
}, {
	"id": "Gipuzkoa",
	"nm": "Gaztelu"
}, {
	"id": "Gipuzkoa",
	"nm": "Getaria"
}, {
	"id": "Gipuzkoa",
	"nm": "Hernani"
}, {
	"id": "Gipuzkoa",
	"nm": "Hernialde"
}, {
	"id": "Gipuzkoa",
	"nm": "Hondarribia"
}, {
	"id": "Gipuzkoa",
	"nm": "Ibarra"
}, {
	"id": "Gipuzkoa",
	"nm": "Idiazabal"
}, {
	"id": "Gipuzkoa",
	"nm": "Ikaztegieta"
}, {
	"id": "Gipuzkoa",
	"nm": "Irun"
}, {
	"id": "Gipuzkoa",
	"nm": "Irura"
}, {
	"id": "Gipuzkoa",
	"nm": "Itsasondo"
}, {
	"id": "Gipuzkoa",
	"nm": "Larraul"
}, {
	"id": "Gipuzkoa",
	"nm": "Lasarte-Oria"
}, {
	"id": "Gipuzkoa",
	"nm": "Lazkao"
}, {
	"id": "Gipuzkoa",
	"nm": "Leaburu"
}, {
	"id": "Gipuzkoa",
	"nm": "Legazpi"
}, {
	"id": "Gipuzkoa",
	"nm": "Legorreta"
}, {
	"id": "Gipuzkoa",
	"nm": "Leintz-Gatzaga"
}, {
	"id": "Gipuzkoa",
	"nm": "Lezo"
}, {
	"id": "Gipuzkoa",
	"nm": "Lizartza"
}, {
	"id": "Gipuzkoa",
	"nm": "Mendaro"
}, {
	"id": "Gipuzkoa",
	"nm": "Mutiloa"
}, {
	"id": "Gipuzkoa",
	"nm": "Mutriku"
}, {
	"id": "Gipuzkoa",
	"nm": "Oiartzun"
}, {
	"id": "Gipuzkoa",
	"nm": "Olaberria"
}, {
	"id": "Gipuzkoa",
	"nm": "Oñati"
}, {
	"id": "Gipuzkoa",
	"nm": "Ordizia"
}, {
	"id": "Gipuzkoa",
	"nm": "Orendain"
}, {
	"id": "Gipuzkoa",
	"nm": "Orexa"
}, {
	"id": "Gipuzkoa",
	"nm": "Orio"
}, {
	"id": "Gipuzkoa",
	"nm": "Ormaiztegi"
}, {
	"id": "Gipuzkoa",
	"nm": "Pasaia"
}, {
	"id": "Gipuzkoa",
	"nm": "Segura"
}, {
	"id": "Gipuzkoa",
	"nm": "Soraluze-Placencia de las Armas"
}, {
	"id": "Gipuzkoa",
	"nm": "Tolosa"
}, {
	"id": "Gipuzkoa",
	"nm": "Urnieta"
}, {
	"id": "Gipuzkoa",
	"nm": "Urretxu"
}, {
	"id": "Gipuzkoa",
	"nm": "Usurbil"
}, {
	"id": "Gipuzkoa",
	"nm": "Villabona"
}, {
	"id": "Gipuzkoa",
	"nm": "Zaldibia"
}, {
	"id": "Gipuzkoa",
	"nm": "Zarautz"
}, {
	"id": "Gipuzkoa",
	"nm": "Zegama"
}, {
	"id": "Gipuzkoa",
	"nm": "Zerain"
}, {
	"id": "Gipuzkoa",
	"nm": "Zestoa"
}, {
	"id": "Gipuzkoa",
	"nm": "Zizurkil"
}, {
	"id": "Gipuzkoa",
	"nm": "Zumaia"
}, {
	"id": "Gipuzkoa",
	"nm": "Zumarraga"
}, {
	"id": "Huelva",
	"nm": "Alájar"
}, {
	"id": "Huelva",
	"nm": "Aljaraque"
}, {
	"id": "Huelva",
	"nm": "Almendro, El"
}, {
	"id": "Huelva",
	"nm": "Almonaster la Real"
}, {
	"id": "Huelva",
	"nm": "Almonte"
}, {
	"id": "Huelva",
	"nm": "Alosno"
}, {
	"id": "Huelva",
	"nm": "Aracena"
}, {
	"id": "Huelva",
	"nm": "Aroche"
}, {
	"id": "Huelva",
	"nm": "Arroyomolinos de León"
}, {
	"id": "Huelva",
	"nm": "Ayamonte"
}, {
	"id": "Huelva",
	"nm": "Beas"
}, {
	"id": "Huelva",
	"nm": "Berrocal"
}, {
	"id": "Huelva",
	"nm": "Bollullos Par del Condado"
}, {
	"id": "Huelva",
	"nm": "Bonares"
}, {
	"id": "Huelva",
	"nm": "Cabezas Rubias"
}, {
	"id": "Huelva",
	"nm": "Cala"
}, {
	"id": "Huelva",
	"nm": "Calañas"
}, {
	"id": "Huelva",
	"nm": "Campillo, El"
}, {
	"id": "Huelva",
	"nm": "Campofrío"
}, {
	"id": "Huelva",
	"nm": "Cañaveral de León"
}, {
	"id": "Huelva",
	"nm": "Cartaya"
}, {
	"id": "Huelva",
	"nm": "Castaño del Robledo"
}, {
	"id": "Huelva",
	"nm": "Cerro de Andévalo, El"
}, {
	"id": "Huelva",
	"nm": "Chucena"
}, {
	"id": "Huelva",
	"nm": "Corteconcepción"
}, {
	"id": "Huelva",
	"nm": "Cortegana"
}, {
	"id": "Huelva",
	"nm": "Cortelazor"
}, {
	"id": "Huelva",
	"nm": "Cumbres de Enmedio"
}, {
	"id": "Huelva",
	"nm": "Cumbres de San Bartolomé"
}, {
	"id": "Huelva",
	"nm": "Cumbres Mayores"
}, {
	"id": "Huelva",
	"nm": "Encinasola"
}, {
	"id": "Huelva",
	"nm": "Escacena del Campo"
}, {
	"id": "Huelva",
	"nm": "Fuenteheridos"
}, {
	"id": "Huelva",
	"nm": "Galaroza"
}, {
	"id": "Huelva",
	"nm": "Gibraleón"
}, {
	"id": "Huelva",
	"nm": "Granada de Río-Tinto, La"
}, {
	"id": "Huelva",
	"nm": "Granado, El"
}, {
	"id": "Huelva",
	"nm": "Higuera de la Sierra"
}, {
	"id": "Huelva",
	"nm": "Hinojales"
}, {
	"id": "Huelva",
	"nm": "Hinojos"
}, {
	"id": "Huelva",
	"nm": "Huelva"
}, {
	"id": "Huelva",
	"nm": "Isla Cristina"
}, {
	"id": "Huelva",
	"nm": "Jabugo"
}, {
	"id": "Huelva",
	"nm": "Lepe"
}, {
	"id": "Huelva",
	"nm": "Linares de la Sierra"
}, {
	"id": "Huelva",
	"nm": "Lucena del Puerto"
}, {
	"id": "Huelva",
	"nm": "Manzanilla"
}, {
	"id": "Huelva",
	"nm": "Marines, Los"
}, {
	"id": "Huelva",
	"nm": "Minas de Riotinto"
}, {
	"id": "Huelva",
	"nm": "Moguer"
}, {
	"id": "Huelva",
	"nm": "Nava, La"
}, {
	"id": "Huelva",
	"nm": "Nerva"
}, {
	"id": "Huelva",
	"nm": "Niebla"
}, {
	"id": "Huelva",
	"nm": "Palma del Condado, La"
}, {
	"id": "Huelva",
	"nm": "Palos de la Frontera"
}, {
	"id": "Huelva",
	"nm": "Paterna del Campo"
}, {
	"id": "Huelva",
	"nm": "Paymogo"
}, {
	"id": "Huelva",
	"nm": "Puebla de Guzmán"
}, {
	"id": "Huelva",
	"nm": "Puerto Moral"
}, {
	"id": "Huelva",
	"nm": "Punta Umbría"
}, {
	"id": "Huelva",
	"nm": "Rociana del Condado"
}, {
	"id": "Huelva",
	"nm": "Rosal de la Frontera"
}, {
	"id": "Huelva",
	"nm": "San Bartolomé de la Torre"
}, {
	"id": "Huelva",
	"nm": "San Juan del Puerto"
}, {
	"id": "Huelva",
	"nm": "San Silvestre de Guzmán"
}, {
	"id": "Huelva",
	"nm": "Sanlúcar de Guadiana"
}, {
	"id": "Huelva",
	"nm": "Santa Ana la Real"
}, {
	"id": "Huelva",
	"nm": "Santa Bárbara de Casa"
}, {
	"id": "Huelva",
	"nm": "Santa Olalla del Cala"
}, {
	"id": "Huelva",
	"nm": "Trigueros"
}, {
	"id": "Huelva",
	"nm": "Valdelarco"
}, {
	"id": "Huelva",
	"nm": "Valverde del Camino"
}, {
	"id": "Huelva",
	"nm": "Villablanca"
}, {
	"id": "Huelva",
	"nm": "Villalba del Alcor"
}, {
	"id": "Huelva",
	"nm": "Villanueva de las Cruces"
}, {
	"id": "Huelva",
	"nm": "Villanueva de los Castillejos"
}, {
	"id": "Huelva",
	"nm": "Villarrasa"
}, {
	"id": "Huelva",
	"nm": "Zalamea la Real"
}, {
	"id": "Huelva",
	"nm": "Zufre"
}, {
	"id": "Huesca",
	"nm": "Abiego"
}, {
	"id": "Huesca",
	"nm": "Abizanda"
}, {
	"id": "Huesca",
	"nm": "Adahuesca"
}, {
	"id": "Huesca",
	"nm": "Agüero"
}, {
	"id": "Huesca",
	"nm": "Aínsa-Sobrarbe"
}, {
	"id": "Huesca",
	"nm": "Aisa"
}, {
	"id": "Huesca",
	"nm": "Albalate de Cinca"
}, {
	"id": "Huesca",
	"nm": "Albalatillo"
}, {
	"id": "Huesca",
	"nm": "Albelda"
}, {
	"id": "Huesca",
	"nm": "Albero Alto"
}, {
	"id": "Huesca",
	"nm": "Albero Bajo"
}, {
	"id": "Huesca",
	"nm": "Alberuela de Tubo"
}, {
	"id": "Huesca",
	"nm": "Alcalá de Gurrea"
}, {
	"id": "Huesca",
	"nm": "Alcalá del Obispo"
}, {
	"id": "Huesca",
	"nm": "Alcampell"
}, {
	"id": "Huesca",
	"nm": "Alcolea de Cinca"
}, {
	"id": "Huesca",
	"nm": "Alcubierre"
}, {
	"id": "Huesca",
	"nm": "Alerre"
}, {
	"id": "Huesca",
	"nm": "Alfántega"
}, {
	"id": "Huesca",
	"nm": "Almudévar"
}, {
	"id": "Huesca",
	"nm": "Almunia de San Juan"
}, {
	"id": "Huesca",
	"nm": "Almuniente"
}, {
	"id": "Huesca",
	"nm": "Alquézar"
}, {
	"id": "Huesca",
	"nm": "Altorricón"
}, {
	"id": "Huesca",
	"nm": "Angüés"
}, {
	"id": "Huesca",
	"nm": "Ansó"
}, {
	"id": "Huesca",
	"nm": "Antillón"
}, {
	"id": "Huesca",
	"nm": "Aragüés del Puerto"
}, {
	"id": "Huesca",
	"nm": "Arén"
}, {
	"id": "Huesca",
	"nm": "Argavieso"
}, {
	"id": "Huesca",
	"nm": "Arguis"
}, {
	"id": "Huesca",
	"nm": "Ayerbe"
}, {
	"id": "Huesca",
	"nm": "Azanuy-Alins"
}, {
	"id": "Huesca",
	"nm": "Azara"
}, {
	"id": "Huesca",
	"nm": "Azlor"
}, {
	"id": "Huesca",
	"nm": "Baélls"
}, {
	"id": "Huesca",
	"nm": "Bailo"
}, {
	"id": "Huesca",
	"nm": "Baldellou"
}, {
	"id": "Huesca",
	"nm": "Ballobar"
}, {
	"id": "Huesca",
	"nm": "Banastás"
}, {
	"id": "Huesca",
	"nm": "Barbastro"
}, {
	"id": "Huesca",
	"nm": "Barbués"
}, {
	"id": "Huesca",
	"nm": "Barbuñales"
}, {
	"id": "Huesca",
	"nm": "Bárcabo"
}, {
	"id": "Huesca",
	"nm": "Belver de Cinca"
}, {
	"id": "Huesca",
	"nm": "Benabarre"
}, {
	"id": "Huesca",
	"nm": "Benasque"
}, {
	"id": "Huesca",
	"nm": "Beranuy"
}, {
	"id": "Huesca",
	"nm": "Berbegal"
}, {
	"id": "Huesca",
	"nm": "Bielsa"
}, {
	"id": "Huesca",
	"nm": "Bierge"
}, {
	"id": "Huesca",
	"nm": "Biescas"
}, {
	"id": "Huesca",
	"nm": "Binaced"
}, {
	"id": "Huesca",
	"nm": "Binéfar"
}, {
	"id": "Huesca",
	"nm": "Bisaurri"
}, {
	"id": "Huesca",
	"nm": "Biscarrués"
}, {
	"id": "Huesca",
	"nm": "Blecua y Torres"
}, {
	"id": "Huesca",
	"nm": "Boltaña"
}, {
	"id": "Huesca",
	"nm": "Bonansa"
}, {
	"id": "Huesca",
	"nm": "Borau"
}, {
	"id": "Huesca",
	"nm": "Broto"
}, {
	"id": "Huesca",
	"nm": "Caldearenas"
}, {
	"id": "Huesca",
	"nm": "Campo"
}, {
	"id": "Huesca",
	"nm": "Camporrélls"
}, {
	"id": "Huesca",
	"nm": "Canal de Berdún"
}, {
	"id": "Huesca",
	"nm": "Candasnos"
}, {
	"id": "Huesca",
	"nm": "Canfranc"
}, {
	"id": "Huesca",
	"nm": "Capdesaso"
}, {
	"id": "Huesca",
	"nm": "Capella"
}, {
	"id": "Huesca",
	"nm": "Casbas de Huesca"
}, {
	"id": "Huesca",
	"nm": "Castejón de Monegros"
}, {
	"id": "Huesca",
	"nm": "Castejón de Sos"
}, {
	"id": "Huesca",
	"nm": "Castejón del Puente"
}, {
	"id": "Huesca",
	"nm": "Castelflorite"
}, {
	"id": "Huesca",
	"nm": "Castiello de Jaca"
}, {
	"id": "Huesca",
	"nm": "Castigaleu"
}, {
	"id": "Huesca",
	"nm": "Castillazuelo"
}, {
	"id": "Huesca",
	"nm": "Castillonroy"
}, {
	"id": "Huesca",
	"nm": "Chalamera"
}, {
	"id": "Huesca",
	"nm": "Chía"
}, {
	"id": "Huesca",
	"nm": "Chimillas"
}, {
	"id": "Huesca",
	"nm": "Colungo"
}, {
	"id": "Huesca",
	"nm": "Esplús"
}, {
	"id": "Huesca",
	"nm": "Estada"
}, {
	"id": "Huesca",
	"nm": "Estadilla"
}, {
	"id": "Huesca",
	"nm": "Estopiñán del Castillo"
}, {
	"id": "Huesca",
	"nm": "Fago"
}, {
	"id": "Huesca",
	"nm": "Fanlo"
}, {
	"id": "Huesca",
	"nm": "Fiscal"
}, {
	"id": "Huesca",
	"nm": "Fonz"
}, {
	"id": "Huesca",
	"nm": "Foradada del Toscar"
}, {
	"id": "Huesca",
	"nm": "Fraga"
}, {
	"id": "Huesca",
	"nm": "Fueva, La"
}, {
	"id": "Huesca",
	"nm": "Gistaín"
}, {
	"id": "Huesca",
	"nm": "Grado, El"
}, {
	"id": "Huesca",
	"nm": "Grañén"
}, {
	"id": "Huesca",
	"nm": "Graus"
}, {
	"id": "Huesca",
	"nm": "Gurrea de Gállego"
}, {
	"id": "Huesca",
	"nm": "Hoz de Jaca"
}, {
	"id": "Huesca",
	"nm": "Hoz y Costean"
}, {
	"id": "Huesca",
	"nm": "Huerto"
}, {
	"id": "Huesca",
	"nm": "Huesca"
}, {
	"id": "Huesca",
	"nm": "Ibieca"
}, {
	"id": "Huesca",
	"nm": "Igriés"
}, {
	"id": "Huesca",
	"nm": "Ilche"
}, {
	"id": "Huesca",
	"nm": "Isábena"
}, {
	"id": "Huesca",
	"nm": "Jaca"
}, {
	"id": "Huesca",
	"nm": "Jasa"
}, {
	"id": "Huesca",
	"nm": "Labuerda"
}, {
	"id": "Huesca",
	"nm": "Laluenga"
}, {
	"id": "Huesca",
	"nm": "Lalueza"
}, {
	"id": "Huesca",
	"nm": "Lanaja"
}, {
	"id": "Huesca",
	"nm": "Laperdiguera"
}, {
	"id": "Huesca",
	"nm": "Lascellas-Ponzano"
}, {
	"id": "Huesca",
	"nm": "Lascuarre"
}, {
	"id": "Huesca",
	"nm": "Laspaúles"
}, {
	"id": "Huesca",
	"nm": "Laspuña"
}, {
	"id": "Huesca",
	"nm": "Loarre"
}, {
	"id": "Huesca",
	"nm": "Loporzano"
}, {
	"id": "Huesca",
	"nm": "Loscorrales"
}, {
	"id": "Huesca",
	"nm": "Lupiñén-Ortilla"
}, {
	"id": "Huesca",
	"nm": "Monesma y Cajigar"
}, {
	"id": "Huesca",
	"nm": "Monflorite-Lascasas"
}, {
	"id": "Huesca",
	"nm": "Montanuy"
}, {
	"id": "Huesca",
	"nm": "Monzón"
}, {
	"id": "Huesca",
	"nm": "Naval"
}, {
	"id": "Huesca",
	"nm": "Novales"
}, {
	"id": "Huesca",
	"nm": "Nueno"
}, {
	"id": "Huesca",
	"nm": "Olvena"
}, {
	"id": "Huesca",
	"nm": "Ontiñena"
}, {
	"id": "Huesca",
	"nm": "Osso de Cinca"
}, {
	"id": "Huesca",
	"nm": "Palo"
}, {
	"id": "Huesca",
	"nm": "Panticosa"
}, {
	"id": "Huesca",
	"nm": "Peñalba"
}, {
	"id": "Huesca",
	"nm": "Peñas de Riglos, Las"
}, {
	"id": "Huesca",
	"nm": "Peralta de Alcofea"
}, {
	"id": "Huesca",
	"nm": "Peralta de Calasanz"
}, {
	"id": "Huesca",
	"nm": "Peraltilla"
}, {
	"id": "Huesca",
	"nm": "Perarrúa"
}, {
	"id": "Huesca",
	"nm": "Pertusa"
}, {
	"id": "Huesca",
	"nm": "Piracés"
}, {
	"id": "Huesca",
	"nm": "Plan"
}, {
	"id": "Huesca",
	"nm": "Poleñino"
}, {
	"id": "Huesca",
	"nm": "Pozán de Vero"
}, {
	"id": "Huesca",
	"nm": "Puebla de Castro, La"
}, {
	"id": "Huesca",
	"nm": "Puente de Montañana"
}, {
	"id": "Huesca",
	"nm": "Puente la Reina de Jaca"
}, {
	"id": "Huesca",
	"nm": "Puértolas"
}, {
	"id": "Huesca",
	"nm": "Pueyo de Araguás, El"
}, {
	"id": "Huesca",
	"nm": "Pueyo de Santa Cruz"
}, {
	"id": "Huesca",
	"nm": "Quicena"
}, {
	"id": "Huesca",
	"nm": "Robres"
}, {
	"id": "Huesca",
	"nm": "Sabiñánigo"
}, {
	"id": "Huesca",
	"nm": "Sahún"
}, {
	"id": "Huesca",
	"nm": "Salas Altas"
}, {
	"id": "Huesca",
	"nm": "Salas Bajas"
}, {
	"id": "Huesca",
	"nm": "Salillas"
}, {
	"id": "Huesca",
	"nm": "Sallent de Gállego"
}, {
	"id": "Huesca",
	"nm": "San Esteban de Litera"
}, {
	"id": "Huesca",
	"nm": "San Juan de Plan"
}, {
	"id": "Huesca",
	"nm": "San Miguel del Cinca"
}, {
	"id": "Huesca",
	"nm": "Sangarrén"
}, {
	"id": "Huesca",
	"nm": "Santa Cilia"
}, {
	"id": "Huesca",
	"nm": "Santa Cruz de la Serós"
}, {
	"id": "Huesca",
	"nm": "Santa María de Dulcis"
}, {
	"id": "Huesca",
	"nm": "Santaliestra y San Quílez"
}, {
	"id": "Huesca",
	"nm": "Sariñena"
}, {
	"id": "Huesca",
	"nm": "Secastilla"
}, {
	"id": "Huesca",
	"nm": "Seira"
}, {
	"id": "Huesca",
	"nm": "Sena"
}, {
	"id": "Huesca",
	"nm": "Senés de Alcubierre"
}, {
	"id": "Huesca",
	"nm": "Sesa"
}, {
	"id": "Huesca",
	"nm": "Sesué"
}, {
	"id": "Huesca",
	"nm": "Siétamo"
}, {
	"id": "Huesca",
	"nm": "Sopeira"
}, {
	"id": "Huesca",
	"nm": "Sotonera, La"
}, {
	"id": "Huesca",
	"nm": "Tamarite de Litera"
}, {
	"id": "Huesca",
	"nm": "Tardienta"
}, {
	"id": "Huesca",
	"nm": "Tella-Sin"
}, {
	"id": "Huesca",
	"nm": "Tierz"
}, {
	"id": "Huesca",
	"nm": "Tolva"
}, {
	"id": "Huesca",
	"nm": "Torla-Ordesa"
}, {
	"id": "Huesca",
	"nm": "Torralba de Aragón"
}, {
	"id": "Huesca",
	"nm": "Torre la Ribera"
}, {
	"id": "Huesca",
	"nm": "Torrente de Cinca"
}, {
	"id": "Huesca",
	"nm": "Torres de Alcanadre"
}, {
	"id": "Huesca",
	"nm": "Torres de Barbués"
}, {
	"id": "Huesca",
	"nm": "Tramaced"
}, {
	"id": "Huesca",
	"nm": "Valfarta"
}, {
	"id": "Huesca",
	"nm": "Valle de Bardají"
}, {
	"id": "Huesca",
	"nm": "Valle de Hecho"
}, {
	"id": "Huesca",
	"nm": "Valle de Lierp"
}, {
	"id": "Huesca",
	"nm": "Velilla de Cinca"
}, {
	"id": "Huesca",
	"nm": "Vencillón"
}, {
	"id": "Huesca",
	"nm": "Viacamp y Litera"
}, {
	"id": "Huesca",
	"nm": "Vicién"
}, {
	"id": "Huesca",
	"nm": "Villanova"
}, {
	"id": "Huesca",
	"nm": "Villanúa"
}, {
	"id": "Huesca",
	"nm": "Villanueva de Sigena"
}, {
	"id": "Huesca",
	"nm": "Yebra de Basa"
}, {
	"id": "Huesca",
	"nm": "Yésero"
}, {
	"id": "Huesca",
	"nm": "Zaidín"
}, {
	"id": "Jaén",
	"nm": "Albanchez de Mágina"
}, {
	"id": "Jaén",
	"nm": "Alcalá la Real"
}, {
	"id": "Jaén",
	"nm": "Alcaudete"
}, {
	"id": "Jaén",
	"nm": "Aldeaquemada"
}, {
	"id": "Jaén",
	"nm": "Andújar"
}, {
	"id": "Jaén",
	"nm": "Arjona"
}, {
	"id": "Jaén",
	"nm": "Arjonilla"
}, {
	"id": "Jaén",
	"nm": "Arquillos"
}, {
	"id": "Jaén",
	"nm": "Arroyo del Ojanco"
}, {
	"id": "Jaén",
	"nm": "Baeza"
}, {
	"id": "Jaén",
	"nm": "Bailén"
}, {
	"id": "Jaén",
	"nm": "Baños de la Encina"
}, {
	"id": "Jaén",
	"nm": "Beas de Segura"
}, {
	"id": "Jaén",
	"nm": "Bedmar y Garcíez"
}, {
	"id": "Jaén",
	"nm": "Begíjar"
}, {
	"id": "Jaén",
	"nm": "Bélmez de la Moraleda"
}, {
	"id": "Jaén",
	"nm": "Benatae"
}, {
	"id": "Jaén",
	"nm": "Cabra del Santo Cristo"
}, {
	"id": "Jaén",
	"nm": "Cambil"
}, {
	"id": "Jaén",
	"nm": "Campillo de Arenas"
}, {
	"id": "Jaén",
	"nm": "Canena"
}, {
	"id": "Jaén",
	"nm": "Carboneros"
}, {
	"id": "Jaén",
	"nm": "Cárcheles"
}, {
	"id": "Jaén",
	"nm": "Carolina, La"
}, {
	"id": "Jaén",
	"nm": "Castellar"
}, {
	"id": "Jaén",
	"nm": "Castillo de Locubín"
}, {
	"id": "Jaén",
	"nm": "Cazalilla"
}, {
	"id": "Jaén",
	"nm": "Cazorla"
}, {
	"id": "Jaén",
	"nm": "Chiclana de Segura"
}, {
	"id": "Jaén",
	"nm": "Chilluévar"
}, {
	"id": "Jaén",
	"nm": "Escañuela"
}, {
	"id": "Jaén",
	"nm": "Espelúy"
}, {
	"id": "Jaén",
	"nm": "Frailes"
}, {
	"id": "Jaén",
	"nm": "Fuensanta de Martos"
}, {
	"id": "Jaén",
	"nm": "Fuerte del Rey"
}, {
	"id": "Jaén",
	"nm": "Génave"
}, {
	"id": "Jaén",
	"nm": "Guardia de Jaén, La"
}, {
	"id": "Jaén",
	"nm": "Guarromán"
}, {
	"id": "Jaén",
	"nm": "Higuera de Calatrava"
}, {
	"id": "Jaén",
	"nm": "Hinojares"
}, {
	"id": "Jaén",
	"nm": "Hornos"
}, {
	"id": "Jaén",
	"nm": "Huelma"
}, {
	"id": "Jaén",
	"nm": "Huesa"
}, {
	"id": "Jaén",
	"nm": "Ibros"
}, {
	"id": "Jaén",
	"nm": "Iruela, La"
}, {
	"id": "Jaén",
	"nm": "Iznatoraf"
}, {
	"id": "Jaén",
	"nm": "Jabalquinto"
}, {
	"id": "Jaén",
	"nm": "Jaén"
}, {
	"id": "Jaén",
	"nm": "Jamilena"
}, {
	"id": "Jaén",
	"nm": "Jimena"
}, {
	"id": "Jaén",
	"nm": "Jódar"
}, {
	"id": "Jaén",
	"nm": "Lahiguera"
}, {
	"id": "Jaén",
	"nm": "Larva"
}, {
	"id": "Jaén",
	"nm": "Linares"
}, {
	"id": "Jaén",
	"nm": "Lopera"
}, {
	"id": "Jaén",
	"nm": "Lupión"
}, {
	"id": "Jaén",
	"nm": "Mancha Real"
}, {
	"id": "Jaén",
	"nm": "Marmolejo"
}, {
	"id": "Jaén",
	"nm": "Martos"
}, {
	"id": "Jaén",
	"nm": "Mengíbar"
}, {
	"id": "Jaén",
	"nm": "Montizón"
}, {
	"id": "Jaén",
	"nm": "Navas de San Juan"
}, {
	"id": "Jaén",
	"nm": "Noalejo"
}, {
	"id": "Jaén",
	"nm": "Orcera"
}, {
	"id": "Jaén",
	"nm": "Peal de Becerro"
}, {
	"id": "Jaén",
	"nm": "Pegalajar"
}, {
	"id": "Jaén",
	"nm": "Porcuna"
}, {
	"id": "Jaén",
	"nm": "Pozo Alcón"
}, {
	"id": "Jaén",
	"nm": "Puente de Génave"
}, {
	"id": "Jaén",
	"nm": "Puerta de Segura, La"
}, {
	"id": "Jaén",
	"nm": "Quesada"
}, {
	"id": "Jaén",
	"nm": "Rus"
}, {
	"id": "Jaén",
	"nm": "Sabiote"
}, {
	"id": "Jaén",
	"nm": "Santa Elena"
}, {
	"id": "Jaén",
	"nm": "Santiago de Calatrava"
}, {
	"id": "Jaén",
	"nm": "Santiago-Pontones"
}, {
	"id": "Jaén",
	"nm": "Santisteban del Puerto"
}, {
	"id": "Jaén",
	"nm": "Santo Tomé"
}, {
	"id": "Jaén",
	"nm": "Segura de la Sierra"
}, {
	"id": "Jaén",
	"nm": "Siles"
}, {
	"id": "Jaén",
	"nm": "Sorihuela del Guadalimar"
}, {
	"id": "Jaén",
	"nm": "Torreblascopedro"
}, {
	"id": "Jaén",
	"nm": "Torredelcampo"
}, {
	"id": "Jaén",
	"nm": "Torredonjimeno"
}, {
	"id": "Jaén",
	"nm": "Torreperogil"
}, {
	"id": "Jaén",
	"nm": "Torres"
}, {
	"id": "Jaén",
	"nm": "Torres de Albánchez"
}, {
	"id": "Jaén",
	"nm": "Úbeda"
}, {
	"id": "Jaén",
	"nm": "Valdepeñas de Jaén"
}, {
	"id": "Jaén",
	"nm": "Vilches"
}, {
	"id": "Jaén",
	"nm": "Villacarrillo"
}, {
	"id": "Jaén",
	"nm": "Villanueva de la Reina"
}, {
	"id": "Jaén",
	"nm": "Villanueva del Arzobispo"
}, {
	"id": "Jaén",
	"nm": "Villardompardo"
}, {
	"id": "Jaén",
	"nm": "Villares, Los"
}, {
	"id": "Jaén",
	"nm": "Villarrodrigo"
}, {
	"id": "Jaén",
	"nm": "Villatorres"
}, {
	"id": "León",
	"nm": "Acebedo"
}, {
	"id": "León",
	"nm": "Algadefe"
}, {
	"id": "León",
	"nm": "Alija del Infantado"
}, {
	"id": "León",
	"nm": "Almanza"
}, {
	"id": "León",
	"nm": "Antigua, La"
}, {
	"id": "León",
	"nm": "Ardón"
}, {
	"id": "León",
	"nm": "Arganza"
}, {
	"id": "León",
	"nm": "Astorga"
}, {
	"id": "León",
	"nm": "Balboa"
}, {
	"id": "León",
	"nm": "Bañeza, La"
}, {
	"id": "León",
	"nm": "Barjas"
}, {
	"id": "León",
	"nm": "Barrios de Luna, Los"
}, {
	"id": "León",
	"nm": "Bembibre"
}, {
	"id": "León",
	"nm": "Benavides"
}, {
	"id": "León",
	"nm": "Benuza"
}, {
	"id": "León",
	"nm": "Bercianos del Páramo"
}, {
	"id": "León",
	"nm": "Bercianos del Real Camino"
}, {
	"id": "León",
	"nm": "Berlanga del Bierzo"
}, {
	"id": "León",
	"nm": "Boca de Huérgano"
}, {
	"id": "León",
	"nm": "Boñar"
}, {
	"id": "León",
	"nm": "Borrenes"
}, {
	"id": "León",
	"nm": "Brazuelo"
}, {
	"id": "León",
	"nm": "Burgo Ranero, El"
}, {
	"id": "León",
	"nm": "Burón"
}, {
	"id": "León",
	"nm": "Bustillo del Páramo"
}, {
	"id": "León",
	"nm": "Cabañas Raras"
}, {
	"id": "León",
	"nm": "Cabreros del Río"
}, {
	"id": "León",
	"nm": "Cabrillanes"
}, {
	"id": "León",
	"nm": "Cacabelos"
}, {
	"id": "León",
	"nm": "Calzada del Coto"
}, {
	"id": "León",
	"nm": "Campazas"
}, {
	"id": "León",
	"nm": "Campo de Villavidel"
}, {
	"id": "León",
	"nm": "Camponaraya"
}, {
	"id": "León",
	"nm": "Candín"
}, {
	"id": "León",
	"nm": "Cármenes"
}, {
	"id": "León",
	"nm": "Carracedelo"
}, {
	"id": "León",
	"nm": "Carrizo"
}, {
	"id": "León",
	"nm": "Carrocera"
}, {
	"id": "León",
	"nm": "Carucedo"
}, {
	"id": "León",
	"nm": "Castilfalé"
}, {
	"id": "León",
	"nm": "Castrillo de Cabrera"
}, {
	"id": "León",
	"nm": "Castrillo de la Valduerna"
}, {
	"id": "León",
	"nm": "Castrocalbón"
}, {
	"id": "León",
	"nm": "Castrocontrigo"
}, {
	"id": "León",
	"nm": "Castropodame"
}, {
	"id": "León",
	"nm": "Castrotierra de Valmadrigal"
}, {
	"id": "León",
	"nm": "Cea"
}, {
	"id": "León",
	"nm": "Cebanico"
}, {
	"id": "León",
	"nm": "Cebrones del Río"
}, {
	"id": "León",
	"nm": "Chozas de Abajo"
}, {
	"id": "León",
	"nm": "Cimanes de la Vega"
}, {
	"id": "León",
	"nm": "Cimanes del Tejar"
}, {
	"id": "León",
	"nm": "Cistierna"
}, {
	"id": "León",
	"nm": "Congosto"
}, {
	"id": "León",
	"nm": "Corbillos de los Oteros"
}, {
	"id": "León",
	"nm": "Corullón"
}, {
	"id": "León",
	"nm": "Crémenes"
}, {
	"id": "León",
	"nm": "Cuadros"
}, {
	"id": "León",
	"nm": "Cubillas de los Oteros"
}, {
	"id": "León",
	"nm": "Cubillas de Rueda"
}, {
	"id": "León",
	"nm": "Cubillos del Sil"
}, {
	"id": "León",
	"nm": "Destriana"
}, {
	"id": "León",
	"nm": "Encinedo"
}, {
	"id": "León",
	"nm": "Ercina, La"
}, {
	"id": "León",
	"nm": "Escobar de Campos"
}, {
	"id": "León",
	"nm": "Fabero"
}, {
	"id": "León",
	"nm": "Folgoso de la Ribera"
}, {
	"id": "León",
	"nm": "Fresno de la Vega"
}, {
	"id": "León",
	"nm": "Fuentes de Carbajal"
}, {
	"id": "León",
	"nm": "Garrafe de Torío"
}, {
	"id": "León",
	"nm": "Gordaliza del Pino"
}, {
	"id": "León",
	"nm": "Gordoncillo"
}, {
	"id": "León",
	"nm": "Gradefes"
}, {
	"id": "León",
	"nm": "Grajal de Campos"
}, {
	"id": "León",
	"nm": "Gusendos de los Oteros"
}, {
	"id": "León",
	"nm": "Hospital de Órbigo"
}, {
	"id": "León",
	"nm": "Igüeña"
}, {
	"id": "León",
	"nm": "Izagre"
}, {
	"id": "León",
	"nm": "Joarilla de las Matas"
}, {
	"id": "León",
	"nm": "Laguna Dalga"
}, {
	"id": "León",
	"nm": "Laguna de Negrillos"
}, {
	"id": "León",
	"nm": "León"
}, {
	"id": "León",
	"nm": "Llamas de la Ribera"
}, {
	"id": "León",
	"nm": "Lucillo"
}, {
	"id": "León",
	"nm": "Luyego"
}, {
	"id": "León",
	"nm": "Magaz de Cepeda"
}, {
	"id": "León",
	"nm": "Mansilla de las Mulas"
}, {
	"id": "León",
	"nm": "Mansilla Mayor"
}, {
	"id": "León",
	"nm": "Maraña"
}, {
	"id": "León",
	"nm": "Matadeón de los Oteros"
}, {
	"id": "León",
	"nm": "Matallana de Torío"
}, {
	"id": "León",
	"nm": "Matanza"
}, {
	"id": "León",
	"nm": "Molinaseca"
}, {
	"id": "León",
	"nm": "Murias de Paredes"
}, {
	"id": "León",
	"nm": "Noceda del Bierzo"
}, {
	"id": "León",
	"nm": "Oencia"
}, {
	"id": "León",
	"nm": "Omañas, Las"
}, {
	"id": "León",
	"nm": "Onzonilla"
}, {
	"id": "León",
	"nm": "Oseja de Sajambre"
}, {
	"id": "León",
	"nm": "Pajares de los Oteros"
}, {
	"id": "León",
	"nm": "Palacios de la Valduerna"
}, {
	"id": "León",
	"nm": "Palacios del Sil"
}, {
	"id": "León",
	"nm": "Páramo del Sil"
}, {
	"id": "León",
	"nm": "Peranzanes"
}, {
	"id": "León",
	"nm": "Pobladura de Pelayo García"
}, {
	"id": "León",
	"nm": "Pola de Gordón, La"
}, {
	"id": "León",
	"nm": "Ponferrada"
}, {
	"id": "León",
	"nm": "Posada de Valdeón"
}, {
	"id": "León",
	"nm": "Pozuelo del Páramo"
}, {
	"id": "León",
	"nm": "Prado de la Guzpeña"
}, {
	"id": "León",
	"nm": "Priaranza del Bierzo"
}, {
	"id": "León",
	"nm": "Prioro"
}, {
	"id": "León",
	"nm": "Puebla de Lillo"
}, {
	"id": "León",
	"nm": "Puente de Domingo Flórez"
}, {
	"id": "León",
	"nm": "Quintana del Castillo"
}, {
	"id": "León",
	"nm": "Quintana del Marco"
}, {
	"id": "León",
	"nm": "Quintana y Congosto"
}, {
	"id": "León",
	"nm": "Regueras de Arriba"
}, {
	"id": "León",
	"nm": "Reyero"
}, {
	"id": "León",
	"nm": "Riaño"
}, {
	"id": "León",
	"nm": "Riego de la Vega"
}, {
	"id": "León",
	"nm": "Riello"
}, {
	"id": "León",
	"nm": "Rioseco de Tapia"
}, {
	"id": "León",
	"nm": "Robla, La"
}, {
	"id": "León",
	"nm": "Roperuelos del Páramo"
}, {
	"id": "León",
	"nm": "Sabero"
}, {
	"id": "León",
	"nm": "Sahagún"
}, {
	"id": "León",
	"nm": "San Adrián del Valle"
}, {
	"id": "León",
	"nm": "San Andrés del Rabanedo"
}, {
	"id": "León",
	"nm": "San Cristóbal de la Polantera"
}, {
	"id": "León",
	"nm": "San Emiliano"
}, {
	"id": "León",
	"nm": "San Esteban de Nogales"
}, {
	"id": "León",
	"nm": "San Justo de la Vega"
}, {
	"id": "León",
	"nm": "San Millán de los Caballeros"
}, {
	"id": "León",
	"nm": "San Pedro Bercianos"
}, {
	"id": "León",
	"nm": "Sancedo"
}, {
	"id": "León",
	"nm": "Santa Colomba de Curueño"
}, {
	"id": "León",
	"nm": "Santa Colomba de Somoza"
}, {
	"id": "León",
	"nm": "Santa Cristina de Valmadrigal"
}, {
	"id": "León",
	"nm": "Santa Elena de Jamuz"
}, {
	"id": "León",
	"nm": "Santa María de la Isla"
}, {
	"id": "León",
	"nm": "Santa María de Ordás"
}, {
	"id": "León",
	"nm": "Santa María del Monte de Cea"
}, {
	"id": "León",
	"nm": "Santa María del Páramo"
}, {
	"id": "León",
	"nm": "Santa Marina del Rey"
}, {
	"id": "León",
	"nm": "Santas Martas"
}, {
	"id": "León",
	"nm": "Santiago Millas"
}, {
	"id": "León",
	"nm": "Santovenia de la Valdoncina"
}, {
	"id": "León",
	"nm": "Sariegos"
}, {
	"id": "León",
	"nm": "Sena de Luna"
}, {
	"id": "León",
	"nm": "Sobrado"
}, {
	"id": "León",
	"nm": "Soto de la Vega"
}, {
	"id": "León",
	"nm": "Soto y Amío"
}, {
	"id": "León",
	"nm": "Toral de los Guzmanes"
}, {
	"id": "León",
	"nm": "Toral de los Vados"
}, {
	"id": "León",
	"nm": "Toreno"
}, {
	"id": "León",
	"nm": "Torre del Bierzo"
}, {
	"id": "León",
	"nm": "Trabadelo"
}, {
	"id": "León",
	"nm": "Truchas"
}, {
	"id": "León",
	"nm": "Turcia"
}, {
	"id": "León",
	"nm": "Urdiales del Páramo"
}, {
	"id": "León",
	"nm": "Val de San Lorenzo"
}, {
	"id": "León",
	"nm": "Valdefresno"
}, {
	"id": "León",
	"nm": "Valdefuentes del Páramo"
}, {
	"id": "León",
	"nm": "Valdelugueros"
}, {
	"id": "León",
	"nm": "Valdemora"
}, {
	"id": "León",
	"nm": "Valdepiélago"
}, {
	"id": "León",
	"nm": "Valdepolo"
}, {
	"id": "León",
	"nm": "Valderas"
}, {
	"id": "León",
	"nm": "Valderrey"
}, {
	"id": "León",
	"nm": "Valderrueda"
}, {
	"id": "León",
	"nm": "Valdesamario"
}, {
	"id": "León",
	"nm": "Valdevimbre"
}, {
	"id": "León",
	"nm": "Valencia de Don Juan"
}, {
	"id": "León",
	"nm": "Vallecillo"
}, {
	"id": "León",
	"nm": "Valverde de la Virgen"
}, {
	"id": "León",
	"nm": "Valverde-Enrique"
}, {
	"id": "León",
	"nm": "Vecilla, La"
}, {
	"id": "León",
	"nm": "Vega de Espinareda"
}, {
	"id": "León",
	"nm": "Vega de Infanzones"
}, {
	"id": "León",
	"nm": "Vega de Valcarce"
}, {
	"id": "León",
	"nm": "Vegacervera"
}, {
	"id": "León",
	"nm": "Vegaquemada"
}, {
	"id": "León",
	"nm": "Vegas del Condado"
}, {
	"id": "León",
	"nm": "Villablino"
}, {
	"id": "León",
	"nm": "Villabraz"
}, {
	"id": "León",
	"nm": "Villadangos del Páramo"
}, {
	"id": "León",
	"nm": "Villademor de la Vega"
}, {
	"id": "León",
	"nm": "Villafranca del Bierzo"
}, {
	"id": "León",
	"nm": "Villagatón"
}, {
	"id": "León",
	"nm": "Villamandos"
}, {
	"id": "León",
	"nm": "Villamanín"
}, {
	"id": "León",
	"nm": "Villamañán"
}, {
	"id": "León",
	"nm": "Villamartín de Don Sancho"
}, {
	"id": "León",
	"nm": "Villamejil"
}, {
	"id": "León",
	"nm": "Villamol"
}, {
	"id": "León",
	"nm": "Villamontán de la Valduerna"
}, {
	"id": "León",
	"nm": "Villamoratiel de las Matas"
}, {
	"id": "León",
	"nm": "Villanueva de las Manzanas"
}, {
	"id": "León",
	"nm": "Villaobispo de Otero"
}, {
	"id": "León",
	"nm": "Villaornate y Castro"
}, {
	"id": "León",
	"nm": "Villaquejida"
}, {
	"id": "León",
	"nm": "Villaquilambre"
}, {
	"id": "León",
	"nm": "Villarejo de Órbigo"
}, {
	"id": "León",
	"nm": "Villares de Órbigo"
}, {
	"id": "León",
	"nm": "Villasabariego"
}, {
	"id": "León",
	"nm": "Villaselán"
}, {
	"id": "León",
	"nm": "Villaturiel"
}, {
	"id": "León",
	"nm": "Villazala"
}, {
	"id": "León",
	"nm": "Villazanzo de Valderaduey"
}, {
	"id": "León",
	"nm": "Zotes del Páramo"
}, {
	"id": "Lleida",
	"nm": "Abella de la Conca"
}, {
	"id": "Lleida",
	"nm": "Àger"
}, {
	"id": "Lleida",
	"nm": "Agramunt"
}, {
	"id": "Lleida",
	"nm": "Aitona"
}, {
	"id": "Lleida",
	"nm": "Alamús, Els"
}, {
	"id": "Lleida",
	"nm": "Alàs i Cerc"
}, {
	"id": "Lleida",
	"nm": "Albagés, L'"
}, {
	"id": "Lleida",
	"nm": "Albatàrrec"
}, {
	"id": "Lleida",
	"nm": "Albesa"
}, {
	"id": "Lleida",
	"nm": "Albi, L'"
}, {
	"id": "Lleida",
	"nm": "Alcanó"
}, {
	"id": "Lleida",
	"nm": "Alcarràs"
}, {
	"id": "Lleida",
	"nm": "Alcoletge"
}, {
	"id": "Lleida",
	"nm": "Alfarràs"
}, {
	"id": "Lleida",
	"nm": "Alfés"
}, {
	"id": "Lleida",
	"nm": "Algerri"
}, {
	"id": "Lleida",
	"nm": "Alguaire"
}, {
	"id": "Lleida",
	"nm": "Alins"
}, {
	"id": "Lleida",
	"nm": "Almacelles"
}, {
	"id": "Lleida",
	"nm": "Almatret"
}, {
	"id": "Lleida",
	"nm": "Almenar"
}, {
	"id": "Lleida",
	"nm": "Alòs de Balaguer"
}, {
	"id": "Lleida",
	"nm": "Alpicat"
}, {
	"id": "Lleida",
	"nm": "Alt Àneu"
}, {
	"id": "Lleida",
	"nm": "Anglesola"
}, {
	"id": "Lleida",
	"nm": "Arbeca"
}, {
	"id": "Lleida",
	"nm": "Arres"
}, {
	"id": "Lleida",
	"nm": "Arsèguel"
}, {
	"id": "Lleida",
	"nm": "Artesa de Lleida"
}, {
	"id": "Lleida",
	"nm": "Artesa de Segre"
}, {
	"id": "Lleida",
	"nm": "Aspa"
}, {
	"id": "Lleida",
	"nm": "Avellanes i Santa Linya, Les"
}, {
	"id": "Lleida",
	"nm": "Baix Pallars"
}, {
	"id": "Lleida",
	"nm": "Balaguer"
}, {
	"id": "Lleida",
	"nm": "Barbens"
}, {
	"id": "Lleida",
	"nm": "Baronia de Rialb, La"
}, {
	"id": "Lleida",
	"nm": "Bassella"
}, {
	"id": "Lleida",
	"nm": "Bausen"
}, {
	"id": "Lleida",
	"nm": "Belianes"
}, {
	"id": "Lleida",
	"nm": "Bellaguarda"
}, {
	"id": "Lleida",
	"nm": "Bellcaire d'Urgell"
}, {
	"id": "Lleida",
	"nm": "Bell-lloc d'Urgell"
}, {
	"id": "Lleida",
	"nm": "Bellmunt d'Urgell"
}, {
	"id": "Lleida",
	"nm": "Bellpuig"
}, {
	"id": "Lleida",
	"nm": "Bellver de Cerdanya"
}, {
	"id": "Lleida",
	"nm": "Bellvís"
}, {
	"id": "Lleida",
	"nm": "Benavent de Segrià"
}, {
	"id": "Lleida",
	"nm": "Biosca"
}, {
	"id": "Lleida",
	"nm": "Bòrdes, Es"
}, {
	"id": "Lleida",
	"nm": "Borges Blanques, Les"
}, {
	"id": "Lleida",
	"nm": "Bossòst"
}, {
	"id": "Lleida",
	"nm": "Bovera"
}, {
	"id": "Lleida",
	"nm": "Cabanabona"
}, {
	"id": "Lleida",
	"nm": "Cabó"
}, {
	"id": "Lleida",
	"nm": "Camarasa"
}, {
	"id": "Lleida",
	"nm": "Canejan"
}, {
	"id": "Lleida",
	"nm": "Castell de Mur"
}, {
	"id": "Lleida",
	"nm": "Castellar de la Ribera"
}, {
	"id": "Lleida",
	"nm": "Castelldans"
}, {
	"id": "Lleida",
	"nm": "Castellnou de Seana"
}, {
	"id": "Lleida",
	"nm": "Castelló de Farfanya"
}, {
	"id": "Lleida",
	"nm": "Castellserà"
}, {
	"id": "Lleida",
	"nm": "Cava"
}, {
	"id": "Lleida",
	"nm": "Cervera"
}, {
	"id": "Lleida",
	"nm": "Cervià de les Garrigues"
}, {
	"id": "Lleida",
	"nm": "Ciutadilla"
}, {
	"id": "Lleida",
	"nm": "Clariana de Cardener"
}, {
	"id": "Lleida",
	"nm": "Cogul, El"
}, {
	"id": "Lleida",
	"nm": "Coll de Nargó"
}, {
	"id": "Lleida",
	"nm": "Coma i la Pedra, La"
}, {
	"id": "Lleida",
	"nm": "Conca de Dalt"
}, {
	"id": "Lleida",
	"nm": "Corbins"
}, {
	"id": "Lleida",
	"nm": "Cubells"
}, {
	"id": "Lleida",
	"nm": "Espluga Calba, L'"
}, {
	"id": "Lleida",
	"nm": "Espot"
}, {
	"id": "Lleida",
	"nm": "Estamariu"
}, {
	"id": "Lleida",
	"nm": "Estaràs"
}, {
	"id": "Lleida",
	"nm": "Esterri d'Àneu"
}, {
	"id": "Lleida",
	"nm": "Esterri de Cardós"
}, {
	"id": "Lleida",
	"nm": "Farrera"
}, {
	"id": "Lleida",
	"nm": "Fígols i Alinyà"
}, {
	"id": "Lleida",
	"nm": "Floresta, La"
}, {
	"id": "Lleida",
	"nm": "Fondarella"
}, {
	"id": "Lleida",
	"nm": "Foradada"
}, {
	"id": "Lleida",
	"nm": "Fuliola, La"
}, {
	"id": "Lleida",
	"nm": "Fulleda"
}, {
	"id": "Lleida",
	"nm": "Gavet de la Conca"
}, {
	"id": "Lleida",
	"nm": "Gimenells i el Pla de la Font"
}, {
	"id": "Lleida",
	"nm": "Golmés"
}, {
	"id": "Lleida",
	"nm": "Gósol"
}, {
	"id": "Lleida",
	"nm": "Granadella, La"
}, {
	"id": "Lleida",
	"nm": "Granja d'Escarp, La"
}, {
	"id": "Lleida",
	"nm": "Granyanella"
}, {
	"id": "Lleida",
	"nm": "Granyena de les Garrigues"
}, {
	"id": "Lleida",
	"nm": "Granyena de Segarra"
}, {
	"id": "Lleida",
	"nm": "Guimerà"
}, {
	"id": "Lleida",
	"nm": "Guingueta d'Àneu, La"
}, {
	"id": "Lleida",
	"nm": "Guissona"
}, {
	"id": "Lleida",
	"nm": "Guixers"
}, {
	"id": "Lleida",
	"nm": "Isona i Conca Dellà"
}, {
	"id": "Lleida",
	"nm": "Ivars de Noguera"
}, {
	"id": "Lleida",
	"nm": "Ivars d'Urgell"
}, {
	"id": "Lleida",
	"nm": "Ivorra"
}, {
	"id": "Lleida",
	"nm": "Josa i Tuixén"
}, {
	"id": "Lleida",
	"nm": "Juncosa"
}, {
	"id": "Lleida",
	"nm": "Juneda"
}, {
	"id": "Lleida",
	"nm": "Les"
}, {
	"id": "Lleida",
	"nm": "Linyola"
}, {
	"id": "Lleida",
	"nm": "Lladorre"
}, {
	"id": "Lleida",
	"nm": "Lladurs"
}, {
	"id": "Lleida",
	"nm": "Llardecans"
}, {
	"id": "Lleida",
	"nm": "Llavorsí"
}, {
	"id": "Lleida",
	"nm": "Lleida"
}, {
	"id": "Lleida",
	"nm": "Lles de Cerdanya"
}, {
	"id": "Lleida",
	"nm": "Llimiana"
}, {
	"id": "Lleida",
	"nm": "Llobera"
}, {
	"id": "Lleida",
	"nm": "Maials"
}, {
	"id": "Lleida",
	"nm": "Maldà"
}, {
	"id": "Lleida",
	"nm": "Massalcoreig"
}, {
	"id": "Lleida",
	"nm": "Massoteres"
}, {
	"id": "Lleida",
	"nm": "Menàrguens"
}, {
	"id": "Lleida",
	"nm": "Miralcamp"
}, {
	"id": "Lleida",
	"nm": "Mollerussa"
}, {
	"id": "Lleida",
	"nm": "Molsosa, La"
}, {
	"id": "Lleida",
	"nm": "Montellà i Martinet"
}, {
	"id": "Lleida",
	"nm": "Montferrer i Castellbò"
}, {
	"id": "Lleida",
	"nm": "Montgai"
}, {
	"id": "Lleida",
	"nm": "Montoliu de Lleida"
}, {
	"id": "Lleida",
	"nm": "Montoliu de Segarra"
}, {
	"id": "Lleida",
	"nm": "Montornès de Segarra"
}, {
	"id": "Lleida",
	"nm": "Nalec"
}, {
	"id": "Lleida",
	"nm": "Naut Aran"
}, {
	"id": "Lleida",
	"nm": "Navès"
}, {
	"id": "Lleida",
	"nm": "Odèn"
}, {
	"id": "Lleida",
	"nm": "Oliana"
}, {
	"id": "Lleida",
	"nm": "Oliola"
}, {
	"id": "Lleida",
	"nm": "Olius"
}, {
	"id": "Lleida",
	"nm": "Oluges, Les"
}, {
	"id": "Lleida",
	"nm": "Omellons, Els"
}, {
	"id": "Lleida",
	"nm": "Omells de na Gaia, Els"
}, {
	"id": "Lleida",
	"nm": "Organyà"
}, {
	"id": "Lleida",
	"nm": "Os de Balaguer"
}, {
	"id": "Lleida",
	"nm": "Ossó de Sió"
}, {
	"id": "Lleida",
	"nm": "Palau d'Anglesola, El"
}, {
	"id": "Lleida",
	"nm": "Penelles"
}, {
	"id": "Lleida",
	"nm": "Peramola"
}, {
	"id": "Lleida",
	"nm": "Pinell de Solsonès"
}, {
	"id": "Lleida",
	"nm": "Pinós"
}, {
	"id": "Lleida",
	"nm": "Plans de Sió, Els"
}, {
	"id": "Lleida",
	"nm": "Poal, El"
}, {
	"id": "Lleida",
	"nm": "Pobla de Cérvoles, La"
}, {
	"id": "Lleida",
	"nm": "Pobla de Segur, La"
}, {
	"id": "Lleida",
	"nm": "Pont de Bar, El"
}, {
	"id": "Lleida",
	"nm": "Pont de Suert, El"
}, {
	"id": "Lleida",
	"nm": "Ponts"
}, {
	"id": "Lleida",
	"nm": "Portella, La"
}, {
	"id": "Lleida",
	"nm": "Prats i Sansor"
}, {
	"id": "Lleida",
	"nm": "Preixana"
}, {
	"id": "Lleida",
	"nm": "Preixens"
}, {
	"id": "Lleida",
	"nm": "Prullans"
}, {
	"id": "Lleida",
	"nm": "Puiggròs"
}, {
	"id": "Lleida",
	"nm": "Puigverd d'Agramunt"
}, {
	"id": "Lleida",
	"nm": "Puigverd de Lleida"
}, {
	"id": "Lleida",
	"nm": "Rialp"
}, {
	"id": "Lleida",
	"nm": "Ribera d'Ondara"
}, {
	"id": "Lleida",
	"nm": "Ribera d'Urgellet"
}, {
	"id": "Lleida",
	"nm": "Riner"
}, {
	"id": "Lleida",
	"nm": "Riu de Cerdanya"
}, {
	"id": "Lleida",
	"nm": "Rosselló"
}, {
	"id": "Lleida",
	"nm": "Salàs de Pallars"
}, {
	"id": "Lleida",
	"nm": "Sanaüja"
}, {
	"id": "Lleida",
	"nm": "Sant Esteve de la Sarga"
}, {
	"id": "Lleida",
	"nm": "Sant Guim de Freixenet"
}, {
	"id": "Lleida",
	"nm": "Sant Guim de la Plana"
}, {
	"id": "Lleida",
	"nm": "Sant Llorenç de Morunys"
}, {
	"id": "Lleida",
	"nm": "Sant Martí de Riucorb"
}, {
	"id": "Lleida",
	"nm": "Sant Ramon"
}, {
	"id": "Lleida",
	"nm": "Sarroca de Bellera"
}, {
	"id": "Lleida",
	"nm": "Sarroca de Lleida"
}, {
	"id": "Lleida",
	"nm": "Senterada"
}, {
	"id": "Lleida",
	"nm": "Sentiu de Sió, La"
}, {
	"id": "Lleida",
	"nm": "Seròs"
}, {
	"id": "Lleida",
	"nm": "Seu d'Urgell, La"
}, {
	"id": "Lleida",
	"nm": "Sidamon"
}, {
	"id": "Lleida",
	"nm": "Soleràs, El"
}, {
	"id": "Lleida",
	"nm": "Solsona"
}, {
	"id": "Lleida",
	"nm": "Soriguera"
}, {
	"id": "Lleida",
	"nm": "Sort"
}, {
	"id": "Lleida",
	"nm": "Soses"
}, {
	"id": "Lleida",
	"nm": "Sudanell"
}, {
	"id": "Lleida",
	"nm": "Sunyer"
}, {
	"id": "Lleida",
	"nm": "Talarn"
}, {
	"id": "Lleida",
	"nm": "Talavera"
}, {
	"id": "Lleida",
	"nm": "Tàrrega"
}, {
	"id": "Lleida",
	"nm": "Tarrés"
}, {
	"id": "Lleida",
	"nm": "Tarroja de Segarra"
}, {
	"id": "Lleida",
	"nm": "Térmens"
}, {
	"id": "Lleida",
	"nm": "Tírvia"
}, {
	"id": "Lleida",
	"nm": "Tiurana"
}, {
	"id": "Lleida",
	"nm": "Torà"
}, {
	"id": "Lleida",
	"nm": "Torms, Els"
}, {
	"id": "Lleida",
	"nm": "Tornabous"
}, {
	"id": "Lleida",
	"nm": "Torre de Cabdella, La"
}, {
	"id": "Lleida",
	"nm": "Torrebesses"
}, {
	"id": "Lleida",
	"nm": "Torrefarrera"
}, {
	"id": "Lleida",
	"nm": "Torrefeta i Florejacs"
}, {
	"id": "Lleida",
	"nm": "Torregrossa"
}, {
	"id": "Lleida",
	"nm": "Torrelameu"
}, {
	"id": "Lleida",
	"nm": "Torres de Segre"
}, {
	"id": "Lleida",
	"nm": "Torre-serona"
}, {
	"id": "Lleida",
	"nm": "Tremp"
}, {
	"id": "Lleida",
	"nm": "Vall de Boí, La"
}, {
	"id": "Lleida",
	"nm": "Vall de Cardós"
}, {
	"id": "Lleida",
	"nm": "Vallbona de les Monges"
}, {
	"id": "Lleida",
	"nm": "Vallfogona de Balaguer"
}, {
	"id": "Lleida",
	"nm": "Valls d'Aguilar, Les"
}, {
	"id": "Lleida",
	"nm": "Valls de Valira, Les"
}, {
	"id": "Lleida",
	"nm": "Vansa i Fórnols, La"
}, {
	"id": "Lleida",
	"nm": "Verdú"
}, {
	"id": "Lleida",
	"nm": "Vielha e Mijaran"
}, {
	"id": "Lleida",
	"nm": "Vilagrassa"
}, {
	"id": "Lleida",
	"nm": "Vilaller"
}, {
	"id": "Lleida",
	"nm": "Vilamòs"
}, {
	"id": "Lleida",
	"nm": "Vilanova de Bellpuig"
}, {
	"id": "Lleida",
	"nm": "Vilanova de la Barca"
}, {
	"id": "Lleida",
	"nm": "Vilanova de l'Aguda"
}, {
	"id": "Lleida",
	"nm": "Vilanova de Meià"
}, {
	"id": "Lleida",
	"nm": "Vilanova de Segrià"
}, {
	"id": "Lleida",
	"nm": "Vila-sana"
}, {
	"id": "Lleida",
	"nm": "Vilosell, El"
}, {
	"id": "Lleida",
	"nm": "Vinaixa"
}, {
	"id": "Rioja, La",
	"nm": "Ábalos"
}, {
	"id": "Rioja, La",
	"nm": "Agoncillo"
}, {
	"id": "Rioja, La",
	"nm": "Aguilar del Río Alhama"
}, {
	"id": "Rioja, La",
	"nm": "Ajamil de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Albelda de Iregua"
}, {
	"id": "Rioja, La",
	"nm": "Alberite"
}, {
	"id": "Rioja, La",
	"nm": "Alcanadre"
}, {
	"id": "Rioja, La",
	"nm": "Aldeanueva de Ebro"
}, {
	"id": "Rioja, La",
	"nm": "Alesanco"
}, {
	"id": "Rioja, La",
	"nm": "Alesón"
}, {
	"id": "Rioja, La",
	"nm": "Alfaro"
}, {
	"id": "Rioja, La",
	"nm": "Almarza de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Anguciana"
}, {
	"id": "Rioja, La",
	"nm": "Anguiano"
}, {
	"id": "Rioja, La",
	"nm": "Arenzana de Abajo"
}, {
	"id": "Rioja, La",
	"nm": "Arenzana de Arriba"
}, {
	"id": "Rioja, La",
	"nm": "Arnedillo"
}, {
	"id": "Rioja, La",
	"nm": "Arnedo"
}, {
	"id": "Rioja, La",
	"nm": "Arrúbal"
}, {
	"id": "Rioja, La",
	"nm": "Ausejo"
}, {
	"id": "Rioja, La",
	"nm": "Autol"
}, {
	"id": "Rioja, La",
	"nm": "Azofra"
}, {
	"id": "Rioja, La",
	"nm": "Badarán"
}, {
	"id": "Rioja, La",
	"nm": "Bañares"
}, {
	"id": "Rioja, La",
	"nm": "Baños de Río Tobía"
}, {
	"id": "Rioja, La",
	"nm": "Baños de Rioja"
}, {
	"id": "Rioja, La",
	"nm": "Berceo"
}, {
	"id": "Rioja, La",
	"nm": "Bergasa"
}, {
	"id": "Rioja, La",
	"nm": "Bergasillas Bajera"
}, {
	"id": "Rioja, La",
	"nm": "Bezares"
}, {
	"id": "Rioja, La",
	"nm": "Bobadilla"
}, {
	"id": "Rioja, La",
	"nm": "Brieva de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Briñas"
}, {
	"id": "Rioja, La",
	"nm": "Briones"
}, {
	"id": "Rioja, La",
	"nm": "Cabezón de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Calahorra"
}, {
	"id": "Rioja, La",
	"nm": "Camprovín"
}, {
	"id": "Rioja, La",
	"nm": "Canales de la Sierra"
}, {
	"id": "Rioja, La",
	"nm": "Canillas de Río Tuerto"
}, {
	"id": "Rioja, La",
	"nm": "Cañas"
}, {
	"id": "Rioja, La",
	"nm": "Cárdenas"
}, {
	"id": "Rioja, La",
	"nm": "Casalarreina"
}, {
	"id": "Rioja, La",
	"nm": "Castañares de Rioja"
}, {
	"id": "Rioja, La",
	"nm": "Castroviejo"
}, {
	"id": "Rioja, La",
	"nm": "Cellorigo"
}, {
	"id": "Rioja, La",
	"nm": "Cenicero"
}, {
	"id": "Rioja, La",
	"nm": "Cervera del Río Alhama"
}, {
	"id": "Rioja, La",
	"nm": "Cidamón"
}, {
	"id": "Rioja, La",
	"nm": "Cihuri"
}, {
	"id": "Rioja, La",
	"nm": "Cirueña"
}, {
	"id": "Rioja, La",
	"nm": "Clavijo"
}, {
	"id": "Rioja, La",
	"nm": "Cordovín"
}, {
	"id": "Rioja, La",
	"nm": "Corera"
}, {
	"id": "Rioja, La",
	"nm": "Cornago"
}, {
	"id": "Rioja, La",
	"nm": "Corporales"
}, {
	"id": "Rioja, La",
	"nm": "Cuzcurrita de Río Tirón"
}, {
	"id": "Rioja, La",
	"nm": "Daroca de Rioja"
}, {
	"id": "Rioja, La",
	"nm": "Enciso"
}, {
	"id": "Rioja, La",
	"nm": "Entrena"
}, {
	"id": "Rioja, La",
	"nm": "Estollo"
}, {
	"id": "Rioja, La",
	"nm": "Ezcaray"
}, {
	"id": "Rioja, La",
	"nm": "Foncea"
}, {
	"id": "Rioja, La",
	"nm": "Fonzaleche"
}, {
	"id": "Rioja, La",
	"nm": "Fuenmayor"
}, {
	"id": "Rioja, La",
	"nm": "Galbárruli"
}, {
	"id": "Rioja, La",
	"nm": "Galilea"
}, {
	"id": "Rioja, La",
	"nm": "Gallinero de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Gimileo"
}, {
	"id": "Rioja, La",
	"nm": "Grañón"
}, {
	"id": "Rioja, La",
	"nm": "Grávalos"
}, {
	"id": "Rioja, La",
	"nm": "Haro"
}, {
	"id": "Rioja, La",
	"nm": "Herce"
}, {
	"id": "Rioja, La",
	"nm": "Herramélluri"
}, {
	"id": "Rioja, La",
	"nm": "Hervías"
}, {
	"id": "Rioja, La",
	"nm": "Hormilla"
}, {
	"id": "Rioja, La",
	"nm": "Hormilleja"
}, {
	"id": "Rioja, La",
	"nm": "Hornillos de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Hornos de Moncalvillo"
}, {
	"id": "Rioja, La",
	"nm": "Huércanos"
}, {
	"id": "Rioja, La",
	"nm": "Igea"
}, {
	"id": "Rioja, La",
	"nm": "Jalón de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Laguna de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Lagunilla del Jubera"
}, {
	"id": "Rioja, La",
	"nm": "Lardero"
}, {
	"id": "Rioja, La",
	"nm": "Ledesma de la Cogolla"
}, {
	"id": "Rioja, La",
	"nm": "Leiva"
}, {
	"id": "Rioja, La",
	"nm": "Leza de Río Leza"
}, {
	"id": "Rioja, La",
	"nm": "Logroño"
}, {
	"id": "Rioja, La",
	"nm": "Lumbreras"
}, {
	"id": "Rioja, La",
	"nm": "Manjarrés"
}, {
	"id": "Rioja, La",
	"nm": "Mansilla de la Sierra"
}, {
	"id": "Rioja, La",
	"nm": "Manzanares de Rioja"
}, {
	"id": "Rioja, La",
	"nm": "Matute"
}, {
	"id": "Rioja, La",
	"nm": "Medrano"
}, {
	"id": "Rioja, La",
	"nm": "Munilla"
}, {
	"id": "Rioja, La",
	"nm": "Murillo de Río Leza"
}, {
	"id": "Rioja, La",
	"nm": "Muro de Aguas"
}, {
	"id": "Rioja, La",
	"nm": "Muro en Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Nájera"
}, {
	"id": "Rioja, La",
	"nm": "Nalda"
}, {
	"id": "Rioja, La",
	"nm": "Navajún"
}, {
	"id": "Rioja, La",
	"nm": "Navarrete"
}, {
	"id": "Rioja, La",
	"nm": "Nestares"
}, {
	"id": "Rioja, La",
	"nm": "Nieva de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Ochánduri"
}, {
	"id": "Rioja, La",
	"nm": "Ocón"
}, {
	"id": "Rioja, La",
	"nm": "Ojacastro"
}, {
	"id": "Rioja, La",
	"nm": "Ollauri"
}, {
	"id": "Rioja, La",
	"nm": "Ortigosa de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Pazuengos"
}, {
	"id": "Rioja, La",
	"nm": "Pedroso"
}, {
	"id": "Rioja, La",
	"nm": "Pinillos"
}, {
	"id": "Rioja, La",
	"nm": "Pradejón"
}, {
	"id": "Rioja, La",
	"nm": "Pradillo"
}, {
	"id": "Rioja, La",
	"nm": "Préjano"
}, {
	"id": "Rioja, La",
	"nm": "Quel"
}, {
	"id": "Rioja, La",
	"nm": "Rabanera"
}, {
	"id": "Rioja, La",
	"nm": "Rasillo de Cameros, El"
}, {
	"id": "Rioja, La",
	"nm": "Redal, El"
}, {
	"id": "Rioja, La",
	"nm": "Ribafrecha"
}, {
	"id": "Rioja, La",
	"nm": "Rincón de Soto"
}, {
	"id": "Rioja, La",
	"nm": "Robres del Castillo"
}, {
	"id": "Rioja, La",
	"nm": "Rodezno"
}, {
	"id": "Rioja, La",
	"nm": "Sajazarra"
}, {
	"id": "Rioja, La",
	"nm": "San Asensio"
}, {
	"id": "Rioja, La",
	"nm": "San Millán de la Cogolla"
}, {
	"id": "Rioja, La",
	"nm": "San Millán de Yécora"
}, {
	"id": "Rioja, La",
	"nm": "San Román de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "San Torcuato"
}, {
	"id": "Rioja, La",
	"nm": "San Vicente de la Sonsierra"
}, {
	"id": "Rioja, La",
	"nm": "Santa Coloma"
}, {
	"id": "Rioja, La",
	"nm": "Santa Engracia del Jubera"
}, {
	"id": "Rioja, La",
	"nm": "Santa Eulalia Bajera"
}, {
	"id": "Rioja, La",
	"nm": "Santo Domingo de la Calzada"
}, {
	"id": "Rioja, La",
	"nm": "Santurde de Rioja"
}, {
	"id": "Rioja, La",
	"nm": "Santurdejo"
}, {
	"id": "Rioja, La",
	"nm": "Sojuela"
}, {
	"id": "Rioja, La",
	"nm": "Sorzano"
}, {
	"id": "Rioja, La",
	"nm": "Sotés"
}, {
	"id": "Rioja, La",
	"nm": "Soto en Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Terroba"
}, {
	"id": "Rioja, La",
	"nm": "Tirgo"
}, {
	"id": "Rioja, La",
	"nm": "Tobía"
}, {
	"id": "Rioja, La",
	"nm": "Tormantos"
}, {
	"id": "Rioja, La",
	"nm": "Torre en Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Torrecilla en Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Torrecilla sobre Alesanco"
}, {
	"id": "Rioja, La",
	"nm": "Torremontalbo"
}, {
	"id": "Rioja, La",
	"nm": "Treviana"
}, {
	"id": "Rioja, La",
	"nm": "Tricio"
}, {
	"id": "Rioja, La",
	"nm": "Tudelilla"
}, {
	"id": "Rioja, La",
	"nm": "Uruñuela"
}, {
	"id": "Rioja, La",
	"nm": "Valdemadera"
}, {
	"id": "Rioja, La",
	"nm": "Valgañón"
}, {
	"id": "Rioja, La",
	"nm": "Ventosa"
}, {
	"id": "Rioja, La",
	"nm": "Ventrosa"
}, {
	"id": "Rioja, La",
	"nm": "Viguera"
}, {
	"id": "Rioja, La",
	"nm": "Villalba de Rioja"
}, {
	"id": "Rioja, La",
	"nm": "Villalobar de Rioja"
}, {
	"id": "Rioja, La",
	"nm": "Villamediana de Iregua"
}, {
	"id": "Rioja, La",
	"nm": "Villanueva de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Villar de Arnedo, El"
}, {
	"id": "Rioja, La",
	"nm": "Villar de Torre"
}, {
	"id": "Rioja, La",
	"nm": "Villarejo"
}, {
	"id": "Rioja, La",
	"nm": "Villarroya"
}, {
	"id": "Rioja, La",
	"nm": "Villarta-Quintana"
}, {
	"id": "Rioja, La",
	"nm": "Villavelayo"
}, {
	"id": "Rioja, La",
	"nm": "Villaverde de Rioja"
}, {
	"id": "Rioja, La",
	"nm": "Villoslada de Cameros"
}, {
	"id": "Rioja, La",
	"nm": "Viniegra de Abajo"
}, {
	"id": "Rioja, La",
	"nm": "Viniegra de Arriba"
}, {
	"id": "Rioja, La",
	"nm": "Zarratón"
}, {
	"id": "Rioja, La",
	"nm": "Zarzosa"
}, {
	"id": "Rioja, La",
	"nm": "Zorraquín"
}, {
	"id": "Lugo",
	"nm": "Abadín"
}, {
	"id": "Lugo",
	"nm": "Alfoz"
}, {
	"id": "Lugo",
	"nm": "Antas de Ulla"
}, {
	"id": "Lugo",
	"nm": "Baleira"
}, {
	"id": "Lugo",
	"nm": "Baralla"
}, {
	"id": "Lugo",
	"nm": "Barreiros"
}, {
	"id": "Lugo",
	"nm": "Becerreá"
}, {
	"id": "Lugo",
	"nm": "Begonte"
}, {
	"id": "Lugo",
	"nm": "Bóveda"
}, {
	"id": "Lugo",
	"nm": "Burela"
}, {
	"id": "Lugo",
	"nm": "Carballedo"
}, {
	"id": "Lugo",
	"nm": "Castro de Rei"
}, {
	"id": "Lugo",
	"nm": "Castroverde"
}, {
	"id": "Lugo",
	"nm": "Cervantes"
}, {
	"id": "Lugo",
	"nm": "Cervo"
}, {
	"id": "Lugo",
	"nm": "Chantada"
}, {
	"id": "Lugo",
	"nm": "Corgo, O"
}, {
	"id": "Lugo",
	"nm": "Cospeito"
}, {
	"id": "Lugo",
	"nm": "Folgoso do Courel"
}, {
	"id": "Lugo",
	"nm": "Fonsagrada, A"
}, {
	"id": "Lugo",
	"nm": "Foz"
}, {
	"id": "Lugo",
	"nm": "Friol"
}, {
	"id": "Lugo",
	"nm": "Guitiriz"
}, {
	"id": "Lugo",
	"nm": "Guntín"
}, {
	"id": "Lugo",
	"nm": "Incio, O"
}, {
	"id": "Lugo",
	"nm": "Láncara"
}, {
	"id": "Lugo",
	"nm": "Lourenzá"
}, {
	"id": "Lugo",
	"nm": "Lugo"
}, {
	"id": "Lugo",
	"nm": "Meira"
}, {
	"id": "Lugo",
	"nm": "Mondoñedo"
}, {
	"id": "Lugo",
	"nm": "Monforte de Lemos"
}, {
	"id": "Lugo",
	"nm": "Monterroso"
}, {
	"id": "Lugo",
	"nm": "Muras"
}, {
	"id": "Lugo",
	"nm": "Navia de Suarna"
}, {
	"id": "Lugo",
	"nm": "Negueira de Muñiz"
}, {
	"id": "Lugo",
	"nm": "Nogais, As"
}, {
	"id": "Lugo",
	"nm": "Ourol"
}, {
	"id": "Lugo",
	"nm": "Outeiro de Rei"
}, {
	"id": "Lugo",
	"nm": "Palas de Rei"
}, {
	"id": "Lugo",
	"nm": "Pantón"
}, {
	"id": "Lugo",
	"nm": "Paradela"
}, {
	"id": "Lugo",
	"nm": "Páramo, O"
}, {
	"id": "Lugo",
	"nm": "Pastoriza, A"
}, {
	"id": "Lugo",
	"nm": "Pedrafita do Cebreiro"
}, {
	"id": "Lugo",
	"nm": "Pobra do Brollón, A"
}, {
	"id": "Lugo",
	"nm": "Pol"
}, {
	"id": "Lugo",
	"nm": "Pontenova, A"
}, {
	"id": "Lugo",
	"nm": "Portomarín"
}, {
	"id": "Lugo",
	"nm": "Quiroga"
}, {
	"id": "Lugo",
	"nm": "Rábade"
}, {
	"id": "Lugo",
	"nm": "Ribadeo"
}, {
	"id": "Lugo",
	"nm": "Ribas de Sil"
}, {
	"id": "Lugo",
	"nm": "Ribeira de Piquín"
}, {
	"id": "Lugo",
	"nm": "Riotorto"
}, {
	"id": "Lugo",
	"nm": "Samos"
}, {
	"id": "Lugo",
	"nm": "Sarria"
}, {
	"id": "Lugo",
	"nm": "Saviñao, O"
}, {
	"id": "Lugo",
	"nm": "Sober"
}, {
	"id": "Lugo",
	"nm": "Taboada"
}, {
	"id": "Lugo",
	"nm": "Trabada"
}, {
	"id": "Lugo",
	"nm": "Triacastela"
}, {
	"id": "Lugo",
	"nm": "Valadouro, O"
}, {
	"id": "Lugo",
	"nm": "Vicedo, O"
}, {
	"id": "Lugo",
	"nm": "Vilalba"
}, {
	"id": "Lugo",
	"nm": "Viveiro"
}, {
	"id": "Lugo",
	"nm": "Xermade"
}, {
	"id": "Lugo",
	"nm": "Xove"
}, {
	"id": "Madrid",
	"nm": "Acebeda, La"
}, {
	"id": "Madrid",
	"nm": "Ajalvir"
}, {
	"id": "Madrid",
	"nm": "Alameda del Valle"
}, {
	"id": "Madrid",
	"nm": "Álamo, El"
}, {
	"id": "Madrid",
	"nm": "Alcalá de Henares"
}, {
	"id": "Madrid",
	"nm": "Alcobendas"
}, {
	"id": "Madrid",
	"nm": "Alcorcón"
}, {
	"id": "Madrid",
	"nm": "Aldea del Fresno"
}, {
	"id": "Madrid",
	"nm": "Algete"
}, {
	"id": "Madrid",
	"nm": "Alpedrete"
}, {
	"id": "Madrid",
	"nm": "Ambite"
}, {
	"id": "Madrid",
	"nm": "Anchuelo"
}, {
	"id": "Madrid",
	"nm": "Aranjuez"
}, {
	"id": "Madrid",
	"nm": "Arganda del Rey"
}, {
	"id": "Madrid",
	"nm": "Arroyomolinos"
}, {
	"id": "Madrid",
	"nm": "Atazar, El"
}, {
	"id": "Madrid",
	"nm": "Batres"
}, {
	"id": "Madrid",
	"nm": "Becerril de la Sierra"
}, {
	"id": "Madrid",
	"nm": "Belmonte de Tajo"
}, {
	"id": "Madrid",
	"nm": "Berrueco, El"
}, {
	"id": "Madrid",
	"nm": "Berzosa del Lozoya"
}, {
	"id": "Madrid",
	"nm": "Boadilla del Monte"
}, {
	"id": "Madrid",
	"nm": "Boalo, El"
}, {
	"id": "Madrid",
	"nm": "Braojos"
}, {
	"id": "Madrid",
	"nm": "Brea de Tajo"
}, {
	"id": "Madrid",
	"nm": "Brunete"
}, {
	"id": "Madrid",
	"nm": "Buitrago del Lozoya"
}, {
	"id": "Madrid",
	"nm": "Bustarviejo"
}, {
	"id": "Madrid",
	"nm": "Cabanillas de la Sierra"
}, {
	"id": "Madrid",
	"nm": "Cabrera, La"
}, {
	"id": "Madrid",
	"nm": "Cadalso de los Vidrios"
}, {
	"id": "Madrid",
	"nm": "Camarma de Esteruelas"
}, {
	"id": "Madrid",
	"nm": "Campo Real"
}, {
	"id": "Madrid",
	"nm": "Canencia"
}, {
	"id": "Madrid",
	"nm": "Carabaña"
}, {
	"id": "Madrid",
	"nm": "Casarrubuelos"
}, {
	"id": "Madrid",
	"nm": "Cenicientos"
}, {
	"id": "Madrid",
	"nm": "Cercedilla"
}, {
	"id": "Madrid",
	"nm": "Cervera de Buitrago"
}, {
	"id": "Madrid",
	"nm": "Chapinería"
}, {
	"id": "Madrid",
	"nm": "Chinchón"
}, {
	"id": "Madrid",
	"nm": "Ciempozuelos"
}, {
	"id": "Madrid",
	"nm": "Cobeña"
}, {
	"id": "Madrid",
	"nm": "Collado Mediano"
}, {
	"id": "Madrid",
	"nm": "Collado Villalba"
}, {
	"id": "Madrid",
	"nm": "Colmenar de Oreja"
}, {
	"id": "Madrid",
	"nm": "Colmenar del Arroyo"
}, {
	"id": "Madrid",
	"nm": "Colmenar Viejo"
}, {
	"id": "Madrid",
	"nm": "Colmenarejo"
}, {
	"id": "Madrid",
	"nm": "Corpa"
}, {
	"id": "Madrid",
	"nm": "Coslada"
}, {
	"id": "Madrid",
	"nm": "Cubas de la Sagra"
}, {
	"id": "Madrid",
	"nm": "Daganzo de Arriba"
}, {
	"id": "Madrid",
	"nm": "Escorial, El"
}, {
	"id": "Madrid",
	"nm": "Estremera"
}, {
	"id": "Madrid",
	"nm": "Fresnedillas de la Oliva"
}, {
	"id": "Madrid",
	"nm": "Fresno de Torote"
}, {
	"id": "Madrid",
	"nm": "Fuenlabrada"
}, {
	"id": "Madrid",
	"nm": "Fuente el Saz de Jarama"
}, {
	"id": "Madrid",
	"nm": "Fuentidueña de Tajo"
}, {
	"id": "Madrid",
	"nm": "Galapagar"
}, {
	"id": "Madrid",
	"nm": "Garganta de los Montes"
}, {
	"id": "Madrid",
	"nm": "Gargantilla del Lozoya y Pinilla de Buitrago"
}, {
	"id": "Madrid",
	"nm": "Gascones"
}, {
	"id": "Madrid",
	"nm": "Getafe"
}, {
	"id": "Madrid",
	"nm": "Griñón"
}, {
	"id": "Madrid",
	"nm": "Guadalix de la Sierra"
}, {
	"id": "Madrid",
	"nm": "Guadarrama"
}, {
	"id": "Madrid",
	"nm": "Hiruela, La"
}, {
	"id": "Madrid",
	"nm": "Horcajo de la Sierra-Aoslos"
}, {
	"id": "Madrid",
	"nm": "Horcajuelo de la Sierra"
}, {
	"id": "Madrid",
	"nm": "Hoyo de Manzanares"
}, {
	"id": "Madrid",
	"nm": "Humanes de Madrid"
}, {
	"id": "Madrid",
	"nm": "Leganés"
}, {
	"id": "Madrid",
	"nm": "Loeches"
}, {
	"id": "Madrid",
	"nm": "Lozoya"
}, {
	"id": "Madrid",
	"nm": "Lozoyuela-Navas-Sieteiglesias"
}, {
	"id": "Madrid",
	"nm": "Madarcos"
}, {
	"id": "Madrid",
	"nm": "Madrid"
}, {
	"id": "Madrid",
	"nm": "Majadahonda"
}, {
	"id": "Madrid",
	"nm": "Manzanares el Real"
}, {
	"id": "Madrid",
	"nm": "Meco"
}, {
	"id": "Madrid",
	"nm": "Mejorada del Campo"
}, {
	"id": "Madrid",
	"nm": "Miraflores de la Sierra"
}, {
	"id": "Madrid",
	"nm": "Molar, El"
}, {
	"id": "Madrid",
	"nm": "Molinos, Los"
}, {
	"id": "Madrid",
	"nm": "Montejo de la Sierra"
}, {
	"id": "Madrid",
	"nm": "Moraleja de Enmedio"
}, {
	"id": "Madrid",
	"nm": "Moralzarzal"
}, {
	"id": "Madrid",
	"nm": "Morata de Tajuña"
}, {
	"id": "Madrid",
	"nm": "Móstoles"
}, {
	"id": "Madrid",
	"nm": "Navacerrada"
}, {
	"id": "Madrid",
	"nm": "Navalafuente"
}, {
	"id": "Madrid",
	"nm": "Navalagamella"
}, {
	"id": "Madrid",
	"nm": "Navalcarnero"
}, {
	"id": "Madrid",
	"nm": "Navarredonda y San Mamés"
}, {
	"id": "Madrid",
	"nm": "Navas del Rey"
}, {
	"id": "Madrid",
	"nm": "Nuevo Baztán"
}, {
	"id": "Madrid",
	"nm": "Olmeda de las Fuentes"
}, {
	"id": "Madrid",
	"nm": "Orusco de Tajuña"
}, {
	"id": "Madrid",
	"nm": "Paracuellos de Jarama"
}, {
	"id": "Madrid",
	"nm": "Parla"
}, {
	"id": "Madrid",
	"nm": "Patones"
}, {
	"id": "Madrid",
	"nm": "Pedrezuela"
}, {
	"id": "Madrid",
	"nm": "Pelayos de la Presa"
}, {
	"id": "Madrid",
	"nm": "Perales de Tajuña"
}, {
	"id": "Madrid",
	"nm": "Pezuela de las Torres"
}, {
	"id": "Madrid",
	"nm": "Pinilla del Valle"
}, {
	"id": "Madrid",
	"nm": "Pinto"
}, {
	"id": "Madrid",
	"nm": "Piñuécar-Gandullas"
}, {
	"id": "Madrid",
	"nm": "Pozuelo de Alarcón"
}, {
	"id": "Madrid",
	"nm": "Pozuelo del Rey"
}, {
	"id": "Madrid",
	"nm": "Prádena del Rincón"
}, {
	"id": "Madrid",
	"nm": "Puebla de la Sierra"
}, {
	"id": "Madrid",
	"nm": "Puentes Viejas"
}, {
	"id": "Madrid",
	"nm": "Quijorna"
}, {
	"id": "Madrid",
	"nm": "Rascafría"
}, {
	"id": "Madrid",
	"nm": "Redueña"
}, {
	"id": "Madrid",
	"nm": "Ribatejada"
}, {
	"id": "Madrid",
	"nm": "Rivas-Vaciamadrid"
}, {
	"id": "Madrid",
	"nm": "Robledillo de la Jara"
}, {
	"id": "Madrid",
	"nm": "Robledo de Chavela"
}, {
	"id": "Madrid",
	"nm": "Robregordo"
}, {
	"id": "Madrid",
	"nm": "Rozas de Madrid, Las"
}, {
	"id": "Madrid",
	"nm": "Rozas de Puerto Real"
}, {
	"id": "Madrid",
	"nm": "San Agustín del Guadalix"
}, {
	"id": "Madrid",
	"nm": "San Fernando de Henares"
}, {
	"id": "Madrid",
	"nm": "San Lorenzo de El Escorial"
}, {
	"id": "Madrid",
	"nm": "San Martín de la Vega"
}, {
	"id": "Madrid",
	"nm": "San Martín de Valdeiglesias"
}, {
	"id": "Madrid",
	"nm": "San Sebastián de los Reyes"
}, {
	"id": "Madrid",
	"nm": "Santa María de la Alameda"
}, {
	"id": "Madrid",
	"nm": "Santorcaz"
}, {
	"id": "Madrid",
	"nm": "Santos de la Humosa, Los"
}, {
	"id": "Madrid",
	"nm": "Serna del Monte, La"
}, {
	"id": "Madrid",
	"nm": "Serranillos del Valle"
}, {
	"id": "Madrid",
	"nm": "Sevilla la Nueva"
}, {
	"id": "Madrid",
	"nm": "Somosierra"
}, {
	"id": "Madrid",
	"nm": "Soto del Real"
}, {
	"id": "Madrid",
	"nm": "Talamanca de Jarama"
}, {
	"id": "Madrid",
	"nm": "Tielmes"
}, {
	"id": "Madrid",
	"nm": "Titulcia"
}, {
	"id": "Madrid",
	"nm": "Torrejón de Ardoz"
}, {
	"id": "Madrid",
	"nm": "Torrejón de la Calzada"
}, {
	"id": "Madrid",
	"nm": "Torrejón de Velasco"
}, {
	"id": "Madrid",
	"nm": "Torrelaguna"
}, {
	"id": "Madrid",
	"nm": "Torrelodones"
}, {
	"id": "Madrid",
	"nm": "Torremocha de Jarama"
}, {
	"id": "Madrid",
	"nm": "Torres de la Alameda"
}, {
	"id": "Madrid",
	"nm": "Tres Cantos"
}, {
	"id": "Madrid",
	"nm": "Valdaracete"
}, {
	"id": "Madrid",
	"nm": "Valdeavero"
}, {
	"id": "Madrid",
	"nm": "Valdelaguna"
}, {
	"id": "Madrid",
	"nm": "Valdemanco"
}, {
	"id": "Madrid",
	"nm": "Valdemaqueda"
}, {
	"id": "Madrid",
	"nm": "Valdemorillo"
}, {
	"id": "Madrid",
	"nm": "Valdemoro"
}, {
	"id": "Madrid",
	"nm": "Valdeolmos-Alalpardo"
}, {
	"id": "Madrid",
	"nm": "Valdepiélagos"
}, {
	"id": "Madrid",
	"nm": "Valdetorres de Jarama"
}, {
	"id": "Madrid",
	"nm": "Valdilecha"
}, {
	"id": "Madrid",
	"nm": "Valverde de Alcalá"
}, {
	"id": "Madrid",
	"nm": "Velilla de San Antonio"
}, {
	"id": "Madrid",
	"nm": "Vellón, El"
}, {
	"id": "Madrid",
	"nm": "Venturada"
}, {
	"id": "Madrid",
	"nm": "Villa del Prado"
}, {
	"id": "Madrid",
	"nm": "Villaconejos"
}, {
	"id": "Madrid",
	"nm": "Villalbilla"
}, {
	"id": "Madrid",
	"nm": "Villamanrique de Tajo"
}, {
	"id": "Madrid",
	"nm": "Villamanta"
}, {
	"id": "Madrid",
	"nm": "Villamantilla"
}, {
	"id": "Madrid",
	"nm": "Villanueva de la Cañada"
}, {
	"id": "Madrid",
	"nm": "Villanueva de Perales"
}, {
	"id": "Madrid",
	"nm": "Villanueva del Pardillo"
}, {
	"id": "Madrid",
	"nm": "Villar del Olmo"
}, {
	"id": "Madrid",
	"nm": "Villarejo de Salvanés"
}, {
	"id": "Madrid",
	"nm": "Villaviciosa de Odón"
}, {
	"id": "Madrid",
	"nm": "Villavieja del Lozoya"
}, {
	"id": "Madrid",
	"nm": "Zarzalejo"
}, {
	"id": "Málaga",
	"nm": "Alameda"
}, {
	"id": "Málaga",
	"nm": "Alcaucín"
}, {
	"id": "Málaga",
	"nm": "Alfarnate"
}, {
	"id": "Málaga",
	"nm": "Alfarnatejo"
}, {
	"id": "Málaga",
	"nm": "Algarrobo"
}, {
	"id": "Málaga",
	"nm": "Algatocín"
}, {
	"id": "Málaga",
	"nm": "Alhaurín de la Torre"
}, {
	"id": "Málaga",
	"nm": "Alhaurín el Grande"
}, {
	"id": "Málaga",
	"nm": "Almáchar"
}, {
	"id": "Málaga",
	"nm": "Almargen"
}, {
	"id": "Málaga",
	"nm": "Almogía"
}, {
	"id": "Málaga",
	"nm": "Álora"
}, {
	"id": "Málaga",
	"nm": "Alozaina"
}, {
	"id": "Málaga",
	"nm": "Alpandeire"
}, {
	"id": "Málaga",
	"nm": "Antequera"
}, {
	"id": "Málaga",
	"nm": "Árchez"
}, {
	"id": "Málaga",
	"nm": "Archidona"
}, {
	"id": "Málaga",
	"nm": "Ardales"
}, {
	"id": "Málaga",
	"nm": "Arenas"
}, {
	"id": "Málaga",
	"nm": "Arriate"
}, {
	"id": "Málaga",
	"nm": "Atajate"
}, {
	"id": "Málaga",
	"nm": "Benadalid"
}, {
	"id": "Málaga",
	"nm": "Benahavís"
}, {
	"id": "Málaga",
	"nm": "Benalauría"
}, {
	"id": "Málaga",
	"nm": "Benalmádena"
}, {
	"id": "Málaga",
	"nm": "Benamargosa"
}, {
	"id": "Málaga",
	"nm": "Benamocarra"
}, {
	"id": "Málaga",
	"nm": "Benaoján"
}, {
	"id": "Málaga",
	"nm": "Benarrabá"
}, {
	"id": "Málaga",
	"nm": "Borge, El"
}, {
	"id": "Málaga",
	"nm": "Burgo, El"
}, {
	"id": "Málaga",
	"nm": "Campillos"
}, {
	"id": "Málaga",
	"nm": "Canillas de Aceituno"
}, {
	"id": "Málaga",
	"nm": "Canillas de Albaida"
}, {
	"id": "Málaga",
	"nm": "Cañete la Real"
}, {
	"id": "Málaga",
	"nm": "Carratraca"
}, {
	"id": "Málaga",
	"nm": "Cartajima"
}, {
	"id": "Málaga",
	"nm": "Cártama"
}, {
	"id": "Málaga",
	"nm": "Casabermeja"
}, {
	"id": "Málaga",
	"nm": "Casarabonela"
}, {
	"id": "Málaga",
	"nm": "Casares"
}, {
	"id": "Málaga",
	"nm": "Coín"
}, {
	"id": "Málaga",
	"nm": "Colmenar"
}, {
	"id": "Málaga",
	"nm": "Comares"
}, {
	"id": "Málaga",
	"nm": "Cómpeta"
}, {
	"id": "Málaga",
	"nm": "Cortes de la Frontera"
}, {
	"id": "Málaga",
	"nm": "Cuevas Bajas"
}, {
	"id": "Málaga",
	"nm": "Cuevas de San Marcos"
}, {
	"id": "Málaga",
	"nm": "Cuevas del Becerro"
}, {
	"id": "Málaga",
	"nm": "Cútar"
}, {
	"id": "Málaga",
	"nm": "Estepona"
}, {
	"id": "Málaga",
	"nm": "Faraján"
}, {
	"id": "Málaga",
	"nm": "Frigiliana"
}, {
	"id": "Málaga",
	"nm": "Fuengirola"
}, {
	"id": "Málaga",
	"nm": "Fuente de Piedra"
}, {
	"id": "Málaga",
	"nm": "Gaucín"
}, {
	"id": "Málaga",
	"nm": "Genalguacil"
}, {
	"id": "Málaga",
	"nm": "Guaro"
}, {
	"id": "Málaga",
	"nm": "Humilladero"
}, {
	"id": "Málaga",
	"nm": "Igualeja"
}, {
	"id": "Málaga",
	"nm": "Istán"
}, {
	"id": "Málaga",
	"nm": "Iznate"
}, {
	"id": "Málaga",
	"nm": "Jimera de Líbar"
}, {
	"id": "Málaga",
	"nm": "Jubrique"
}, {
	"id": "Málaga",
	"nm": "Júzcar"
}, {
	"id": "Málaga",
	"nm": "Macharaviaya"
}, {
	"id": "Málaga",
	"nm": "Málaga"
}, {
	"id": "Málaga",
	"nm": "Manilva"
}, {
	"id": "Málaga",
	"nm": "Marbella"
}, {
	"id": "Málaga",
	"nm": "Mijas"
}, {
	"id": "Málaga",
	"nm": "Moclinejo"
}, {
	"id": "Málaga",
	"nm": "Mollina"
}, {
	"id": "Málaga",
	"nm": "Monda"
}, {
	"id": "Málaga",
	"nm": "Montecorto"
}, {
	"id": "Málaga",
	"nm": "Montejaque"
}, {
	"id": "Málaga",
	"nm": "Nerja"
}, {
	"id": "Málaga",
	"nm": "Ojén"
}, {
	"id": "Málaga",
	"nm": "Parauta"
}, {
	"id": "Málaga",
	"nm": "Periana"
}, {
	"id": "Málaga",
	"nm": "Pizarra"
}, {
	"id": "Málaga",
	"nm": "Pujerra"
}, {
	"id": "Málaga",
	"nm": "Rincón de la Victoria"
}, {
	"id": "Málaga",
	"nm": "Riogordo"
}, {
	"id": "Málaga",
	"nm": "Ronda"
}, {
	"id": "Málaga",
	"nm": "Salares"
}, {
	"id": "Málaga",
	"nm": "Sayalonga"
}, {
	"id": "Málaga",
	"nm": "Sedella"
}, {
	"id": "Málaga",
	"nm": "Serrato"
}, {
	"id": "Málaga",
	"nm": "Sierra de Yeguas"
}, {
	"id": "Málaga",
	"nm": "Teba"
}, {
	"id": "Málaga",
	"nm": "Tolox"
}, {
	"id": "Málaga",
	"nm": "Torremolinos"
}, {
	"id": "Málaga",
	"nm": "Torrox"
}, {
	"id": "Málaga",
	"nm": "Totalán"
}, {
	"id": "Málaga",
	"nm": "Valle de Abdalajís"
}, {
	"id": "Málaga",
	"nm": "Vélez-Málaga"
}, {
	"id": "Málaga",
	"nm": "Villanueva de Algaidas"
}, {
	"id": "Málaga",
	"nm": "Villanueva de la Concepción"
}, {
	"id": "Málaga",
	"nm": "Villanueva de Tapia"
}, {
	"id": "Málaga",
	"nm": "Villanueva del Rosario"
}, {
	"id": "Málaga",
	"nm": "Villanueva del Trabuco"
}, {
	"id": "Málaga",
	"nm": "Viñuela"
}, {
	"id": "Málaga",
	"nm": "Yunquera"
}, {
	"id": "Murcia",
	"nm": "Abanilla"
}, {
	"id": "Murcia",
	"nm": "Abarán"
}, {
	"id": "Murcia",
	"nm": "Águilas"
}, {
	"id": "Murcia",
	"nm": "Albudeite"
}, {
	"id": "Murcia",
	"nm": "Alcantarilla"
}, {
	"id": "Murcia",
	"nm": "Alcázares, Los"
}, {
	"id": "Murcia",
	"nm": "Aledo"
}, {
	"id": "Murcia",
	"nm": "Alguazas"
}, {
	"id": "Murcia",
	"nm": "Alhama de Murcia"
}, {
	"id": "Murcia",
	"nm": "Archena"
}, {
	"id": "Murcia",
	"nm": "Beniel"
}, {
	"id": "Murcia",
	"nm": "Blanca"
}, {
	"id": "Murcia",
	"nm": "Bullas"
}, {
	"id": "Murcia",
	"nm": "Calasparra"
}, {
	"id": "Murcia",
	"nm": "Campos del Río"
}, {
	"id": "Murcia",
	"nm": "Caravaca de la Cruz"
}, {
	"id": "Murcia",
	"nm": "Cartagena"
}, {
	"id": "Murcia",
	"nm": "Cehegín"
}, {
	"id": "Murcia",
	"nm": "Ceutí"
}, {
	"id": "Murcia",
	"nm": "Cieza"
}, {
	"id": "Murcia",
	"nm": "Fortuna"
}, {
	"id": "Murcia",
	"nm": "Fuente Álamo de Murcia"
}, {
	"id": "Murcia",
	"nm": "Jumilla"
}, {
	"id": "Murcia",
	"nm": "Librilla"
}, {
	"id": "Murcia",
	"nm": "Lorca"
}, {
	"id": "Murcia",
	"nm": "Lorquí"
}, {
	"id": "Murcia",
	"nm": "Mazarrón"
}, {
	"id": "Murcia",
	"nm": "Molina de Segura"
}, {
	"id": "Murcia",
	"nm": "Moratalla"
}, {
	"id": "Murcia",
	"nm": "Mula"
}, {
	"id": "Murcia",
	"nm": "Murcia"
}, {
	"id": "Murcia",
	"nm": "Ojós"
}, {
	"id": "Murcia",
	"nm": "Pliego"
}, {
	"id": "Murcia",
	"nm": "Puerto Lumbreras"
}, {
	"id": "Murcia",
	"nm": "Ricote"
}, {
	"id": "Murcia",
	"nm": "San Javier"
}, {
	"id": "Murcia",
	"nm": "San Pedro del Pinatar"
}, {
	"id": "Murcia",
	"nm": "Santomera"
}, {
	"id": "Murcia",
	"nm": "Torre-Pacheco"
}, {
	"id": "Murcia",
	"nm": "Torres de Cotillas, Las"
}, {
	"id": "Murcia",
	"nm": "Totana"
}, {
	"id": "Murcia",
	"nm": "Ulea"
}, {
	"id": "Murcia",
	"nm": "Unión, La"
}, {
	"id": "Murcia",
	"nm": "Villanueva del Río Segura"
}, {
	"id": "Murcia",
	"nm": "Yecla"
}, {
	"id": "Navarra",
	"nm": "Abáigar"
}, {
	"id": "Navarra",
	"nm": "Abárzuza/Abartzuza"
}, {
	"id": "Navarra",
	"nm": "Abaurregaina/Abaurrea Alta"
}, {
	"id": "Navarra",
	"nm": "Abaurrepea/Abaurrea Baja"
}, {
	"id": "Navarra",
	"nm": "Aberin"
}, {
	"id": "Navarra",
	"nm": "Ablitas"
}, {
	"id": "Navarra",
	"nm": "Adiós"
}, {
	"id": "Navarra",
	"nm": "Aguilar de Codés"
}, {
	"id": "Navarra",
	"nm": "Aibar/Oibar"
}, {
	"id": "Navarra",
	"nm": "Allín/Allin"
}, {
	"id": "Navarra",
	"nm": "Allo"
}, {
	"id": "Navarra",
	"nm": "Altsasu/Alsasua"
}, {
	"id": "Navarra",
	"nm": "Améscoa Baja"
}, {
	"id": "Navarra",
	"nm": "Ancín/Antzin"
}, {
	"id": "Navarra",
	"nm": "Andosilla"
}, {
	"id": "Navarra",
	"nm": "Ansoáin/Antsoain"
}, {
	"id": "Navarra",
	"nm": "Anue"
}, {
	"id": "Navarra",
	"nm": "Añorbe"
}, {
	"id": "Navarra",
	"nm": "Aoiz/Agoitz"
}, {
	"id": "Navarra",
	"nm": "Araitz"
}, {
	"id": "Navarra",
	"nm": "Arakil"
}, {
	"id": "Navarra",
	"nm": "Aranarache/Aranaratxe"
}, {
	"id": "Navarra",
	"nm": "Aranguren"
}, {
	"id": "Navarra",
	"nm": "Arano"
}, {
	"id": "Navarra",
	"nm": "Arantza"
}, {
	"id": "Navarra",
	"nm": "Aras"
}, {
	"id": "Navarra",
	"nm": "Arbizu"
}, {
	"id": "Navarra",
	"nm": "Arce/Artzi"
}, {
	"id": "Navarra",
	"nm": "Arcos, Los"
}, {
	"id": "Navarra",
	"nm": "Arellano"
}, {
	"id": "Navarra",
	"nm": "Areso"
}, {
	"id": "Navarra",
	"nm": "Arguedas"
}, {
	"id": "Navarra",
	"nm": "Aria"
}, {
	"id": "Navarra",
	"nm": "Aribe"
}, {
	"id": "Navarra",
	"nm": "Armañanzas"
}, {
	"id": "Navarra",
	"nm": "Arróniz"
}, {
	"id": "Navarra",
	"nm": "Arruazu"
}, {
	"id": "Navarra",
	"nm": "Artajona"
}, {
	"id": "Navarra",
	"nm": "Artazu"
}, {
	"id": "Navarra",
	"nm": "Atez/Atetz"
}, {
	"id": "Navarra",
	"nm": "Auritz/Burguete"
}, {
	"id": "Navarra",
	"nm": "Ayegui/Aiegi"
}, {
	"id": "Navarra",
	"nm": "Azagra"
}, {
	"id": "Navarra",
	"nm": "Azuelo"
}, {
	"id": "Navarra",
	"nm": "Bakaiku"
}, {
	"id": "Navarra",
	"nm": "Barañain"
}, {
	"id": "Navarra",
	"nm": "Barásoain"
}, {
	"id": "Navarra",
	"nm": "Barbarin"
}, {
	"id": "Navarra",
	"nm": "Bargota"
}, {
	"id": "Navarra",
	"nm": "Barillas"
}, {
	"id": "Navarra",
	"nm": "Basaburua"
}, {
	"id": "Navarra",
	"nm": "Baztan"
}, {
	"id": "Navarra",
	"nm": "Beintza-Labaien"
}, {
	"id": "Navarra",
	"nm": "Beire"
}, {
	"id": "Navarra",
	"nm": "Belascoáin"
}, {
	"id": "Navarra",
	"nm": "Bera"
}, {
	"id": "Navarra",
	"nm": "Berbinzana"
}, {
	"id": "Navarra",
	"nm": "Beriáin"
}, {
	"id": "Navarra",
	"nm": "Berrioplano/Berriobeiti"
}, {
	"id": "Navarra",
	"nm": "Berriozar"
}, {
	"id": "Navarra",
	"nm": "Bertizarana"
}, {
	"id": "Navarra",
	"nm": "Betelu"
}, {
	"id": "Navarra",
	"nm": "Bidaurreta"
}, {
	"id": "Navarra",
	"nm": "Biurrun-Olcoz"
}, {
	"id": "Navarra",
	"nm": "Buñuel"
}, {
	"id": "Navarra",
	"nm": "Burgui/Burgi"
}, {
	"id": "Navarra",
	"nm": "Burlada/Burlata"
}, {
	"id": "Navarra",
	"nm": "Busto, El"
}, {
	"id": "Navarra",
	"nm": "Cabanillas"
}, {
	"id": "Navarra",
	"nm": "Cabredo"
}, {
	"id": "Navarra",
	"nm": "Cadreita"
}, {
	"id": "Navarra",
	"nm": "Caparroso"
}, {
	"id": "Navarra",
	"nm": "Cárcar"
}, {
	"id": "Navarra",
	"nm": "Carcastillo"
}, {
	"id": "Navarra",
	"nm": "Cascante"
}, {
	"id": "Navarra",
	"nm": "Cáseda"
}, {
	"id": "Navarra",
	"nm": "Castejón"
}, {
	"id": "Navarra",
	"nm": "Castillonuevo"
}, {
	"id": "Navarra",
	"nm": "Cendea de Olza/Oltza Zendea"
}, {
	"id": "Navarra",
	"nm": "Cintruénigo"
}, {
	"id": "Navarra",
	"nm": "Cirauqui/Zirauki"
}, {
	"id": "Navarra",
	"nm": "Ciriza/Ziritza"
}, {
	"id": "Navarra",
	"nm": "Cizur"
}, {
	"id": "Navarra",
	"nm": "Corella"
}, {
	"id": "Navarra",
	"nm": "Cortes"
}, {
	"id": "Navarra",
	"nm": "Desojo"
}, {
	"id": "Navarra",
	"nm": "Dicastillo"
}, {
	"id": "Navarra",
	"nm": "Donamaria"
}, {
	"id": "Navarra",
	"nm": "Doneztebe/Santesteban"
}, {
	"id": "Navarra",
	"nm": "Echarri"
}, {
	"id": "Navarra",
	"nm": "Elgorriaga"
}, {
	"id": "Navarra",
	"nm": "Enériz/Eneritz"
}, {
	"id": "Navarra",
	"nm": "Eratsun"
}, {
	"id": "Navarra",
	"nm": "Ergoiena"
}, {
	"id": "Navarra",
	"nm": "Erro"
}, {
	"id": "Navarra",
	"nm": "Eslava"
}, {
	"id": "Navarra",
	"nm": "Esparza de Salazar/Espartza Zaraitzu"
}, {
	"id": "Navarra",
	"nm": "Espronceda"
}, {
	"id": "Navarra",
	"nm": "Estella-Lizarra"
}, {
	"id": "Navarra",
	"nm": "Esteribar"
}, {
	"id": "Navarra",
	"nm": "Etayo"
}, {
	"id": "Navarra",
	"nm": "Etxalar"
}, {
	"id": "Navarra",
	"nm": "Etxarri Aranatz"
}, {
	"id": "Navarra",
	"nm": "Etxauri"
}, {
	"id": "Navarra",
	"nm": "Eulate"
}, {
	"id": "Navarra",
	"nm": "Ezcabarte"
}, {
	"id": "Navarra",
	"nm": "Ezcároz/Ezkaroze"
}, {
	"id": "Navarra",
	"nm": "Ezkurra"
}, {
	"id": "Navarra",
	"nm": "Ezprogui"
}, {
	"id": "Navarra",
	"nm": "Falces"
}, {
	"id": "Navarra",
	"nm": "Fitero"
}, {
	"id": "Navarra",
	"nm": "Fontellas"
}, {
	"id": "Navarra",
	"nm": "Funes"
}, {
	"id": "Navarra",
	"nm": "Fustiñana"
}, {
	"id": "Navarra",
	"nm": "Galar"
}, {
	"id": "Navarra",
	"nm": "Gallipienzo/Galipentzu"
}, {
	"id": "Navarra",
	"nm": "Gallués/Galoze"
}, {
	"id": "Navarra",
	"nm": "Garaioa"
}, {
	"id": "Navarra",
	"nm": "Garde"
}, {
	"id": "Navarra",
	"nm": "Garínoain"
}, {
	"id": "Navarra",
	"nm": "Garralda"
}, {
	"id": "Navarra",
	"nm": "Genevilla"
}, {
	"id": "Navarra",
	"nm": "Goizueta"
}, {
	"id": "Navarra",
	"nm": "Goñi"
}, {
	"id": "Navarra",
	"nm": "Güesa/Gorza"
}, {
	"id": "Navarra",
	"nm": "Guesálaz/Gesalatz"
}, {
	"id": "Navarra",
	"nm": "Guirguillano"
}, {
	"id": "Navarra",
	"nm": "Hiriberri/Villanueva de Aezkoa"
}, {
	"id": "Navarra",
	"nm": "Huarte/Uharte"
}, {
	"id": "Navarra",
	"nm": "Ibargoiti"
}, {
	"id": "Navarra",
	"nm": "Igantzi"
}, {
	"id": "Navarra",
	"nm": "Igúzquiza"
}, {
	"id": "Navarra",
	"nm": "Imotz"
}, {
	"id": "Navarra",
	"nm": "Irañeta"
}, {
	"id": "Navarra",
	"nm": "Irurtzun"
}, {
	"id": "Navarra",
	"nm": "Isaba/Izaba"
}, {
	"id": "Navarra",
	"nm": "Ituren"
}, {
	"id": "Navarra",
	"nm": "Iturmendi"
}, {
	"id": "Navarra",
	"nm": "Iza/Itza"
}, {
	"id": "Navarra",
	"nm": "Izagaondoa"
}, {
	"id": "Navarra",
	"nm": "Izalzu/Itzaltzu"
}, {
	"id": "Navarra",
	"nm": "Jaurrieta"
}, {
	"id": "Navarra",
	"nm": "Javier"
}, {
	"id": "Navarra",
	"nm": "Juslapeña"
}, {
	"id": "Navarra",
	"nm": "Lakuntza"
}, {
	"id": "Navarra",
	"nm": "Lana"
}, {
	"id": "Navarra",
	"nm": "Lantz"
}, {
	"id": "Navarra",
	"nm": "Lapoblación"
}, {
	"id": "Navarra",
	"nm": "Larraga"
}, {
	"id": "Navarra",
	"nm": "Larraona"
}, {
	"id": "Navarra",
	"nm": "Larraun"
}, {
	"id": "Navarra",
	"nm": "Lazagurría"
}, {
	"id": "Navarra",
	"nm": "Leache/Leatxe"
}, {
	"id": "Navarra",
	"nm": "Legarda"
}, {
	"id": "Navarra",
	"nm": "Legaria"
}, {
	"id": "Navarra",
	"nm": "Leitza"
}, {
	"id": "Navarra",
	"nm": "Lekunberri"
}, {
	"id": "Navarra",
	"nm": "Leoz/Leotz"
}, {
	"id": "Navarra",
	"nm": "Lerga"
}, {
	"id": "Navarra",
	"nm": "Lerín"
}, {
	"id": "Navarra",
	"nm": "Lesaka"
}, {
	"id": "Navarra",
	"nm": "Lezáun"
}, {
	"id": "Navarra",
	"nm": "Liédena"
}, {
	"id": "Navarra",
	"nm": "Lizoáin-Arriasgoiti"
}, {
	"id": "Navarra",
	"nm": "Lodosa"
}, {
	"id": "Navarra",
	"nm": "Lónguida/Longida"
}, {
	"id": "Navarra",
	"nm": "Lumbier"
}, {
	"id": "Navarra",
	"nm": "Luquin"
}, {
	"id": "Navarra",
	"nm": "Luzaide/Valcarlos"
}, {
	"id": "Navarra",
	"nm": "Mañeru"
}, {
	"id": "Navarra",
	"nm": "Marañón"
}, {
	"id": "Navarra",
	"nm": "Marcilla"
}, {
	"id": "Navarra",
	"nm": "Mélida"
}, {
	"id": "Navarra",
	"nm": "Mendavia"
}, {
	"id": "Navarra",
	"nm": "Mendaza"
}, {
	"id": "Navarra",
	"nm": "Mendigorría"
}, {
	"id": "Navarra",
	"nm": "Metauten"
}, {
	"id": "Navarra",
	"nm": "Milagro"
}, {
	"id": "Navarra",
	"nm": "Mirafuentes"
}, {
	"id": "Navarra",
	"nm": "Miranda de Arga"
}, {
	"id": "Navarra",
	"nm": "Monreal/Elo"
}, {
	"id": "Navarra",
	"nm": "Monteagudo"
}, {
	"id": "Navarra",
	"nm": "Morentin"
}, {
	"id": "Navarra",
	"nm": "Mues"
}, {
	"id": "Navarra",
	"nm": "Murchante"
}, {
	"id": "Navarra",
	"nm": "Murieta"
}, {
	"id": "Navarra",
	"nm": "Murillo el Cuende"
}, {
	"id": "Navarra",
	"nm": "Murillo el Fruto"
}, {
	"id": "Navarra",
	"nm": "Muruzábal"
}, {
	"id": "Navarra",
	"nm": "Navascués/Nabaskoze"
}, {
	"id": "Navarra",
	"nm": "Nazar"
}, {
	"id": "Navarra",
	"nm": "Noáin (Valle de Elorz)/Noain (Elortzibar)"
}, {
	"id": "Navarra",
	"nm": "Obanos"
}, {
	"id": "Navarra",
	"nm": "Ochagavía/Otsagabia"
}, {
	"id": "Navarra",
	"nm": "Oco"
}, {
	"id": "Navarra",
	"nm": "Odieta"
}, {
	"id": "Navarra",
	"nm": "Oiz"
}, {
	"id": "Navarra",
	"nm": "Olaibar"
}, {
	"id": "Navarra",
	"nm": "Olazti/Olazagutía"
}, {
	"id": "Navarra",
	"nm": "Olejua"
}, {
	"id": "Navarra",
	"nm": "Olite/Erriberri"
}, {
	"id": "Navarra",
	"nm": "Ollo"
}, {
	"id": "Navarra",
	"nm": "Olóriz/Oloritz"
}, {
	"id": "Navarra",
	"nm": "Orbaizeta"
}, {
	"id": "Navarra",
	"nm": "Orbara"
}, {
	"id": "Navarra",
	"nm": "Orísoain"
}, {
	"id": "Navarra",
	"nm": "Orkoien"
}, {
	"id": "Navarra",
	"nm": "Oronz/Orontze"
}, {
	"id": "Navarra",
	"nm": "Oroz-Betelu/Orotz-Betelu"
}, {
	"id": "Navarra",
	"nm": "Orreaga/Roncesvalles"
}, {
	"id": "Navarra",
	"nm": "Oteiza"
}, {
	"id": "Navarra",
	"nm": "Pamplona/Iruña"
}, {
	"id": "Navarra",
	"nm": "Peralta/Azkoien"
}, {
	"id": "Navarra",
	"nm": "Petilla de Aragón"
}, {
	"id": "Navarra",
	"nm": "Piedramillera"
}, {
	"id": "Navarra",
	"nm": "Pitillas"
}, {
	"id": "Navarra",
	"nm": "Puente la Reina/Gares"
}, {
	"id": "Navarra",
	"nm": "Pueyo"
}, {
	"id": "Navarra",
	"nm": "Ribaforada"
}, {
	"id": "Navarra",
	"nm": "Romanzado"
}, {
	"id": "Navarra",
	"nm": "Roncal/Erronkari"
}, {
	"id": "Navarra",
	"nm": "Sada"
}, {
	"id": "Navarra",
	"nm": "Saldías"
}, {
	"id": "Navarra",
	"nm": "Salinas de Oro/Jaitz"
}, {
	"id": "Navarra",
	"nm": "San Adrián"
}, {
	"id": "Navarra",
	"nm": "San Martín de Unx"
}, {
	"id": "Navarra",
	"nm": "Sangüesa/Zangoza"
}, {
	"id": "Navarra",
	"nm": "Sansol"
}, {
	"id": "Navarra",
	"nm": "Santacara"
}, {
	"id": "Navarra",
	"nm": "Sarriés/Sartze"
}, {
	"id": "Navarra",
	"nm": "Sartaguda"
}, {
	"id": "Navarra",
	"nm": "Sesma"
}, {
	"id": "Navarra",
	"nm": "Sorlada"
}, {
	"id": "Navarra",
	"nm": "Sunbilla"
}, {
	"id": "Navarra",
	"nm": "Tafalla"
}, {
	"id": "Navarra",
	"nm": "Tiebas-Muruarte de Reta"
}, {
	"id": "Navarra",
	"nm": "Tirapu"
}, {
	"id": "Navarra",
	"nm": "Torralba del Río"
}, {
	"id": "Navarra",
	"nm": "Torres del Río"
}, {
	"id": "Navarra",
	"nm": "Tudela"
}, {
	"id": "Navarra",
	"nm": "Tulebras"
}, {
	"id": "Navarra",
	"nm": "Ucar"
}, {
	"id": "Navarra",
	"nm": "Uharte Arakil"
}, {
	"id": "Navarra",
	"nm": "Ujué"
}, {
	"id": "Navarra",
	"nm": "Ultzama"
}, {
	"id": "Navarra",
	"nm": "Unciti"
}, {
	"id": "Navarra",
	"nm": "Unzué/Untzue"
}, {
	"id": "Navarra",
	"nm": "Urdazubi/Urdax"
}, {
	"id": "Navarra",
	"nm": "Urdiain"
}, {
	"id": "Navarra",
	"nm": "Urraul Alto"
}, {
	"id": "Navarra",
	"nm": "Urraul Bajo"
}, {
	"id": "Navarra",
	"nm": "Urroz"
}, {
	"id": "Navarra",
	"nm": "Urroz-Villa"
}, {
	"id": "Navarra",
	"nm": "Urzainqui/Urzainki"
}, {
	"id": "Navarra",
	"nm": "Uterga"
}, {
	"id": "Navarra",
	"nm": "Uztárroz/Uztarroze"
}, {
	"id": "Navarra",
	"nm": "Valle de Egüés/Eguesibar"
}, {
	"id": "Navarra",
	"nm": "Valle de Yerri/Deierri"
}, {
	"id": "Navarra",
	"nm": "Valtierra"
}, {
	"id": "Navarra",
	"nm": "Viana"
}, {
	"id": "Navarra",
	"nm": "Vidángoz/Bidankoze"
}, {
	"id": "Navarra",
	"nm": "Villafranca"
}, {
	"id": "Navarra",
	"nm": "Villamayor de Monjardín"
}, {
	"id": "Navarra",
	"nm": "Villatuerta"
}, {
	"id": "Navarra",
	"nm": "Villava/Atarrabia"
}, {
	"id": "Navarra",
	"nm": "Yesa"
}, {
	"id": "Navarra",
	"nm": "Zabalza/Zabaltza"
}, {
	"id": "Navarra",
	"nm": "Ziordia"
}, {
	"id": "Navarra",
	"nm": "Zizur Mayor/Zizur Nagusia"
}, {
	"id": "Navarra",
	"nm": "Zubieta"
}, {
	"id": "Navarra",
	"nm": "Zugarramurdi"
}, {
	"id": "Navarra",
	"nm": "Zúñiga"
}, {
	"id": "Ourense",
	"nm": "Allariz"
}, {
	"id": "Ourense",
	"nm": "Amoeiro"
}, {
	"id": "Ourense",
	"nm": "Arnoia, A"
}, {
	"id": "Ourense",
	"nm": "Avión"
}, {
	"id": "Ourense",
	"nm": "Baltar"
}, {
	"id": "Ourense",
	"nm": "Bande"
}, {
	"id": "Ourense",
	"nm": "Baños de Molgas"
}, {
	"id": "Ourense",
	"nm": "Barbadás"
}, {
	"id": "Ourense",
	"nm": "Barco de Valdeorras, O"
}, {
	"id": "Ourense",
	"nm": "Beade"
}, {
	"id": "Ourense",
	"nm": "Beariz"
}, {
	"id": "Ourense",
	"nm": "Blancos, Os"
}, {
	"id": "Ourense",
	"nm": "Boborás"
}, {
	"id": "Ourense",
	"nm": "Bola, A"
}, {
	"id": "Ourense",
	"nm": "Bolo, O"
}, {
	"id": "Ourense",
	"nm": "Calvos de Randín"
}, {
	"id": "Ourense",
	"nm": "Carballeda de Avia"
}, {
	"id": "Ourense",
	"nm": "Carballeda de Valdeorras"
}, {
	"id": "Ourense",
	"nm": "Carballiño, O"
}, {
	"id": "Ourense",
	"nm": "Cartelle"
}, {
	"id": "Ourense",
	"nm": "Castrelo de Miño"
}, {
	"id": "Ourense",
	"nm": "Castrelo do Val"
}, {
	"id": "Ourense",
	"nm": "Castro Caldelas"
}, {
	"id": "Ourense",
	"nm": "Celanova"
}, {
	"id": "Ourense",
	"nm": "Cenlle"
}, {
	"id": "Ourense",
	"nm": "Chandrexa de Queixa"
}, {
	"id": "Ourense",
	"nm": "Coles"
}, {
	"id": "Ourense",
	"nm": "Cortegada"
}, {
	"id": "Ourense",
	"nm": "Cualedro"
}, {
	"id": "Ourense",
	"nm": "Entrimo"
}, {
	"id": "Ourense",
	"nm": "Esgos"
}, {
	"id": "Ourense",
	"nm": "Gomesende"
}, {
	"id": "Ourense",
	"nm": "Gudiña, A"
}, {
	"id": "Ourense",
	"nm": "Irixo, O"
}, {
	"id": "Ourense",
	"nm": "Larouco"
}, {
	"id": "Ourense",
	"nm": "Laza"
}, {
	"id": "Ourense",
	"nm": "Leiro"
}, {
	"id": "Ourense",
	"nm": "Lobeira"
}, {
	"id": "Ourense",
	"nm": "Lobios"
}, {
	"id": "Ourense",
	"nm": "Maceda"
}, {
	"id": "Ourense",
	"nm": "Manzaneda"
}, {
	"id": "Ourense",
	"nm": "Maside"
}, {
	"id": "Ourense",
	"nm": "Melón"
}, {
	"id": "Ourense",
	"nm": "Merca, A"
}, {
	"id": "Ourense",
	"nm": "Mezquita, A"
}, {
	"id": "Ourense",
	"nm": "Montederramo"
}, {
	"id": "Ourense",
	"nm": "Monterrei"
}, {
	"id": "Ourense",
	"nm": "Muíños"
}, {
	"id": "Ourense",
	"nm": "Nogueira de Ramuín"
}, {
	"id": "Ourense",
	"nm": "Oímbra"
}, {
	"id": "Ourense",
	"nm": "Ourense"
}, {
	"id": "Ourense",
	"nm": "Paderne de Allariz"
}, {
	"id": "Ourense",
	"nm": "Padrenda"
}, {
	"id": "Ourense",
	"nm": "Parada de Sil"
}, {
	"id": "Ourense",
	"nm": "Pereiro de Aguiar, O"
}, {
	"id": "Ourense",
	"nm": "Peroxa, A"
}, {
	"id": "Ourense",
	"nm": "Petín"
}, {
	"id": "Ourense",
	"nm": "Piñor"
}, {
	"id": "Ourense",
	"nm": "Pobra de Trives, A"
}, {
	"id": "Ourense",
	"nm": "Pontedeva"
}, {
	"id": "Ourense",
	"nm": "Porqueira"
}, {
	"id": "Ourense",
	"nm": "Punxín"
}, {
	"id": "Ourense",
	"nm": "Quintela de Leirado"
}, {
	"id": "Ourense",
	"nm": "Rairiz de Veiga"
}, {
	"id": "Ourense",
	"nm": "Ramirás"
}, {
	"id": "Ourense",
	"nm": "Ribadavia"
}, {
	"id": "Ourense",
	"nm": "Riós"
}, {
	"id": "Ourense",
	"nm": "Rúa, A"
}, {
	"id": "Ourense",
	"nm": "Rubiá"
}, {
	"id": "Ourense",
	"nm": "San Amaro"
}, {
	"id": "Ourense",
	"nm": "San Cibrao das Viñas"
}, {
	"id": "Ourense",
	"nm": "San Cristovo de Cea"
}, {
	"id": "Ourense",
	"nm": "San Xoán de Río"
}, {
	"id": "Ourense",
	"nm": "Sandiás"
}, {
	"id": "Ourense",
	"nm": "Sarreaus"
}, {
	"id": "Ourense",
	"nm": "Taboadela"
}, {
	"id": "Ourense",
	"nm": "Teixeira, A"
}, {
	"id": "Ourense",
	"nm": "Toén"
}, {
	"id": "Ourense",
	"nm": "Trasmiras"
}, {
	"id": "Ourense",
	"nm": "Veiga, A"
}, {
	"id": "Ourense",
	"nm": "Verea"
}, {
	"id": "Ourense",
	"nm": "Verín"
}, {
	"id": "Ourense",
	"nm": "Viana do Bolo"
}, {
	"id": "Ourense",
	"nm": "Vilamarín"
}, {
	"id": "Ourense",
	"nm": "Vilamartín de Valdeorras"
}, {
	"id": "Ourense",
	"nm": "Vilar de Barrio"
}, {
	"id": "Ourense",
	"nm": "Vilar de Santos"
}, {
	"id": "Ourense",
	"nm": "Vilardevós"
}, {
	"id": "Ourense",
	"nm": "Vilariño de Conso"
}, {
	"id": "Ourense",
	"nm": "Xinzo de Limia"
}, {
	"id": "Ourense",
	"nm": "Xunqueira de Ambía"
}, {
	"id": "Ourense",
	"nm": "Xunqueira de Espadanedo"
}, {
	"id": "Asturias",
	"nm": "Allande"
}, {
	"id": "Asturias",
	"nm": "Aller"
}, {
	"id": "Asturias",
	"nm": "Amieva"
}, {
	"id": "Asturias",
	"nm": "Avilés"
}, {
	"id": "Asturias",
	"nm": "Belmonte de Miranda"
}, {
	"id": "Asturias",
	"nm": "Bimenes"
}, {
	"id": "Asturias",
	"nm": "Boal"
}, {
	"id": "Asturias",
	"nm": "Cabrales"
}, {
	"id": "Asturias",
	"nm": "Cabranes"
}, {
	"id": "Asturias",
	"nm": "Candamo"
}, {
	"id": "Asturias",
	"nm": "Cangas de Onís"
}, {
	"id": "Asturias",
	"nm": "Cangas del Narcea"
}, {
	"id": "Asturias",
	"nm": "Caravia"
}, {
	"id": "Asturias",
	"nm": "Carreño"
}, {
	"id": "Asturias",
	"nm": "Caso"
}, {
	"id": "Asturias",
	"nm": "Castrillón"
}, {
	"id": "Asturias",
	"nm": "Castropol"
}, {
	"id": "Asturias",
	"nm": "Coaña"
}, {
	"id": "Asturias",
	"nm": "Colunga"
}, {
	"id": "Asturias",
	"nm": "Corvera de Asturias"
}, {
	"id": "Asturias",
	"nm": "Cudillero"
}, {
	"id": "Asturias",
	"nm": "Degaña"
}, {
	"id": "Asturias",
	"nm": "Franco, El"
}, {
	"id": "Asturias",
	"nm": "Gijón"
}, {
	"id": "Asturias",
	"nm": "Gozón"
}, {
	"id": "Asturias",
	"nm": "Grado"
}, {
	"id": "Asturias",
	"nm": "Grandas de Salime"
}, {
	"id": "Asturias",
	"nm": "Ibias"
}, {
	"id": "Asturias",
	"nm": "Illano"
}, {
	"id": "Asturias",
	"nm": "Illas"
}, {
	"id": "Asturias",
	"nm": "Langreo"
}, {
	"id": "Asturias",
	"nm": "Laviana"
}, {
	"id": "Asturias",
	"nm": "Lena"
}, {
	"id": "Asturias",
	"nm": "Llanera"
}, {
	"id": "Asturias",
	"nm": "Llanes"
}, {
	"id": "Asturias",
	"nm": "Mieres"
}, {
	"id": "Asturias",
	"nm": "Morcín"
}, {
	"id": "Asturias",
	"nm": "Muros de Nalón"
}, {
	"id": "Asturias",
	"nm": "Nava"
}, {
	"id": "Asturias",
	"nm": "Navia"
}, {
	"id": "Asturias",
	"nm": "Noreña"
}, {
	"id": "Asturias",
	"nm": "Onís"
}, {
	"id": "Asturias",
	"nm": "Oviedo"
}, {
	"id": "Asturias",
	"nm": "Parres"
}, {
	"id": "Asturias",
	"nm": "Peñamellera Alta"
}, {
	"id": "Asturias",
	"nm": "Peñamellera Baja"
}, {
	"id": "Asturias",
	"nm": "Pesoz"
}, {
	"id": "Asturias",
	"nm": "Piloña"
}, {
	"id": "Asturias",
	"nm": "Ponga"
}, {
	"id": "Asturias",
	"nm": "Pravia"
}, {
	"id": "Asturias",
	"nm": "Proaza"
}, {
	"id": "Asturias",
	"nm": "Quirós"
}, {
	"id": "Asturias",
	"nm": "Regueras, Las"
}, {
	"id": "Asturias",
	"nm": "Ribadedeva"
}, {
	"id": "Asturias",
	"nm": "Ribadesella"
}, {
	"id": "Asturias",
	"nm": "Ribera de Arriba"
}, {
	"id": "Asturias",
	"nm": "Riosa"
}, {
	"id": "Asturias",
	"nm": "Salas"
}, {
	"id": "Asturias",
	"nm": "San Martín de Oscos"
}, {
	"id": "Asturias",
	"nm": "San Martín del Rey Aurelio"
}, {
	"id": "Asturias",
	"nm": "San Tirso de Abres"
}, {
	"id": "Asturias",
	"nm": "Santa Eulalia de Oscos"
}, {
	"id": "Asturias",
	"nm": "Santo Adriano"
}, {
	"id": "Asturias",
	"nm": "Sariego"
}, {
	"id": "Asturias",
	"nm": "Siero"
}, {
	"id": "Asturias",
	"nm": "Sobrescobio"
}, {
	"id": "Asturias",
	"nm": "Somiedo"
}, {
	"id": "Asturias",
	"nm": "Soto del Barco"
}, {
	"id": "Asturias",
	"nm": "Tapia de Casariego"
}, {
	"id": "Asturias",
	"nm": "Taramundi"
}, {
	"id": "Asturias",
	"nm": "Teverga"
}, {
	"id": "Asturias",
	"nm": "Tineo"
}, {
	"id": "Asturias",
	"nm": "Valdés"
}, {
	"id": "Asturias",
	"nm": "Vegadeo"
}, {
	"id": "Asturias",
	"nm": "Villanueva de Oscos"
}, {
	"id": "Asturias",
	"nm": "Villaviciosa"
}, {
	"id": "Asturias",
	"nm": "Villayón"
}, {
	"id": "Asturias",
	"nm": "Yernes y Tameza"
}, {
	"id": "Palencia",
	"nm": "Abarca de Campos"
}, {
	"id": "Palencia",
	"nm": "Abia de las Torres"
}, {
	"id": "Palencia",
	"nm": "Aguilar de Campoo"
}, {
	"id": "Palencia",
	"nm": "Alar del Rey"
}, {
	"id": "Palencia",
	"nm": "Alba de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Amayuelas de Arriba"
}, {
	"id": "Palencia",
	"nm": "Ampudia"
}, {
	"id": "Palencia",
	"nm": "Amusco"
}, {
	"id": "Palencia",
	"nm": "Antigüedad"
}, {
	"id": "Palencia",
	"nm": "Arconada"
}, {
	"id": "Palencia",
	"nm": "Astudillo"
}, {
	"id": "Palencia",
	"nm": "Autilla del Pino"
}, {
	"id": "Palencia",
	"nm": "Autillo de Campos"
}, {
	"id": "Palencia",
	"nm": "Ayuela"
}, {
	"id": "Palencia",
	"nm": "Baltanás"
}, {
	"id": "Palencia",
	"nm": "Baquerín de Campos"
}, {
	"id": "Palencia",
	"nm": "Bárcena de Campos"
}, {
	"id": "Palencia",
	"nm": "Barruelo de Santullán"
}, {
	"id": "Palencia",
	"nm": "Báscones de Ojeda"
}, {
	"id": "Palencia",
	"nm": "Becerril de Campos"
}, {
	"id": "Palencia",
	"nm": "Belmonte de Campos"
}, {
	"id": "Palencia",
	"nm": "Berzosilla"
}, {
	"id": "Palencia",
	"nm": "Boada de Campos"
}, {
	"id": "Palencia",
	"nm": "Boadilla de Rioseco"
}, {
	"id": "Palencia",
	"nm": "Boadilla del Camino"
}, {
	"id": "Palencia",
	"nm": "Brañosera"
}, {
	"id": "Palencia",
	"nm": "Buenavista de Valdavia"
}, {
	"id": "Palencia",
	"nm": "Bustillo de la Vega"
}, {
	"id": "Palencia",
	"nm": "Bustillo del Páramo de Carrión"
}, {
	"id": "Palencia",
	"nm": "Calahorra de Boedo"
}, {
	"id": "Palencia",
	"nm": "Calzada de los Molinos"
}, {
	"id": "Palencia",
	"nm": "Capillas"
}, {
	"id": "Palencia",
	"nm": "Cardeñosa de Volpejera"
}, {
	"id": "Palencia",
	"nm": "Carrión de los Condes"
}, {
	"id": "Palencia",
	"nm": "Castil de Vela"
}, {
	"id": "Palencia",
	"nm": "Castrejón de la Peña"
}, {
	"id": "Palencia",
	"nm": "Castrillo de Don Juan"
}, {
	"id": "Palencia",
	"nm": "Castrillo de Onielo"
}, {
	"id": "Palencia",
	"nm": "Castrillo de Villavega"
}, {
	"id": "Palencia",
	"nm": "Castromocho"
}, {
	"id": "Palencia",
	"nm": "Cervatos de la Cueza"
}, {
	"id": "Palencia",
	"nm": "Cervera de Pisuerga"
}, {
	"id": "Palencia",
	"nm": "Cevico de la Torre"
}, {
	"id": "Palencia",
	"nm": "Cevico Navero"
}, {
	"id": "Palencia",
	"nm": "Cisneros"
}, {
	"id": "Palencia",
	"nm": "Cobos de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Collazos de Boedo"
}, {
	"id": "Palencia",
	"nm": "Congosto de Valdavia"
}, {
	"id": "Palencia",
	"nm": "Cordovilla la Real"
}, {
	"id": "Palencia",
	"nm": "Cubillas de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Dehesa de Montejo"
}, {
	"id": "Palencia",
	"nm": "Dehesa de Romanos"
}, {
	"id": "Palencia",
	"nm": "Dueñas"
}, {
	"id": "Palencia",
	"nm": "Espinosa de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Espinosa de Villagonzalo"
}, {
	"id": "Palencia",
	"nm": "Frechilla"
}, {
	"id": "Palencia",
	"nm": "Fresno del Río"
}, {
	"id": "Palencia",
	"nm": "Frómista"
}, {
	"id": "Palencia",
	"nm": "Fuentes de Nava"
}, {
	"id": "Palencia",
	"nm": "Fuentes de Valdepero"
}, {
	"id": "Palencia",
	"nm": "Grijota"
}, {
	"id": "Palencia",
	"nm": "Guardo"
}, {
	"id": "Palencia",
	"nm": "Guaza de Campos"
}, {
	"id": "Palencia",
	"nm": "Hérmedes de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Herrera de Pisuerga"
}, {
	"id": "Palencia",
	"nm": "Herrera de Valdecañas"
}, {
	"id": "Palencia",
	"nm": "Hontoria de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Hornillos de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Husillos"
}, {
	"id": "Palencia",
	"nm": "Itero de la Vega"
}, {
	"id": "Palencia",
	"nm": "Lagartos"
}, {
	"id": "Palencia",
	"nm": "Lantadilla"
}, {
	"id": "Palencia",
	"nm": "Ledigos"
}, {
	"id": "Palencia",
	"nm": "Loma de Ucieza"
}, {
	"id": "Palencia",
	"nm": "Lomas"
}, {
	"id": "Palencia",
	"nm": "Magaz de Pisuerga"
}, {
	"id": "Palencia",
	"nm": "Manquillos"
}, {
	"id": "Palencia",
	"nm": "Mantinos"
}, {
	"id": "Palencia",
	"nm": "Marcilla de Campos"
}, {
	"id": "Palencia",
	"nm": "Mazariegos"
}, {
	"id": "Palencia",
	"nm": "Mazuecos de Valdeginate"
}, {
	"id": "Palencia",
	"nm": "Melgar de Yuso"
}, {
	"id": "Palencia",
	"nm": "Meneses de Campos"
}, {
	"id": "Palencia",
	"nm": "Micieces de Ojeda"
}, {
	"id": "Palencia",
	"nm": "Monzón de Campos"
}, {
	"id": "Palencia",
	"nm": "Moratinos"
}, {
	"id": "Palencia",
	"nm": "Mudá"
}, {
	"id": "Palencia",
	"nm": "Nogal de las Huertas"
}, {
	"id": "Palencia",
	"nm": "Olea de Boedo"
}, {
	"id": "Palencia",
	"nm": "Olmos de Ojeda"
}, {
	"id": "Palencia",
	"nm": "Osornillo"
}, {
	"id": "Palencia",
	"nm": "Osorno la Mayor"
}, {
	"id": "Palencia",
	"nm": "Palencia"
}, {
	"id": "Palencia",
	"nm": "Palenzuela"
}, {
	"id": "Palencia",
	"nm": "Páramo de Boedo"
}, {
	"id": "Palencia",
	"nm": "Paredes de Nava"
}, {
	"id": "Palencia",
	"nm": "Payo de Ojeda"
}, {
	"id": "Palencia",
	"nm": "Pedraza de Campos"
}, {
	"id": "Palencia",
	"nm": "Pedrosa de la Vega"
}, {
	"id": "Palencia",
	"nm": "Perales"
}, {
	"id": "Palencia",
	"nm": "Pernía, La"
}, {
	"id": "Palencia",
	"nm": "Pino del Río"
}, {
	"id": "Palencia",
	"nm": "Piña de Campos"
}, {
	"id": "Palencia",
	"nm": "Población de Arroyo"
}, {
	"id": "Palencia",
	"nm": "Población de Campos"
}, {
	"id": "Palencia",
	"nm": "Población de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Polentinos"
}, {
	"id": "Palencia",
	"nm": "Pomar de Valdivia"
}, {
	"id": "Palencia",
	"nm": "Poza de la Vega"
}, {
	"id": "Palencia",
	"nm": "Pozo de Urama"
}, {
	"id": "Palencia",
	"nm": "Prádanos de Ojeda"
}, {
	"id": "Palencia",
	"nm": "Puebla de Valdavia, La"
}, {
	"id": "Palencia",
	"nm": "Quintana del Puente"
}, {
	"id": "Palencia",
	"nm": "Quintanilla de Onsoña"
}, {
	"id": "Palencia",
	"nm": "Reinoso de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Renedo de la Vega"
}, {
	"id": "Palencia",
	"nm": "Requena de Campos"
}, {
	"id": "Palencia",
	"nm": "Respenda de la Peña"
}, {
	"id": "Palencia",
	"nm": "Revenga de Campos"
}, {
	"id": "Palencia",
	"nm": "Revilla de Collazos"
}, {
	"id": "Palencia",
	"nm": "Ribas de Campos"
}, {
	"id": "Palencia",
	"nm": "Riberos de la Cueza"
}, {
	"id": "Palencia",
	"nm": "Saldaña"
}, {
	"id": "Palencia",
	"nm": "Salinas de Pisuerga"
}, {
	"id": "Palencia",
	"nm": "San Cebrián de Campos"
}, {
	"id": "Palencia",
	"nm": "San Cebrián de Mudá"
}, {
	"id": "Palencia",
	"nm": "San Cristóbal de Boedo"
}, {
	"id": "Palencia",
	"nm": "San Mamés de Campos"
}, {
	"id": "Palencia",
	"nm": "San Román de la Cuba"
}, {
	"id": "Palencia",
	"nm": "Santa Cecilia del Alcor"
}, {
	"id": "Palencia",
	"nm": "Santa Cruz de Boedo"
}, {
	"id": "Palencia",
	"nm": "Santervás de la Vega"
}, {
	"id": "Palencia",
	"nm": "Santibáñez de Ecla"
}, {
	"id": "Palencia",
	"nm": "Santibáñez de la Peña"
}, {
	"id": "Palencia",
	"nm": "Santoyo"
}, {
	"id": "Palencia",
	"nm": "Serna, La"
}, {
	"id": "Palencia",
	"nm": "Soto de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Sotobañado y Priorato"
}, {
	"id": "Palencia",
	"nm": "Tabanera de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Tabanera de Valdavia"
}, {
	"id": "Palencia",
	"nm": "Támara de Campos"
}, {
	"id": "Palencia",
	"nm": "Tariego de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Torquemada"
}, {
	"id": "Palencia",
	"nm": "Torremormojón"
}, {
	"id": "Palencia",
	"nm": "Triollo"
}, {
	"id": "Palencia",
	"nm": "Valbuena de Pisuerga"
}, {
	"id": "Palencia",
	"nm": "Valdeolmillos"
}, {
	"id": "Palencia",
	"nm": "Valderrábano"
}, {
	"id": "Palencia",
	"nm": "Valde-Ucieza"
}, {
	"id": "Palencia",
	"nm": "Valle de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Valle del Retortillo"
}, {
	"id": "Palencia",
	"nm": "Velilla del Río Carrión"
}, {
	"id": "Palencia",
	"nm": "Venta de Baños"
}, {
	"id": "Palencia",
	"nm": "Vertavillo"
}, {
	"id": "Palencia",
	"nm": "Vid de Ojeda, La"
}, {
	"id": "Palencia",
	"nm": "Villabasta de Valdavia"
}, {
	"id": "Palencia",
	"nm": "Villacidaler"
}, {
	"id": "Palencia",
	"nm": "Villaconancio"
}, {
	"id": "Palencia",
	"nm": "Villada"
}, {
	"id": "Palencia",
	"nm": "Villaeles de Valdavia"
}, {
	"id": "Palencia",
	"nm": "Villahán"
}, {
	"id": "Palencia",
	"nm": "Villaherreros"
}, {
	"id": "Palencia",
	"nm": "Villalaco"
}, {
	"id": "Palencia",
	"nm": "Villalba de Guardo"
}, {
	"id": "Palencia",
	"nm": "Villalcázar de Sirga"
}, {
	"id": "Palencia",
	"nm": "Villalcón"
}, {
	"id": "Palencia",
	"nm": "Villalobón"
}, {
	"id": "Palencia",
	"nm": "Villaluenga de la Vega"
}, {
	"id": "Palencia",
	"nm": "Villamartín de Campos"
}, {
	"id": "Palencia",
	"nm": "Villamediana"
}, {
	"id": "Palencia",
	"nm": "Villameriel"
}, {
	"id": "Palencia",
	"nm": "Villamoronta"
}, {
	"id": "Palencia",
	"nm": "Villamuera de la Cueza"
}, {
	"id": "Palencia",
	"nm": "Villamuriel de Cerrato"
}, {
	"id": "Palencia",
	"nm": "Villanueva del Rebollar"
}, {
	"id": "Palencia",
	"nm": "Villanuño de Valdavia"
}, {
	"id": "Palencia",
	"nm": "Villaprovedo"
}, {
	"id": "Palencia",
	"nm": "Villarmentero de Campos"
}, {
	"id": "Palencia",
	"nm": "Villarrabé"
}, {
	"id": "Palencia",
	"nm": "Villarramiel"
}, {
	"id": "Palencia",
	"nm": "Villasarracino"
}, {
	"id": "Palencia",
	"nm": "Villasila de Valdavia"
}, {
	"id": "Palencia",
	"nm": "Villaturde"
}, {
	"id": "Palencia",
	"nm": "Villaumbrales"
}, {
	"id": "Palencia",
	"nm": "Villaviudas"
}, {
	"id": "Palencia",
	"nm": "Villerías de Campos"
}, {
	"id": "Palencia",
	"nm": "Villodre"
}, {
	"id": "Palencia",
	"nm": "Villodrigo"
}, {
	"id": "Palencia",
	"nm": "Villoldo"
}, {
	"id": "Palencia",
	"nm": "Villota del Páramo"
}, {
	"id": "Palencia",
	"nm": "Villovieco"
}, {
	"id": "Palmas, Las",
	"nm": "Agaete"
}, {
	"id": "Palmas, Las",
	"nm": "Agüimes"
}, {
	"id": "Palmas, Las",
	"nm": "Aldea de San Nicolás, La"
}, {
	"id": "Palmas, Las",
	"nm": "Antigua"
}, {
	"id": "Palmas, Las",
	"nm": "Arrecife"
}, {
	"id": "Palmas, Las",
	"nm": "Artenara"
}, {
	"id": "Palmas, Las",
	"nm": "Arucas"
}, {
	"id": "Palmas, Las",
	"nm": "Betancuria"
}, {
	"id": "Palmas, Las",
	"nm": "Firgas"
}, {
	"id": "Palmas, Las",
	"nm": "Gáldar"
}, {
	"id": "Palmas, Las",
	"nm": "Haría"
}, {
	"id": "Palmas, Las",
	"nm": "Ingenio"
}, {
	"id": "Palmas, Las",
	"nm": "Mogán"
}, {
	"id": "Palmas, Las",
	"nm": "Moya"
}, {
	"id": "Palmas, Las",
	"nm": "Oliva, La"
}, {
	"id": "Palmas, Las",
	"nm": "Pájara"
}, {
	"id": "Palmas, Las",
	"nm": "Palmas de Gran Canaria, Las"
}, {
	"id": "Palmas, Las",
	"nm": "Puerto del Rosario"
}, {
	"id": "Palmas, Las",
	"nm": "San Bartolomé"
}, {
	"id": "Palmas, Las",
	"nm": "San Bartolomé de Tirajana"
}, {
	"id": "Palmas, Las",
	"nm": "Santa Brígida"
}, {
	"id": "Palmas, Las",
	"nm": "Santa Lucía de Tirajana"
}, {
	"id": "Palmas, Las",
	"nm": "Santa María de Guía de Gran Canaria"
}, {
	"id": "Palmas, Las",
	"nm": "Teguise"
}, {
	"id": "Palmas, Las",
	"nm": "Tejeda"
}, {
	"id": "Palmas, Las",
	"nm": "Telde"
}, {
	"id": "Palmas, Las",
	"nm": "Teror"
}, {
	"id": "Palmas, Las",
	"nm": "Tías"
}, {
	"id": "Palmas, Las",
	"nm": "Tinajo"
}, {
	"id": "Palmas, Las",
	"nm": "Tuineje"
}, {
	"id": "Palmas, Las",
	"nm": "Valleseco"
}, {
	"id": "Palmas, Las",
	"nm": "Valsequillo de Gran Canaria"
}, {
	"id": "Palmas, Las",
	"nm": "Vega de San Mateo"
}, {
	"id": "Palmas, Las",
	"nm": "Yaiza"
}, {
	"id": "Pontevedra",
	"nm": "Agolada"
}, {
	"id": "Pontevedra",
	"nm": "Arbo"
}, {
	"id": "Pontevedra",
	"nm": "Baiona"
}, {
	"id": "Pontevedra",
	"nm": "Barro"
}, {
	"id": "Pontevedra",
	"nm": "Bueu"
}, {
	"id": "Pontevedra",
	"nm": "Caldas de Reis"
}, {
	"id": "Pontevedra",
	"nm": "Cambados"
}, {
	"id": "Pontevedra",
	"nm": "Campo Lameiro"
}, {
	"id": "Pontevedra",
	"nm": "Cangas"
}, {
	"id": "Pontevedra",
	"nm": "Cañiza, A"
}, {
	"id": "Pontevedra",
	"nm": "Catoira"
}, {
	"id": "Pontevedra",
	"nm": "Cerdedo"
}, {
	"id": "Pontevedra",
	"nm": "Cotobade"
}, {
	"id": "Pontevedra",
	"nm": "Covelo"
}, {
	"id": "Pontevedra",
	"nm": "Crecente"
}, {
	"id": "Pontevedra",
	"nm": "Cuntis"
}, {
	"id": "Pontevedra",
	"nm": "Dozón"
}, {
	"id": "Pontevedra",
	"nm": "Estrada, A"
}, {
	"id": "Pontevedra",
	"nm": "Forcarei"
}, {
	"id": "Pontevedra",
	"nm": "Fornelos de Montes"
}, {
	"id": "Pontevedra",
	"nm": "Gondomar"
}, {
	"id": "Pontevedra",
	"nm": "Grove, O"
}, {
	"id": "Pontevedra",
	"nm": "Guarda, A"
}, {
	"id": "Pontevedra",
	"nm": "Illa de Arousa, A"
}, {
	"id": "Pontevedra",
	"nm": "Lalín"
}, {
	"id": "Pontevedra",
	"nm": "Lama, A"
}, {
	"id": "Pontevedra",
	"nm": "Marín"
}, {
	"id": "Pontevedra",
	"nm": "Meaño"
}, {
	"id": "Pontevedra",
	"nm": "Meis"
}, {
	"id": "Pontevedra",
	"nm": "Moaña"
}, {
	"id": "Pontevedra",
	"nm": "Mondariz"
}, {
	"id": "Pontevedra",
	"nm": "Mondariz-Balneario"
}, {
	"id": "Pontevedra",
	"nm": "Moraña"
}, {
	"id": "Pontevedra",
	"nm": "Mos"
}, {
	"id": "Pontevedra",
	"nm": "Neves, As"
}, {
	"id": "Pontevedra",
	"nm": "Nigrán"
}, {
	"id": "Pontevedra",
	"nm": "Oia"
}, {
	"id": "Pontevedra",
	"nm": "Pazos de Borbén"
}, {
	"id": "Pontevedra",
	"nm": "Poio"
}, {
	"id": "Pontevedra",
	"nm": "Ponte Caldelas"
}, {
	"id": "Pontevedra",
	"nm": "Ponteareas"
}, {
	"id": "Pontevedra",
	"nm": "Pontecesures"
}, {
	"id": "Pontevedra",
	"nm": "Pontevedra"
}, {
	"id": "Pontevedra",
	"nm": "Porriño, O"
}, {
	"id": "Pontevedra",
	"nm": "Portas"
}, {
	"id": "Pontevedra",
	"nm": "Redondela"
}, {
	"id": "Pontevedra",
	"nm": "Ribadumia"
}, {
	"id": "Pontevedra",
	"nm": "Rodeiro"
}, {
	"id": "Pontevedra",
	"nm": "Rosal, O"
}, {
	"id": "Pontevedra",
	"nm": "Salceda de Caselas"
}, {
	"id": "Pontevedra",
	"nm": "Salvaterra de Miño"
}, {
	"id": "Pontevedra",
	"nm": "Sanxenxo"
}, {
	"id": "Pontevedra",
	"nm": "Silleda"
}, {
	"id": "Pontevedra",
	"nm": "Soutomaior"
}, {
	"id": "Pontevedra",
	"nm": "Tomiño"
}, {
	"id": "Pontevedra",
	"nm": "Tui"
}, {
	"id": "Pontevedra",
	"nm": "Valga"
}, {
	"id": "Pontevedra",
	"nm": "Vigo"
}, {
	"id": "Pontevedra",
	"nm": "Vila de Cruces"
}, {
	"id": "Pontevedra",
	"nm": "Vilaboa"
}, {
	"id": "Pontevedra",
	"nm": "Vilagarcía de Arousa"
}, {
	"id": "Pontevedra",
	"nm": "Vilanova de Arousa"
}, {
	"id": "Salamanca",
	"nm": "Abusejo"
}, {
	"id": "Salamanca",
	"nm": "Agallas"
}, {
	"id": "Salamanca",
	"nm": "Ahigal de los Aceiteros"
}, {
	"id": "Salamanca",
	"nm": "Ahigal de Villarino"
}, {
	"id": "Salamanca",
	"nm": "Alameda de Gardón, La"
}, {
	"id": "Salamanca",
	"nm": "Alamedilla, La"
}, {
	"id": "Salamanca",
	"nm": "Alaraz"
}, {
	"id": "Salamanca",
	"nm": "Alba de Tormes"
}, {
	"id": "Salamanca",
	"nm": "Alba de Yeltes"
}, {
	"id": "Salamanca",
	"nm": "Alberca, La"
}, {
	"id": "Salamanca",
	"nm": "Alberguería de Argañán, La"
}, {
	"id": "Salamanca",
	"nm": "Alconada"
}, {
	"id": "Salamanca",
	"nm": "Aldea del Obispo"
}, {
	"id": "Salamanca",
	"nm": "Aldeacipreste"
}, {
	"id": "Salamanca",
	"nm": "Aldeadávila de la Ribera"
}, {
	"id": "Salamanca",
	"nm": "Aldealengua"
}, {
	"id": "Salamanca",
	"nm": "Aldeanueva de Figueroa"
}, {
	"id": "Salamanca",
	"nm": "Aldeanueva de la Sierra"
}, {
	"id": "Salamanca",
	"nm": "Aldearrodrigo"
}, {
	"id": "Salamanca",
	"nm": "Aldearrubia"
}, {
	"id": "Salamanca",
	"nm": "Aldeaseca de Alba"
}, {
	"id": "Salamanca",
	"nm": "Aldeaseca de la Frontera"
}, {
	"id": "Salamanca",
	"nm": "Aldeatejada"
}, {
	"id": "Salamanca",
	"nm": "Aldeavieja de Tormes"
}, {
	"id": "Salamanca",
	"nm": "Aldehuela de la Bóveda"
}, {
	"id": "Salamanca",
	"nm": "Aldehuela de Yeltes"
}, {
	"id": "Salamanca",
	"nm": "Almenara de Tormes"
}, {
	"id": "Salamanca",
	"nm": "Almendra"
}, {
	"id": "Salamanca",
	"nm": "Anaya de Alba"
}, {
	"id": "Salamanca",
	"nm": "Añover de Tormes"
}, {
	"id": "Salamanca",
	"nm": "Arabayona de Mógica"
}, {
	"id": "Salamanca",
	"nm": "Arapiles"
}, {
	"id": "Salamanca",
	"nm": "Arcediano"
}, {
	"id": "Salamanca",
	"nm": "Arco, El"
}, {
	"id": "Salamanca",
	"nm": "Armenteros"
}, {
	"id": "Salamanca",
	"nm": "Atalaya, La"
}, {
	"id": "Salamanca",
	"nm": "Babilafuente"
}, {
	"id": "Salamanca",
	"nm": "Bañobárez"
}, {
	"id": "Salamanca",
	"nm": "Barbadillo"
}, {
	"id": "Salamanca",
	"nm": "Barbalos"
}, {
	"id": "Salamanca",
	"nm": "Barceo"
}, {
	"id": "Salamanca",
	"nm": "Barruecopardo"
}, {
	"id": "Salamanca",
	"nm": "Bastida, La"
}, {
	"id": "Salamanca",
	"nm": "Béjar"
}, {
	"id": "Salamanca",
	"nm": "Beleña"
}, {
	"id": "Salamanca",
	"nm": "Bermellar"
}, {
	"id": "Salamanca",
	"nm": "Berrocal de Huebra"
}, {
	"id": "Salamanca",
	"nm": "Berrocal de Salvatierra"
}, {
	"id": "Salamanca",
	"nm": "Boada"
}, {
	"id": "Salamanca",
	"nm": "Bodón, El"
}, {
	"id": "Salamanca",
	"nm": "Bogajo"
}, {
	"id": "Salamanca",
	"nm": "Bouza, La"
}, {
	"id": "Salamanca",
	"nm": "Bóveda del Río Almar"
}, {
	"id": "Salamanca",
	"nm": "Brincones"
}, {
	"id": "Salamanca",
	"nm": "Buenamadre"
}, {
	"id": "Salamanca",
	"nm": "Buenavista"
}, {
	"id": "Salamanca",
	"nm": "Cabaco, El"
}, {
	"id": "Salamanca",
	"nm": "Cabeza de Béjar, La"
}, {
	"id": "Salamanca",
	"nm": "Cabeza del Caballo"
}, {
	"id": "Salamanca",
	"nm": "Cabezabellosa de la Calzada"
}, {
	"id": "Salamanca",
	"nm": "Cabrerizos"
}, {
	"id": "Salamanca",
	"nm": "Cabrillas"
}, {
	"id": "Salamanca",
	"nm": "Calvarrasa de Abajo"
}, {
	"id": "Salamanca",
	"nm": "Calvarrasa de Arriba"
}, {
	"id": "Salamanca",
	"nm": "Calzada de Béjar, La"
}, {
	"id": "Salamanca",
	"nm": "Calzada de Don Diego"
}, {
	"id": "Salamanca",
	"nm": "Calzada de Valdunciel"
}, {
	"id": "Salamanca",
	"nm": "Campillo de Azaba"
}, {
	"id": "Salamanca",
	"nm": "Campo de Peñaranda, El"
}, {
	"id": "Salamanca",
	"nm": "Candelario"
}, {
	"id": "Salamanca",
	"nm": "Canillas de Abajo"
}, {
	"id": "Salamanca",
	"nm": "Cantagallo"
}, {
	"id": "Salamanca",
	"nm": "Cantalapiedra"
}, {
	"id": "Salamanca",
	"nm": "Cantalpino"
}, {
	"id": "Salamanca",
	"nm": "Cantaracillo"
}, {
	"id": "Salamanca",
	"nm": "Carbajosa de la Sagrada"
}, {
	"id": "Salamanca",
	"nm": "Carpio de Azaba"
}, {
	"id": "Salamanca",
	"nm": "Carrascal de Barregas"
}, {
	"id": "Salamanca",
	"nm": "Carrascal del Obispo"
}, {
	"id": "Salamanca",
	"nm": "Casafranca"
}, {
	"id": "Salamanca",
	"nm": "Casas del Conde, Las"
}, {
	"id": "Salamanca",
	"nm": "Casillas de Flores"
}, {
	"id": "Salamanca",
	"nm": "Castellanos de Moriscos"
}, {
	"id": "Salamanca",
	"nm": "Castellanos de Villiquera"
}, {
	"id": "Salamanca",
	"nm": "Castillejo de Martín Viejo"
}, {
	"id": "Salamanca",
	"nm": "Castraz"
}, {
	"id": "Salamanca",
	"nm": "Cepeda"
}, {
	"id": "Salamanca",
	"nm": "Cereceda de la Sierra"
}, {
	"id": "Salamanca",
	"nm": "Cerezal de Peñahorcada"
}, {
	"id": "Salamanca",
	"nm": "Cerralbo"
}, {
	"id": "Salamanca",
	"nm": "Cerro, El"
}, {
	"id": "Salamanca",
	"nm": "Cespedosa de Tormes"
}, {
	"id": "Salamanca",
	"nm": "Chagarcía Medianero"
}, {
	"id": "Salamanca",
	"nm": "Cilleros de la Bastida"
}, {
	"id": "Salamanca",
	"nm": "Cipérez"
}, {
	"id": "Salamanca",
	"nm": "Ciudad Rodrigo"
}, {
	"id": "Salamanca",
	"nm": "Coca de Alba"
}, {
	"id": "Salamanca",
	"nm": "Colmenar de Montemayor"
}, {
	"id": "Salamanca",
	"nm": "Cordovilla"
}, {
	"id": "Salamanca",
	"nm": "Cristóbal"
}, {
	"id": "Salamanca",
	"nm": "Cubo de Don Sancho, El"
}, {
	"id": "Salamanca",
	"nm": "Dios le Guarde"
}, {
	"id": "Salamanca",
	"nm": "Doñinos de Ledesma"
}, {
	"id": "Salamanca",
	"nm": "Doñinos de Salamanca"
}, {
	"id": "Salamanca",
	"nm": "Ejeme"
}, {
	"id": "Salamanca",
	"nm": "Encina de San Silvestre"
}, {
	"id": "Salamanca",
	"nm": "Encina, La"
}, {
	"id": "Salamanca",
	"nm": "Encinas de Abajo"
}, {
	"id": "Salamanca",
	"nm": "Encinas de Arriba"
}, {
	"id": "Salamanca",
	"nm": "Encinasola de los Comendadores"
}, {
	"id": "Salamanca",
	"nm": "Endrinal"
}, {
	"id": "Salamanca",
	"nm": "Escurial de la Sierra"
}, {
	"id": "Salamanca",
	"nm": "Espadaña"
}, {
	"id": "Salamanca",
	"nm": "Espeja"
}, {
	"id": "Salamanca",
	"nm": "Espino de la Orbada"
}, {
	"id": "Salamanca",
	"nm": "Florida de Liébana"
}, {
	"id": "Salamanca",
	"nm": "Forfoleda"
}, {
	"id": "Salamanca",
	"nm": "Frades de la Sierra"
}, {
	"id": "Salamanca",
	"nm": "Fregeneda, La"
}, {
	"id": "Salamanca",
	"nm": "Fresnedoso"
}, {
	"id": "Salamanca",
	"nm": "Fresno Alhándiga"
}, {
	"id": "Salamanca",
	"nm": "Fuente de San Esteban, La"
}, {
	"id": "Salamanca",
	"nm": "Fuenteguinaldo"
}, {
	"id": "Salamanca",
	"nm": "Fuenteliante"
}, {
	"id": "Salamanca",
	"nm": "Fuenterroble de Salvatierra"
}, {
	"id": "Salamanca",
	"nm": "Fuentes de Béjar"
}, {
	"id": "Salamanca",
	"nm": "Fuentes de Oñoro"
}, {
	"id": "Salamanca",
	"nm": "Gajates"
}, {
	"id": "Salamanca",
	"nm": "Galindo y Perahuy"
}, {
	"id": "Salamanca",
	"nm": "Galinduste"
}, {
	"id": "Salamanca",
	"nm": "Galisancho"
}, {
	"id": "Salamanca",
	"nm": "Gallegos de Argañán"
}, {
	"id": "Salamanca",
	"nm": "Gallegos de Solmirón"
}, {
	"id": "Salamanca",
	"nm": "Garcibuey"
}, {
	"id": "Salamanca",
	"nm": "Garcihernández"
}, {
	"id": "Salamanca",
	"nm": "Garcirrey"
}, {
	"id": "Salamanca",
	"nm": "Gejuelo del Barro"
}, {
	"id": "Salamanca",
	"nm": "Golpejas"
}, {
	"id": "Salamanca",
	"nm": "Gomecello"
}, {
	"id": "Salamanca",
	"nm": "Guadramiro"
}, {
	"id": "Salamanca",
	"nm": "Guijo de Ávila"
}, {
	"id": "Salamanca",
	"nm": "Guijuelo"
}, {
	"id": "Salamanca",
	"nm": "Herguijuela de Ciudad Rodrigo"
}, {
	"id": "Salamanca",
	"nm": "Herguijuela de la Sierra"
}, {
	"id": "Salamanca",
	"nm": "Herguijuela del Campo"
}, {
	"id": "Salamanca",
	"nm": "Hinojosa de Duero"
}, {
	"id": "Salamanca",
	"nm": "Horcajo de Montemayor"
}, {
	"id": "Salamanca",
	"nm": "Horcajo Medianero"
}, {
	"id": "Salamanca",
	"nm": "Hoya, La"
}, {
	"id": "Salamanca",
	"nm": "Huerta"
}, {
	"id": "Salamanca",
	"nm": "Iruelos"
}, {
	"id": "Salamanca",
	"nm": "Ituero de Azaba"
}, {
	"id": "Salamanca",
	"nm": "Juzbado"
}, {
	"id": "Salamanca",
	"nm": "Lagunilla"
}, {
	"id": "Salamanca",
	"nm": "Larrodrigo"
}, {
	"id": "Salamanca",
	"nm": "Ledesma"
}, {
	"id": "Salamanca",
	"nm": "Ledrada"
}, {
	"id": "Salamanca",
	"nm": "Linares de Riofrío"
}, {
	"id": "Salamanca",
	"nm": "Lumbrales"
}, {
	"id": "Salamanca",
	"nm": "Machacón"
}, {
	"id": "Salamanca",
	"nm": "Macotera"
}, {
	"id": "Salamanca",
	"nm": "Madroñal"
}, {
	"id": "Salamanca",
	"nm": "Maíllo, El"
}, {
	"id": "Salamanca",
	"nm": "Malpartida"
}, {
	"id": "Salamanca",
	"nm": "Mancera de Abajo"
}, {
	"id": "Salamanca",
	"nm": "Manzano, El"
}, {
	"id": "Salamanca",
	"nm": "Martiago"
}, {
	"id": "Salamanca",
	"nm": "Martín de Yeltes"
}, {
	"id": "Salamanca",
	"nm": "Martinamor"
}, {
	"id": "Salamanca",
	"nm": "Masueco"
}, {
	"id": "Salamanca",
	"nm": "Mata de Ledesma, La"
}, {
	"id": "Salamanca",
	"nm": "Matilla de los Caños del Río"
}, {
	"id": "Salamanca",
	"nm": "Maya, La"
}, {
	"id": "Salamanca",
	"nm": "Membribe de la Sierra"
}, {
	"id": "Salamanca",
	"nm": "Mieza"
}, {
	"id": "Salamanca",
	"nm": "Milano, El"
}, {
	"id": "Salamanca",
	"nm": "Miranda de Azán"
}, {
	"id": "Salamanca",
	"nm": "Miranda del Castañar"
}, {
	"id": "Salamanca",
	"nm": "Mogarraz"
}, {
	"id": "Salamanca",
	"nm": "Molinillo"
}, {
	"id": "Salamanca",
	"nm": "Monforte de la Sierra"
}, {
	"id": "Salamanca",
	"nm": "Monleón"
}, {
	"id": "Salamanca",
	"nm": "Monleras"
}, {
	"id": "Salamanca",
	"nm": "Monsagro"
}, {
	"id": "Salamanca",
	"nm": "Montejo"
}, {
	"id": "Salamanca",
	"nm": "Montemayor del Río"
}, {
	"id": "Salamanca",
	"nm": "Monterrubio de Armuña"
}, {
	"id": "Salamanca",
	"nm": "Monterrubio de la Sierra"
}, {
	"id": "Salamanca",
	"nm": "Morasverdes"
}, {
	"id": "Salamanca",
	"nm": "Morille"
}, {
	"id": "Salamanca",
	"nm": "Moríñigo"
}, {
	"id": "Salamanca",
	"nm": "Moriscos"
}, {
	"id": "Salamanca",
	"nm": "Moronta"
}, {
	"id": "Salamanca",
	"nm": "Mozárbez"
}, {
	"id": "Salamanca",
	"nm": "Narros de Matalayegua"
}, {
	"id": "Salamanca",
	"nm": "Nava de Béjar"
}, {
	"id": "Salamanca",
	"nm": "Nava de Francia"
}, {
	"id": "Salamanca",
	"nm": "Nava de Sotrobal"
}, {
	"id": "Salamanca",
	"nm": "Navacarros"
}, {
	"id": "Salamanca",
	"nm": "Navales"
}, {
	"id": "Salamanca",
	"nm": "Navalmoral de Béjar"
}, {
	"id": "Salamanca",
	"nm": "Navamorales"
}, {
	"id": "Salamanca",
	"nm": "Navarredonda de la Rinconada"
}, {
	"id": "Salamanca",
	"nm": "Navasfrías"
}, {
	"id": "Salamanca",
	"nm": "Negrilla de Palencia"
}, {
	"id": "Salamanca",
	"nm": "Olmedo de Camaces"
}, {
	"id": "Salamanca",
	"nm": "Orbada, La"
}, {
	"id": "Salamanca",
	"nm": "Pajares de la Laguna"
}, {
	"id": "Salamanca",
	"nm": "Palacios del Arzobispo"
}, {
	"id": "Salamanca",
	"nm": "Palaciosrubios"
}, {
	"id": "Salamanca",
	"nm": "Palencia de Negrilla"
}, {
	"id": "Salamanca",
	"nm": "Parada de Arriba"
}, {
	"id": "Salamanca",
	"nm": "Parada de Rubiales"
}, {
	"id": "Salamanca",
	"nm": "Paradinas de San Juan"
}, {
	"id": "Salamanca",
	"nm": "Pastores"
}, {
	"id": "Salamanca",
	"nm": "Payo, El"
}, {
	"id": "Salamanca",
	"nm": "Pedraza de Alba"
}, {
	"id": "Salamanca",
	"nm": "Pedrosillo de Alba"
}, {
	"id": "Salamanca",
	"nm": "Pedrosillo de los Aires"
}, {
	"id": "Salamanca",
	"nm": "Pedrosillo el Ralo"
}, {
	"id": "Salamanca",
	"nm": "Pedroso de la Armuña, El"
}, {
	"id": "Salamanca",
	"nm": "Pelabravo"
}, {
	"id": "Salamanca",
	"nm": "Pelarrodríguez"
}, {
	"id": "Salamanca",
	"nm": "Pelayos"
}, {
	"id": "Salamanca",
	"nm": "Peña, La"
}, {
	"id": "Salamanca",
	"nm": "Peñacaballera"
}, {
	"id": "Salamanca",
	"nm": "Peñaparda"
}, {
	"id": "Salamanca",
	"nm": "Peñaranda de Bracamonte"
}, {
	"id": "Salamanca",
	"nm": "Peñarandilla"
}, {
	"id": "Salamanca",
	"nm": "Peralejos de Abajo"
}, {
	"id": "Salamanca",
	"nm": "Peralejos de Arriba"
}, {
	"id": "Salamanca",
	"nm": "Pereña de la Ribera"
}, {
	"id": "Salamanca",
	"nm": "Peromingo"
}, {
	"id": "Salamanca",
	"nm": "Pinedas"
}, {
	"id": "Salamanca",
	"nm": "Pino de Tormes, El"
}, {
	"id": "Salamanca",
	"nm": "Pitiegua"
}, {
	"id": "Salamanca",
	"nm": "Pizarral"
}, {
	"id": "Salamanca",
	"nm": "Poveda de las Cintas"
}, {
	"id": "Salamanca",
	"nm": "Pozos de Hinojo"
}, {
	"id": "Salamanca",
	"nm": "Puebla de Azaba"
}, {
	"id": "Salamanca",
	"nm": "Puebla de San Medel"
}, {
	"id": "Salamanca",
	"nm": "Puebla de Yeltes"
}, {
	"id": "Salamanca",
	"nm": "Puente del Congosto"
}, {
	"id": "Salamanca",
	"nm": "Puertas"
}, {
	"id": "Salamanca",
	"nm": "Puerto de Béjar"
}, {
	"id": "Salamanca",
	"nm": "Puerto Seguro"
}, {
	"id": "Salamanca",
	"nm": "Rágama"
}, {
	"id": "Salamanca",
	"nm": "Redonda, La"
}, {
	"id": "Salamanca",
	"nm": "Retortillo"
}, {
	"id": "Salamanca",
	"nm": "Rinconada de la Sierra, La"
}, {
	"id": "Salamanca",
	"nm": "Robleda"
}, {
	"id": "Salamanca",
	"nm": "Robliza de Cojos"
}, {
	"id": "Salamanca",
	"nm": "Rollán"
}, {
	"id": "Salamanca",
	"nm": "Saelices el Chico"
}, {
	"id": "Salamanca",
	"nm": "Sagrada, La"
}, {
	"id": "Salamanca",
	"nm": "Sahugo, El"
}, {
	"id": "Salamanca",
	"nm": "Salamanca"
}, {
	"id": "Salamanca",
	"nm": "Saldeana"
}, {
	"id": "Salamanca",
	"nm": "Salmoral"
}, {
	"id": "Salamanca",
	"nm": "Salvatierra de Tormes"
}, {
	"id": "Salamanca",
	"nm": "San Cristóbal de la Cuesta"
}, {
	"id": "Salamanca",
	"nm": "San Esteban de la Sierra"
}, {
	"id": "Salamanca",
	"nm": "San Felices de los Gallegos"
}, {
	"id": "Salamanca",
	"nm": "San Martín del Castañar"
}, {
	"id": "Salamanca",
	"nm": "San Miguel de Valero"
}, {
	"id": "Salamanca",
	"nm": "San Miguel del Robledo"
}, {
	"id": "Salamanca",
	"nm": "San Morales"
}, {
	"id": "Salamanca",
	"nm": "San Muñoz"
}, {
	"id": "Salamanca",
	"nm": "San Pedro de Rozados"
}, {
	"id": "Salamanca",
	"nm": "San Pedro del Valle"
}, {
	"id": "Salamanca",
	"nm": "San Pelayo de Guareña"
}, {
	"id": "Salamanca",
	"nm": "Sanchón de la Ribera"
}, {
	"id": "Salamanca",
	"nm": "Sanchón de la Sagrada"
}, {
	"id": "Salamanca",
	"nm": "Sanchotello"
}, {
	"id": "Salamanca",
	"nm": "Sancti-Spíritus"
}, {
	"id": "Salamanca",
	"nm": "Sando"
}, {
	"id": "Salamanca",
	"nm": "Santa María de Sando"
}, {
	"id": "Salamanca",
	"nm": "Santa Marta de Tormes"
}, {
	"id": "Salamanca",
	"nm": "Santiago de la Puebla"
}, {
	"id": "Salamanca",
	"nm": "Santibáñez de Béjar"
}, {
	"id": "Salamanca",
	"nm": "Santibáñez de la Sierra"
}, {
	"id": "Salamanca",
	"nm": "Santiz"
}, {
	"id": "Salamanca",
	"nm": "Santos, Los"
}, {
	"id": "Salamanca",
	"nm": "Sardón de los Frailes"
}, {
	"id": "Salamanca",
	"nm": "Saucelle"
}, {
	"id": "Salamanca",
	"nm": "Sepulcro-Hilario"
}, {
	"id": "Salamanca",
	"nm": "Sequeros"
}, {
	"id": "Salamanca",
	"nm": "Serradilla del Arroyo"
}, {
	"id": "Salamanca",
	"nm": "Serradilla del Llano"
}, {
	"id": "Salamanca",
	"nm": "Sierpe, La"
}, {
	"id": "Salamanca",
	"nm": "Sieteiglesias de Tormes"
}, {
	"id": "Salamanca",
	"nm": "Sobradillo"
}, {
	"id": "Salamanca",
	"nm": "Sorihuela"
}, {
	"id": "Salamanca",
	"nm": "Sotoserrano"
}, {
	"id": "Salamanca",
	"nm": "Tabera de Abajo"
}, {
	"id": "Salamanca",
	"nm": "Tala, La"
}, {
	"id": "Salamanca",
	"nm": "Tamames"
}, {
	"id": "Salamanca",
	"nm": "Tarazona de Guareña"
}, {
	"id": "Salamanca",
	"nm": "Tardáguila"
}, {
	"id": "Salamanca",
	"nm": "Tejado, El"
}, {
	"id": "Salamanca",
	"nm": "Tejeda y Segoyuela"
}, {
	"id": "Salamanca",
	"nm": "Tenebrón"
}, {
	"id": "Salamanca",
	"nm": "Terradillos"
}, {
	"id": "Salamanca",
	"nm": "Topas"
}, {
	"id": "Salamanca",
	"nm": "Tordillos"
}, {
	"id": "Salamanca",
	"nm": "Tornadizo, El"
}, {
	"id": "Salamanca",
	"nm": "Torresmenudas"
}, {
	"id": "Salamanca",
	"nm": "Trabanca"
}, {
	"id": "Salamanca",
	"nm": "Tremedal de Tormes"
}, {
	"id": "Salamanca",
	"nm": "Valdecarros"
}, {
	"id": "Salamanca",
	"nm": "Valdefuentes de Sangusín"
}, {
	"id": "Salamanca",
	"nm": "Valdehijaderos"
}, {
	"id": "Salamanca",
	"nm": "Valdelacasa"
}, {
	"id": "Salamanca",
	"nm": "Valdelageve"
}, {
	"id": "Salamanca",
	"nm": "Valdelosa"
}, {
	"id": "Salamanca",
	"nm": "Valdemierque"
}, {
	"id": "Salamanca",
	"nm": "Valderrodrigo"
}, {
	"id": "Salamanca",
	"nm": "Valdunciel"
}, {
	"id": "Salamanca",
	"nm": "Valero"
}, {
	"id": "Salamanca",
	"nm": "Vallejera de Riofrío"
}, {
	"id": "Salamanca",
	"nm": "Valsalabroso"
}, {
	"id": "Salamanca",
	"nm": "Valverde de Valdelacasa"
}, {
	"id": "Salamanca",
	"nm": "Valverdón"
}, {
	"id": "Salamanca",
	"nm": "Vecinos"
}, {
	"id": "Salamanca",
	"nm": "Vega de Tirados"
}, {
	"id": "Salamanca",
	"nm": "Veguillas, Las"
}, {
	"id": "Salamanca",
	"nm": "Vellés, La"
}, {
	"id": "Salamanca",
	"nm": "Ventosa del Río Almar"
}, {
	"id": "Salamanca",
	"nm": "Vídola, La"
}, {
	"id": "Salamanca",
	"nm": "Villaflores"
}, {
	"id": "Salamanca",
	"nm": "Villagonzalo de Tormes"
}, {
	"id": "Salamanca",
	"nm": "Villalba de los Llanos"
}, {
	"id": "Salamanca",
	"nm": "Villamayor"
}, {
	"id": "Salamanca",
	"nm": "Villanueva del Conde"
}, {
	"id": "Salamanca",
	"nm": "Villar de Argañán"
}, {
	"id": "Salamanca",
	"nm": "Villar de Ciervo"
}, {
	"id": "Salamanca",
	"nm": "Villar de Gallimazo"
}, {
	"id": "Salamanca",
	"nm": "Villar de la Yegua"
}, {
	"id": "Salamanca",
	"nm": "Villar de Peralonso"
}, {
	"id": "Salamanca",
	"nm": "Villar de Samaniego"
}, {
	"id": "Salamanca",
	"nm": "Villares de la Reina"
}, {
	"id": "Salamanca",
	"nm": "Villares de Yeltes"
}, {
	"id": "Salamanca",
	"nm": "Villarino de los Aires"
}, {
	"id": "Salamanca",
	"nm": "Villarmayor"
}, {
	"id": "Salamanca",
	"nm": "Villarmuerto"
}, {
	"id": "Salamanca",
	"nm": "Villasbuenas"
}, {
	"id": "Salamanca",
	"nm": "Villasdardo"
}, {
	"id": "Salamanca",
	"nm": "Villaseco de los Gamitos"
}, {
	"id": "Salamanca",
	"nm": "Villaseco de los Reyes"
}, {
	"id": "Salamanca",
	"nm": "Villasrubias"
}, {
	"id": "Salamanca",
	"nm": "Villaverde de Guareña"
}, {
	"id": "Salamanca",
	"nm": "Villavieja de Yeltes"
}, {
	"id": "Salamanca",
	"nm": "Villoria"
}, {
	"id": "Salamanca",
	"nm": "Villoruela"
}, {
	"id": "Salamanca",
	"nm": "Vilvestre"
}, {
	"id": "Salamanca",
	"nm": "Vitigudino"
}, {
	"id": "Salamanca",
	"nm": "Yecla de Yeltes"
}, {
	"id": "Salamanca",
	"nm": "Zamarra"
}, {
	"id": "Salamanca",
	"nm": "Zamayón"
}, {
	"id": "Salamanca",
	"nm": "Zarapicos"
}, {
	"id": "Salamanca",
	"nm": "Zarza de Pumareda, La"
}, {
	"id": "Salamanca",
	"nm": "Zorita de la Frontera"
}, {
	"id": "Palmas, Las",
	"nm": "Adeje"
}, {
	"id": "Palmas, Las",
	"nm": "Agulo"
}, {
	"id": "Palmas, Las",
	"nm": "Alajeró"
}, {
	"id": "Palmas, Las",
	"nm": "Arafo"
}, {
	"id": "Palmas, Las",
	"nm": "Arico"
}, {
	"id": "Palmas, Las",
	"nm": "Arona"
}, {
	"id": "Palmas, Las",
	"nm": "Barlovento"
}, {
	"id": "Palmas, Las",
	"nm": "Breña Alta"
}, {
	"id": "Palmas, Las",
	"nm": "Breña Baja"
}, {
	"id": "Palmas, Las",
	"nm": "Buenavista del Norte"
}, {
	"id": "Palmas, Las",
	"nm": "Candelaria"
}, {
	"id": "Palmas, Las",
	"nm": "Fasnia"
}, {
	"id": "Palmas, Las",
	"nm": "Frontera"
}, {
	"id": "Palmas, Las",
	"nm": "Fuencaliente de la Palma"
}, {
	"id": "Palmas, Las",
	"nm": "Garachico"
}, {
	"id": "Palmas, Las",
	"nm": "Garafía"
}, {
	"id": "Palmas, Las",
	"nm": "Granadilla de Abona"
}, {
	"id": "Palmas, Las",
	"nm": "Guancha, La"
}, {
	"id": "Palmas, Las",
	"nm": "Guía de Isora"
}, {
	"id": "Palmas, Las",
	"nm": "Güímar"
}, {
	"id": "Palmas, Las",
	"nm": "Hermigua"
}, {
	"id": "Palmas, Las",
	"nm": "Icod de los Vinos"
}, {
	"id": "Palmas, Las",
	"nm": "Llanos de Aridane, Los"
}, {
	"id": "Palmas, Las",
	"nm": "Matanza de Acentejo, La"
}, {
	"id": "Palmas, Las",
	"nm": "Orotava, La"
}, {
	"id": "Palmas, Las",
	"nm": "Paso, El"
}, {
	"id": "Palmas, Las",
	"nm": "Pinar de El Hierro, El"
}, {
	"id": "Palmas, Las",
	"nm": "Puerto de la Cruz"
}, {
	"id": "Palmas, Las",
	"nm": "Puntagorda"
}, {
	"id": "Palmas, Las",
	"nm": "Puntallana"
}, {
	"id": "Palmas, Las",
	"nm": "Realejos, Los"
}, {
	"id": "Palmas, Las",
	"nm": "Rosario, El"
}, {
	"id": "Palmas, Las",
	"nm": "San Andrés y Sauces"
}, {
	"id": "Palmas, Las",
	"nm": "San Cristóbal de La Laguna"
}, {
	"id": "Palmas, Las",
	"nm": "San Juan de la Rambla"
}, {
	"id": "Palmas, Las",
	"nm": "San Miguel de Abona"
}, {
	"id": "Palmas, Las",
	"nm": "San Sebastián de la Gomera"
}, {
	"id": "Palmas, Las",
	"nm": "Santa Cruz de la Palma"
}, {
	"id": "Palmas, Las",
	"nm": "Santa Cruz de Tenerife"
}, {
	"id": "Palmas, Las",
	"nm": "Santa Úrsula"
}, {
	"id": "Palmas, Las",
	"nm": "Santiago del Teide"
}, {
	"id": "Palmas, Las",
	"nm": "Sauzal, El"
}, {
	"id": "Palmas, Las",
	"nm": "Silos, Los"
}, {
	"id": "Palmas, Las",
	"nm": "Tacoronte"
}, {
	"id": "Palmas, Las",
	"nm": "Tanque, El"
}, {
	"id": "Palmas, Las",
	"nm": "Tazacorte"
}, {
	"id": "Palmas, Las",
	"nm": "Tegueste"
}, {
	"id": "Palmas, Las",
	"nm": "Tijarafe"
}, {
	"id": "Palmas, Las",
	"nm": "Valle Gran Rey"
}, {
	"id": "Palmas, Las",
	"nm": "Vallehermoso"
}, {
	"id": "Palmas, Las",
	"nm": "Valverde"
}, {
	"id": "Palmas, Las",
	"nm": "Victoria de Acentejo, La"
}, {
	"id": "Palmas, Las",
	"nm": "Vilaflor de Chasna"
}, {
	"id": "Palmas, Las",
	"nm": "Villa de Mazo"
}, {
	"id": "Cantabria",
	"nm": "Alfoz de Lloredo"
}, {
	"id": "Cantabria",
	"nm": "Ampuero"
}, {
	"id": "Cantabria",
	"nm": "Anievas"
}, {
	"id": "Cantabria",
	"nm": "Arenas de Iguña"
}, {
	"id": "Cantabria",
	"nm": "Argoños"
}, {
	"id": "Cantabria",
	"nm": "Arnuero"
}, {
	"id": "Cantabria",
	"nm": "Arredondo"
}, {
	"id": "Cantabria",
	"nm": "Astillero, El"
}, {
	"id": "Cantabria",
	"nm": "Bárcena de Cicero"
}, {
	"id": "Cantabria",
	"nm": "Bárcena de Pie de Concha"
}, {
	"id": "Cantabria",
	"nm": "Bareyo"
}, {
	"id": "Cantabria",
	"nm": "Cabezón de la Sal"
}, {
	"id": "Cantabria",
	"nm": "Cabezón de Liébana"
}, {
	"id": "Cantabria",
	"nm": "Cabuérniga"
}, {
	"id": "Cantabria",
	"nm": "Camaleño"
}, {
	"id": "Cantabria",
	"nm": "Camargo"
}, {
	"id": "Cantabria",
	"nm": "Campoo de Enmedio"
}, {
	"id": "Cantabria",
	"nm": "Campoo de Yuso"
}, {
	"id": "Cantabria",
	"nm": "Cartes"
}, {
	"id": "Cantabria",
	"nm": "Castañeda"
}, {
	"id": "Cantabria",
	"nm": "Castro-Urdiales"
}, {
	"id": "Cantabria",
	"nm": "Cieza"
}, {
	"id": "Cantabria",
	"nm": "Cillorigo de Liébana"
}, {
	"id": "Cantabria",
	"nm": "Colindres"
}, {
	"id": "Cantabria",
	"nm": "Comillas"
}, {
	"id": "Cantabria",
	"nm": "Corrales de Buelna, Los"
}, {
	"id": "Cantabria",
	"nm": "Corvera de Toranzo"
}, {
	"id": "Cantabria",
	"nm": "Entrambasaguas"
}, {
	"id": "Cantabria",
	"nm": "Escalante"
}, {
	"id": "Cantabria",
	"nm": "Guriezo"
}, {
	"id": "Cantabria",
	"nm": "Hazas de Cesto"
}, {
	"id": "Cantabria",
	"nm": "Hermandad de Campoo de Suso"
}, {
	"id": "Cantabria",
	"nm": "Herrerías"
}, {
	"id": "Cantabria",
	"nm": "Lamasón"
}, {
	"id": "Cantabria",
	"nm": "Laredo"
}, {
	"id": "Cantabria",
	"nm": "Liendo"
}, {
	"id": "Cantabria",
	"nm": "Liérganes"
}, {
	"id": "Cantabria",
	"nm": "Limpias"
}, {
	"id": "Cantabria",
	"nm": "Luena"
}, {
	"id": "Cantabria",
	"nm": "Marina de Cudeyo"
}, {
	"id": "Cantabria",
	"nm": "Mazcuerras"
}, {
	"id": "Cantabria",
	"nm": "Medio Cudeyo"
}, {
	"id": "Cantabria",
	"nm": "Meruelo"
}, {
	"id": "Cantabria",
	"nm": "Miengo"
}, {
	"id": "Cantabria",
	"nm": "Miera"
}, {
	"id": "Cantabria",
	"nm": "Molledo"
}, {
	"id": "Cantabria",
	"nm": "Noja"
}, {
	"id": "Cantabria",
	"nm": "Penagos"
}, {
	"id": "Cantabria",
	"nm": "Peñarrubia"
}, {
	"id": "Cantabria",
	"nm": "Pesaguero"
}, {
	"id": "Cantabria",
	"nm": "Pesquera"
}, {
	"id": "Cantabria",
	"nm": "Piélagos"
}, {
	"id": "Cantabria",
	"nm": "Polaciones"
}, {
	"id": "Cantabria",
	"nm": "Polanco"
}, {
	"id": "Cantabria",
	"nm": "Potes"
}, {
	"id": "Cantabria",
	"nm": "Puente Viesgo"
}, {
	"id": "Cantabria",
	"nm": "Ramales de la Victoria"
}, {
	"id": "Cantabria",
	"nm": "Rasines"
}, {
	"id": "Cantabria",
	"nm": "Reinosa"
}, {
	"id": "Cantabria",
	"nm": "Reocín"
}, {
	"id": "Cantabria",
	"nm": "Ribamontán al Mar"
}, {
	"id": "Cantabria",
	"nm": "Ribamontán al Monte"
}, {
	"id": "Cantabria",
	"nm": "Rionansa"
}, {
	"id": "Cantabria",
	"nm": "Riotuerto"
}, {
	"id": "Cantabria",
	"nm": "Rozas de Valdearroyo, Las"
}, {
	"id": "Cantabria",
	"nm": "Ruente"
}, {
	"id": "Cantabria",
	"nm": "Ruesga"
}, {
	"id": "Cantabria",
	"nm": "Ruiloba"
}, {
	"id": "Cantabria",
	"nm": "San Felices de Buelna"
}, {
	"id": "Cantabria",
	"nm": "San Miguel de Aguayo"
}, {
	"id": "Cantabria",
	"nm": "San Pedro del Romeral"
}, {
	"id": "Cantabria",
	"nm": "San Roque de Riomiera"
}, {
	"id": "Cantabria",
	"nm": "San Vicente de la Barquera"
}, {
	"id": "Cantabria",
	"nm": "Santa Cruz de Bezana"
}, {
	"id": "Cantabria",
	"nm": "Santa María de Cayón"
}, {
	"id": "Cantabria",
	"nm": "Santander"
}, {
	"id": "Cantabria",
	"nm": "Santillana del Mar"
}, {
	"id": "Cantabria",
	"nm": "Santiurde de Reinosa"
}, {
	"id": "Cantabria",
	"nm": "Santiurde de Toranzo"
}, {
	"id": "Cantabria",
	"nm": "Santoña"
}, {
	"id": "Cantabria",
	"nm": "Saro"
}, {
	"id": "Cantabria",
	"nm": "Selaya"
}, {
	"id": "Cantabria",
	"nm": "Soba"
}, {
	"id": "Cantabria",
	"nm": "Solórzano"
}, {
	"id": "Cantabria",
	"nm": "Suances"
}, {
	"id": "Cantabria",
	"nm": "Tojos, Los"
}, {
	"id": "Cantabria",
	"nm": "Torrelavega"
}, {
	"id": "Cantabria",
	"nm": "Tresviso"
}, {
	"id": "Cantabria",
	"nm": "Tudanca"
}, {
	"id": "Cantabria",
	"nm": "Udías"
}, {
	"id": "Cantabria",
	"nm": "Val de San Vicente"
}, {
	"id": "Cantabria",
	"nm": "Valdáliga"
}, {
	"id": "Cantabria",
	"nm": "Valdeolea"
}, {
	"id": "Cantabria",
	"nm": "Valdeprado del Río"
}, {
	"id": "Cantabria",
	"nm": "Valderredible"
}, {
	"id": "Cantabria",
	"nm": "Valle de Villaverde"
}, {
	"id": "Cantabria",
	"nm": "Vega de Liébana"
}, {
	"id": "Cantabria",
	"nm": "Vega de Pas"
}, {
	"id": "Cantabria",
	"nm": "Villacarriedo"
}, {
	"id": "Cantabria",
	"nm": "Villaescusa"
}, {
	"id": "Cantabria",
	"nm": "Villafufre"
}, {
	"id": "Cantabria",
	"nm": "Voto"
}, {
	"id": "Segovia",
	"nm": "Abades"
}, {
	"id": "Segovia",
	"nm": "Adrada de Pirón"
}, {
	"id": "Segovia",
	"nm": "Adrados"
}, {
	"id": "Segovia",
	"nm": "Aguilafuente"
}, {
	"id": "Segovia",
	"nm": "Alconada de Maderuelo"
}, {
	"id": "Segovia",
	"nm": "Aldea Real"
}, {
	"id": "Segovia",
	"nm": "Aldealcorvo"
}, {
	"id": "Segovia",
	"nm": "Aldealengua de Pedraza"
}, {
	"id": "Segovia",
	"nm": "Aldealengua de Santa María"
}, {
	"id": "Segovia",
	"nm": "Aldeanueva de la Serrezuela"
}, {
	"id": "Segovia",
	"nm": "Aldeanueva del Codonal"
}, {
	"id": "Segovia",
	"nm": "Aldeasoña"
}, {
	"id": "Segovia",
	"nm": "Aldehorno"
}, {
	"id": "Segovia",
	"nm": "Aldehuela del Codonal"
}, {
	"id": "Segovia",
	"nm": "Aldeonte"
}, {
	"id": "Segovia",
	"nm": "Anaya"
}, {
	"id": "Segovia",
	"nm": "Añe"
}, {
	"id": "Segovia",
	"nm": "Arahuetes"
}, {
	"id": "Segovia",
	"nm": "Arcones"
}, {
	"id": "Segovia",
	"nm": "Arevalillo de Cega"
}, {
	"id": "Segovia",
	"nm": "Armuña"
}, {
	"id": "Segovia",
	"nm": "Ayllón"
}, {
	"id": "Segovia",
	"nm": "Barbolla"
}, {
	"id": "Segovia",
	"nm": "Basardilla"
}, {
	"id": "Segovia",
	"nm": "Bercial"
}, {
	"id": "Segovia",
	"nm": "Bercimuel"
}, {
	"id": "Segovia",
	"nm": "Bernardos"
}, {
	"id": "Segovia",
	"nm": "Bernuy de Porreros"
}, {
	"id": "Segovia",
	"nm": "Boceguillas"
}, {
	"id": "Segovia",
	"nm": "Brieva"
}, {
	"id": "Segovia",
	"nm": "Caballar"
}, {
	"id": "Segovia",
	"nm": "Cabañas de Polendos"
}, {
	"id": "Segovia",
	"nm": "Cabezuela"
}, {
	"id": "Segovia",
	"nm": "Calabazas de Fuentidueña"
}, {
	"id": "Segovia",
	"nm": "Campo de San Pedro"
}, {
	"id": "Segovia",
	"nm": "Cantalejo"
}, {
	"id": "Segovia",
	"nm": "Cantimpalos"
}, {
	"id": "Segovia",
	"nm": "Carbonero el Mayor"
}, {
	"id": "Segovia",
	"nm": "Carrascal del Río"
}, {
	"id": "Segovia",
	"nm": "Casla"
}, {
	"id": "Segovia",
	"nm": "Castillejo de Mesleón"
}, {
	"id": "Segovia",
	"nm": "Castro de Fuentidueña"
}, {
	"id": "Segovia",
	"nm": "Castrojimeno"
}, {
	"id": "Segovia",
	"nm": "Castroserna de Abajo"
}, {
	"id": "Segovia",
	"nm": "Castroserracín"
}, {
	"id": "Segovia",
	"nm": "Cedillo de la Torre"
}, {
	"id": "Segovia",
	"nm": "Cerezo de Abajo"
}, {
	"id": "Segovia",
	"nm": "Cerezo de Arriba"
}, {
	"id": "Segovia",
	"nm": "Chañe"
}, {
	"id": "Segovia",
	"nm": "Cilleruelo de San Mamés"
}, {
	"id": "Segovia",
	"nm": "Cobos de Fuentidueña"
}, {
	"id": "Segovia",
	"nm": "Coca"
}, {
	"id": "Segovia",
	"nm": "Codorniz"
}, {
	"id": "Segovia",
	"nm": "Collado Hermoso"
}, {
	"id": "Segovia",
	"nm": "Condado de Castilnovo"
}, {
	"id": "Segovia",
	"nm": "Corral de Ayllón"
}, {
	"id": "Segovia",
	"nm": "Cozuelos de Fuentidueña"
}, {
	"id": "Segovia",
	"nm": "Cubillo"
}, {
	"id": "Segovia",
	"nm": "Cuéllar"
}, {
	"id": "Segovia",
	"nm": "Cuevas de Provanco"
}, {
	"id": "Segovia",
	"nm": "Domingo García"
}, {
	"id": "Segovia",
	"nm": "Donhierro"
}, {
	"id": "Segovia",
	"nm": "Duruelo"
}, {
	"id": "Segovia",
	"nm": "Encinas"
}, {
	"id": "Segovia",
	"nm": "Encinillas"
}, {
	"id": "Segovia",
	"nm": "Escalona del Prado"
}, {
	"id": "Segovia",
	"nm": "Escarabajosa de Cabezas"
}, {
	"id": "Segovia",
	"nm": "Escobar de Polendos"
}, {
	"id": "Segovia",
	"nm": "Espinar, El"
}, {
	"id": "Segovia",
	"nm": "Espirdo"
}, {
	"id": "Segovia",
	"nm": "Fresneda de Cuéllar"
}, {
	"id": "Segovia",
	"nm": "Fresno de Cantespino"
}, {
	"id": "Segovia",
	"nm": "Fresno de la Fuente"
}, {
	"id": "Segovia",
	"nm": "Frumales"
}, {
	"id": "Segovia",
	"nm": "Fuente de Santa Cruz"
}, {
	"id": "Segovia",
	"nm": "Fuente el Olmo de Fuentidueña"
}, {
	"id": "Segovia",
	"nm": "Fuente el Olmo de Íscar"
}, {
	"id": "Segovia",
	"nm": "Fuentepelayo"
}, {
	"id": "Segovia",
	"nm": "Fuentepiñel"
}, {
	"id": "Segovia",
	"nm": "Fuenterrebollo"
}, {
	"id": "Segovia",
	"nm": "Fuentesaúco de Fuentidueña"
}, {
	"id": "Segovia",
	"nm": "Fuentesoto"
}, {
	"id": "Segovia",
	"nm": "Fuentidueña"
}, {
	"id": "Segovia",
	"nm": "Gallegos"
}, {
	"id": "Segovia",
	"nm": "Garcillán"
}, {
	"id": "Segovia",
	"nm": "Gomezserracín"
}, {
	"id": "Segovia",
	"nm": "Grajera"
}, {
	"id": "Segovia",
	"nm": "Honrubia de la Cuesta"
}, {
	"id": "Segovia",
	"nm": "Hontalbilla"
}, {
	"id": "Segovia",
	"nm": "Hontanares de Eresma"
}, {
	"id": "Segovia",
	"nm": "Huertos, Los"
}, {
	"id": "Segovia",
	"nm": "Ituero y Lama"
}, {
	"id": "Segovia",
	"nm": "Juarros de Riomoros"
}, {
	"id": "Segovia",
	"nm": "Juarros de Voltoya"
}, {
	"id": "Segovia",
	"nm": "Labajos"
}, {
	"id": "Segovia",
	"nm": "Laguna de Contreras"
}, {
	"id": "Segovia",
	"nm": "Languilla"
}, {
	"id": "Segovia",
	"nm": "Lastras de Cuéllar"
}, {
	"id": "Segovia",
	"nm": "Lastras del Pozo"
}, {
	"id": "Segovia",
	"nm": "Lastrilla, La"
}, {
	"id": "Segovia",
	"nm": "Losa, La"
}, {
	"id": "Segovia",
	"nm": "Maderuelo"
}, {
	"id": "Segovia",
	"nm": "Marazoleja"
}, {
	"id": "Segovia",
	"nm": "Marazuela"
}, {
	"id": "Segovia",
	"nm": "Martín Miguel"
}, {
	"id": "Segovia",
	"nm": "Martín Muñoz de la Dehesa"
}, {
	"id": "Segovia",
	"nm": "Martín Muñoz de las Posadas"
}, {
	"id": "Segovia",
	"nm": "Marugán"
}, {
	"id": "Segovia",
	"nm": "Mata de Cuéllar"
}, {
	"id": "Segovia",
	"nm": "Matabuena"
}, {
	"id": "Segovia",
	"nm": "Matilla, La"
}, {
	"id": "Segovia",
	"nm": "Melque de Cercos"
}, {
	"id": "Segovia",
	"nm": "Membibre de la Hoz"
}, {
	"id": "Segovia",
	"nm": "Migueláñez"
}, {
	"id": "Segovia",
	"nm": "Montejo de Arévalo"
}, {
	"id": "Segovia",
	"nm": "Montejo de la Vega de la Serrezuela"
}, {
	"id": "Segovia",
	"nm": "Monterrubio"
}, {
	"id": "Segovia",
	"nm": "Moral de Hornuez"
}, {
	"id": "Segovia",
	"nm": "Mozoncillo"
}, {
	"id": "Segovia",
	"nm": "Muñopedro"
}, {
	"id": "Segovia",
	"nm": "Muñoveros"
}, {
	"id": "Segovia",
	"nm": "Nava de la Asunción"
}, {
	"id": "Segovia",
	"nm": "Navafría"
}, {
	"id": "Segovia",
	"nm": "Navalilla"
}, {
	"id": "Segovia",
	"nm": "Navalmanzano"
}, {
	"id": "Segovia",
	"nm": "Navares de Ayuso"
}, {
	"id": "Segovia",
	"nm": "Navares de Enmedio"
}, {
	"id": "Segovia",
	"nm": "Navares de las Cuevas"
}, {
	"id": "Segovia",
	"nm": "Navas de Oro"
}, {
	"id": "Segovia",
	"nm": "Navas de Riofrío"
}, {
	"id": "Segovia",
	"nm": "Navas de San Antonio"
}, {
	"id": "Segovia",
	"nm": "Nieva"
}, {
	"id": "Segovia",
	"nm": "Olombrada"
}, {
	"id": "Segovia",
	"nm": "Orejana"
}, {
	"id": "Segovia",
	"nm": "Ortigosa de Pestaño"
}, {
	"id": "Segovia",
	"nm": "Ortigosa del Monte"
}, {
	"id": "Segovia",
	"nm": "Otero de Herreros"
}, {
	"id": "Segovia",
	"nm": "Pajarejos"
}, {
	"id": "Segovia",
	"nm": "Palazuelos de Eresma"
}, {
	"id": "Segovia",
	"nm": "Pedraza"
}, {
	"id": "Segovia",
	"nm": "Pelayos del Arroyo"
}, {
	"id": "Segovia",
	"nm": "Perosillo"
}, {
	"id": "Segovia",
	"nm": "Pinarejos"
}, {
	"id": "Segovia",
	"nm": "Pinarnegrillo"
}, {
	"id": "Segovia",
	"nm": "Pradales"
}, {
	"id": "Segovia",
	"nm": "Prádena"
}, {
	"id": "Segovia",
	"nm": "Puebla de Pedraza"
}, {
	"id": "Segovia",
	"nm": "Rapariegos"
}, {
	"id": "Segovia",
	"nm": "Real Sitio de San Ildefonso"
}, {
	"id": "Segovia",
	"nm": "Rebollo"
}, {
	"id": "Segovia",
	"nm": "Remondo"
}, {
	"id": "Segovia",
	"nm": "Riaguas de San Bartolomé"
}, {
	"id": "Segovia",
	"nm": "Riaza"
}, {
	"id": "Segovia",
	"nm": "Ribota"
}, {
	"id": "Segovia",
	"nm": "Riofrío de Riaza"
}, {
	"id": "Segovia",
	"nm": "Roda de Eresma"
}, {
	"id": "Segovia",
	"nm": "Sacramenia"
}, {
	"id": "Segovia",
	"nm": "Samboal"
}, {
	"id": "Segovia",
	"nm": "San Cristóbal de Cuéllar"
}, {
	"id": "Segovia",
	"nm": "San Cristóbal de la Vega"
}, {
	"id": "Segovia",
	"nm": "San Cristóbal de Segovia"
}, {
	"id": "Segovia",
	"nm": "San Martín y Mudrián"
}, {
	"id": "Segovia",
	"nm": "San Miguel de Bernuy"
}, {
	"id": "Segovia",
	"nm": "San Pedro de Gaíllos"
}, {
	"id": "Segovia",
	"nm": "Sanchonuño"
}, {
	"id": "Segovia",
	"nm": "Sangarcía"
}, {
	"id": "Segovia",
	"nm": "Santa María la Real de Nieva"
}, {
	"id": "Segovia",
	"nm": "Santa Marta del Cerro"
}, {
	"id": "Segovia",
	"nm": "Santiuste de Pedraza"
}, {
	"id": "Segovia",
	"nm": "Santiuste de San Juan Bautista"
}, {
	"id": "Segovia",
	"nm": "Santo Domingo de Pirón"
}, {
	"id": "Segovia",
	"nm": "Santo Tomé del Puerto"
}, {
	"id": "Segovia",
	"nm": "Sauquillo de Cabezas"
}, {
	"id": "Segovia",
	"nm": "Sebúlcor"
}, {
	"id": "Segovia",
	"nm": "Segovia"
}, {
	"id": "Segovia",
	"nm": "Sepúlveda"
}, {
	"id": "Segovia",
	"nm": "Sequera de Fresno"
}, {
	"id": "Segovia",
	"nm": "Sotillo"
}, {
	"id": "Segovia",
	"nm": "Sotosalbos"
}, {
	"id": "Segovia",
	"nm": "Tabanera la Luenga"
}, {
	"id": "Segovia",
	"nm": "Tolocirio"
}, {
	"id": "Segovia",
	"nm": "Torre Val de San Pedro"
}, {
	"id": "Segovia",
	"nm": "Torreadrada"
}, {
	"id": "Segovia",
	"nm": "Torrecaballeros"
}, {
	"id": "Segovia",
	"nm": "Torrecilla del Pinar"
}, {
	"id": "Segovia",
	"nm": "Torreiglesias"
}, {
	"id": "Segovia",
	"nm": "Trescasas"
}, {
	"id": "Segovia",
	"nm": "Turégano"
}, {
	"id": "Segovia",
	"nm": "Urueñas"
}, {
	"id": "Segovia",
	"nm": "Valdeprados"
}, {
	"id": "Segovia",
	"nm": "Valdevacas de Montejo"
}, {
	"id": "Segovia",
	"nm": "Valdevacas y Guijar"
}, {
	"id": "Segovia",
	"nm": "Valle de Tabladillo"
}, {
	"id": "Segovia",
	"nm": "Vallelado"
}, {
	"id": "Segovia",
	"nm": "Valleruela de Pedraza"
}, {
	"id": "Segovia",
	"nm": "Valleruela de Sepúlveda"
}, {
	"id": "Segovia",
	"nm": "Valseca"
}, {
	"id": "Segovia",
	"nm": "Valtiendas"
}, {
	"id": "Segovia",
	"nm": "Valverde del Majano"
}, {
	"id": "Segovia",
	"nm": "Veganzones"
}, {
	"id": "Segovia",
	"nm": "Vegas de Matute"
}, {
	"id": "Segovia",
	"nm": "Ventosilla y Tejadilla"
}, {
	"id": "Segovia",
	"nm": "Villacastín"
}, {
	"id": "Segovia",
	"nm": "Villaverde de Íscar"
}, {
	"id": "Segovia",
	"nm": "Villaverde de Montejo"
}, {
	"id": "Segovia",
	"nm": "Villeguillo"
}, {
	"id": "Segovia",
	"nm": "Yanguas de Eresma"
}, {
	"id": "Segovia",
	"nm": "Zarzuela del Monte"
}, {
	"id": "Segovia",
	"nm": "Zarzuela del Pinar"
}, {
	"id": "Sevilla",
	"nm": "Aguadulce"
}, {
	"id": "Sevilla",
	"nm": "Alanís"
}, {
	"id": "Sevilla",
	"nm": "Albaida del Aljarafe"
}, {
	"id": "Sevilla",
	"nm": "Alcalá de Guadaíra"
}, {
	"id": "Sevilla",
	"nm": "Alcalá del Río"
}, {
	"id": "Sevilla",
	"nm": "Alcolea del Río"
}, {
	"id": "Sevilla",
	"nm": "Algaba, La"
}, {
	"id": "Sevilla",
	"nm": "Algámitas"
}, {
	"id": "Sevilla",
	"nm": "Almadén de la Plata"
}, {
	"id": "Sevilla",
	"nm": "Almensilla"
}, {
	"id": "Sevilla",
	"nm": "Arahal"
}, {
	"id": "Sevilla",
	"nm": "Aznalcázar"
}, {
	"id": "Sevilla",
	"nm": "Aznalcóllar"
}, {
	"id": "Sevilla",
	"nm": "Badolatosa"
}, {
	"id": "Sevilla",
	"nm": "Benacazón"
}, {
	"id": "Sevilla",
	"nm": "Bollullos de la Mitación"
}, {
	"id": "Sevilla",
	"nm": "Bormujos"
}, {
	"id": "Sevilla",
	"nm": "Brenes"
}, {
	"id": "Sevilla",
	"nm": "Burguillos"
}, {
	"id": "Sevilla",
	"nm": "Cabezas de San Juan, Las"
}, {
	"id": "Sevilla",
	"nm": "Camas"
}, {
	"id": "Sevilla",
	"nm": "Campana, La"
}, {
	"id": "Sevilla",
	"nm": "Cantillana"
}, {
	"id": "Sevilla",
	"nm": "Cañada Rosal"
}, {
	"id": "Sevilla",
	"nm": "Carmona"
}, {
	"id": "Sevilla",
	"nm": "Carrión de los Céspedes"
}, {
	"id": "Sevilla",
	"nm": "Casariche"
}, {
	"id": "Sevilla",
	"nm": "Castilblanco de los Arroyos"
}, {
	"id": "Sevilla",
	"nm": "Castilleja de Guzmán"
}, {
	"id": "Sevilla",
	"nm": "Castilleja de la Cuesta"
}, {
	"id": "Sevilla",
	"nm": "Castilleja del Campo"
}, {
	"id": "Sevilla",
	"nm": "Castillo de las Guardas, El"
}, {
	"id": "Sevilla",
	"nm": "Cazalla de la Sierra"
}, {
	"id": "Sevilla",
	"nm": "Constantina"
}, {
	"id": "Sevilla",
	"nm": "Coria del Río"
}, {
	"id": "Sevilla",
	"nm": "Coripe"
}, {
	"id": "Sevilla",
	"nm": "Coronil, El"
}, {
	"id": "Sevilla",
	"nm": "Corrales, Los"
}, {
	"id": "Sevilla",
	"nm": "Cuervo de Sevilla, El"
}, {
	"id": "Sevilla",
	"nm": "Dos Hermanas"
}, {
	"id": "Sevilla",
	"nm": "Écija"
}, {
	"id": "Sevilla",
	"nm": "Espartinas"
}, {
	"id": "Sevilla",
	"nm": "Estepa"
}, {
	"id": "Sevilla",
	"nm": "Fuentes de Andalucía"
}, {
	"id": "Sevilla",
	"nm": "Garrobo, El"
}, {
	"id": "Sevilla",
	"nm": "Gelves"
}, {
	"id": "Sevilla",
	"nm": "Gerena"
}, {
	"id": "Sevilla",
	"nm": "Gilena"
}, {
	"id": "Sevilla",
	"nm": "Gines"
}, {
	"id": "Sevilla",
	"nm": "Guadalcanal"
}, {
	"id": "Sevilla",
	"nm": "Guillena"
}, {
	"id": "Sevilla",
	"nm": "Herrera"
}, {
	"id": "Sevilla",
	"nm": "Huévar del Aljarafe"
}, {
	"id": "Sevilla",
	"nm": "Isla Mayor"
}, {
	"id": "Sevilla",
	"nm": "Lantejuela"
}, {
	"id": "Sevilla",
	"nm": "Lebrija"
}, {
	"id": "Sevilla",
	"nm": "Lora de Estepa"
}, {
	"id": "Sevilla",
	"nm": "Lora del Río"
}, {
	"id": "Sevilla",
	"nm": "Luisiana, La"
}, {
	"id": "Sevilla",
	"nm": "Madroño, El"
}, {
	"id": "Sevilla",
	"nm": "Mairena del Alcor"
}, {
	"id": "Sevilla",
	"nm": "Mairena del Aljarafe"
}, {
	"id": "Sevilla",
	"nm": "Marchena"
}, {
	"id": "Sevilla",
	"nm": "Marinaleda"
}, {
	"id": "Sevilla",
	"nm": "Martín de la Jara"
}, {
	"id": "Sevilla",
	"nm": "Molares, Los"
}, {
	"id": "Sevilla",
	"nm": "Montellano"
}, {
	"id": "Sevilla",
	"nm": "Morón de la Frontera"
}, {
	"id": "Sevilla",
	"nm": "Navas de la Concepción, Las"
}, {
	"id": "Sevilla",
	"nm": "Olivares"
}, {
	"id": "Sevilla",
	"nm": "Osuna"
}, {
	"id": "Sevilla",
	"nm": "Palacios y Villafranca, Los"
}, {
	"id": "Sevilla",
	"nm": "Palomares del Río"
}, {
	"id": "Sevilla",
	"nm": "Paradas"
}, {
	"id": "Sevilla",
	"nm": "Pedrera"
}, {
	"id": "Sevilla",
	"nm": "Pedroso, El"
}, {
	"id": "Sevilla",
	"nm": "Peñaflor"
}, {
	"id": "Sevilla",
	"nm": "Pilas"
}, {
	"id": "Sevilla",
	"nm": "Pruna"
}, {
	"id": "Sevilla",
	"nm": "Puebla de Cazalla, La"
}, {
	"id": "Sevilla",
	"nm": "Puebla de los Infantes, La"
}, {
	"id": "Sevilla",
	"nm": "Puebla del Río, La"
}, {
	"id": "Sevilla",
	"nm": "Real de la Jara, El"
}, {
	"id": "Sevilla",
	"nm": "Rinconada, La"
}, {
	"id": "Sevilla",
	"nm": "Roda de Andalucía, La"
}, {
	"id": "Sevilla",
	"nm": "Ronquillo, El"
}, {
	"id": "Sevilla",
	"nm": "Rubio, El"
}, {
	"id": "Sevilla",
	"nm": "Salteras"
}, {
	"id": "Sevilla",
	"nm": "San Juan de Aznalfarache"
}, {
	"id": "Sevilla",
	"nm": "San Nicolás del Puerto"
}, {
	"id": "Sevilla",
	"nm": "Sanlúcar la Mayor"
}, {
	"id": "Sevilla",
	"nm": "Santiponce"
}, {
	"id": "Sevilla",
	"nm": "Saucejo, El"
}, {
	"id": "Sevilla",
	"nm": "Sevilla"
}, {
	"id": "Sevilla",
	"nm": "Tocina"
}, {
	"id": "Sevilla",
	"nm": "Tomares"
}, {
	"id": "Sevilla",
	"nm": "Umbrete"
}, {
	"id": "Sevilla",
	"nm": "Utrera"
}, {
	"id": "Sevilla",
	"nm": "Valencina de la Concepción"
}, {
	"id": "Sevilla",
	"nm": "Villamanrique de la Condesa"
}, {
	"id": "Sevilla",
	"nm": "Villanueva de San Juan"
}, {
	"id": "Sevilla",
	"nm": "Villanueva del Ariscal"
}, {
	"id": "Sevilla",
	"nm": "Villanueva del Río y Minas"
}, {
	"id": "Sevilla",
	"nm": "Villaverde del Río"
}, {
	"id": "Sevilla",
	"nm": "Viso del Alcor, El"
}, {
	"id": "Soria",
	"nm": "Abejar"
}, {
	"id": "Soria",
	"nm": "Adradas"
}, {
	"id": "Soria",
	"nm": "Ágreda"
}, {
	"id": "Soria",
	"nm": "Alconaba"
}, {
	"id": "Soria",
	"nm": "Alcubilla de Avellaneda"
}, {
	"id": "Soria",
	"nm": "Alcubilla de las Peñas"
}, {
	"id": "Soria",
	"nm": "Aldealafuente"
}, {
	"id": "Soria",
	"nm": "Aldealices"
}, {
	"id": "Soria",
	"nm": "Aldealpozo"
}, {
	"id": "Soria",
	"nm": "Aldealseñor"
}, {
	"id": "Soria",
	"nm": "Aldehuela de Periáñez"
}, {
	"id": "Soria",
	"nm": "Aldehuelas, Las"
}, {
	"id": "Soria",
	"nm": "Alentisque"
}, {
	"id": "Soria",
	"nm": "Aliud"
}, {
	"id": "Soria",
	"nm": "Almajano"
}, {
	"id": "Soria",
	"nm": "Almaluez"
}, {
	"id": "Soria",
	"nm": "Almarza"
}, {
	"id": "Soria",
	"nm": "Almazán"
}, {
	"id": "Soria",
	"nm": "Almazul"
}, {
	"id": "Soria",
	"nm": "Almenar de Soria"
}, {
	"id": "Soria",
	"nm": "Alpanseque"
}, {
	"id": "Soria",
	"nm": "Arancón"
}, {
	"id": "Soria",
	"nm": "Arcos de Jalón"
}, {
	"id": "Soria",
	"nm": "Arenillas"
}, {
	"id": "Soria",
	"nm": "Arévalo de la Sierra"
}, {
	"id": "Soria",
	"nm": "Ausejo de la Sierra"
}, {
	"id": "Soria",
	"nm": "Baraona"
}, {
	"id": "Soria",
	"nm": "Barca"
}, {
	"id": "Soria",
	"nm": "Barcones"
}, {
	"id": "Soria",
	"nm": "Bayubas de Abajo"
}, {
	"id": "Soria",
	"nm": "Bayubas de Arriba"
}, {
	"id": "Soria",
	"nm": "Beratón"
}, {
	"id": "Soria",
	"nm": "Berlanga de Duero"
}, {
	"id": "Soria",
	"nm": "Blacos"
}, {
	"id": "Soria",
	"nm": "Bliecos"
}, {
	"id": "Soria",
	"nm": "Borjabad"
}, {
	"id": "Soria",
	"nm": "Borobia"
}, {
	"id": "Soria",
	"nm": "Buberos"
}, {
	"id": "Soria",
	"nm": "Buitrago"
}, {
	"id": "Soria",
	"nm": "Burgo de Osma-Ciudad de Osma"
}, {
	"id": "Soria",
	"nm": "Cabrejas del Campo"
}, {
	"id": "Soria",
	"nm": "Cabrejas del Pinar"
}, {
	"id": "Soria",
	"nm": "Calatañazor"
}, {
	"id": "Soria",
	"nm": "Caltojar"
}, {
	"id": "Soria",
	"nm": "Candilichera"
}, {
	"id": "Soria",
	"nm": "Cañamaque"
}, {
	"id": "Soria",
	"nm": "Carabantes"
}, {
	"id": "Soria",
	"nm": "Caracena"
}, {
	"id": "Soria",
	"nm": "Carrascosa de Abajo"
}, {
	"id": "Soria",
	"nm": "Carrascosa de la Sierra"
}, {
	"id": "Soria",
	"nm": "Casarejos"
}, {
	"id": "Soria",
	"nm": "Castilfrío de la Sierra"
}, {
	"id": "Soria",
	"nm": "Castillejo de Robledo"
}, {
	"id": "Soria",
	"nm": "Castilruiz"
}, {
	"id": "Soria",
	"nm": "Centenera de Andaluz"
}, {
	"id": "Soria",
	"nm": "Cerbón"
}, {
	"id": "Soria",
	"nm": "Cidones"
}, {
	"id": "Soria",
	"nm": "Cigudosa"
}, {
	"id": "Soria",
	"nm": "Cihuela"
}, {
	"id": "Soria",
	"nm": "Ciria"
}, {
	"id": "Soria",
	"nm": "Cirujales del Río"
}, {
	"id": "Soria",
	"nm": "Coscurita"
}, {
	"id": "Soria",
	"nm": "Covaleda"
}, {
	"id": "Soria",
	"nm": "Cubilla"
}, {
	"id": "Soria",
	"nm": "Cubo de la Solana"
}, {
	"id": "Soria",
	"nm": "Cueva de Ágreda"
}, {
	"id": "Soria",
	"nm": "Dévanos"
}, {
	"id": "Soria",
	"nm": "Deza"
}, {
	"id": "Soria",
	"nm": "Duruelo de la Sierra"
}, {
	"id": "Soria",
	"nm": "Escobosa de Almazán"
}, {
	"id": "Soria",
	"nm": "Espeja de San Marcelino"
}, {
	"id": "Soria",
	"nm": "Espejón"
}, {
	"id": "Soria",
	"nm": "Estepa de San Juan"
}, {
	"id": "Soria",
	"nm": "Frechilla de Almazán"
}, {
	"id": "Soria",
	"nm": "Fresno de Caracena"
}, {
	"id": "Soria",
	"nm": "Fuentearmegil"
}, {
	"id": "Soria",
	"nm": "Fuentecambrón"
}, {
	"id": "Soria",
	"nm": "Fuentecantos"
}, {
	"id": "Soria",
	"nm": "Fuentelmonge"
}, {
	"id": "Soria",
	"nm": "Fuentelsaz de Soria"
}, {
	"id": "Soria",
	"nm": "Fuentepinilla"
}, {
	"id": "Soria",
	"nm": "Fuentes de Magaña"
}, {
	"id": "Soria",
	"nm": "Fuentestrún"
}, {
	"id": "Soria",
	"nm": "Garray"
}, {
	"id": "Soria",
	"nm": "Golmayo"
}, {
	"id": "Soria",
	"nm": "Gómara"
}, {
	"id": "Soria",
	"nm": "Gormaz"
}, {
	"id": "Soria",
	"nm": "Herrera de Soria"
}, {
	"id": "Soria",
	"nm": "Hinojosa del Campo"
}, {
	"id": "Soria",
	"nm": "Langa de Duero"
}, {
	"id": "Soria",
	"nm": "Liceras"
}, {
	"id": "Soria",
	"nm": "Losilla, La"
}, {
	"id": "Soria",
	"nm": "Magaña"
}, {
	"id": "Soria",
	"nm": "Maján"
}, {
	"id": "Soria",
	"nm": "Matalebreras"
}, {
	"id": "Soria",
	"nm": "Matamala de Almazán"
}, {
	"id": "Soria",
	"nm": "Medinaceli"
}, {
	"id": "Soria",
	"nm": "Miño de Medinaceli"
}, {
	"id": "Soria",
	"nm": "Miño de San Esteban"
}, {
	"id": "Soria",
	"nm": "Molinos de Duero"
}, {
	"id": "Soria",
	"nm": "Momblona"
}, {
	"id": "Soria",
	"nm": "Monteagudo de las Vicarías"
}, {
	"id": "Soria",
	"nm": "Montejo de Tiermes"
}, {
	"id": "Soria",
	"nm": "Montenegro de Cameros"
}, {
	"id": "Soria",
	"nm": "Morón de Almazán"
}, {
	"id": "Soria",
	"nm": "Muriel de la Fuente"
}, {
	"id": "Soria",
	"nm": "Muriel Viejo"
}, {
	"id": "Soria",
	"nm": "Nafría de Ucero"
}, {
	"id": "Soria",
	"nm": "Narros"
}, {
	"id": "Soria",
	"nm": "Navaleno"
}, {
	"id": "Soria",
	"nm": "Nepas"
}, {
	"id": "Soria",
	"nm": "Nolay"
}, {
	"id": "Soria",
	"nm": "Noviercas"
}, {
	"id": "Soria",
	"nm": "Ólvega"
}, {
	"id": "Soria",
	"nm": "Oncala"
}, {
	"id": "Soria",
	"nm": "Pinilla del Campo"
}, {
	"id": "Soria",
	"nm": "Portillo de Soria"
}, {
	"id": "Soria",
	"nm": "Póveda de Soria, La"
}, {
	"id": "Soria",
	"nm": "Pozalmuro"
}, {
	"id": "Soria",
	"nm": "Quintana Redonda"
}, {
	"id": "Soria",
	"nm": "Quintanas de Gormaz"
}, {
	"id": "Soria",
	"nm": "Quiñonería"
}, {
	"id": "Soria",
	"nm": "Rábanos, Los"
}, {
	"id": "Soria",
	"nm": "Rebollar"
}, {
	"id": "Soria",
	"nm": "Recuerda"
}, {
	"id": "Soria",
	"nm": "Rello"
}, {
	"id": "Soria",
	"nm": "Renieblas"
}, {
	"id": "Soria",
	"nm": "Retortillo de Soria"
}, {
	"id": "Soria",
	"nm": "Reznos"
}, {
	"id": "Soria",
	"nm": "Riba de Escalote, La"
}, {
	"id": "Soria",
	"nm": "Rioseco de Soria"
}, {
	"id": "Soria",
	"nm": "Rollamienta"
}, {
	"id": "Soria",
	"nm": "Royo, El"
}, {
	"id": "Soria",
	"nm": "Salduero"
}, {
	"id": "Soria",
	"nm": "San Esteban de Gormaz"
}, {
	"id": "Soria",
	"nm": "San Felices"
}, {
	"id": "Soria",
	"nm": "San Leonardo de Yagüe"
}, {
	"id": "Soria",
	"nm": "San Pedro Manrique"
}, {
	"id": "Soria",
	"nm": "Santa Cruz de Yanguas"
}, {
	"id": "Soria",
	"nm": "Santa María de Huerta"
}, {
	"id": "Soria",
	"nm": "Santa María de las Hoyas"
}, {
	"id": "Soria",
	"nm": "Serón de Nágima"
}, {
	"id": "Soria",
	"nm": "Soliedra"
}, {
	"id": "Soria",
	"nm": "Soria"
}, {
	"id": "Soria",
	"nm": "Sotillo del Rincón"
}, {
	"id": "Soria",
	"nm": "Suellacabras"
}, {
	"id": "Soria",
	"nm": "Tajahuerce"
}, {
	"id": "Soria",
	"nm": "Tajueco"
}, {
	"id": "Soria",
	"nm": "Talveila"
}, {
	"id": "Soria",
	"nm": "Tardelcuende"
}, {
	"id": "Soria",
	"nm": "Taroda"
}, {
	"id": "Soria",
	"nm": "Tejado"
}, {
	"id": "Soria",
	"nm": "Torlengua"
}, {
	"id": "Soria",
	"nm": "Torreblacos"
}, {
	"id": "Soria",
	"nm": "Torrubia de Soria"
}, {
	"id": "Soria",
	"nm": "Trévago"
}, {
	"id": "Soria",
	"nm": "Ucero"
}, {
	"id": "Soria",
	"nm": "Vadillo"
}, {
	"id": "Soria",
	"nm": "Valdeavellano de Tera"
}, {
	"id": "Soria",
	"nm": "Valdegeña"
}, {
	"id": "Soria",
	"nm": "Valdelagua del Cerro"
}, {
	"id": "Soria",
	"nm": "Valdemaluque"
}, {
	"id": "Soria",
	"nm": "Valdenebro"
}, {
	"id": "Soria",
	"nm": "Valdeprado"
}, {
	"id": "Soria",
	"nm": "Valderrodilla"
}, {
	"id": "Soria",
	"nm": "Valtajeros"
}, {
	"id": "Soria",
	"nm": "Velamazán"
}, {
	"id": "Soria",
	"nm": "Velilla de la Sierra"
}, {
	"id": "Soria",
	"nm": "Velilla de los Ajos"
}, {
	"id": "Soria",
	"nm": "Viana de Duero"
}, {
	"id": "Soria",
	"nm": "Villaciervos"
}, {
	"id": "Soria",
	"nm": "Villanueva de Gormaz"
}, {
	"id": "Soria",
	"nm": "Villar del Ala"
}, {
	"id": "Soria",
	"nm": "Villar del Campo"
}, {
	"id": "Soria",
	"nm": "Villar del Río"
}, {
	"id": "Soria",
	"nm": "Villares de Soria, Los"
}, {
	"id": "Soria",
	"nm": "Villasayas"
}, {
	"id": "Soria",
	"nm": "Villaseca de Arciel"
}, {
	"id": "Soria",
	"nm": "Vinuesa"
}, {
	"id": "Soria",
	"nm": "Vizmanos"
}, {
	"id": "Soria",
	"nm": "Vozmediano"
}, {
	"id": "Soria",
	"nm": "Yanguas"
}, {
	"id": "Soria",
	"nm": "Yelo"
}, {
	"id": "Tarragona",
	"nm": "Aiguamúrcia"
}, {
	"id": "Tarragona",
	"nm": "Albinyana"
}, {
	"id": "Tarragona",
	"nm": "Albiol, L'"
}, {
	"id": "Tarragona",
	"nm": "Alcanar"
}, {
	"id": "Tarragona",
	"nm": "Alcover"
}, {
	"id": "Tarragona",
	"nm": "Aldea, L'"
}, {
	"id": "Tarragona",
	"nm": "Aldover"
}, {
	"id": "Tarragona",
	"nm": "Aleixar, L'"
}, {
	"id": "Tarragona",
	"nm": "Alfara de Carles"
}, {
	"id": "Tarragona",
	"nm": "Alforja"
}, {
	"id": "Tarragona",
	"nm": "Alió"
}, {
	"id": "Tarragona",
	"nm": "Almoster"
}, {
	"id": "Tarragona",
	"nm": "Altafulla"
}, {
	"id": "Tarragona",
	"nm": "Ametlla de Mar, L'"
}, {
	"id": "Tarragona",
	"nm": "Ampolla, L'"
}, {
	"id": "Tarragona",
	"nm": "Amposta"
}, {
	"id": "Tarragona",
	"nm": "Arboç, L'"
}, {
	"id": "Tarragona",
	"nm": "Arbolí"
}, {
	"id": "Tarragona",
	"nm": "Argentera, L'"
}, {
	"id": "Tarragona",
	"nm": "Arnes"
}, {
	"id": "Tarragona",
	"nm": "Ascó"
}, {
	"id": "Tarragona",
	"nm": "Banyeres del Penedès"
}, {
	"id": "Tarragona",
	"nm": "Barberà de la Conca"
}, {
	"id": "Tarragona",
	"nm": "Batea"
}, {
	"id": "Tarragona",
	"nm": "Bellmunt del Priorat"
}, {
	"id": "Tarragona",
	"nm": "Bellvei"
}, {
	"id": "Tarragona",
	"nm": "Benifallet"
}, {
	"id": "Tarragona",
	"nm": "Benissanet"
}, {
	"id": "Tarragona",
	"nm": "Bisbal de Falset, La"
}, {
	"id": "Tarragona",
	"nm": "Bisbal del Penedès, La"
}, {
	"id": "Tarragona",
	"nm": "Blancafort"
}, {
	"id": "Tarragona",
	"nm": "Bonastre"
}, {
	"id": "Tarragona",
	"nm": "Borges del Camp, Les"
}, {
	"id": "Tarragona",
	"nm": "Bot"
}, {
	"id": "Tarragona",
	"nm": "Botarell"
}, {
	"id": "Tarragona",
	"nm": "Bràfim"
}, {
	"id": "Tarragona",
	"nm": "Cabacés"
}, {
	"id": "Tarragona",
	"nm": "Cabra del Camp"
}, {
	"id": "Tarragona",
	"nm": "Calafell"
}, {
	"id": "Tarragona",
	"nm": "Camarles"
}, {
	"id": "Tarragona",
	"nm": "Cambrils"
}, {
	"id": "Tarragona",
	"nm": "Canonja, La"
}, {
	"id": "Tarragona",
	"nm": "Capafonts"
}, {
	"id": "Tarragona",
	"nm": "Capçanes"
}, {
	"id": "Tarragona",
	"nm": "Caseres"
}, {
	"id": "Tarragona",
	"nm": "Castellvell del Camp"
}, {
	"id": "Tarragona",
	"nm": "Catllar, El"
}, {
	"id": "Tarragona",
	"nm": "Colldejou"
}, {
	"id": "Tarragona",
	"nm": "Conesa"
}, {
	"id": "Tarragona",
	"nm": "Constantí"
}, {
	"id": "Tarragona",
	"nm": "Corbera d'Ebre"
}, {
	"id": "Tarragona",
	"nm": "Cornudella de Montsant"
}, {
	"id": "Tarragona",
	"nm": "Creixell"
}, {
	"id": "Tarragona",
	"nm": "Cunit"
}, {
	"id": "Tarragona",
	"nm": "Deltebre"
}, {
	"id": "Tarragona",
	"nm": "Duesaigües"
}, {
	"id": "Tarragona",
	"nm": "Espluga de Francolí, L'"
}, {
	"id": "Tarragona",
	"nm": "Falset"
}, {
	"id": "Tarragona",
	"nm": "Fatarella, La"
}, {
	"id": "Tarragona",
	"nm": "Febró, La"
}, {
	"id": "Tarragona",
	"nm": "Figuera, La"
}, {
	"id": "Tarragona",
	"nm": "Figuerola del Camp"
}, {
	"id": "Tarragona",
	"nm": "Flix"
}, {
	"id": "Tarragona",
	"nm": "Forès"
}, {
	"id": "Tarragona",
	"nm": "Freginals"
}, {
	"id": "Tarragona",
	"nm": "Galera, La"
}, {
	"id": "Tarragona",
	"nm": "Gandesa"
}, {
	"id": "Tarragona",
	"nm": "Garcia"
}, {
	"id": "Tarragona",
	"nm": "Garidells, Els"
}, {
	"id": "Tarragona",
	"nm": "Ginestar"
}, {
	"id": "Tarragona",
	"nm": "Godall"
}, {
	"id": "Tarragona",
	"nm": "Gratallops"
}, {
	"id": "Tarragona",
	"nm": "Guiamets, Els"
}, {
	"id": "Tarragona",
	"nm": "Horta de Sant Joan"
}, {
	"id": "Tarragona",
	"nm": "Lloar, El"
}, {
	"id": "Tarragona",
	"nm": "Llorac"
}, {
	"id": "Tarragona",
	"nm": "Llorenç del Penedès"
}, {
	"id": "Tarragona",
	"nm": "Marçà"
}, {
	"id": "Tarragona",
	"nm": "Margalef"
}, {
	"id": "Tarragona",
	"nm": "Mas de Barberans"
}, {
	"id": "Tarragona",
	"nm": "Masdenverge"
}, {
	"id": "Tarragona",
	"nm": "Masllorenç"
}, {
	"id": "Tarragona",
	"nm": "Masó, La"
}, {
	"id": "Tarragona",
	"nm": "Maspujols"
}, {
	"id": "Tarragona",
	"nm": "Masroig, El"
}, {
	"id": "Tarragona",
	"nm": "Milà, El"
}, {
	"id": "Tarragona",
	"nm": "Miravet"
}, {
	"id": "Tarragona",
	"nm": "Molar, El"
}, {
	"id": "Tarragona",
	"nm": "Montblanc"
}, {
	"id": "Tarragona",
	"nm": "Montbrió del Camp"
}, {
	"id": "Tarragona",
	"nm": "Montferri"
}, {
	"id": "Tarragona",
	"nm": "Montmell, El"
}, {
	"id": "Tarragona",
	"nm": "Mont-ral"
}, {
	"id": "Tarragona",
	"nm": "Mont-roig del Camp"
}, {
	"id": "Tarragona",
	"nm": "Móra d'Ebre"
}, {
	"id": "Tarragona",
	"nm": "Móra la Nova"
}, {
	"id": "Tarragona",
	"nm": "Morell, El"
}, {
	"id": "Tarragona",
	"nm": "Morera de Montsant, La"
}, {
	"id": "Tarragona",
	"nm": "Nou de Gaià, La"
}, {
	"id": "Tarragona",
	"nm": "Nulles"
}, {
	"id": "Tarragona",
	"nm": "Pallaresos, Els"
}, {
	"id": "Tarragona",
	"nm": "Palma d'Ebre, La"
}, {
	"id": "Tarragona",
	"nm": "Passanant i Belltall"
}, {
	"id": "Tarragona",
	"nm": "Paüls"
}, {
	"id": "Tarragona",
	"nm": "Perafort"
}, {
	"id": "Tarragona",
	"nm": "Perelló, El"
}, {
	"id": "Tarragona",
	"nm": "Piles, Les"
}, {
	"id": "Tarragona",
	"nm": "Pinell de Brai, El"
}, {
	"id": "Tarragona",
	"nm": "Pira"
}, {
	"id": "Tarragona",
	"nm": "Pla de Santa Maria, El"
}, {
	"id": "Tarragona",
	"nm": "Pobla de Mafumet, La"
}, {
	"id": "Tarragona",
	"nm": "Pobla de Massaluca, La"
}, {
	"id": "Tarragona",
	"nm": "Pobla de Montornès, La"
}, {
	"id": "Tarragona",
	"nm": "Poboleda"
}, {
	"id": "Tarragona",
	"nm": "Pont d'Armentera, El"
}, {
	"id": "Tarragona",
	"nm": "Pontils"
}, {
	"id": "Tarragona",
	"nm": "Porrera"
}, {
	"id": "Tarragona",
	"nm": "Pradell de la Teixeta"
}, {
	"id": "Tarragona",
	"nm": "Prades"
}, {
	"id": "Tarragona",
	"nm": "Prat de Comte"
}, {
	"id": "Tarragona",
	"nm": "Pratdip"
}, {
	"id": "Tarragona",
	"nm": "Puigpelat"
}, {
	"id": "Tarragona",
	"nm": "Querol"
}, {
	"id": "Tarragona",
	"nm": "Rasquera"
}, {
	"id": "Tarragona",
	"nm": "Renau"
}, {
	"id": "Tarragona",
	"nm": "Reus"
}, {
	"id": "Tarragona",
	"nm": "Riba, La"
}, {
	"id": "Tarragona",
	"nm": "Riba-roja d'Ebre"
}, {
	"id": "Tarragona",
	"nm": "Riera de Gaià, La"
}, {
	"id": "Tarragona",
	"nm": "Riudecanyes"
}, {
	"id": "Tarragona",
	"nm": "Riudecols"
}, {
	"id": "Tarragona",
	"nm": "Riudoms"
}, {
	"id": "Tarragona",
	"nm": "Rocafort de Queralt"
}, {
	"id": "Tarragona",
	"nm": "Roda de Berà"
}, {
	"id": "Tarragona",
	"nm": "Rodonyà"
}, {
	"id": "Tarragona",
	"nm": "Roquetes"
}, {
	"id": "Tarragona",
	"nm": "Rourell, El"
}, {
	"id": "Tarragona",
	"nm": "Salomó"
}, {
	"id": "Tarragona",
	"nm": "Salou"
}, {
	"id": "Tarragona",
	"nm": "Sant Carles de la Ràpita"
}, {
	"id": "Tarragona",
	"nm": "Sant Jaume dels Domenys"
}, {
	"id": "Tarragona",
	"nm": "Sant Jaume d'Enveja"
}, {
	"id": "Tarragona",
	"nm": "Santa Bàrbara"
}, {
	"id": "Tarragona",
	"nm": "Santa Coloma de Queralt"
}, {
	"id": "Tarragona",
	"nm": "Santa Oliva"
}, {
	"id": "Tarragona",
	"nm": "Sarral"
}, {
	"id": "Tarragona",
	"nm": "Savallà del Comtat"
}, {
	"id": "Tarragona",
	"nm": "Secuita, La"
}, {
	"id": "Tarragona",
	"nm": "Selva del Camp, La"
}, {
	"id": "Tarragona",
	"nm": "Senan"
}, {
	"id": "Tarragona",
	"nm": "Sénia, La"
}, {
	"id": "Tarragona",
	"nm": "Solivella"
}, {
	"id": "Tarragona",
	"nm": "Tarragona"
}, {
	"id": "Tarragona",
	"nm": "Tivenys"
}, {
	"id": "Tarragona",
	"nm": "Tivissa"
}, {
	"id": "Tarragona",
	"nm": "Torre de Fontaubella, La"
}, {
	"id": "Tarragona",
	"nm": "Torre de l'Espanyol, La"
}, {
	"id": "Tarragona",
	"nm": "Torredembarra"
}, {
	"id": "Tarragona",
	"nm": "Torroja del Priorat"
}, {
	"id": "Tarragona",
	"nm": "Tortosa"
}, {
	"id": "Tarragona",
	"nm": "Ulldecona"
}, {
	"id": "Tarragona",
	"nm": "Ulldemolins"
}, {
	"id": "Tarragona",
	"nm": "Vallclara"
}, {
	"id": "Tarragona",
	"nm": "Vallfogona de Riucorb"
}, {
	"id": "Tarragona",
	"nm": "Vallmoll"
}, {
	"id": "Tarragona",
	"nm": "Valls"
}, {
	"id": "Tarragona",
	"nm": "Vandellòs i l'Hospitalet de l'Infant"
}, {
	"id": "Tarragona",
	"nm": "Vendrell, El"
}, {
	"id": "Tarragona",
	"nm": "Vespella de Gaià"
}, {
	"id": "Tarragona",
	"nm": "Vilabella"
}, {
	"id": "Tarragona",
	"nm": "Vilalba dels Arcs"
}, {
	"id": "Tarragona",
	"nm": "Vilallonga del Camp"
}, {
	"id": "Tarragona",
	"nm": "Vilanova de Prades"
}, {
	"id": "Tarragona",
	"nm": "Vilanova d'Escornalbou"
}, {
	"id": "Tarragona",
	"nm": "Vilaplana"
}, {
	"id": "Tarragona",
	"nm": "Vila-rodona"
}, {
	"id": "Tarragona",
	"nm": "Vila-seca"
}, {
	"id": "Tarragona",
	"nm": "Vilaverd"
}, {
	"id": "Tarragona",
	"nm": "Vilella Alta, La"
}, {
	"id": "Tarragona",
	"nm": "Vilella Baixa, La"
}, {
	"id": "Tarragona",
	"nm": "Vimbodí i Poblet"
}, {
	"id": "Tarragona",
	"nm": "Vinebre"
}, {
	"id": "Tarragona",
	"nm": "Vinyols i els Arcs"
}, {
	"id": "Tarragona",
	"nm": "Xerta"
}, {
	"id": "Teruel",
	"nm": "Ababuj"
}, {
	"id": "Teruel",
	"nm": "Abejuela"
}, {
	"id": "Teruel",
	"nm": "Aguatón"
}, {
	"id": "Teruel",
	"nm": "Aguaviva"
}, {
	"id": "Teruel",
	"nm": "Aguilar del Alfambra"
}, {
	"id": "Teruel",
	"nm": "Alacón"
}, {
	"id": "Teruel",
	"nm": "Alba"
}, {
	"id": "Teruel",
	"nm": "Albalate del Arzobispo"
}, {
	"id": "Teruel",
	"nm": "Albarracín"
}, {
	"id": "Teruel",
	"nm": "Albentosa"
}, {
	"id": "Teruel",
	"nm": "Alcaine"
}, {
	"id": "Teruel",
	"nm": "Alcalá de la Selva"
}, {
	"id": "Teruel",
	"nm": "Alcañiz"
}, {
	"id": "Teruel",
	"nm": "Alcorisa"
}, {
	"id": "Teruel",
	"nm": "Alfambra"
}, {
	"id": "Teruel",
	"nm": "Aliaga"
}, {
	"id": "Teruel",
	"nm": "Allepuz"
}, {
	"id": "Teruel",
	"nm": "Alloza"
}, {
	"id": "Teruel",
	"nm": "Allueva"
}, {
	"id": "Teruel",
	"nm": "Almohaja"
}, {
	"id": "Teruel",
	"nm": "Alobras"
}, {
	"id": "Teruel",
	"nm": "Alpeñés"
}, {
	"id": "Teruel",
	"nm": "Anadón"
}, {
	"id": "Teruel",
	"nm": "Andorra"
}, {
	"id": "Teruel",
	"nm": "Arcos de las Salinas"
}, {
	"id": "Teruel",
	"nm": "Arens de Lledó"
}, {
	"id": "Teruel",
	"nm": "Argente"
}, {
	"id": "Teruel",
	"nm": "Ariño"
}, {
	"id": "Teruel",
	"nm": "Azaila"
}, {
	"id": "Teruel",
	"nm": "Bádenas"
}, {
	"id": "Teruel",
	"nm": "Báguena"
}, {
	"id": "Teruel",
	"nm": "Bañón"
}, {
	"id": "Teruel",
	"nm": "Barrachina"
}, {
	"id": "Teruel",
	"nm": "Bea"
}, {
	"id": "Teruel",
	"nm": "Beceite"
}, {
	"id": "Teruel",
	"nm": "Bello"
}, {
	"id": "Teruel",
	"nm": "Belmonte de San José"
}, {
	"id": "Teruel",
	"nm": "Berge"
}, {
	"id": "Teruel",
	"nm": "Bezas"
}, {
	"id": "Teruel",
	"nm": "Blancas"
}, {
	"id": "Teruel",
	"nm": "Blesa"
}, {
	"id": "Teruel",
	"nm": "Bordón"
}, {
	"id": "Teruel",
	"nm": "Bronchales"
}, {
	"id": "Teruel",
	"nm": "Bueña"
}, {
	"id": "Teruel",
	"nm": "Burbáguena"
}, {
	"id": "Teruel",
	"nm": "Cabra de Mora"
}, {
	"id": "Teruel",
	"nm": "Calaceite"
}, {
	"id": "Teruel",
	"nm": "Calamocha"
}, {
	"id": "Teruel",
	"nm": "Calanda"
}, {
	"id": "Teruel",
	"nm": "Calomarde"
}, {
	"id": "Teruel",
	"nm": "Camañas"
}, {
	"id": "Teruel",
	"nm": "Camarena de la Sierra"
}, {
	"id": "Teruel",
	"nm": "Camarillas"
}, {
	"id": "Teruel",
	"nm": "Caminreal"
}, {
	"id": "Teruel",
	"nm": "Cantavieja"
}, {
	"id": "Teruel",
	"nm": "Cañada de Benatanduz"
}, {
	"id": "Teruel",
	"nm": "Cañada de Verich, La"
}, {
	"id": "Teruel",
	"nm": "Cañada Vellida"
}, {
	"id": "Teruel",
	"nm": "Cañizar del Olivar"
}, {
	"id": "Teruel",
	"nm": "Cascante del Río"
}, {
	"id": "Teruel",
	"nm": "Castejón de Tornos"
}, {
	"id": "Teruel",
	"nm": "Castel de Cabra"
}, {
	"id": "Teruel",
	"nm": "Castellar, El"
}, {
	"id": "Teruel",
	"nm": "Castellote"
}, {
	"id": "Teruel",
	"nm": "Castelnou"
}, {
	"id": "Teruel",
	"nm": "Castelserás"
}, {
	"id": "Teruel",
	"nm": "Cedrillas"
}, {
	"id": "Teruel",
	"nm": "Celadas"
}, {
	"id": "Teruel",
	"nm": "Cella"
}, {
	"id": "Teruel",
	"nm": "Cerollera, La"
}, {
	"id": "Teruel",
	"nm": "Codoñera, La"
}, {
	"id": "Teruel",
	"nm": "Corbalán"
}, {
	"id": "Teruel",
	"nm": "Cortes de Aragón"
}, {
	"id": "Teruel",
	"nm": "Cosa"
}, {
	"id": "Teruel",
	"nm": "Cretas"
}, {
	"id": "Teruel",
	"nm": "Crivillén"
}, {
	"id": "Teruel",
	"nm": "Cuba, La"
}, {
	"id": "Teruel",
	"nm": "Cubla"
}, {
	"id": "Teruel",
	"nm": "Cucalón"
}, {
	"id": "Teruel",
	"nm": "Cuervo, El"
}, {
	"id": "Teruel",
	"nm": "Cuevas de Almudén"
}, {
	"id": "Teruel",
	"nm": "Cuevas Labradas"
}, {
	"id": "Teruel",
	"nm": "Ejulve"
}, {
	"id": "Teruel",
	"nm": "Escorihuela"
}, {
	"id": "Teruel",
	"nm": "Escucha"
}, {
	"id": "Teruel",
	"nm": "Estercuel"
}, {
	"id": "Teruel",
	"nm": "Ferreruela de Huerva"
}, {
	"id": "Teruel",
	"nm": "Fonfría"
}, {
	"id": "Teruel",
	"nm": "Formiche Alto"
}, {
	"id": "Teruel",
	"nm": "Fórnoles"
}, {
	"id": "Teruel",
	"nm": "Fortanete"
}, {
	"id": "Teruel",
	"nm": "Foz-Calanda"
}, {
	"id": "Teruel",
	"nm": "Fresneda, La"
}, {
	"id": "Teruel",
	"nm": "Frías de Albarracín"
}, {
	"id": "Teruel",
	"nm": "Fuenferrada"
}, {
	"id": "Teruel",
	"nm": "Fuentes Calientes"
}, {
	"id": "Teruel",
	"nm": "Fuentes Claras"
}, {
	"id": "Teruel",
	"nm": "Fuentes de Rubielos"
}, {
	"id": "Teruel",
	"nm": "Fuentespalda"
}, {
	"id": "Teruel",
	"nm": "Galve"
}, {
	"id": "Teruel",
	"nm": "Gargallo"
}, {
	"id": "Teruel",
	"nm": "Gea de Albarracín"
}, {
	"id": "Teruel",
	"nm": "Ginebrosa, La"
}, {
	"id": "Teruel",
	"nm": "Griegos"
}, {
	"id": "Teruel",
	"nm": "Guadalaviar"
}, {
	"id": "Teruel",
	"nm": "Gúdar"
}, {
	"id": "Teruel",
	"nm": "Híjar"
}, {
	"id": "Teruel",
	"nm": "Hinojosa de Jarque"
}, {
	"id": "Teruel",
	"nm": "Hoz de la Vieja, La"
}, {
	"id": "Teruel",
	"nm": "Huesa del Común"
}, {
	"id": "Teruel",
	"nm": "Iglesuela del Cid, La"
}, {
	"id": "Teruel",
	"nm": "Jabaloyas"
}, {
	"id": "Teruel",
	"nm": "Jarque de la Val"
}, {
	"id": "Teruel",
	"nm": "Jatiel"
}, {
	"id": "Teruel",
	"nm": "Jorcas"
}, {
	"id": "Teruel",
	"nm": "Josa"
}, {
	"id": "Teruel",
	"nm": "Lagueruela"
}, {
	"id": "Teruel",
	"nm": "Lanzuela"
}, {
	"id": "Teruel",
	"nm": "Libros"
}, {
	"id": "Teruel",
	"nm": "Lidón"
}, {
	"id": "Teruel",
	"nm": "Linares de Mora"
}, {
	"id": "Teruel",
	"nm": "Lledó"
}, {
	"id": "Teruel",
	"nm": "Loscos"
}, {
	"id": "Teruel",
	"nm": "Maicas"
}, {
	"id": "Teruel",
	"nm": "Manzanera"
}, {
	"id": "Teruel",
	"nm": "Martín del Río"
}, {
	"id": "Teruel",
	"nm": "Mas de las Matas"
}, {
	"id": "Teruel",
	"nm": "Mata de los Olmos, La"
}, {
	"id": "Teruel",
	"nm": "Mazaleón"
}, {
	"id": "Teruel",
	"nm": "Mezquita de Jarque"
}, {
	"id": "Teruel",
	"nm": "Mirambel"
}, {
	"id": "Teruel",
	"nm": "Miravete de la Sierra"
}, {
	"id": "Teruel",
	"nm": "Molinos"
}, {
	"id": "Teruel",
	"nm": "Monforte de Moyuela"
}, {
	"id": "Teruel",
	"nm": "Monreal del Campo"
}, {
	"id": "Teruel",
	"nm": "Monroyo"
}, {
	"id": "Teruel",
	"nm": "Montalbán"
}, {
	"id": "Teruel",
	"nm": "Monteagudo del Castillo"
}, {
	"id": "Teruel",
	"nm": "Monterde de Albarracín"
}, {
	"id": "Teruel",
	"nm": "Mora de Rubielos"
}, {
	"id": "Teruel",
	"nm": "Moscardón"
}, {
	"id": "Teruel",
	"nm": "Mosqueruela"
}, {
	"id": "Teruel",
	"nm": "Muniesa"
}, {
	"id": "Teruel",
	"nm": "Noguera de Albarracín"
}, {
	"id": "Teruel",
	"nm": "Nogueras"
}, {
	"id": "Teruel",
	"nm": "Nogueruelas"
}, {
	"id": "Teruel",
	"nm": "Obón"
}, {
	"id": "Teruel",
	"nm": "Odón"
}, {
	"id": "Teruel",
	"nm": "Ojos Negros"
}, {
	"id": "Teruel",
	"nm": "Olba"
}, {
	"id": "Teruel",
	"nm": "Oliete"
}, {
	"id": "Teruel",
	"nm": "Olmos, Los"
}, {
	"id": "Teruel",
	"nm": "Orihuela del Tremedal"
}, {
	"id": "Teruel",
	"nm": "Orrios"
}, {
	"id": "Teruel",
	"nm": "Palomar de Arroyos"
}, {
	"id": "Teruel",
	"nm": "Pancrudo"
}, {
	"id": "Teruel",
	"nm": "Parras de Castellote, Las"
}, {
	"id": "Teruel",
	"nm": "Peñarroya de Tastavins"
}, {
	"id": "Teruel",
	"nm": "Peracense"
}, {
	"id": "Teruel",
	"nm": "Peralejos"
}, {
	"id": "Teruel",
	"nm": "Perales del Alfambra"
}, {
	"id": "Teruel",
	"nm": "Pitarque"
}, {
	"id": "Teruel",
	"nm": "Plou"
}, {
	"id": "Teruel",
	"nm": "Pobo, El"
}, {
	"id": "Teruel",
	"nm": "Portellada, La"
}, {
	"id": "Teruel",
	"nm": "Pozondón"
}, {
	"id": "Teruel",
	"nm": "Pozuel del Campo"
}, {
	"id": "Teruel",
	"nm": "Puebla de Híjar, La"
}, {
	"id": "Teruel",
	"nm": "Puebla de Valverde, La"
}, {
	"id": "Teruel",
	"nm": "Puertomingalvo"
}, {
	"id": "Teruel",
	"nm": "Ráfales"
}, {
	"id": "Teruel",
	"nm": "Rillo"
}, {
	"id": "Teruel",
	"nm": "Riodeva"
}, {
	"id": "Teruel",
	"nm": "Ródenas"
}, {
	"id": "Teruel",
	"nm": "Royuela"
}, {
	"id": "Teruel",
	"nm": "Rubiales"
}, {
	"id": "Teruel",
	"nm": "Rubielos de la Cérida"
}, {
	"id": "Teruel",
	"nm": "Rubielos de Mora"
}, {
	"id": "Teruel",
	"nm": "Salcedillo"
}, {
	"id": "Teruel",
	"nm": "Saldón"
}, {
	"id": "Teruel",
	"nm": "Samper de Calanda"
}, {
	"id": "Teruel",
	"nm": "San Agustín"
}, {
	"id": "Teruel",
	"nm": "San Martín del Río"
}, {
	"id": "Teruel",
	"nm": "Santa Cruz de Nogueras"
}, {
	"id": "Teruel",
	"nm": "Santa Eulalia"
}, {
	"id": "Teruel",
	"nm": "Sarrión"
}, {
	"id": "Teruel",
	"nm": "Segura de los Baños"
}, {
	"id": "Teruel",
	"nm": "Seno"
}, {
	"id": "Teruel",
	"nm": "Singra"
}, {
	"id": "Teruel",
	"nm": "Terriente"
}, {
	"id": "Teruel",
	"nm": "Teruel"
}, {
	"id": "Teruel",
	"nm": "Toril y Masegoso"
}, {
	"id": "Teruel",
	"nm": "Tormón"
}, {
	"id": "Teruel",
	"nm": "Tornos"
}, {
	"id": "Teruel",
	"nm": "Torralba de los Sisones"
}, {
	"id": "Teruel",
	"nm": "Torre de Arcas"
}, {
	"id": "Teruel",
	"nm": "Torre de las Arcas"
}, {
	"id": "Teruel",
	"nm": "Torre del Compte"
}, {
	"id": "Teruel",
	"nm": "Torre los Negros"
}, {
	"id": "Teruel",
	"nm": "Torrecilla de Alcañiz"
}, {
	"id": "Teruel",
	"nm": "Torrecilla del Rebollar"
}, {
	"id": "Teruel",
	"nm": "Torrelacárcel"
}, {
	"id": "Teruel",
	"nm": "Torremocha de Jiloca"
}, {
	"id": "Teruel",
	"nm": "Torres de Albarracín"
}, {
	"id": "Teruel",
	"nm": "Torrevelilla"
}, {
	"id": "Teruel",
	"nm": "Torrijas"
}, {
	"id": "Teruel",
	"nm": "Torrijo del Campo"
}, {
	"id": "Teruel",
	"nm": "Tramacastiel"
}, {
	"id": "Teruel",
	"nm": "Tramacastilla"
}, {
	"id": "Teruel",
	"nm": "Tronchón"
}, {
	"id": "Teruel",
	"nm": "Urrea de Gaén"
}, {
	"id": "Teruel",
	"nm": "Utrillas"
}, {
	"id": "Teruel",
	"nm": "Valacloche"
}, {
	"id": "Teruel",
	"nm": "Valbona"
}, {
	"id": "Teruel",
	"nm": "Valdealgorfa"
}, {
	"id": "Teruel",
	"nm": "Valdecuenca"
}, {
	"id": "Teruel",
	"nm": "Valdelinares"
}, {
	"id": "Teruel",
	"nm": "Valdeltormo"
}, {
	"id": "Teruel",
	"nm": "Valderrobres"
}, {
	"id": "Teruel",
	"nm": "Valjunquera"
}, {
	"id": "Teruel",
	"nm": "Vallecillo, El"
}, {
	"id": "Teruel",
	"nm": "Veguillas de la Sierra"
}, {
	"id": "Teruel",
	"nm": "Villafranca del Campo"
}, {
	"id": "Teruel",
	"nm": "Villahermosa del Campo"
}, {
	"id": "Teruel",
	"nm": "Villanueva del Rebollar de la Sierra"
}, {
	"id": "Teruel",
	"nm": "Villar del Cobo"
}, {
	"id": "Teruel",
	"nm": "Villar del Salz"
}, {
	"id": "Teruel",
	"nm": "Villarluengo"
}, {
	"id": "Teruel",
	"nm": "Villarquemado"
}, {
	"id": "Teruel",
	"nm": "Villarroya de los Pinares"
}, {
	"id": "Teruel",
	"nm": "Villastar"
}, {
	"id": "Teruel",
	"nm": "Villel"
}, {
	"id": "Teruel",
	"nm": "Vinaceite"
}, {
	"id": "Teruel",
	"nm": "Visiedo"
}, {
	"id": "Teruel",
	"nm": "Vivel del Río Martín"
}, {
	"id": "Teruel",
	"nm": "Zoma, La"
}, {
	"id": "Toledo",
	"nm": "Ajofrín"
}, {
	"id": "Toledo",
	"nm": "Alameda de la Sagra"
}, {
	"id": "Toledo",
	"nm": "Albarreal de Tajo"
}, {
	"id": "Toledo",
	"nm": "Alcabón"
}, {
	"id": "Toledo",
	"nm": "Alcañizo"
}, {
	"id": "Toledo",
	"nm": "Alcaudete de la Jara"
}, {
	"id": "Toledo",
	"nm": "Alcolea de Tajo"
}, {
	"id": "Toledo",
	"nm": "Aldea en Cabo"
}, {
	"id": "Toledo",
	"nm": "Aldeanueva de Barbarroya"
}, {
	"id": "Toledo",
	"nm": "Aldeanueva de San Bartolomé"
}, {
	"id": "Toledo",
	"nm": "Almendral de la Cañada"
}, {
	"id": "Toledo",
	"nm": "Almonacid de Toledo"
}, {
	"id": "Toledo",
	"nm": "Almorox"
}, {
	"id": "Toledo",
	"nm": "Añover de Tajo"
}, {
	"id": "Toledo",
	"nm": "Arcicóllar"
}, {
	"id": "Toledo",
	"nm": "Argés"
}, {
	"id": "Toledo",
	"nm": "Azután"
}, {
	"id": "Toledo",
	"nm": "Barcience"
}, {
	"id": "Toledo",
	"nm": "Bargas"
}, {
	"id": "Toledo",
	"nm": "Belvís de la Jara"
}, {
	"id": "Toledo",
	"nm": "Borox"
}, {
	"id": "Toledo",
	"nm": "Buenaventura"
}, {
	"id": "Toledo",
	"nm": "Burguillos de Toledo"
}, {
	"id": "Toledo",
	"nm": "Burujón"
}, {
	"id": "Toledo",
	"nm": "Cabañas de la Sagra"
}, {
	"id": "Toledo",
	"nm": "Cabañas de Yepes"
}, {
	"id": "Toledo",
	"nm": "Cabezamesada"
}, {
	"id": "Toledo",
	"nm": "Calera y Chozas"
}, {
	"id": "Toledo",
	"nm": "Caleruela"
}, {
	"id": "Toledo",
	"nm": "Calzada de Oropesa"
}, {
	"id": "Toledo",
	"nm": "Camarena"
}, {
	"id": "Toledo",
	"nm": "Camarenilla"
}, {
	"id": "Toledo",
	"nm": "Campillo de la Jara, El"
}, {
	"id": "Toledo",
	"nm": "Camuñas"
}, {
	"id": "Toledo",
	"nm": "Cardiel de los Montes"
}, {
	"id": "Toledo",
	"nm": "Carmena"
}, {
	"id": "Toledo",
	"nm": "Carpio de Tajo, El"
}, {
	"id": "Toledo",
	"nm": "Carranque"
}, {
	"id": "Toledo",
	"nm": "Carriches"
}, {
	"id": "Toledo",
	"nm": "Casar de Escalona, El"
}, {
	"id": "Toledo",
	"nm": "Casarrubios del Monte"
}, {
	"id": "Toledo",
	"nm": "Casasbuenas"
}, {
	"id": "Toledo",
	"nm": "Castillo de Bayuela"
}, {
	"id": "Toledo",
	"nm": "Cazalegas"
}, {
	"id": "Toledo",
	"nm": "Cebolla"
}, {
	"id": "Toledo",
	"nm": "Cedillo del Condado"
}, {
	"id": "Toledo",
	"nm": "Cerralbos, Los"
}, {
	"id": "Toledo",
	"nm": "Cervera de los Montes"
}, {
	"id": "Toledo",
	"nm": "Chozas de Canales"
}, {
	"id": "Toledo",
	"nm": "Chueca"
}, {
	"id": "Toledo",
	"nm": "Ciruelos"
}, {
	"id": "Toledo",
	"nm": "Cobeja"
}, {
	"id": "Toledo",
	"nm": "Cobisa"
}, {
	"id": "Toledo",
	"nm": "Consuegra"
}, {
	"id": "Toledo",
	"nm": "Corral de Almaguer"
}, {
	"id": "Toledo",
	"nm": "Cuerva"
}, {
	"id": "Toledo",
	"nm": "Domingo Pérez"
}, {
	"id": "Toledo",
	"nm": "Dosbarrios"
}, {
	"id": "Toledo",
	"nm": "Erustes"
}, {
	"id": "Toledo",
	"nm": "Escalona"
}, {
	"id": "Toledo",
	"nm": "Escalonilla"
}, {
	"id": "Toledo",
	"nm": "Espinoso del Rey"
}, {
	"id": "Toledo",
	"nm": "Esquivias"
}, {
	"id": "Toledo",
	"nm": "Estrella, La"
}, {
	"id": "Toledo",
	"nm": "Fuensalida"
}, {
	"id": "Toledo",
	"nm": "Gálvez"
}, {
	"id": "Toledo",
	"nm": "Garciotum"
}, {
	"id": "Toledo",
	"nm": "Gerindote"
}, {
	"id": "Toledo",
	"nm": "Guadamur"
}, {
	"id": "Toledo",
	"nm": "Guardia, La"
}, {
	"id": "Toledo",
	"nm": "Herencias, Las"
}, {
	"id": "Toledo",
	"nm": "Herreruela de Oropesa"
}, {
	"id": "Toledo",
	"nm": "Hinojosa de San Vicente"
}, {
	"id": "Toledo",
	"nm": "Hontanar"
}, {
	"id": "Toledo",
	"nm": "Hormigos"
}, {
	"id": "Toledo",
	"nm": "Huecas"
}, {
	"id": "Toledo",
	"nm": "Huerta de Valdecarábanos"
}, {
	"id": "Toledo",
	"nm": "Iglesuela, La"
}, {
	"id": "Toledo",
	"nm": "Illán de Vacas"
}, {
	"id": "Toledo",
	"nm": "Illescas"
}, {
	"id": "Toledo",
	"nm": "Lagartera"
}, {
	"id": "Toledo",
	"nm": "Layos"
}, {
	"id": "Toledo",
	"nm": "Lillo"
}, {
	"id": "Toledo",
	"nm": "Lominchar"
}, {
	"id": "Toledo",
	"nm": "Lucillos"
}, {
	"id": "Toledo",
	"nm": "Madridejos"
}, {
	"id": "Toledo",
	"nm": "Magán"
}, {
	"id": "Toledo",
	"nm": "Malpica de Tajo"
}, {
	"id": "Toledo",
	"nm": "Manzaneque"
}, {
	"id": "Toledo",
	"nm": "Maqueda"
}, {
	"id": "Toledo",
	"nm": "Marjaliza"
}, {
	"id": "Toledo",
	"nm": "Marrupe"
}, {
	"id": "Toledo",
	"nm": "Mascaraque"
}, {
	"id": "Toledo",
	"nm": "Mata, La"
}, {
	"id": "Toledo",
	"nm": "Mazarambroz"
}, {
	"id": "Toledo",
	"nm": "Mejorada"
}, {
	"id": "Toledo",
	"nm": "Menasalbas"
}, {
	"id": "Toledo",
	"nm": "Méntrida"
}, {
	"id": "Toledo",
	"nm": "Mesegar de Tajo"
}, {
	"id": "Toledo",
	"nm": "Miguel Esteban"
}, {
	"id": "Toledo",
	"nm": "Mocejón"
}, {
	"id": "Toledo",
	"nm": "Mohedas de la Jara"
}, {
	"id": "Toledo",
	"nm": "Montearagón"
}, {
	"id": "Toledo",
	"nm": "Montesclaros"
}, {
	"id": "Toledo",
	"nm": "Mora"
}, {
	"id": "Toledo",
	"nm": "Nambroca"
}, {
	"id": "Toledo",
	"nm": "Nava de Ricomalillo, La"
}, {
	"id": "Toledo",
	"nm": "Navahermosa"
}, {
	"id": "Toledo",
	"nm": "Navalcán"
}, {
	"id": "Toledo",
	"nm": "Navalmoralejo"
}, {
	"id": "Toledo",
	"nm": "Navalmorales, Los"
}, {
	"id": "Toledo",
	"nm": "Navalucillos, Los"
}, {
	"id": "Toledo",
	"nm": "Navamorcuende"
}, {
	"id": "Toledo",
	"nm": "Noblejas"
}, {
	"id": "Toledo",
	"nm": "Noez"
}, {
	"id": "Toledo",
	"nm": "Nombela"
}, {
	"id": "Toledo",
	"nm": "Novés"
}, {
	"id": "Toledo",
	"nm": "Numancia de la Sagra"
}, {
	"id": "Toledo",
	"nm": "Nuño Gómez"
}, {
	"id": "Toledo",
	"nm": "Ocaña"
}, {
	"id": "Toledo",
	"nm": "Olías del Rey"
}, {
	"id": "Toledo",
	"nm": "Ontígola"
}, {
	"id": "Toledo",
	"nm": "Orgaz"
}, {
	"id": "Toledo",
	"nm": "Oropesa"
}, {
	"id": "Toledo",
	"nm": "Otero"
}, {
	"id": "Toledo",
	"nm": "Palomeque"
}, {
	"id": "Toledo",
	"nm": "Pantoja"
}, {
	"id": "Toledo",
	"nm": "Paredes de Escalona"
}, {
	"id": "Toledo",
	"nm": "Parrillas"
}, {
	"id": "Toledo",
	"nm": "Pelahustán"
}, {
	"id": "Toledo",
	"nm": "Pepino"
}, {
	"id": "Toledo",
	"nm": "Polán"
}, {
	"id": "Toledo",
	"nm": "Portillo de Toledo"
}, {
	"id": "Toledo",
	"nm": "Puebla de Almoradiel, La"
}, {
	"id": "Toledo",
	"nm": "Puebla de Montalbán, La"
}, {
	"id": "Toledo",
	"nm": "Pueblanueva, La"
}, {
	"id": "Toledo",
	"nm": "Puente del Arzobispo, El"
}, {
	"id": "Toledo",
	"nm": "Puerto de San Vicente"
}, {
	"id": "Toledo",
	"nm": "Pulgar"
}, {
	"id": "Toledo",
	"nm": "Quero"
}, {
	"id": "Toledo",
	"nm": "Quintanar de la Orden"
}, {
	"id": "Toledo",
	"nm": "Quismondo"
}, {
	"id": "Toledo",
	"nm": "Real de San Vicente, El"
}, {
	"id": "Toledo",
	"nm": "Recas"
}, {
	"id": "Toledo",
	"nm": "Retamoso de la Jara"
}, {
	"id": "Toledo",
	"nm": "Rielves"
}, {
	"id": "Toledo",
	"nm": "Robledo del Mazo"
}, {
	"id": "Toledo",
	"nm": "Romeral, El"
}, {
	"id": "Toledo",
	"nm": "San Bartolomé de las Abiertas"
}, {
	"id": "Toledo",
	"nm": "San Martín de Montalbán"
}, {
	"id": "Toledo",
	"nm": "San Martín de Pusa"
}, {
	"id": "Toledo",
	"nm": "San Pablo de los Montes"
}, {
	"id": "Toledo",
	"nm": "San Román de los Montes"
}, {
	"id": "Toledo",
	"nm": "Santa Ana de Pusa"
}, {
	"id": "Toledo",
	"nm": "Santa Cruz de la Zarza"
}, {
	"id": "Toledo",
	"nm": "Santa Cruz del Retamar"
}, {
	"id": "Toledo",
	"nm": "Santa Olalla"
}, {
	"id": "Toledo",
	"nm": "Santo Domingo-Caudilla"
}, {
	"id": "Toledo",
	"nm": "Sartajada"
}, {
	"id": "Toledo",
	"nm": "Segurilla"
}, {
	"id": "Toledo",
	"nm": "Seseña"
}, {
	"id": "Toledo",
	"nm": "Sevilleja de la Jara"
}, {
	"id": "Toledo",
	"nm": "Sonseca"
}, {
	"id": "Toledo",
	"nm": "Sotillo de las Palomas"
}, {
	"id": "Toledo",
	"nm": "Talavera de la Reina"
}, {
	"id": "Toledo",
	"nm": "Tembleque"
}, {
	"id": "Toledo",
	"nm": "Toboso, El"
}, {
	"id": "Toledo",
	"nm": "Toledo"
}, {
	"id": "Toledo",
	"nm": "Torralba de Oropesa"
}, {
	"id": "Toledo",
	"nm": "Torre de Esteban Hambrán, La"
}, {
	"id": "Toledo",
	"nm": "Torrecilla de la Jara"
}, {
	"id": "Toledo",
	"nm": "Torrico"
}, {
	"id": "Toledo",
	"nm": "Torrijos"
}, {
	"id": "Toledo",
	"nm": "Totanés"
}, {
	"id": "Toledo",
	"nm": "Turleque"
}, {
	"id": "Toledo",
	"nm": "Ugena"
}, {
	"id": "Toledo",
	"nm": "Urda"
}, {
	"id": "Toledo",
	"nm": "Valdeverdeja"
}, {
	"id": "Toledo",
	"nm": "Valmojado"
}, {
	"id": "Toledo",
	"nm": "Velada"
}, {
	"id": "Toledo",
	"nm": "Ventas con Peña Aguilera, Las"
}, {
	"id": "Toledo",
	"nm": "Ventas de Retamosa, Las"
}, {
	"id": "Toledo",
	"nm": "Ventas de San Julián, Las"
}, {
	"id": "Toledo",
	"nm": "Villa de Don Fadrique, La"
}, {
	"id": "Toledo",
	"nm": "Villacañas"
}, {
	"id": "Toledo",
	"nm": "Villafranca de los Caballeros"
}, {
	"id": "Toledo",
	"nm": "Villaluenga de la Sagra"
}, {
	"id": "Toledo",
	"nm": "Villamiel de Toledo"
}, {
	"id": "Toledo",
	"nm": "Villaminaya"
}, {
	"id": "Toledo",
	"nm": "Villamuelas"
}, {
	"id": "Toledo",
	"nm": "Villanueva de Alcardete"
}, {
	"id": "Toledo",
	"nm": "Villanueva de Bogas"
}, {
	"id": "Toledo",
	"nm": "Villarejo de Montalbán"
}, {
	"id": "Toledo",
	"nm": "Villarrubia de Santiago"
}, {
	"id": "Toledo",
	"nm": "Villaseca de la Sagra"
}, {
	"id": "Toledo",
	"nm": "Villasequilla"
}, {
	"id": "Toledo",
	"nm": "Villatobas"
}, {
	"id": "Toledo",
	"nm": "Viso de San Juan, El"
}, {
	"id": "Toledo",
	"nm": "Yébenes, Los"
}, {
	"id": "Toledo",
	"nm": "Yeles"
}, {
	"id": "Toledo",
	"nm": "Yepes"
}, {
	"id": "Toledo",
	"nm": "Yuncler"
}, {
	"id": "Toledo",
	"nm": "Yunclillos"
}, {
	"id": "Toledo",
	"nm": "Yuncos"
}, {
	"id": "Valencia/València",
	"nm": "Ademuz"
}, {
	"id": "Valencia/València",
	"nm": "Ador"
}, {
	"id": "Valencia/València",
	"nm": "Agullent"
}, {
	"id": "Valencia/València",
	"nm": "Aielo de Malferit"
}, {
	"id": "Valencia/València",
	"nm": "Aielo de Rugat"
}, {
	"id": "Valencia/València",
	"nm": "Alaquàs"
}, {
	"id": "Valencia/València",
	"nm": "Albaida"
}, {
	"id": "Valencia/València",
	"nm": "Albal"
}, {
	"id": "Valencia/València",
	"nm": "Albalat de la Ribera"
}, {
	"id": "Valencia/València",
	"nm": "Albalat dels Sorells"
}, {
	"id": "Valencia/València",
	"nm": "Albalat dels Tarongers"
}, {
	"id": "Valencia/València",
	"nm": "Alberic"
}, {
	"id": "Valencia/València",
	"nm": "Alborache"
}, {
	"id": "Valencia/València",
	"nm": "Alboraya"
}, {
	"id": "Valencia/València",
	"nm": "Albuixech"
}, {
	"id": "Valencia/València",
	"nm": "Alcàntera de Xúquer"
}, {
	"id": "Valencia/València",
	"nm": "Alcàsser"
}, {
	"id": "Valencia/València",
	"nm": "Alcublas"
}, {
	"id": "Valencia/València",
	"nm": "Alcúdia de Crespins, l'"
}, {
	"id": "Valencia/València",
	"nm": "Alcúdia, l'"
}, {
	"id": "Valencia/València",
	"nm": "Aldaia"
}, {
	"id": "Valencia/València",
	"nm": "Alfafar"
}, {
	"id": "Valencia/València",
	"nm": "Alfara de la Baronia"
}, {
	"id": "Valencia/València",
	"nm": "Alfara del Patriarca"
}, {
	"id": "Valencia/València",
	"nm": "Alfarp"
}, {
	"id": "Valencia/València",
	"nm": "Alfarrasí"
}, {
	"id": "Valencia/València",
	"nm": "Alfauir"
}, {
	"id": "Valencia/València",
	"nm": "Algar de Palancia"
}, {
	"id": "Valencia/València",
	"nm": "Algemesí"
}, {
	"id": "Valencia/València",
	"nm": "Algimia de Alfara"
}, {
	"id": "Valencia/València",
	"nm": "Alginet"
}, {
	"id": "Valencia/València",
	"nm": "Almàssera"
}, {
	"id": "Valencia/València",
	"nm": "Almiserà"
}, {
	"id": "Valencia/València",
	"nm": "Almoines"
}, {
	"id": "Valencia/València",
	"nm": "Almussafes"
}, {
	"id": "Valencia/València",
	"nm": "Alpuente"
}, {
	"id": "Valencia/València",
	"nm": "Alqueria de la Comtessa, l'"
}, {
	"id": "Valencia/València",
	"nm": "Alzira"
}, {
	"id": "Valencia/València",
	"nm": "Andilla"
}, {
	"id": "Valencia/València",
	"nm": "Anna"
}, {
	"id": "Valencia/València",
	"nm": "Antella"
}, {
	"id": "Valencia/València",
	"nm": "Aras de los Olmos"
}, {
	"id": "Valencia/València",
	"nm": "Atzeneta d'Albaida"
}, {
	"id": "Valencia/València",
	"nm": "Ayora"
}, {
	"id": "Valencia/València",
	"nm": "Barx"
}, {
	"id": "Valencia/València",
	"nm": "Barxeta"
}, {
	"id": "Valencia/València",
	"nm": "Bèlgida"
}, {
	"id": "Valencia/València",
	"nm": "Bellreguard"
}, {
	"id": "Valencia/València",
	"nm": "Bellús"
}, {
	"id": "Valencia/València",
	"nm": "Benagéber"
}, {
	"id": "Valencia/València",
	"nm": "Benaguasil"
}, {
	"id": "Valencia/València",
	"nm": "Benavites"
}, {
	"id": "Valencia/València",
	"nm": "Beneixida"
}, {
	"id": "Valencia/València",
	"nm": "Benetússer"
}, {
	"id": "Valencia/València",
	"nm": "Beniarjó"
}, {
	"id": "Valencia/València",
	"nm": "Beniatjar"
}, {
	"id": "Valencia/València",
	"nm": "Benicolet"
}, {
	"id": "Valencia/València",
	"nm": "Benicull de Xúquer"
}, {
	"id": "Valencia/València",
	"nm": "Benifaió"
}, {
	"id": "Valencia/València",
	"nm": "Benifairó de la Valldigna"
}, {
	"id": "Valencia/València",
	"nm": "Benifairó de les Valls"
}, {
	"id": "Valencia/València",
	"nm": "Beniflá"
}, {
	"id": "Valencia/València",
	"nm": "Benigànim"
}, {
	"id": "Valencia/València",
	"nm": "Benimodo"
}, {
	"id": "Valencia/València",
	"nm": "Benimuslem"
}, {
	"id": "Valencia/València",
	"nm": "Beniparrell"
}, {
	"id": "Valencia/València",
	"nm": "Benirredrà"
}, {
	"id": "Valencia/València",
	"nm": "Benisanó"
}, {
	"id": "Valencia/València",
	"nm": "Benissoda"
}, {
	"id": "Valencia/València",
	"nm": "Benisuera"
}, {
	"id": "Valencia/València",
	"nm": "Bétera"
}, {
	"id": "Valencia/València",
	"nm": "Bicorp"
}, {
	"id": "Valencia/València",
	"nm": "Bocairent"
}, {
	"id": "Valencia/València",
	"nm": "Bolbaite"
}, {
	"id": "Valencia/València",
	"nm": "Bonrepòs i Mirambell"
}, {
	"id": "Valencia/València",
	"nm": "Bufali"
}, {
	"id": "Valencia/València",
	"nm": "Bugarra"
}, {
	"id": "Valencia/València",
	"nm": "Buñol"
}, {
	"id": "Valencia/València",
	"nm": "Burjassot"
}, {
	"id": "Valencia/València",
	"nm": "Calles"
}, {
	"id": "Valencia/València",
	"nm": "Camporrobles"
}, {
	"id": "Valencia/València",
	"nm": "Canals"
}, {
	"id": "Valencia/València",
	"nm": "Canet d'En Berenguer"
}, {
	"id": "Valencia/València",
	"nm": "Carcaixent"
}, {
	"id": "Valencia/València",
	"nm": "Càrcer"
}, {
	"id": "Valencia/València",
	"nm": "Carlet"
}, {
	"id": "Valencia/València",
	"nm": "Carrícola"
}, {
	"id": "Valencia/València",
	"nm": "Casas Altas"
}, {
	"id": "Valencia/València",
	"nm": "Casas Bajas"
}, {
	"id": "Valencia/València",
	"nm": "Casinos"
}, {
	"id": "Valencia/València",
	"nm": "Castelló de Rugat"
}, {
	"id": "Valencia/València",
	"nm": "Castellonet de la Conquesta"
}, {
	"id": "Valencia/València",
	"nm": "Castielfabib"
}, {
	"id": "Valencia/València",
	"nm": "Catadau"
}, {
	"id": "Valencia/València",
	"nm": "Catarroja"
}, {
	"id": "Valencia/València",
	"nm": "Caudete de las Fuentes"
}, {
	"id": "Valencia/València",
	"nm": "Cerdà"
}, {
	"id": "Valencia/València",
	"nm": "Chella"
}, {
	"id": "Valencia/València",
	"nm": "Chelva"
}, {
	"id": "Valencia/València",
	"nm": "Chera"
}, {
	"id": "Valencia/València",
	"nm": "Cheste"
}, {
	"id": "Valencia/València",
	"nm": "Chiva"
}, {
	"id": "Valencia/València",
	"nm": "Chulilla"
}, {
	"id": "Valencia/València",
	"nm": "Cofrentes"
}, {
	"id": "Valencia/València",
	"nm": "Corbera"
}, {
	"id": "Valencia/València",
	"nm": "Cortes de Pallás"
}, {
	"id": "Valencia/València",
	"nm": "Cotes"
}, {
	"id": "Valencia/València",
	"nm": "Cullera"
}, {
	"id": "Valencia/València",
	"nm": "Daimús"
}, {
	"id": "Valencia/València",
	"nm": "Domeño"
}, {
	"id": "Valencia/València",
	"nm": "Dos Aguas"
}, {
	"id": "Valencia/València",
	"nm": "Eliana, l'"
}, {
	"id": "Valencia/València",
	"nm": "Emperador"
}, {
	"id": "Valencia/València",
	"nm": "Enguera"
}, {
	"id": "Valencia/València",
	"nm": "Ènova, l'"
}, {
	"id": "Valencia/València",
	"nm": "Estivella"
}, {
	"id": "Valencia/València",
	"nm": "Estubeny"
}, {
	"id": "Valencia/València",
	"nm": "Faura"
}, {
	"id": "Valencia/València",
	"nm": "Favara"
}, {
	"id": "Valencia/València",
	"nm": "Foios"
}, {
	"id": "Valencia/València",
	"nm": "Font de la Figuera, la"
}, {
	"id": "Valencia/València",
	"nm": "Font d'En Carròs, la"
}, {
	"id": "Valencia/València",
	"nm": "Fontanars dels Alforins"
}, {
	"id": "Valencia/València",
	"nm": "Fortaleny"
}, {
	"id": "Valencia/València",
	"nm": "Fuenterrobles"
}, {
	"id": "Valencia/València",
	"nm": "Gandia"
}, {
	"id": "Valencia/València",
	"nm": "Gátova"
}, {
	"id": "Valencia/València",
	"nm": "Gavarda"
}, {
	"id": "Valencia/València",
	"nm": "Genovés"
}, {
	"id": "Valencia/València",
	"nm": "Gestalgar"
}, {
	"id": "Valencia/València",
	"nm": "Gilet"
}, {
	"id": "Valencia/València",
	"nm": "Godella"
}, {
	"id": "Valencia/València",
	"nm": "Godelleta"
}, {
	"id": "Valencia/València",
	"nm": "Granja de la Costera, la"
}, {
	"id": "Valencia/València",
	"nm": "Guadasséquies"
}, {
	"id": "Valencia/València",
	"nm": "Guadassuar"
}, {
	"id": "Valencia/València",
	"nm": "Guardamar de la Safor"
}, {
	"id": "Valencia/València",
	"nm": "Higueruelas"
}, {
	"id": "Valencia/València",
	"nm": "Jalance"
}, {
	"id": "Valencia/València",
	"nm": "Jarafuel"
}, {
	"id": "Valencia/València",
	"nm": "Llanera de Ranes"
}, {
	"id": "Valencia/València",
	"nm": "Llaurí"
}, {
	"id": "Valencia/València",
	"nm": "Llíria"
}, {
	"id": "Valencia/València",
	"nm": "Llocnou de la Corona"
}, {
	"id": "Valencia/València",
	"nm": "Llocnou de Sant Jeroni"
}, {
	"id": "Valencia/València",
	"nm": "Llocnou d'En Fenollet"
}, {
	"id": "Valencia/València",
	"nm": "Llombai"
}, {
	"id": "Valencia/València",
	"nm": "Llosa de Ranes, la"
}, {
	"id": "Valencia/València",
	"nm": "Llutxent"
}, {
	"id": "Valencia/València",
	"nm": "Loriguilla"
}, {
	"id": "Valencia/València",
	"nm": "Losa del Obispo"
}, {
	"id": "Valencia/València",
	"nm": "Macastre"
}, {
	"id": "Valencia/València",
	"nm": "Manises"
}, {
	"id": "Valencia/València",
	"nm": "Manuel"
}, {
	"id": "Valencia/València",
	"nm": "Marines"
}, {
	"id": "Valencia/València",
	"nm": "Massalavés"
}, {
	"id": "Valencia/València",
	"nm": "Massalfassar"
}, {
	"id": "Valencia/València",
	"nm": "Massamagrell"
}, {
	"id": "Valencia/València",
	"nm": "Massanassa"
}, {
	"id": "Valencia/València",
	"nm": "Meliana"
}, {
	"id": "Valencia/València",
	"nm": "Millares"
}, {
	"id": "Valencia/València",
	"nm": "Miramar"
}, {
	"id": "Valencia/València",
	"nm": "Mislata"
}, {
	"id": "Valencia/València",
	"nm": "Mogente/Moixent"
}, {
	"id": "Valencia/València",
	"nm": "Moncada"
}, {
	"id": "Valencia/València",
	"nm": "Montaverner"
}, {
	"id": "Valencia/València",
	"nm": "Montesa"
}, {
	"id": "Valencia/València",
	"nm": "Montitxelvo/Montichelvo"
}, {
	"id": "Valencia/València",
	"nm": "Montroi/Montroy"
}, {
	"id": "Valencia/València",
	"nm": "Montserrat"
}, {
	"id": "Valencia/València",
	"nm": "Museros"
}, {
	"id": "Valencia/València",
	"nm": "Náquera"
}, {
	"id": "Valencia/València",
	"nm": "Navarrés"
}, {
	"id": "Valencia/València",
	"nm": "Novelé/Novetlè"
}, {
	"id": "Valencia/València",
	"nm": "Oliva"
}, {
	"id": "Valencia/València",
	"nm": "Olleria, l'"
}, {
	"id": "Valencia/València",
	"nm": "Olocau"
}, {
	"id": "Valencia/València",
	"nm": "Ontinyent"
}, {
	"id": "Valencia/València",
	"nm": "Otos"
}, {
	"id": "Valencia/València",
	"nm": "Paiporta"
}, {
	"id": "Valencia/València",
	"nm": "Palma de Gandía"
}, {
	"id": "Valencia/València",
	"nm": "Palmera"
}, {
	"id": "Valencia/València",
	"nm": "Palomar, el"
}, {
	"id": "Valencia/València",
	"nm": "Paterna"
}, {
	"id": "Valencia/València",
	"nm": "Pedralba"
}, {
	"id": "Valencia/València",
	"nm": "Petrés"
}, {
	"id": "Valencia/València",
	"nm": "Picanya"
}, {
	"id": "Valencia/València",
	"nm": "Picassent"
}, {
	"id": "Valencia/València",
	"nm": "Piles"
}, {
	"id": "Valencia/València",
	"nm": "Pinet"
}, {
	"id": "Valencia/València",
	"nm": "Pobla de Farnals, la"
}, {
	"id": "Valencia/València",
	"nm": "Pobla de Vallbona, la"
}, {
	"id": "Valencia/València",
	"nm": "Pobla del Duc, la"
}, {
	"id": "Valencia/València",
	"nm": "Pobla Llarga, la"
}, {
	"id": "Valencia/València",
	"nm": "Polinyà de Xúquer"
}, {
	"id": "Valencia/València",
	"nm": "Potries"
}, {
	"id": "Valencia/València",
	"nm": "Puçol"
}, {
	"id": "Valencia/València",
	"nm": "Puebla de San Miguel"
}, {
	"id": "Valencia/València",
	"nm": "Puig de Santa Maria, el"
}, {
	"id": "Valencia/València",
	"nm": "Quart de les Valls"
}, {
	"id": "Valencia/València",
	"nm": "Quart de Poblet"
}, {
	"id": "Valencia/València",
	"nm": "Quartell"
}, {
	"id": "Valencia/València",
	"nm": "Quatretonda"
}, {
	"id": "Valencia/València",
	"nm": "Quesa"
}, {
	"id": "Valencia/València",
	"nm": "Rafelbunyol"
}, {
	"id": "Valencia/València",
	"nm": "Rafelcofer"
}, {
	"id": "Valencia/València",
	"nm": "Rafelguaraf"
}, {
	"id": "Valencia/València",
	"nm": "Ráfol de Salem"
}, {
	"id": "Valencia/València",
	"nm": "Real"
}, {
	"id": "Valencia/València",
	"nm": "Real de Gandía"
}, {
	"id": "Valencia/València",
	"nm": "Requena"
}, {
	"id": "Valencia/València",
	"nm": "Riba-roja de Túria"
}, {
	"id": "Valencia/València",
	"nm": "Riola"
}, {
	"id": "Valencia/València",
	"nm": "Rocafort"
}, {
	"id": "Valencia/València",
	"nm": "Rotglà i Corberà"
}, {
	"id": "Valencia/València",
	"nm": "Rótova"
}, {
	"id": "Valencia/València",
	"nm": "Rugat"
}, {
	"id": "Valencia/València",
	"nm": "Sagunto/Sagunt"
}, {
	"id": "Valencia/València",
	"nm": "Salem"
}, {
	"id": "Valencia/València",
	"nm": "San Antonio de Benagéber"
}, {
	"id": "Valencia/València",
	"nm": "Sant Joanet"
}, {
	"id": "Valencia/València",
	"nm": "Sedaví"
}, {
	"id": "Valencia/València",
	"nm": "Segart"
}, {
	"id": "Valencia/València",
	"nm": "Sellent"
}, {
	"id": "Valencia/València",
	"nm": "Sempere"
}, {
	"id": "Valencia/València",
	"nm": "Senyera"
}, {
	"id": "Valencia/València",
	"nm": "Serra"
}, {
	"id": "Valencia/València",
	"nm": "Siete Aguas"
}, {
	"id": "Valencia/València",
	"nm": "Silla"
}, {
	"id": "Valencia/València",
	"nm": "Simat de la Valldigna"
}, {
	"id": "Valencia/València",
	"nm": "Sinarcas"
}, {
	"id": "Valencia/València",
	"nm": "Sollana"
}, {
	"id": "Valencia/València",
	"nm": "Sot de Chera"
}, {
	"id": "Valencia/València",
	"nm": "Sueca"
}, {
	"id": "Valencia/València",
	"nm": "Sumacàrcer"
}, {
	"id": "Valencia/València",
	"nm": "Tavernes Blanques"
}, {
	"id": "Valencia/València",
	"nm": "Tavernes de la Valldigna"
}, {
	"id": "Valencia/València",
	"nm": "Teresa de Cofrentes"
}, {
	"id": "Valencia/València",
	"nm": "Terrateig"
}, {
	"id": "Valencia/València",
	"nm": "Titaguas"
}, {
	"id": "Valencia/València",
	"nm": "Torrebaja"
}, {
	"id": "Valencia/València",
	"nm": "Torrella"
}, {
	"id": "Valencia/València",
	"nm": "Torrent"
}, {
	"id": "Valencia/València",
	"nm": "Torres Torres"
}, {
	"id": "Valencia/València",
	"nm": "Tous"
}, {
	"id": "Valencia/València",
	"nm": "Tuéjar"
}, {
	"id": "Valencia/València",
	"nm": "Turís"
}, {
	"id": "Valencia/València",
	"nm": "Utiel"
}, {
	"id": "Valencia/València",
	"nm": "Valencia"
}, {
	"id": "Valencia/València",
	"nm": "Vallada"
}, {
	"id": "Valencia/València",
	"nm": "Vallanca"
}, {
	"id": "Valencia/València",
	"nm": "Vallés"
}, {
	"id": "Valencia/València",
	"nm": "Venta del Moro"
}, {
	"id": "Valencia/València",
	"nm": "Vilallonga/Villalonga"
}, {
	"id": "Valencia/València",
	"nm": "Vilamarxant"
}, {
	"id": "Valencia/València",
	"nm": "Villanueva de Castellón"
}, {
	"id": "Valencia/València",
	"nm": "Villar del Arzobispo"
}, {
	"id": "Valencia/València",
	"nm": "Villargordo del Cabriel"
}, {
	"id": "Valencia/València",
	"nm": "Vinalesa"
}, {
	"id": "Valencia/València",
	"nm": "Xàtiva"
}, {
	"id": "Valencia/València",
	"nm": "Xeraco"
}, {
	"id": "Valencia/València",
	"nm": "Xeresa"
}, {
	"id": "Valencia/València",
	"nm": "Xirivella"
}, {
	"id": "Valencia/València",
	"nm": "Yátova"
}, {
	"id": "Valencia/València",
	"nm": "Yesa, La"
}, {
	"id": "Valencia/València",
	"nm": "Zarra"
}, {
	"id": "Valladolid",
	"nm": "Adalia"
}, {
	"id": "Valladolid",
	"nm": "Aguasal"
}, {
	"id": "Valladolid",
	"nm": "Aguilar de Campos"
}, {
	"id": "Valladolid",
	"nm": "Alaejos"
}, {
	"id": "Valladolid",
	"nm": "Alcazarén"
}, {
	"id": "Valladolid",
	"nm": "Aldea de San Miguel"
}, {
	"id": "Valladolid",
	"nm": "Aldeamayor de San Martín"
}, {
	"id": "Valladolid",
	"nm": "Almenara de Adaja"
}, {
	"id": "Valladolid",
	"nm": "Amusquillo"
}, {
	"id": "Valladolid",
	"nm": "Arroyo de la Encomienda"
}, {
	"id": "Valladolid",
	"nm": "Ataquines"
}, {
	"id": "Valladolid",
	"nm": "Bahabón"
}, {
	"id": "Valladolid",
	"nm": "Barcial de la Loma"
}, {
	"id": "Valladolid",
	"nm": "Barruelo del Valle"
}, {
	"id": "Valladolid",
	"nm": "Becilla de Valderaduey"
}, {
	"id": "Valladolid",
	"nm": "Benafarces"
}, {
	"id": "Valladolid",
	"nm": "Bercero"
}, {
	"id": "Valladolid",
	"nm": "Berceruelo"
}, {
	"id": "Valladolid",
	"nm": "Berrueces"
}, {
	"id": "Valladolid",
	"nm": "Bobadilla del Campo"
}, {
	"id": "Valladolid",
	"nm": "Bocigas"
}, {
	"id": "Valladolid",
	"nm": "Bocos de Duero"
}, {
	"id": "Valladolid",
	"nm": "Boecillo"
}, {
	"id": "Valladolid",
	"nm": "Bolaños de Campos"
}, {
	"id": "Valladolid",
	"nm": "Brahojos de Medina"
}, {
	"id": "Valladolid",
	"nm": "Bustillo de Chaves"
}, {
	"id": "Valladolid",
	"nm": "Cabezón de Pisuerga"
}, {
	"id": "Valladolid",
	"nm": "Cabezón de Valderaduey"
}, {
	"id": "Valladolid",
	"nm": "Cabreros del Monte"
}, {
	"id": "Valladolid",
	"nm": "Campaspero"
}, {
	"id": "Valladolid",
	"nm": "Campillo, El"
}, {
	"id": "Valladolid",
	"nm": "Camporredondo"
}, {
	"id": "Valladolid",
	"nm": "Canalejas de Peñafiel"
}, {
	"id": "Valladolid",
	"nm": "Canillas de Esgueva"
}, {
	"id": "Valladolid",
	"nm": "Carpio"
}, {
	"id": "Valladolid",
	"nm": "Casasola de Arión"
}, {
	"id": "Valladolid",
	"nm": "Castrejón de Trabancos"
}, {
	"id": "Valladolid",
	"nm": "Castrillo de Duero"
}, {
	"id": "Valladolid",
	"nm": "Castrillo-Tejeriego"
}, {
	"id": "Valladolid",
	"nm": "Castrobol"
}, {
	"id": "Valladolid",
	"nm": "Castrodeza"
}, {
	"id": "Valladolid",
	"nm": "Castromembibre"
}, {
	"id": "Valladolid",
	"nm": "Castromonte"
}, {
	"id": "Valladolid",
	"nm": "Castronuevo de Esgueva"
}, {
	"id": "Valladolid",
	"nm": "Castronuño"
}, {
	"id": "Valladolid",
	"nm": "Castroponce"
}, {
	"id": "Valladolid",
	"nm": "Castroverde de Cerrato"
}, {
	"id": "Valladolid",
	"nm": "Ceinos de Campos"
}, {
	"id": "Valladolid",
	"nm": "Cervillego de la Cruz"
}, {
	"id": "Valladolid",
	"nm": "Cigales"
}, {
	"id": "Valladolid",
	"nm": "Ciguñuela"
}, {
	"id": "Valladolid",
	"nm": "Cistérniga"
}, {
	"id": "Valladolid",
	"nm": "Cogeces de Íscar"
}, {
	"id": "Valladolid",
	"nm": "Cogeces del Monte"
}, {
	"id": "Valladolid",
	"nm": "Corcos"
}, {
	"id": "Valladolid",
	"nm": "Corrales de Duero"
}, {
	"id": "Valladolid",
	"nm": "Cubillas de Santa Marta"
}, {
	"id": "Valladolid",
	"nm": "Cuenca de Campos"
}, {
	"id": "Valladolid",
	"nm": "Curiel de Duero"
}, {
	"id": "Valladolid",
	"nm": "Encinas de Esgueva"
}, {
	"id": "Valladolid",
	"nm": "Esguevillas de Esgueva"
}, {
	"id": "Valladolid",
	"nm": "Fombellida"
}, {
	"id": "Valladolid",
	"nm": "Fompedraza"
}, {
	"id": "Valladolid",
	"nm": "Fontihoyuelo"
}, {
	"id": "Valladolid",
	"nm": "Fresno el Viejo"
}, {
	"id": "Valladolid",
	"nm": "Fuensaldaña"
}, {
	"id": "Valladolid",
	"nm": "Fuente el Sol"
}, {
	"id": "Valladolid",
	"nm": "Fuente-Olmedo"
}, {
	"id": "Valladolid",
	"nm": "Gallegos de Hornija"
}, {
	"id": "Valladolid",
	"nm": "Gatón de Campos"
}, {
	"id": "Valladolid",
	"nm": "Geria"
}, {
	"id": "Valladolid",
	"nm": "Herrín de Campos"
}, {
	"id": "Valladolid",
	"nm": "Hornillos de Eresma"
}, {
	"id": "Valladolid",
	"nm": "Íscar"
}, {
	"id": "Valladolid",
	"nm": "Laguna de Duero"
}, {
	"id": "Valladolid",
	"nm": "Langayo"
}, {
	"id": "Valladolid",
	"nm": "Llano de Olmedo"
}, {
	"id": "Valladolid",
	"nm": "Lomoviejo"
}, {
	"id": "Valladolid",
	"nm": "Manzanillo"
}, {
	"id": "Valladolid",
	"nm": "Marzales"
}, {
	"id": "Valladolid",
	"nm": "Matapozuelos"
}, {
	"id": "Valladolid",
	"nm": "Matilla de los Caños"
}, {
	"id": "Valladolid",
	"nm": "Mayorga"
}, {
	"id": "Valladolid",
	"nm": "Medina de Rioseco"
}, {
	"id": "Valladolid",
	"nm": "Medina del Campo"
}, {
	"id": "Valladolid",
	"nm": "Megeces"
}, {
	"id": "Valladolid",
	"nm": "Melgar de Abajo"
}, {
	"id": "Valladolid",
	"nm": "Melgar de Arriba"
}, {
	"id": "Valladolid",
	"nm": "Mojados"
}, {
	"id": "Valladolid",
	"nm": "Monasterio de Vega"
}, {
	"id": "Valladolid",
	"nm": "Montealegre de Campos"
}, {
	"id": "Valladolid",
	"nm": "Montemayor de Pililla"
}, {
	"id": "Valladolid",
	"nm": "Moral de la Reina"
}, {
	"id": "Valladolid",
	"nm": "Moraleja de las Panaderas"
}, {
	"id": "Valladolid",
	"nm": "Morales de Campos"
}, {
	"id": "Valladolid",
	"nm": "Mota del Marqués"
}, {
	"id": "Valladolid",
	"nm": "Mucientes"
}, {
	"id": "Valladolid",
	"nm": "Mudarra, La"
}, {
	"id": "Valladolid",
	"nm": "Muriel"
}, {
	"id": "Valladolid",
	"nm": "Nava del Rey"
}, {
	"id": "Valladolid",
	"nm": "Nueva Villa de las Torres"
}, {
	"id": "Valladolid",
	"nm": "Olivares de Duero"
}, {
	"id": "Valladolid",
	"nm": "Olmedo"
}, {
	"id": "Valladolid",
	"nm": "Olmos de Esgueva"
}, {
	"id": "Valladolid",
	"nm": "Olmos de Peñafiel"
}, {
	"id": "Valladolid",
	"nm": "Palazuelo de Vedija"
}, {
	"id": "Valladolid",
	"nm": "Parrilla, La"
}, {
	"id": "Valladolid",
	"nm": "Pedraja de Portillo, La"
}, {
	"id": "Valladolid",
	"nm": "Pedrajas de San Esteban"
}, {
	"id": "Valladolid",
	"nm": "Pedrosa del Rey"
}, {
	"id": "Valladolid",
	"nm": "Peñafiel"
}, {
	"id": "Valladolid",
	"nm": "Peñaflor de Hornija"
}, {
	"id": "Valladolid",
	"nm": "Pesquera de Duero"
}, {
	"id": "Valladolid",
	"nm": "Piña de Esgueva"
}, {
	"id": "Valladolid",
	"nm": "Piñel de Abajo"
}, {
	"id": "Valladolid",
	"nm": "Piñel de Arriba"
}, {
	"id": "Valladolid",
	"nm": "Pollos"
}, {
	"id": "Valladolid",
	"nm": "Portillo"
}, {
	"id": "Valladolid",
	"nm": "Pozal de Gallinas"
}, {
	"id": "Valladolid",
	"nm": "Pozaldez"
}, {
	"id": "Valladolid",
	"nm": "Pozuelo de la Orden"
}, {
	"id": "Valladolid",
	"nm": "Puras"
}, {
	"id": "Valladolid",
	"nm": "Quintanilla de Arriba"
}, {
	"id": "Valladolid",
	"nm": "Quintanilla de Onésimo"
}, {
	"id": "Valladolid",
	"nm": "Quintanilla de Trigueros"
}, {
	"id": "Valladolid",
	"nm": "Quintanilla del Molar"
}, {
	"id": "Valladolid",
	"nm": "Rábano"
}, {
	"id": "Valladolid",
	"nm": "Ramiro"
}, {
	"id": "Valladolid",
	"nm": "Renedo de Esgueva"
}, {
	"id": "Valladolid",
	"nm": "Roales de Campos"
}, {
	"id": "Valladolid",
	"nm": "Robladillo"
}, {
	"id": "Valladolid",
	"nm": "Roturas"
}, {
	"id": "Valladolid",
	"nm": "Rubí de Bracamonte"
}, {
	"id": "Valladolid",
	"nm": "Rueda"
}, {
	"id": "Valladolid",
	"nm": "Saelices de Mayorga"
}, {
	"id": "Valladolid",
	"nm": "Salvador de Zapardiel"
}, {
	"id": "Valladolid",
	"nm": "San Cebrián de Mazote"
}, {
	"id": "Valladolid",
	"nm": "San Llorente"
}, {
	"id": "Valladolid",
	"nm": "San Martín de Valvení"
}, {
	"id": "Valladolid",
	"nm": "San Miguel del Arroyo"
}, {
	"id": "Valladolid",
	"nm": "San Miguel del Pino"
}, {
	"id": "Valladolid",
	"nm": "San Pablo de la Moraleja"
}, {
	"id": "Valladolid",
	"nm": "San Pedro de Latarce"
}, {
	"id": "Valladolid",
	"nm": "San Pelayo"
}, {
	"id": "Valladolid",
	"nm": "San Román de Hornija"
}, {
	"id": "Valladolid",
	"nm": "San Salvador"
}, {
	"id": "Valladolid",
	"nm": "San Vicente del Palacio"
}, {
	"id": "Valladolid",
	"nm": "Santa Eufemia del Arroyo"
}, {
	"id": "Valladolid",
	"nm": "Santervás de Campos"
}, {
	"id": "Valladolid",
	"nm": "Santibáñez de Valcorba"
}, {
	"id": "Valladolid",
	"nm": "Santovenia de Pisuerga"
}, {
	"id": "Valladolid",
	"nm": "Sardón de Duero"
}, {
	"id": "Valladolid",
	"nm": "Seca, La"
}, {
	"id": "Valladolid",
	"nm": "Serrada"
}, {
	"id": "Valladolid",
	"nm": "Siete Iglesias de Trabancos"
}, {
	"id": "Valladolid",
	"nm": "Simancas"
}, {
	"id": "Valladolid",
	"nm": "Tamariz de Campos"
}, {
	"id": "Valladolid",
	"nm": "Tiedra"
}, {
	"id": "Valladolid",
	"nm": "Tordehumos"
}, {
	"id": "Valladolid",
	"nm": "Tordesillas"
}, {
	"id": "Valladolid",
	"nm": "Torre de Esgueva"
}, {
	"id": "Valladolid",
	"nm": "Torre de Peñafiel"
}, {
	"id": "Valladolid",
	"nm": "Torrecilla de la Abadesa"
}, {
	"id": "Valladolid",
	"nm": "Torrecilla de la Orden"
}, {
	"id": "Valladolid",
	"nm": "Torrecilla de la Torre"
}, {
	"id": "Valladolid",
	"nm": "Torrelobatón"
}, {
	"id": "Valladolid",
	"nm": "Torrescárcela"
}, {
	"id": "Valladolid",
	"nm": "Traspinedo"
}, {
	"id": "Valladolid",
	"nm": "Trigueros del Valle"
}, {
	"id": "Valladolid",
	"nm": "Tudela de Duero"
}, {
	"id": "Valladolid",
	"nm": "Unión de Campos, La"
}, {
	"id": "Valladolid",
	"nm": "Urones de Castroponce"
}, {
	"id": "Valladolid",
	"nm": "Urueña"
}, {
	"id": "Valladolid",
	"nm": "Valbuena de Duero"
}, {
	"id": "Valladolid",
	"nm": "Valdearcos de la Vega"
}, {
	"id": "Valladolid",
	"nm": "Valdenebro de los Valles"
}, {
	"id": "Valladolid",
	"nm": "Valdestillas"
}, {
	"id": "Valladolid",
	"nm": "Valdunquillo"
}, {
	"id": "Valladolid",
	"nm": "Valladolid"
}, {
	"id": "Valladolid",
	"nm": "Valoria la Buena"
}, {
	"id": "Valladolid",
	"nm": "Valverde de Campos"
}, {
	"id": "Valladolid",
	"nm": "Vega de Ruiponce"
}, {
	"id": "Valladolid",
	"nm": "Vega de Valdetronco"
}, {
	"id": "Valladolid",
	"nm": "Velascálvaro"
}, {
	"id": "Valladolid",
	"nm": "Velilla"
}, {
	"id": "Valladolid",
	"nm": "Velliza"
}, {
	"id": "Valladolid",
	"nm": "Ventosa de la Cuesta"
}, {
	"id": "Valladolid",
	"nm": "Viana de Cega"
}, {
	"id": "Valladolid",
	"nm": "Villabáñez"
}, {
	"id": "Valladolid",
	"nm": "Villabaruz de Campos"
}, {
	"id": "Valladolid",
	"nm": "Villabrágima"
}, {
	"id": "Valladolid",
	"nm": "Villacarralón"
}, {
	"id": "Valladolid",
	"nm": "Villacid de Campos"
}, {
	"id": "Valladolid",
	"nm": "Villaco"
}, {
	"id": "Valladolid",
	"nm": "Villafrades de Campos"
}, {
	"id": "Valladolid",
	"nm": "Villafranca de Duero"
}, {
	"id": "Valladolid",
	"nm": "Villafrechós"
}, {
	"id": "Valladolid",
	"nm": "Villafuerte"
}, {
	"id": "Valladolid",
	"nm": "Villagarcía de Campos"
}, {
	"id": "Valladolid",
	"nm": "Villagómez la Nueva"
}, {
	"id": "Valladolid",
	"nm": "Villalán de Campos"
}, {
	"id": "Valladolid",
	"nm": "Villalar de los Comuneros"
}, {
	"id": "Valladolid",
	"nm": "Villalba de la Loma"
}, {
	"id": "Valladolid",
	"nm": "Villalba de los Alcores"
}, {
	"id": "Valladolid",
	"nm": "Villalbarba"
}, {
	"id": "Valladolid",
	"nm": "Villalón de Campos"
}, {
	"id": "Valladolid",
	"nm": "Villamuriel de Campos"
}, {
	"id": "Valladolid",
	"nm": "Villán de Tordesillas"
}, {
	"id": "Valladolid",
	"nm": "Villanubla"
}, {
	"id": "Valladolid",
	"nm": "Villanueva de Duero"
}, {
	"id": "Valladolid",
	"nm": "Villanueva de la Condesa"
}, {
	"id": "Valladolid",
	"nm": "Villanueva de los Caballeros"
}, {
	"id": "Valladolid",
	"nm": "Villanueva de los Infantes"
}, {
	"id": "Valladolid",
	"nm": "Villanueva de San Mancio"
}, {
	"id": "Valladolid",
	"nm": "Villardefrades"
}, {
	"id": "Valladolid",
	"nm": "Villarmentero de Esgueva"
}, {
	"id": "Valladolid",
	"nm": "Villasexmir"
}, {
	"id": "Valladolid",
	"nm": "Villavaquerín"
}, {
	"id": "Valladolid",
	"nm": "Villavellid"
}, {
	"id": "Valladolid",
	"nm": "Villaverde de Medina"
}, {
	"id": "Valladolid",
	"nm": "Villavicencio de los Caballeros"
}, {
	"id": "Valladolid",
	"nm": "Viloria"
}, {
	"id": "Valladolid",
	"nm": "Wamba"
}, {
	"id": "Valladolid",
	"nm": "Zaratán"
}, {
	"id": "Valladolid",
	"nm": "Zarza, La"
}, {
	"id": "Bizkaia",
	"nm": "Abadiño"
}, {
	"id": "Bizkaia",
	"nm": "Abanto y Ciérvana-Abanto Zierbena"
}, {
	"id": "Bizkaia",
	"nm": "Ajangiz"
}, {
	"id": "Bizkaia",
	"nm": "Alonsotegi"
}, {
	"id": "Bizkaia",
	"nm": "Amorebieta-Etxano"
}, {
	"id": "Bizkaia",
	"nm": "Amoroto"
}, {
	"id": "Bizkaia",
	"nm": "Arakaldo"
}, {
	"id": "Bizkaia",
	"nm": "Arantzazu"
}, {
	"id": "Bizkaia",
	"nm": "Areatza"
}, {
	"id": "Bizkaia",
	"nm": "Arrankudiaga"
}, {
	"id": "Bizkaia",
	"nm": "Arratzu"
}, {
	"id": "Bizkaia",
	"nm": "Arrieta"
}, {
	"id": "Bizkaia",
	"nm": "Arrigorriaga"
}, {
	"id": "Bizkaia",
	"nm": "Artea"
}, {
	"id": "Bizkaia",
	"nm": "Artzentales"
}, {
	"id": "Bizkaia",
	"nm": "Atxondo"
}, {
	"id": "Bizkaia",
	"nm": "Aulesti"
}, {
	"id": "Bizkaia",
	"nm": "Bakio"
}, {
	"id": "Bizkaia",
	"nm": "Balmaseda"
}, {
	"id": "Bizkaia",
	"nm": "Barakaldo"
}, {
	"id": "Bizkaia",
	"nm": "Barrika"
}, {
	"id": "Bizkaia",
	"nm": "Basauri"
}, {
	"id": "Bizkaia",
	"nm": "Bedia"
}, {
	"id": "Bizkaia",
	"nm": "Berango"
}, {
	"id": "Bizkaia",
	"nm": "Bermeo"
}, {
	"id": "Bizkaia",
	"nm": "Berriatua"
}, {
	"id": "Bizkaia",
	"nm": "Berriz"
}, {
	"id": "Bizkaia",
	"nm": "Bilbao"
}, {
	"id": "Bizkaia",
	"nm": "Busturia"
}, {
	"id": "Bizkaia",
	"nm": "Derio"
}, {
	"id": "Bizkaia",
	"nm": "Dima"
}, {
	"id": "Bizkaia",
	"nm": "Durango"
}, {
	"id": "Bizkaia",
	"nm": "Ea"
}, {
	"id": "Bizkaia",
	"nm": "Elantxobe"
}, {
	"id": "Bizkaia",
	"nm": "Elorrio"
}, {
	"id": "Bizkaia",
	"nm": "Erandio"
}, {
	"id": "Bizkaia",
	"nm": "Ereño"
}, {
	"id": "Bizkaia",
	"nm": "Ermua"
}, {
	"id": "Bizkaia",
	"nm": "Errigoiti"
}, {
	"id": "Bizkaia",
	"nm": "Etxebarri"
}, {
	"id": "Bizkaia",
	"nm": "Etxebarria"
}, {
	"id": "Bizkaia",
	"nm": "Forua"
}, {
	"id": "Bizkaia",
	"nm": "Fruiz"
}, {
	"id": "Bizkaia",
	"nm": "Galdakao"
}, {
	"id": "Bizkaia",
	"nm": "Galdames"
}, {
	"id": "Bizkaia",
	"nm": "Gamiz-Fika"
}, {
	"id": "Bizkaia",
	"nm": "Garai"
}, {
	"id": "Bizkaia",
	"nm": "Gatika"
}, {
	"id": "Bizkaia",
	"nm": "Gautegiz Arteaga"
}, {
	"id": "Bizkaia",
	"nm": "Gernika-Lumo"
}, {
	"id": "Bizkaia",
	"nm": "Getxo"
}, {
	"id": "Bizkaia",
	"nm": "Gizaburuaga"
}, {
	"id": "Bizkaia",
	"nm": "Gordexola"
}, {
	"id": "Bizkaia",
	"nm": "Gorliz"
}, {
	"id": "Bizkaia",
	"nm": "Güeñes"
}, {
	"id": "Bizkaia",
	"nm": "Ibarrangelu"
}, {
	"id": "Bizkaia",
	"nm": "Igorre"
}, {
	"id": "Bizkaia",
	"nm": "Ispaster"
}, {
	"id": "Bizkaia",
	"nm": "Iurreta"
}, {
	"id": "Bizkaia",
	"nm": "Izurtza"
}, {
	"id": "Bizkaia",
	"nm": "Karrantza Harana/Valle de Carranza"
}, {
	"id": "Bizkaia",
	"nm": "Kortezubi"
}, {
	"id": "Bizkaia",
	"nm": "Lanestosa"
}, {
	"id": "Bizkaia",
	"nm": "Larrabetzu"
}, {
	"id": "Bizkaia",
	"nm": "Laukiz"
}, {
	"id": "Bizkaia",
	"nm": "Leioa"
}, {
	"id": "Bizkaia",
	"nm": "Lekeitio"
}, {
	"id": "Bizkaia",
	"nm": "Lemoa"
}, {
	"id": "Bizkaia",
	"nm": "Lemoiz"
}, {
	"id": "Bizkaia",
	"nm": "Lezama"
}, {
	"id": "Bizkaia",
	"nm": "Loiu"
}, {
	"id": "Bizkaia",
	"nm": "Mallabia"
}, {
	"id": "Bizkaia",
	"nm": "Mañaria"
}, {
	"id": "Bizkaia",
	"nm": "Markina-Xemein"
}, {
	"id": "Bizkaia",
	"nm": "Maruri-Jatabe"
}, {
	"id": "Bizkaia",
	"nm": "Mendata"
}, {
	"id": "Bizkaia",
	"nm": "Mendexa"
}, {
	"id": "Bizkaia",
	"nm": "Meñaka"
}, {
	"id": "Bizkaia",
	"nm": "Morga"
}, {
	"id": "Bizkaia",
	"nm": "Mundaka"
}, {
	"id": "Bizkaia",
	"nm": "Mungia"
}, {
	"id": "Bizkaia",
	"nm": "Munitibar-Arbatzegi Gerrikaitz"
}, {
	"id": "Bizkaia",
	"nm": "Murueta"
}, {
	"id": "Bizkaia",
	"nm": "Muskiz"
}, {
	"id": "Bizkaia",
	"nm": "Muxika"
}, {
	"id": "Bizkaia",
	"nm": "Nabarniz"
}, {
	"id": "Bizkaia",
	"nm": "Ondarroa"
}, {
	"id": "Bizkaia",
	"nm": "Orozko"
}, {
	"id": "Bizkaia",
	"nm": "Ortuella"
}, {
	"id": "Bizkaia",
	"nm": "Otxandio"
}, {
	"id": "Bizkaia",
	"nm": "Plentzia"
}, {
	"id": "Bizkaia",
	"nm": "Portugalete"
}, {
	"id": "Bizkaia",
	"nm": "Santurtzi"
}, {
	"id": "Bizkaia",
	"nm": "Sestao"
}, {
	"id": "Bizkaia",
	"nm": "Sondika"
}, {
	"id": "Bizkaia",
	"nm": "Sopela"
}, {
	"id": "Bizkaia",
	"nm": "Sopuerta"
}, {
	"id": "Bizkaia",
	"nm": "Sukarrieta"
}, {
	"id": "Bizkaia",
	"nm": "Trucios-Turtzioz"
}, {
	"id": "Bizkaia",
	"nm": "Ubide"
}, {
	"id": "Bizkaia",
	"nm": "Ugao-Miraballes"
}, {
	"id": "Bizkaia",
	"nm": "Urduliz"
}, {
	"id": "Bizkaia",
	"nm": "Urduña/Orduña"
}, {
	"id": "Bizkaia",
	"nm": "Valle de Trápaga-Trapagaran"
}, {
	"id": "Bizkaia",
	"nm": "Zaldibar"
}, {
	"id": "Bizkaia",
	"nm": "Zalla"
}, {
	"id": "Bizkaia",
	"nm": "Zamudio"
}, {
	"id": "Bizkaia",
	"nm": "Zaratamo"
}, {
	"id": "Bizkaia",
	"nm": "Zeanuri"
}, {
	"id": "Bizkaia",
	"nm": "Zeberio"
}, {
	"id": "Bizkaia",
	"nm": "Zierbena"
}, {
	"id": "Bizkaia",
	"nm": "Ziortza-Bolibar"
}, {
	"id": "Zamora",
	"nm": "Abezames"
}, {
	"id": "Zamora",
	"nm": "Alcañices"
}, {
	"id": "Zamora",
	"nm": "Alcubilla de Nogales"
}, {
	"id": "Zamora",
	"nm": "Alfaraz de Sayago"
}, {
	"id": "Zamora",
	"nm": "Algodre"
}, {
	"id": "Zamora",
	"nm": "Almaraz de Duero"
}, {
	"id": "Zamora",
	"nm": "Almeida de Sayago"
}, {
	"id": "Zamora",
	"nm": "Andavías"
}, {
	"id": "Zamora",
	"nm": "Arcenillas"
}, {
	"id": "Zamora",
	"nm": "Arcos de la Polvorosa"
}, {
	"id": "Zamora",
	"nm": "Argañín"
}, {
	"id": "Zamora",
	"nm": "Argujillo"
}, {
	"id": "Zamora",
	"nm": "Arquillinos"
}, {
	"id": "Zamora",
	"nm": "Arrabalde"
}, {
	"id": "Zamora",
	"nm": "Aspariegos"
}, {
	"id": "Zamora",
	"nm": "Asturianos"
}, {
	"id": "Zamora",
	"nm": "Ayoó de Vidriales"
}, {
	"id": "Zamora",
	"nm": "Barcial del Barco"
}, {
	"id": "Zamora",
	"nm": "Belver de los Montes"
}, {
	"id": "Zamora",
	"nm": "Benavente"
}, {
	"id": "Zamora",
	"nm": "Benegiles"
}, {
	"id": "Zamora",
	"nm": "Bermillo de Sayago"
}, {
	"id": "Zamora",
	"nm": "Bóveda de Toro, La"
}, {
	"id": "Zamora",
	"nm": "Bretó"
}, {
	"id": "Zamora",
	"nm": "Bretocino"
}, {
	"id": "Zamora",
	"nm": "Brime de Sog"
}, {
	"id": "Zamora",
	"nm": "Brime de Urz"
}, {
	"id": "Zamora",
	"nm": "Burganes de Valverde"
}, {
	"id": "Zamora",
	"nm": "Bustillo del Oro"
}, {
	"id": "Zamora",
	"nm": "Cabañas de Sayago"
}, {
	"id": "Zamora",
	"nm": "Calzadilla de Tera"
}, {
	"id": "Zamora",
	"nm": "Camarzana de Tera"
}, {
	"id": "Zamora",
	"nm": "Cañizal"
}, {
	"id": "Zamora",
	"nm": "Cañizo"
}, {
	"id": "Zamora",
	"nm": "Carbajales de Alba"
}, {
	"id": "Zamora",
	"nm": "Carbellino"
}, {
	"id": "Zamora",
	"nm": "Casaseca de Campeán"
}, {
	"id": "Zamora",
	"nm": "Casaseca de las Chanas"
}, {
	"id": "Zamora",
	"nm": "Castrillo de la Guareña"
}, {
	"id": "Zamora",
	"nm": "Castrogonzalo"
}, {
	"id": "Zamora",
	"nm": "Castronuevo"
}, {
	"id": "Zamora",
	"nm": "Castroverde de Campos"
}, {
	"id": "Zamora",
	"nm": "Cazurra"
}, {
	"id": "Zamora",
	"nm": "Cerecinos de Campos"
}, {
	"id": "Zamora",
	"nm": "Cerecinos del Carrizal"
}, {
	"id": "Zamora",
	"nm": "Cernadilla"
}, {
	"id": "Zamora",
	"nm": "Cobreros"
}, {
	"id": "Zamora",
	"nm": "Coomonte"
}, {
	"id": "Zamora",
	"nm": "Coreses"
}, {
	"id": "Zamora",
	"nm": "Corrales del Vino"
}, {
	"id": "Zamora",
	"nm": "Cotanes del Monte"
}, {
	"id": "Zamora",
	"nm": "Cubillos"
}, {
	"id": "Zamora",
	"nm": "Cubo de Benavente"
}, {
	"id": "Zamora",
	"nm": "Cubo de Tierra del Vino, El"
}, {
	"id": "Zamora",
	"nm": "Cuelgamures"
}, {
	"id": "Zamora",
	"nm": "Entrala"
}, {
	"id": "Zamora",
	"nm": "Espadañedo"
}, {
	"id": "Zamora",
	"nm": "Faramontanos de Tábara"
}, {
	"id": "Zamora",
	"nm": "Fariza"
}, {
	"id": "Zamora",
	"nm": "Fermoselle"
}, {
	"id": "Zamora",
	"nm": "Ferreras de Abajo"
}, {
	"id": "Zamora",
	"nm": "Ferreras de Arriba"
}, {
	"id": "Zamora",
	"nm": "Ferreruela"
}, {
	"id": "Zamora",
	"nm": "Figueruela de Arriba"
}, {
	"id": "Zamora",
	"nm": "Fonfría"
}, {
	"id": "Zamora",
	"nm": "Fresno de la Polvorosa"
}, {
	"id": "Zamora",
	"nm": "Fresno de la Ribera"
}, {
	"id": "Zamora",
	"nm": "Fresno de Sayago"
}, {
	"id": "Zamora",
	"nm": "Friera de Valverde"
}, {
	"id": "Zamora",
	"nm": "Fuente Encalada"
}, {
	"id": "Zamora",
	"nm": "Fuentelapeña"
}, {
	"id": "Zamora",
	"nm": "Fuentes de Ropel"
}, {
	"id": "Zamora",
	"nm": "Fuentesaúco"
}, {
	"id": "Zamora",
	"nm": "Fuentesecas"
}, {
	"id": "Zamora",
	"nm": "Fuentespreadas"
}, {
	"id": "Zamora",
	"nm": "Galende"
}, {
	"id": "Zamora",
	"nm": "Gallegos del Pan"
}, {
	"id": "Zamora",
	"nm": "Gallegos del Río"
}, {
	"id": "Zamora",
	"nm": "Gamones"
}, {
	"id": "Zamora",
	"nm": "Gema"
}, {
	"id": "Zamora",
	"nm": "Granja de Moreruela"
}, {
	"id": "Zamora",
	"nm": "Granucillo"
}, {
	"id": "Zamora",
	"nm": "Guarrate"
}, {
	"id": "Zamora",
	"nm": "Hermisende"
}, {
	"id": "Zamora",
	"nm": "Hiniesta, La"
}, {
	"id": "Zamora",
	"nm": "Jambrina"
}, {
	"id": "Zamora",
	"nm": "Justel"
}, {
	"id": "Zamora",
	"nm": "Losacino"
}, {
	"id": "Zamora",
	"nm": "Losacio"
}, {
	"id": "Zamora",
	"nm": "Lubián"
}, {
	"id": "Zamora",
	"nm": "Luelmo"
}, {
	"id": "Zamora",
	"nm": "Maderal, El"
}, {
	"id": "Zamora",
	"nm": "Madridanos"
}, {
	"id": "Zamora",
	"nm": "Mahide"
}, {
	"id": "Zamora",
	"nm": "Maire de Castroponce"
}, {
	"id": "Zamora",
	"nm": "Malva"
}, {
	"id": "Zamora",
	"nm": "Manganeses de la Lampreana"
}, {
	"id": "Zamora",
	"nm": "Manganeses de la Polvorosa"
}, {
	"id": "Zamora",
	"nm": "Manzanal de Arriba"
}, {
	"id": "Zamora",
	"nm": "Manzanal de los Infantes"
}, {
	"id": "Zamora",
	"nm": "Manzanal del Barco"
}, {
	"id": "Zamora",
	"nm": "Matilla de Arzón"
}, {
	"id": "Zamora",
	"nm": "Matilla la Seca"
}, {
	"id": "Zamora",
	"nm": "Mayalde"
}, {
	"id": "Zamora",
	"nm": "Melgar de Tera"
}, {
	"id": "Zamora",
	"nm": "Micereces de Tera"
}, {
	"id": "Zamora",
	"nm": "Milles de la Polvorosa"
}, {
	"id": "Zamora",
	"nm": "Molacillos"
}, {
	"id": "Zamora",
	"nm": "Molezuelas de la Carballeda"
}, {
	"id": "Zamora",
	"nm": "Mombuey"
}, {
	"id": "Zamora",
	"nm": "Monfarracinos"
}, {
	"id": "Zamora",
	"nm": "Montamarta"
}, {
	"id": "Zamora",
	"nm": "Moral de Sayago"
}, {
	"id": "Zamora",
	"nm": "Moraleja de Sayago"
}, {
	"id": "Zamora",
	"nm": "Moraleja del Vino"
}, {
	"id": "Zamora",
	"nm": "Morales de Rey"
}, {
	"id": "Zamora",
	"nm": "Morales de Toro"
}, {
	"id": "Zamora",
	"nm": "Morales de Valverde"
}, {
	"id": "Zamora",
	"nm": "Morales del Vino"
}, {
	"id": "Zamora",
	"nm": "Moralina"
}, {
	"id": "Zamora",
	"nm": "Moreruela de los Infanzones"
}, {
	"id": "Zamora",
	"nm": "Moreruela de Tábara"
}, {
	"id": "Zamora",
	"nm": "Muelas de los Caballeros"
}, {
	"id": "Zamora",
	"nm": "Muelas del Pan"
}, {
	"id": "Zamora",
	"nm": "Muga de Sayago"
}, {
	"id": "Zamora",
	"nm": "Navianos de Valverde"
}, {
	"id": "Zamora",
	"nm": "Olmillos de Castro"
}, {
	"id": "Zamora",
	"nm": "Otero de Bodas"
}, {
	"id": "Zamora",
	"nm": "Pajares de la Lampreana"
}, {
	"id": "Zamora",
	"nm": "Palacios de Sanabria"
}, {
	"id": "Zamora",
	"nm": "Palacios del Pan"
}, {
	"id": "Zamora",
	"nm": "Pedralba de la Pradería"
}, {
	"id": "Zamora",
	"nm": "Pego, El"
}, {
	"id": "Zamora",
	"nm": "Peleagonzalo"
}, {
	"id": "Zamora",
	"nm": "Peleas de Abajo"
}, {
	"id": "Zamora",
	"nm": "Peñausende"
}, {
	"id": "Zamora",
	"nm": "Peque"
}, {
	"id": "Zamora",
	"nm": "Perdigón, El"
}, {
	"id": "Zamora",
	"nm": "Pereruela"
}, {
	"id": "Zamora",
	"nm": "Perilla de Castro"
}, {
	"id": "Zamora",
	"nm": "Pías"
}, {
	"id": "Zamora",
	"nm": "Piedrahita de Castro"
}, {
	"id": "Zamora",
	"nm": "Pinilla de Toro"
}, {
	"id": "Zamora",
	"nm": "Pino del Oro"
}, {
	"id": "Zamora",
	"nm": "Piñero, El"
}, {
	"id": "Zamora",
	"nm": "Pobladura de Valderaduey"
}, {
	"id": "Zamora",
	"nm": "Pobladura del Valle"
}, {
	"id": "Zamora",
	"nm": "Porto"
}, {
	"id": "Zamora",
	"nm": "Pozoantiguo"
}, {
	"id": "Zamora",
	"nm": "Pozuelo de Tábara"
}, {
	"id": "Zamora",
	"nm": "Prado"
}, {
	"id": "Zamora",
	"nm": "Puebla de Sanabria"
}, {
	"id": "Zamora",
	"nm": "Pueblica de Valverde"
}, {
	"id": "Zamora",
	"nm": "Quintanilla de Urz"
}, {
	"id": "Zamora",
	"nm": "Quintanilla del Monte"
}, {
	"id": "Zamora",
	"nm": "Quintanilla del Olmo"
}, {
	"id": "Zamora",
	"nm": "Quiruelas de Vidriales"
}, {
	"id": "Zamora",
	"nm": "Rabanales"
}, {
	"id": "Zamora",
	"nm": "Rábano de Aliste"
}, {
	"id": "Zamora",
	"nm": "Requejo"
}, {
	"id": "Zamora",
	"nm": "Revellinos"
}, {
	"id": "Zamora",
	"nm": "Riofrío de Aliste"
}, {
	"id": "Zamora",
	"nm": "Rionegro del Puente"
}, {
	"id": "Zamora",
	"nm": "Roales"
}, {
	"id": "Zamora",
	"nm": "Robleda-Cervantes"
}, {
	"id": "Zamora",
	"nm": "Roelos de Sayago"
}, {
	"id": "Zamora",
	"nm": "Rosinos de la Requejada"
}, {
	"id": "Zamora",
	"nm": "Salce"
}, {
	"id": "Zamora",
	"nm": "Samir de los Caños"
}, {
	"id": "Zamora",
	"nm": "San Agustín del Pozo"
}, {
	"id": "Zamora",
	"nm": "San Cebrián de Castro"
}, {
	"id": "Zamora",
	"nm": "San Cristóbal de Entreviñas"
}, {
	"id": "Zamora",
	"nm": "San Esteban del Molar"
}, {
	"id": "Zamora",
	"nm": "San Justo"
}, {
	"id": "Zamora",
	"nm": "San Martín de Valderaduey"
}, {
	"id": "Zamora",
	"nm": "San Miguel de la Ribera"
}, {
	"id": "Zamora",
	"nm": "San Miguel del Valle"
}, {
	"id": "Zamora",
	"nm": "San Pedro de Ceque"
}, {
	"id": "Zamora",
	"nm": "San Pedro de la Nave-Almendra"
}, {
	"id": "Zamora",
	"nm": "San Vicente de la Cabeza"
}, {
	"id": "Zamora",
	"nm": "San Vitero"
}, {
	"id": "Zamora",
	"nm": "Santa Clara de Avedillo"
}, {
	"id": "Zamora",
	"nm": "Santa Colomba de las Monjas"
}, {
	"id": "Zamora",
	"nm": "Santa Cristina de la Polvorosa"
}, {
	"id": "Zamora",
	"nm": "Santa Croya de Tera"
}, {
	"id": "Zamora",
	"nm": "Santa Eufemia del Barco"
}, {
	"id": "Zamora",
	"nm": "Santa María de la Vega"
}, {
	"id": "Zamora",
	"nm": "Santa María de Valverde"
}, {
	"id": "Zamora",
	"nm": "Santibáñez de Tera"
}, {
	"id": "Zamora",
	"nm": "Santibáñez de Vidriales"
}, {
	"id": "Zamora",
	"nm": "Santovenia"
}, {
	"id": "Zamora",
	"nm": "Sanzoles"
}, {
	"id": "Zamora",
	"nm": "Tábara"
}, {
	"id": "Zamora",
	"nm": "Tapioles"
}, {
	"id": "Zamora",
	"nm": "Toro"
}, {
	"id": "Zamora",
	"nm": "Torre del Valle, La"
}, {
	"id": "Zamora",
	"nm": "Torregamones"
}, {
	"id": "Zamora",
	"nm": "Torres del Carrizal"
}, {
	"id": "Zamora",
	"nm": "Trabazos"
}, {
	"id": "Zamora",
	"nm": "Trefacio"
}, {
	"id": "Zamora",
	"nm": "Uña de Quintana"
}, {
	"id": "Zamora",
	"nm": "Vadillo de la Guareña"
}, {
	"id": "Zamora",
	"nm": "Valcabado"
}, {
	"id": "Zamora",
	"nm": "Valdefinjas"
}, {
	"id": "Zamora",
	"nm": "Valdescorriel"
}, {
	"id": "Zamora",
	"nm": "Vallesa de la Guareña"
}, {
	"id": "Zamora",
	"nm": "Vega de Tera"
}, {
	"id": "Zamora",
	"nm": "Vega de Villalobos"
}, {
	"id": "Zamora",
	"nm": "Vegalatrave"
}, {
	"id": "Zamora",
	"nm": "Venialbo"
}, {
	"id": "Zamora",
	"nm": "Vezdemarbán"
}, {
	"id": "Zamora",
	"nm": "Vidayanes"
}, {
	"id": "Zamora",
	"nm": "Videmala"
}, {
	"id": "Zamora",
	"nm": "Villabrázaro"
}, {
	"id": "Zamora",
	"nm": "Villabuena del Puente"
}, {
	"id": "Zamora",
	"nm": "Villadepera"
}, {
	"id": "Zamora",
	"nm": "Villaescusa"
}, {
	"id": "Zamora",
	"nm": "Villafáfila"
}, {
	"id": "Zamora",
	"nm": "Villaferrueña"
}, {
	"id": "Zamora",
	"nm": "Villageriz"
}, {
	"id": "Zamora",
	"nm": "Villalazán"
}, {
	"id": "Zamora",
	"nm": "Villalba de la Lampreana"
}, {
	"id": "Zamora",
	"nm": "Villalcampo"
}, {
	"id": "Zamora",
	"nm": "Villalobos"
}, {
	"id": "Zamora",
	"nm": "Villalonso"
}, {
	"id": "Zamora",
	"nm": "Villalpando"
}, {
	"id": "Zamora",
	"nm": "Villalube"
}, {
	"id": "Zamora",
	"nm": "Villamayor de Campos"
}, {
	"id": "Zamora",
	"nm": "Villamor de los Escuderos"
}, {
	"id": "Zamora",
	"nm": "Villanázar"
}, {
	"id": "Zamora",
	"nm": "Villanueva de Azoague"
}, {
	"id": "Zamora",
	"nm": "Villanueva de Campeán"
}, {
	"id": "Zamora",
	"nm": "Villanueva de las Peras"
}, {
	"id": "Zamora",
	"nm": "Villanueva del Campo"
}, {
	"id": "Zamora",
	"nm": "Villar de Fallaves"
}, {
	"id": "Zamora",
	"nm": "Villar del Buey"
}, {
	"id": "Zamora",
	"nm": "Villaralbo"
}, {
	"id": "Zamora",
	"nm": "Villardeciervos"
}, {
	"id": "Zamora",
	"nm": "Villardiegua de la Ribera"
}, {
	"id": "Zamora",
	"nm": "Villárdiga"
}, {
	"id": "Zamora",
	"nm": "Villardondiego"
}, {
	"id": "Zamora",
	"nm": "Villarrín de Campos"
}, {
	"id": "Zamora",
	"nm": "Villaseco del Pan"
}, {
	"id": "Zamora",
	"nm": "Villavendimio"
}, {
	"id": "Zamora",
	"nm": "Villaveza de Valverde"
}, {
	"id": "Zamora",
	"nm": "Villaveza del Agua"
}, {
	"id": "Zamora",
	"nm": "Viñas"
}, {
	"id": "Zamora",
	"nm": "Zamora"
}, {
	"id": "Zaragoza",
	"nm": "Abanto"
}, {
	"id": "Zaragoza",
	"nm": "Acered"
}, {
	"id": "Zaragoza",
	"nm": "Agón"
}, {
	"id": "Zaragoza",
	"nm": "Aguarón"
}, {
	"id": "Zaragoza",
	"nm": "Aguilón"
}, {
	"id": "Zaragoza",
	"nm": "Ainzón"
}, {
	"id": "Zaragoza",
	"nm": "Aladrén"
}, {
	"id": "Zaragoza",
	"nm": "Alagón"
}, {
	"id": "Zaragoza",
	"nm": "Alarba"
}, {
	"id": "Zaragoza",
	"nm": "Alberite de San Juan"
}, {
	"id": "Zaragoza",
	"nm": "Albeta"
}, {
	"id": "Zaragoza",
	"nm": "Alborge"
}, {
	"id": "Zaragoza",
	"nm": "Alcalá de Ebro"
}, {
	"id": "Zaragoza",
	"nm": "Alcalá de Moncayo"
}, {
	"id": "Zaragoza",
	"nm": "Alconchel de Ariza"
}, {
	"id": "Zaragoza",
	"nm": "Aldehuela de Liestos"
}, {
	"id": "Zaragoza",
	"nm": "Alfajarín"
}, {
	"id": "Zaragoza",
	"nm": "Alfamén"
}, {
	"id": "Zaragoza",
	"nm": "Alforque"
}, {
	"id": "Zaragoza",
	"nm": "Alhama de Aragón"
}, {
	"id": "Zaragoza",
	"nm": "Almochuel"
}, {
	"id": "Zaragoza",
	"nm": "Almolda, La"
}, {
	"id": "Zaragoza",
	"nm": "Almonacid de la Cuba"
}, {
	"id": "Zaragoza",
	"nm": "Almonacid de la Sierra"
}, {
	"id": "Zaragoza",
	"nm": "Almunia de Doña Godina, La"
}, {
	"id": "Zaragoza",
	"nm": "Alpartir"
}, {
	"id": "Zaragoza",
	"nm": "Ambel"
}, {
	"id": "Zaragoza",
	"nm": "Anento"
}, {
	"id": "Zaragoza",
	"nm": "Aniñón"
}, {
	"id": "Zaragoza",
	"nm": "Añón de Moncayo"
}, {
	"id": "Zaragoza",
	"nm": "Aranda de Moncayo"
}, {
	"id": "Zaragoza",
	"nm": "Arándiga"
}, {
	"id": "Zaragoza",
	"nm": "Ardisa"
}, {
	"id": "Zaragoza",
	"nm": "Ariza"
}, {
	"id": "Zaragoza",
	"nm": "Artieda"
}, {
	"id": "Zaragoza",
	"nm": "Asín"
}, {
	"id": "Zaragoza",
	"nm": "Atea"
}, {
	"id": "Zaragoza",
	"nm": "Ateca"
}, {
	"id": "Zaragoza",
	"nm": "Azuara"
}, {
	"id": "Zaragoza",
	"nm": "Badules"
}, {
	"id": "Zaragoza",
	"nm": "Bagüés"
}, {
	"id": "Zaragoza",
	"nm": "Balconchán"
}, {
	"id": "Zaragoza",
	"nm": "Bárboles"
}, {
	"id": "Zaragoza",
	"nm": "Bardallur"
}, {
	"id": "Zaragoza",
	"nm": "Belchite"
}, {
	"id": "Zaragoza",
	"nm": "Belmonte de Gracián"
}, {
	"id": "Zaragoza",
	"nm": "Berdejo"
}, {
	"id": "Zaragoza",
	"nm": "Berrueco"
}, {
	"id": "Zaragoza",
	"nm": "Biel"
}, {
	"id": "Zaragoza",
	"nm": "Bijuesca"
}, {
	"id": "Zaragoza",
	"nm": "Biota"
}, {
	"id": "Zaragoza",
	"nm": "Bisimbre"
}, {
	"id": "Zaragoza",
	"nm": "Boquiñeni"
}, {
	"id": "Zaragoza",
	"nm": "Bordalba"
}, {
	"id": "Zaragoza",
	"nm": "Borja"
}, {
	"id": "Zaragoza",
	"nm": "Botorrita"
}, {
	"id": "Zaragoza",
	"nm": "Brea de Aragón"
}, {
	"id": "Zaragoza",
	"nm": "Bubierca"
}, {
	"id": "Zaragoza",
	"nm": "Bujaraloz"
}, {
	"id": "Zaragoza",
	"nm": "Bulbuente"
}, {
	"id": "Zaragoza",
	"nm": "Bureta"
}, {
	"id": "Zaragoza",
	"nm": "Burgo de Ebro, El"
}, {
	"id": "Zaragoza",
	"nm": "Buste, El"
}, {
	"id": "Zaragoza",
	"nm": "Cabañas de Ebro"
}, {
	"id": "Zaragoza",
	"nm": "Cabolafuente"
}, {
	"id": "Zaragoza",
	"nm": "Cadrete"
}, {
	"id": "Zaragoza",
	"nm": "Calatayud"
}, {
	"id": "Zaragoza",
	"nm": "Calatorao"
}, {
	"id": "Zaragoza",
	"nm": "Calcena"
}, {
	"id": "Zaragoza",
	"nm": "Calmarza"
}, {
	"id": "Zaragoza",
	"nm": "Campillo de Aragón"
}, {
	"id": "Zaragoza",
	"nm": "Carenas"
}, {
	"id": "Zaragoza",
	"nm": "Cariñena"
}, {
	"id": "Zaragoza",
	"nm": "Caspe"
}, {
	"id": "Zaragoza",
	"nm": "Castejón de Alarba"
}, {
	"id": "Zaragoza",
	"nm": "Castejón de las Armas"
}, {
	"id": "Zaragoza",
	"nm": "Castejón de Valdejasa"
}, {
	"id": "Zaragoza",
	"nm": "Castiliscar"
}, {
	"id": "Zaragoza",
	"nm": "Cervera de la Cañada"
}, {
	"id": "Zaragoza",
	"nm": "Cerveruela"
}, {
	"id": "Zaragoza",
	"nm": "Cetina"
}, {
	"id": "Zaragoza",
	"nm": "Chiprana"
}, {
	"id": "Zaragoza",
	"nm": "Chodes"
}, {
	"id": "Zaragoza",
	"nm": "Cimballa"
}, {
	"id": "Zaragoza",
	"nm": "Cinco Olivas"
}, {
	"id": "Zaragoza",
	"nm": "Clarés de Ribota"
}, {
	"id": "Zaragoza",
	"nm": "Codo"
}, {
	"id": "Zaragoza",
	"nm": "Codos"
}, {
	"id": "Zaragoza",
	"nm": "Contamina"
}, {
	"id": "Zaragoza",
	"nm": "Cosuenda"
}, {
	"id": "Zaragoza",
	"nm": "Cuarte de Huerva"
}, {
	"id": "Zaragoza",
	"nm": "Cubel"
}, {
	"id": "Zaragoza",
	"nm": "Cuerlas, Las"
}, {
	"id": "Zaragoza",
	"nm": "Daroca"
}, {
	"id": "Zaragoza",
	"nm": "Ejea de los Caballeros"
}, {
	"id": "Zaragoza",
	"nm": "Embid de Ariza"
}, {
	"id": "Zaragoza",
	"nm": "Encinacorba"
}, {
	"id": "Zaragoza",
	"nm": "Épila"
}, {
	"id": "Zaragoza",
	"nm": "Erla"
}, {
	"id": "Zaragoza",
	"nm": "Escatrón"
}, {
	"id": "Zaragoza",
	"nm": "Fabara"
}, {
	"id": "Zaragoza",
	"nm": "Farlete"
}, {
	"id": "Zaragoza",
	"nm": "Fayón"
}, {
	"id": "Zaragoza",
	"nm": "Fayos, Los"
}, {
	"id": "Zaragoza",
	"nm": "Figueruelas"
}, {
	"id": "Zaragoza",
	"nm": "Fombuena"
}, {
	"id": "Zaragoza",
	"nm": "Frago, El"
}, {
	"id": "Zaragoza",
	"nm": "Frasno, El"
}, {
	"id": "Zaragoza",
	"nm": "Fréscano"
}, {
	"id": "Zaragoza",
	"nm": "Fuendejalón"
}, {
	"id": "Zaragoza",
	"nm": "Fuendetodos"
}, {
	"id": "Zaragoza",
	"nm": "Fuentes de Ebro"
}, {
	"id": "Zaragoza",
	"nm": "Fuentes de Jiloca"
}, {
	"id": "Zaragoza",
	"nm": "Gallocanta"
}, {
	"id": "Zaragoza",
	"nm": "Gallur"
}, {
	"id": "Zaragoza",
	"nm": "Gelsa"
}, {
	"id": "Zaragoza",
	"nm": "Godojos"
}, {
	"id": "Zaragoza",
	"nm": "Gotor"
}, {
	"id": "Zaragoza",
	"nm": "Grisel"
}, {
	"id": "Zaragoza",
	"nm": "Grisén"
}, {
	"id": "Zaragoza",
	"nm": "Herrera de los Navarros"
}, {
	"id": "Zaragoza",
	"nm": "Ibdes"
}, {
	"id": "Zaragoza",
	"nm": "Illueca"
}, {
	"id": "Zaragoza",
	"nm": "Isuerre"
}, {
	"id": "Zaragoza",
	"nm": "Jaraba"
}, {
	"id": "Zaragoza",
	"nm": "Jarque"
}, {
	"id": "Zaragoza",
	"nm": "Jaulín"
}, {
	"id": "Zaragoza",
	"nm": "Joyosa, La"
}, {
	"id": "Zaragoza",
	"nm": "Lagata"
}, {
	"id": "Zaragoza",
	"nm": "Langa del Castillo"
}, {
	"id": "Zaragoza",
	"nm": "Layana"
}, {
	"id": "Zaragoza",
	"nm": "Lécera"
}, {
	"id": "Zaragoza",
	"nm": "Lechón"
}, {
	"id": "Zaragoza",
	"nm": "Leciñena"
}, {
	"id": "Zaragoza",
	"nm": "Letux"
}, {
	"id": "Zaragoza",
	"nm": "Litago"
}, {
	"id": "Zaragoza",
	"nm": "Lituénigo"
}, {
	"id": "Zaragoza",
	"nm": "Lobera de Onsella"
}, {
	"id": "Zaragoza",
	"nm": "Longares"
}, {
	"id": "Zaragoza",
	"nm": "Longás"
}, {
	"id": "Zaragoza",
	"nm": "Lucena de Jalón"
}, {
	"id": "Zaragoza",
	"nm": "Luceni"
}, {
	"id": "Zaragoza",
	"nm": "Luesia"
}, {
	"id": "Zaragoza",
	"nm": "Luesma"
}, {
	"id": "Zaragoza",
	"nm": "Lumpiaque"
}, {
	"id": "Zaragoza",
	"nm": "Luna"
}, {
	"id": "Zaragoza",
	"nm": "Maella"
}, {
	"id": "Zaragoza",
	"nm": "Magallón"
}, {
	"id": "Zaragoza",
	"nm": "Mainar"
}, {
	"id": "Zaragoza",
	"nm": "Malanquilla"
}, {
	"id": "Zaragoza",
	"nm": "Maleján"
}, {
	"id": "Zaragoza",
	"nm": "Mallén"
}, {
	"id": "Zaragoza",
	"nm": "Malón"
}, {
	"id": "Zaragoza",
	"nm": "Maluenda"
}, {
	"id": "Zaragoza",
	"nm": "Manchones"
}, {
	"id": "Zaragoza",
	"nm": "Mara"
}, {
	"id": "Zaragoza",
	"nm": "María de Huerva"
}, {
	"id": "Zaragoza",
	"nm": "Marracos"
}, {
	"id": "Zaragoza",
	"nm": "Mediana de Aragón"
}, {
	"id": "Zaragoza",
	"nm": "Mequinenza"
}, {
	"id": "Zaragoza",
	"nm": "Mesones de Isuela"
}, {
	"id": "Zaragoza",
	"nm": "Mezalocha"
}, {
	"id": "Zaragoza",
	"nm": "Mianos"
}, {
	"id": "Zaragoza",
	"nm": "Miedes de Aragón"
}, {
	"id": "Zaragoza",
	"nm": "Monegrillo"
}, {
	"id": "Zaragoza",
	"nm": "Moneva"
}, {
	"id": "Zaragoza",
	"nm": "Monreal de Ariza"
}, {
	"id": "Zaragoza",
	"nm": "Monterde"
}, {
	"id": "Zaragoza",
	"nm": "Montón"
}, {
	"id": "Zaragoza",
	"nm": "Morata de Jalón"
}, {
	"id": "Zaragoza",
	"nm": "Morata de Jiloca"
}, {
	"id": "Zaragoza",
	"nm": "Morés"
}, {
	"id": "Zaragoza",
	"nm": "Moros"
}, {
	"id": "Zaragoza",
	"nm": "Moyuela"
}, {
	"id": "Zaragoza",
	"nm": "Mozota"
}, {
	"id": "Zaragoza",
	"nm": "Muel"
}, {
	"id": "Zaragoza",
	"nm": "Muela, La"
}, {
	"id": "Zaragoza",
	"nm": "Munébrega"
}, {
	"id": "Zaragoza",
	"nm": "Murero"
}, {
	"id": "Zaragoza",
	"nm": "Murillo de Gállego"
}, {
	"id": "Zaragoza",
	"nm": "Navardún"
}, {
	"id": "Zaragoza",
	"nm": "Nigüella"
}, {
	"id": "Zaragoza",
	"nm": "Nombrevilla"
}, {
	"id": "Zaragoza",
	"nm": "Nonaspe"
}, {
	"id": "Zaragoza",
	"nm": "Novallas"
}, {
	"id": "Zaragoza",
	"nm": "Novillas"
}, {
	"id": "Zaragoza",
	"nm": "Nuévalos"
}, {
	"id": "Zaragoza",
	"nm": "Nuez de Ebro"
}, {
	"id": "Zaragoza",
	"nm": "Olvés"
}, {
	"id": "Zaragoza",
	"nm": "Orcajo"
}, {
	"id": "Zaragoza",
	"nm": "Orera"
}, {
	"id": "Zaragoza",
	"nm": "Orés"
}, {
	"id": "Zaragoza",
	"nm": "Oseja"
}, {
	"id": "Zaragoza",
	"nm": "Osera de Ebro"
}, {
	"id": "Zaragoza",
	"nm": "Paniza"
}, {
	"id": "Zaragoza",
	"nm": "Paracuellos de Jiloca"
}, {
	"id": "Zaragoza",
	"nm": "Paracuellos de la Ribera"
}, {
	"id": "Zaragoza",
	"nm": "Pastriz"
}, {
	"id": "Zaragoza",
	"nm": "Pedrola"
}, {
	"id": "Zaragoza",
	"nm": "Pedrosas, Las"
}, {
	"id": "Zaragoza",
	"nm": "Perdiguera"
}, {
	"id": "Zaragoza",
	"nm": "Piedratajada"
}, {
	"id": "Zaragoza",
	"nm": "Pina de Ebro"
}, {
	"id": "Zaragoza",
	"nm": "Pinseque"
}, {
	"id": "Zaragoza",
	"nm": "Pintanos, Los"
}, {
	"id": "Zaragoza",
	"nm": "Plasencia de Jalón"
}, {
	"id": "Zaragoza",
	"nm": "Pleitas"
}, {
	"id": "Zaragoza",
	"nm": "Plenas"
}, {
	"id": "Zaragoza",
	"nm": "Pomer"
}, {
	"id": "Zaragoza",
	"nm": "Pozuel de Ariza"
}, {
	"id": "Zaragoza",
	"nm": "Pozuelo de Aragón"
}, {
	"id": "Zaragoza",
	"nm": "Pradilla de Ebro"
}, {
	"id": "Zaragoza",
	"nm": "Puebla de Albortón"
}, {
	"id": "Zaragoza",
	"nm": "Puebla de Alfindén, La"
}, {
	"id": "Zaragoza",
	"nm": "Puendeluna"
}, {
	"id": "Zaragoza",
	"nm": "Purujosa"
}, {
	"id": "Zaragoza",
	"nm": "Quinto"
}, {
	"id": "Zaragoza",
	"nm": "Remolinos"
}, {
	"id": "Zaragoza",
	"nm": "Retascón"
}, {
	"id": "Zaragoza",
	"nm": "Ricla"
}, {
	"id": "Zaragoza",
	"nm": "Romanos"
}, {
	"id": "Zaragoza",
	"nm": "Rueda de Jalón"
}, {
	"id": "Zaragoza",
	"nm": "Ruesca"
}, {
	"id": "Zaragoza",
	"nm": "Sabiñán"
}, {
	"id": "Zaragoza",
	"nm": "Sádaba"
}, {
	"id": "Zaragoza",
	"nm": "Salillas de Jalón"
}, {
	"id": "Zaragoza",
	"nm": "Salvatierra de Esca"
}, {
	"id": "Zaragoza",
	"nm": "Samper del Salz"
}, {
	"id": "Zaragoza",
	"nm": "San Martín de la Virgen de Moncayo"
}, {
	"id": "Zaragoza",
	"nm": "San Mateo de Gállego"
}, {
	"id": "Zaragoza",
	"nm": "Santa Cruz de Grío"
}, {
	"id": "Zaragoza",
	"nm": "Santa Cruz de Moncayo"
}, {
	"id": "Zaragoza",
	"nm": "Santa Eulalia de Gállego"
}, {
	"id": "Zaragoza",
	"nm": "Santed"
}, {
	"id": "Zaragoza",
	"nm": "Sástago"
}, {
	"id": "Zaragoza",
	"nm": "Sediles"
}, {
	"id": "Zaragoza",
	"nm": "Sestrica"
}, {
	"id": "Zaragoza",
	"nm": "Sierra de Luna"
}, {
	"id": "Zaragoza",
	"nm": "Sigüés"
}, {
	"id": "Zaragoza",
	"nm": "Sisamón"
}, {
	"id": "Zaragoza",
	"nm": "Sobradiel"
}, {
	"id": "Zaragoza",
	"nm": "Sos del Rey Católico"
}, {
	"id": "Zaragoza",
	"nm": "Tabuenca"
}, {
	"id": "Zaragoza",
	"nm": "Talamantes"
}, {
	"id": "Zaragoza",
	"nm": "Tarazona"
}, {
	"id": "Zaragoza",
	"nm": "Tauste"
}, {
	"id": "Zaragoza",
	"nm": "Terrer"
}, {
	"id": "Zaragoza",
	"nm": "Tierga"
}, {
	"id": "Zaragoza",
	"nm": "Tobed"
}, {
	"id": "Zaragoza",
	"nm": "Torralba de los Frailes"
}, {
	"id": "Zaragoza",
	"nm": "Torralba de Ribota"
}, {
	"id": "Zaragoza",
	"nm": "Torralbilla"
}, {
	"id": "Zaragoza",
	"nm": "Torrehermosa"
}, {
	"id": "Zaragoza",
	"nm": "Torrelapaja"
}, {
	"id": "Zaragoza",
	"nm": "Torrellas"
}, {
	"id": "Zaragoza",
	"nm": "Torres de Berrellén"
}, {
	"id": "Zaragoza",
	"nm": "Torrijo de la Cañada"
}, {
	"id": "Zaragoza",
	"nm": "Tosos"
}, {
	"id": "Zaragoza",
	"nm": "Trasmoz"
}, {
	"id": "Zaragoza",
	"nm": "Trasobares"
}, {
	"id": "Zaragoza",
	"nm": "Uncastillo"
}, {
	"id": "Zaragoza",
	"nm": "Undués de Lerda"
}, {
	"id": "Zaragoza",
	"nm": "Urrea de Jalón"
}, {
	"id": "Zaragoza",
	"nm": "Urriés"
}, {
	"id": "Zaragoza",
	"nm": "Used"
}, {
	"id": "Zaragoza",
	"nm": "Utebo"
}, {
	"id": "Zaragoza",
	"nm": "Val de San Martín"
}, {
	"id": "Zaragoza",
	"nm": "Valdehorna"
}, {
	"id": "Zaragoza",
	"nm": "Valmadrid"
}, {
	"id": "Zaragoza",
	"nm": "Valpalmas"
}, {
	"id": "Zaragoza",
	"nm": "Valtorres"
}, {
	"id": "Zaragoza",
	"nm": "Velilla de Ebro"
}, {
	"id": "Zaragoza",
	"nm": "Velilla de Jiloca"
}, {
	"id": "Zaragoza",
	"nm": "Vera de Moncayo"
}, {
	"id": "Zaragoza",
	"nm": "Vierlas"
}, {
	"id": "Zaragoza",
	"nm": "Villadoz"
}, {
	"id": "Zaragoza",
	"nm": "Villafeliche"
}, {
	"id": "Zaragoza",
	"nm": "Villafranca de Ebro"
}, {
	"id": "Zaragoza",
	"nm": "Villalba de Perejil"
}, {
	"id": "Zaragoza",
	"nm": "Villalengua"
}, {
	"id": "Zaragoza",
	"nm": "Villamayor de Gállego"
}, {
	"id": "Zaragoza",
	"nm": "Villanueva de Gállego"
}, {
	"id": "Zaragoza",
	"nm": "Villanueva de Huerva"
}, {
	"id": "Zaragoza",
	"nm": "Villanueva de Jiloca"
}, {
	"id": "Zaragoza",
	"nm": "Villar de los Navarros"
}, {
	"id": "Zaragoza",
	"nm": "Villarreal de Huerva"
}, {
	"id": "Zaragoza",
	"nm": "Villarroya de la Sierra"
}, {
	"id": "Zaragoza",
	"nm": "Villarroya del Campo"
}, {
	"id": "Zaragoza",
	"nm": "Vilueña, La"
}, {
	"id": "Zaragoza",
	"nm": "Vistabella"
}, {
	"id": "Zaragoza",
	"nm": "Zaida, La"
}, {
	"id": "Zaragoza",
	"nm": "Zaragoza"
}, {
	"id": "Zaragoza",
	"nm": "Zuera"
}, {
	"id": "Ceuta",
	"nm": "Ceuta"
}, {
	"id": "Melilla",
	"nm": "Melilla"
}]
