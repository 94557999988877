// src/pages/TeacherProfilePage.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../helpers/apiHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <FontAwesomeIcon
        key={i}
        icon={faStar}
        className={i <= rating ? 'text-yellow-400' : 'text-gray-300'}
      />
    );
  }
  return stars;
};

function TeacherProfilePage() {
  const { id } = useParams();
  const [teacher, setTeacher] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeacher = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await get(`users/teachers/${id}`);
        setTeacher(response);
      } catch (error) {
        console.error('Failed to fetch teacher:', error);
        setError('Failed to fetch teacher. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTeacher();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white">
        <p className="text-lg">Cargando perfil del profesor...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white">
        <p className="text-lg">{error}</p>
      </div>
    );
  }

  if (!teacher) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white">
        <p className="text-lg">No se encontró el perfil del profesor.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white p-10">
      <div className="bg-white p-6 rounded-lg shadow-lg text-gray-800">
        <div className="flex items-center mb-4">
          {teacher.user.profileImage ? (
            <img src={teacher.user.profileImage} alt="Avatar" className="w-16 h-16 rounded-full flex-shrink-0" />
          ) : (
            <div className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0"></div>
          )}
          <div className="ml-4">
            <h2 className="text-2xl font-bold">{teacher.user.name}</h2>
          </div>
        </div>
        <p className="mb-2 text-gray-700">{teacher.bio}</p>
        <p className="mb-2 text-gray-700">
          <span className="font-semibold">Tarifa por hora:</span> €{teacher.hourly_rate}
        </p>
        <p className="mb-2 text-gray-700">
          <span className="font-semibold">Rating:</span> {renderStars(teacher.rating)} ({teacher.reviews_count})
        </p>
        {teacher.subjects && (
          <p className="mb-2 text-gray-700">
            <span className="font-semibold">Materias:</span> {teacher.subjects.map((subject) => subject.name).join(', ')}
          </p>
        )}
        <p className="mb-2 text-gray-700">
          <span className="font-semibold">Ubicaciones:</span> {teacher.availability}
        </p>
        <div className="mt-6">
          <h3 className="text-xl font-bold">Reseñas:</h3>
          {teacher.reviews && teacher.reviews.length > 0 ? (
            teacher.reviews.map((review) => (
              <div key={review.id} className="bg-gray-100 p-4 rounded-lg shadow mt-4">
                <div className="flex items-center">
                  <div className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"></div>
                  <div className="ml-4">
                    <p className="font-semibold">{review.student && review.student.name ? review.student.name : 'Usuario Desconocido'}</p>
                    <div>{renderStars(review.rating)}</div>
                  </div>
                </div>
                <p className="mt-2">{review.comment}</p>
              </div>
            ))
          ) : (
            <p>No hay reseñas disponibles.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default TeacherProfilePage;
