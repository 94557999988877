import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { post } from '../helpers/apiHelper';

function EmailVerification() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [verificationMessage, setVerificationMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await post('auth/verify-email', { token }); // Envía el token como parte del cuerpo de la solicitud
                setVerificationMessage(response.message);
                Swal.fire('Success', response.message, 'success');
                navigate('/');
            } catch (error) {
                console.error('Email verification failed:', error);
                Swal.fire('Error', 'Email verification failed', 'error');
            }
        };

        verifyEmail();
    }, [token]);

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold">
                        Email Verification
                    </h2>
                </div>
                <div className="text-center">{verificationMessage}</div>
            </div>
        </div>
    );
}

export default EmailVerification;
